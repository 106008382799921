import { Component, OnInit, Input, EventEmitter, Output, NgZone, OnDestroy } from '@angular/core';
import { UserNode, TreeNode, RespMessage } from '../../../core/model/data-model';
import { FolderTreeService } from '../../../core/service/folder-tree.service';
import { BaseComponent } from '../base/base.component';
import { dataEncode, dataDecode, showInfo, MessageType } from '../../common';
import { UiService } from 'app/core/service';

@Component({
  selector: 'doc-tree-view',
  templateUrl: './tree-view.component.html',
  styleUrls: ['./tree-view.component.css']
})
export class TreeViewComponent extends BaseComponent implements OnInit, OnDestroy {

  @Input() folderId = 0;
  @Input() folderType = '';
  @Input() nodeName = '';
  @Input() routerPath = '';
  @Input() curNode = {} as TreeNode;
  @Output() onPickTreeNode = new EventEmitter<TreeNode>();
  childrenNode: TreeNode[];
  constructor(private folderTreeService: FolderTreeService, private uiService: UiService, private zone: NgZone) {
    super();
  }

  ngOnInit() {

  }

  getTreeNodes(curNode: TreeNode, folderType) {
    this.folderTreeService.getTreeNodes({ folderId: curNode.node_id, folderType: folderType, curNode: curNode, deptId: curNode.dept_id })
      .subscribe((resp: RespMessage<TreeNode>) => {
        if (resp.state === 'Ok') {
          this.childrenNode = curNode.childNode;
          // console.log('TreeViewComponent:childrenNode', this.childrenNode);
        } else {
          showInfo(resp.message || 'warning', 3000, MessageType.warning);
        }
      },
        error => {
          console.error('folderTreeService.getTreeNodes ::', JSON.stringify(error));
        });
  }

  pickTreeNode(curNode: TreeNode, event: Event) {
    this.folderTreeService.currentNavTreeNode = curNode;
    this.onPickTreeNode.emit(curNode);
    if (event.target['tagName'] === 'A') {
      this.uiService.closeNav();
    }
  }


  ngOnDestroy() {
    this.folderTreeService.removeOpenTreeView(this);
    console.log('TreeViewComponent.....ngOnDestroy');
  }

}
