import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Env } from '../model/data-model';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class EnvService extends BaseService {

  public env$: Observable<Env>;

  constructor(private http: HttpClient) {
    super();
    this.env$ = this.http.get<Env>(`./assets/env/env.json?d=${new Date().getTime().toString()}`);
  }

  checkReload() {
    // environment
    this.env$.subscribe(
      (env: Env) => {
        // build date: 2018-09-18 15:30
        console.info('build date:', environment.buildDate, env.buildDate);
        if (env.buildDate !== environment.buildDate) {
          location.reload(true);
        }
      },
      error => console.error('checkReload', error)
    );
  }


}
