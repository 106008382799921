<div class="search">
  <div class="input-wrap">
    <a class="mdi mdi-magnify search-btn"  (click)="doQuery()"></a>
    <input type="text" #qryDoc placeholder="{{ 'Search' | translate }}" name="qryDoc" [(ngModel)]="lookup" >
    <a class="mdi mdi-menu-down search-filter show-pop" (click)="openQuery(refForm)"></a>
  </div><em class="show-pop" show-pop="show-search-example">搜尋範例</em>
  <div class="popbg-fill va-center show-search-filter">
    <div class="pop-box pop-size-600">
      <div class="modal-content">
        <div class="modal-header">
          <button class="close"  (click)="resetAll(refForm)">
            <i class="mdi mdi-close"></i>
          </button>
          <h4>{{ "Search Conditions" | translate }}</h4>
        </div>
        <div class="modal-body">
          <form #refForm="ngForm">
            <dl class="margin-b">
              <dt>{{ "File Path" | translate }}</dt>
              <dd>
                <label class="marg20-r">
                  <input type="radio" name="queryType" (click)="queryType=''" [checked]="queryType==''">{{ "Global Search" | translate }}
                </label>
                <label class="marg20-r">
                  <input type="radio" name="queryType" (click)="getBreadcrumbs()" [checked]="queryType=='breadcrumbs'">{{ "Current Path" | translate }}
                </label>
                <label class="marg10-r">
                  <input type="radio" name="queryType" [checked]="queryType=='pick_tree_node'">
                </label>
                <a class="btn btn-xs btn-light show-pop" href="javascript:void(0)" show-pop="show-nav-tree">{{ "Selected Path" | translate }} </a>
                {{pickNode}}
              </dd>
              <dt>{{ "Name" | translate }}</dt>
              <dd>
                <input class="form-control" ngModel name="documentName" [(ngModel)]="documentName" type="text">
              </dd>
              <dt>{{ "Date" | translate }}</dt>
              <dd class="input-group">
                <input class="form-control datetimepicker" ngModel name="createBeginDate"  type="text" #createBeginDate>
                <div class="input-group-addon">to </div>
                <input class="form-control datetimepicker" ngModel name="createEndDate"  type="text" #createEndDate>
              </dd>
              <dt>{{ "User" | translate }}</dt>
              <dd>
                <input class="form-control" ngModel name="author" [(ngModel)]="author" type="text">
              </dd>
              <dt>{{ "Description1" | translate }}</dt>
              <dd>
                <input class="form-control" ngModel name="description" [(ngModel)]="description" type="text">
              </dd>
              <dt>{{ "Keyword" | translate }}</dt>
              <dd>
                <input class="form-control" ngModel name="keyword" [(ngModel)]="keyword" type="text">
              </dd>
              <dt>{{ "Text Content" | translate }}</dt>
              <dd>
                <input class="form-control" ngModel name="content" [(ngModel)]="content" type="text">
              </dd>
            </dl>
          </form>
        </div>

        <div class="modal-footer center">
          <a class="btn btn-sm btn-dark close-btn marg6-r" (click)="resetAll(refForm)">
            <i class="mdi mdi-reply"></i>{{ "Cancel" | translate }}</a>
          <a class="btn btn-sm btn-info marg6-r" (click)="resetAll(refForm)">
            <i class="mdi mdi-rotate-3d"></i>{{ "Reset" | translate }}</a>
          <a class="btn btn-sm btn-primary" (click)="query(refForm)">
            <i class="mdi mdi-magnify"></i>{{ "Search" | translate }} </a>
        </div>
      </div>
    </div>
  </div>
   <div class="popbg-fill show-search-example va-center">
      <div class="pop-box pop-size-700">
        <div class="modal-content">
          <div class="modal-header">
            <button class="close"><i class="mdi mdi-close"></i></button>
            <h4>搜尋範例</h4><h5 style="color: red;">放大鏡搜尋的資料夾名稱及檔案名稱預設為模糊查詢，因此不適用此範例</h5>
          </div>
          <div class="modal-body pad10">
            <table class="table table-striped table-dark-head">
              <tbody>
                <tr>
                  <th>範例</th>
                  <th>說明</th>
                </tr>
                <tr>
                  <td>aaa and bbb and ccc</td>
                  <td>包含"aaa"且包含"bbb"且包含"ccc"</td>
                </tr>
                <tr>
                  <td>(aaa and bbb) or ccc</td>
                  <td>包含"aaa"且包含"bbb"，或包含"ccc"</td>
                </tr>
                <tr>
                  <td>"aaa and bbb" or ccc</td>
                  <td>包含"aaa and bbb" 或包含"ccc"<br>
                    <div class="gray">若想搜尋包含 " and、or、&、|、(、) "或空白等字元，請將該內容加雙引號。   </div>
                  </td>
                </tr>
                <tr>
                  <td>"aaa*"</td>
                  <td>包含"aaa"為前置詞<br>
                    <div class="gray">若想使用前置詞或後置詞搜尋，請使用"*"，並將內容加雙引號。   </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="modal-footer center"> <a class="btn btn-sm btn-dark close-btn marg6-r" href="#"> <i class="mdi mdi-close"></i>關閉</a></div>
        </div>
      </div>
    </div>
</div>
<doc-pick-tree #pickTree (onSelected)="getPickTreeNode($event)"></doc-pick-tree>
