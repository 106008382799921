import { Component, OnInit, ViewChild, Input, OnDestroy } from '@angular/core';
import { RespMessage, DocumentContent, NotificationDto, DocumentFolder, DocumentDto, DataRow } from 'app/core/model/data-model';
import { loadingHide, popbox, showInfo, MessageType, RouteMap, showConfirm, loadingShow } from 'app/shared/common';
import { DocumentViewComponent } from 'app/folder';
import { DocumentService, NotificationService, FolderService } from '../../core/service';
import { Router } from '@angular/router';
import { BaseComponent, PickFavoriteFolderComponent } from 'app/shared/component';

@Component({
  selector: 'doc-bell',
  templateUrl: './bell.component.html',
  styleUrls: ['./bell.component.css']
})
export class BellComponent extends BaseComponent implements OnInit, OnDestroy {

  readonly popFavCss = 'pop-fav-from-bell';
  readonly popDocCss = 'pop-doc-from-bell';
  readonly popDownloadCss = 'pop-download-from-bell';
  timer: any;
  @Input() notificationDtos: NotificationDto[] = [];
  @ViewChild('documentView', { static: false }) documentViewComponent: DocumentViewComponent;

  constructor(private documentService: DocumentService, private notificationService: NotificationService,
    private folderService: FolderService, private router: Router) {
    super();
  }

  ngOnInit() {
    this.queryUnread();
    this.timer = setInterval(() => this.queryUnread(), 60000); // 1 min
  }

  openNotification(dto: NotificationDto) {
    if (dto.isFolder) {
      const url = RouteMap[dto.folderType];
      this.router.navigate([url, { data: this.dataEncode({ folderId: dto.folderId }) }]);
    } else {
      this.openDoc(dto);
    }
    this.readOne(dto);
  }

  openDoc(dto: NotificationDto) {
    this.documentService.openDoc({ folderId: dto.folderId, folderType: dto.folderType, docId: dto.docId })
      .subscribe((resp: RespMessage) => {
        loadingHide();
        if (resp.state === 'Ok') {
          const documentContent: DocumentContent = resp.result;
          this.documentViewComponent.show(documentContent);
          popbox(this.popDocCss);
        } else {
          showInfo(resp.message, 2000, MessageType.error);
        }
      }, error => {
        loadingHide();
        showInfo(error.message || 'error', 3000, MessageType.error);
        console.error('documentService.openDoc ::', JSON.stringify(error));
      });
  }

  queryUnread() {
    const dateTime = this.notificationDtos[0] && this.notificationDtos[0].createTime;
    this.notificationService.queryUnread({ dateTime: dateTime })
      .subscribe((resp: RespMessage<NotificationDto[]>) => {
        if (resp.state === 'Ok') {
          this.notificationDtos = resp.result.concat(this.notificationDtos.concat());
        } else {
          console.warn('notificationService.queryUnread ::', resp.message);
        }
      }, error => {
        console.error('notificationService.queryUnread ::', JSON.stringify(error));
      });
  }

  readOne(dto: NotificationDto) {
    const idx = this.notificationDtos.indexOf(dto);
    if (idx !== -1) {
      this.read([dto.notifId]);
      this.notificationDtos.splice(idx, 1);
    }
  }

  readAll() {
    const notifIds = this.notificationDtos.splice(0).map(dto => dto.notifId);
    this.read(notifIds);
  }

  private read(notifIds: number[]) {
    this.notificationService.read(notifIds)
      .subscribe((resp: RespMessage<boolean>) => {
        if (resp.state === 'Ok' && resp.result === true) {
          // nothing
        } else {
          showInfo(resp.message, 2000, MessageType.error);
        }
      }, error => {
        console.error('notificationService.read ::', JSON.stringify(error));
      });
  }

  showAddfav(data: DataRow, pickFavoriteFolder: PickFavoriteFolderComponent) {
    const doc = data.data as DocumentDto;
    if (doc.inFavorite) {
      this.delfav(data);
    } else {
      pickFavoriteFolder.documentDto = doc;
      pickFavoriteFolder.show();
    }
  }

  onAddfav(doc: DocumentDto, pickFavoriteFolder: PickFavoriteFolderComponent) {
    const data = { type: 'doc', name: doc.documentName, readCount: doc.readCount, modifyTime: doc.modifyTime, data: doc };
    this.showAddfav(data, pickFavoriteFolder);
  }

  delfav(data: DataRow) {
    const doc = data.data as DocumentDto;
    showConfirm(`移除收藏`, `您確定要刪除${doc.documentName}?`, MessageType.question)
      .then(
        result => {
          if (result.value) {
            loadingShow();
            this.folderService.deleteFavoriteByDocId({ docId: doc.documentId })
              .subscribe((resp: RespMessage) => {
                loadingHide();
                if (resp.state === 'Ok') {
                  doc.inFavorite = false;
                  showInfo('Success', 2000, MessageType.success);
                } else {
                  showInfo(resp.message, 2000, MessageType.error);
                }
              }, error => {
                loadingHide();
                showInfo(error.message || 'error', 3000, MessageType.error);
                console.error('folderService.deleteFavoriteByDocId ::', JSON.stringify(error));
              });
          }
        },
        reject => { }
      );
  }

  getResourceType(dto: NotificationDto): String {
    if (dto.isFolder) {
      return 'Folder';
    } else if (dto.isArchive) {
      return 'File';
    } else {
      return 'Document';
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.timer);
  }

}
