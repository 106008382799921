import { Component, OnInit, Input } from '@angular/core';
import { FolderType, FolderTypeName, RouteMap } from '../../common';
import { FolderHierarchy, BreadcrumbsDto } from '../../../core/model/data-model';
import { BreadcrumbsService } from '../../../core/service/breadcrumbs.service';
import { FolderTreeService } from '../../../core/service/folder-tree.service';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'doc-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.css']
})
export class BreadcrumbsComponent extends BaseComponent implements OnInit {

  @Input() folderType: any;
  @Input() folderTypeName: any;
  @Input() folderHierarchy: FolderHierarchy[];
  @Input() viewType = 'system'; // ||  content || cap
  hideHeader = 'false';
  constructor(private breadcrumbsService: BreadcrumbsService, private folderTreeService: FolderTreeService) {
    super();
  }

  ngOnInit() {
    this.hideHeader = sessionStorage.getItem('contentOnly') || 'false';
  }

  updateBreadcrumbsInfo(breadcrumbs: BreadcrumbsDto) {
    this.folderType = breadcrumbs.folderType;
    this.folderTypeName = breadcrumbs.folderTypeName;
    this.folderHierarchy = breadcrumbs.folderHierarchy;
    // console.log('updateBreadcrumbsInfo folderType', this.folderType);
    // console.log('updateBreadcrumbsInfo folderTypeName', this.folderTypeName);
    // console.log('updateBreadcrumbsInfo folderHierarchy', this.folderHierarchy);

    if (this.folderHierarchy != null && this.folderHierarchy.length > 0) {
      this.folderHierarchy = this.folderHierarchy.sort(
        (a, b) => a.seq > b.seq ? -1 : 1
      );
      breadcrumbs.folderId = this.folderHierarchy[this.folderHierarchy.length - 1].folder_id;
    }
    // const breadcrumbs: BreadcrumbsDto = { folderId: 0, folderType: this.folderType, folderTypeName: this.folderTypeName, folderHierarchy: this.folderHierarchy };
    // console.log('BreadcrumbsComponent breadcrumbs', breadcrumbs);
    this.breadcrumbsService.currentBreadcrumbs = { ...breadcrumbs };
  }

  getRouterMap(): string {
    const url = RouteMap[this.folderType];
    // console.log(' getRouterMap() ', this.folderType, url);
    return url;
  }

}
