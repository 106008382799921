import { TreeNode, User } from './../../core/model/data-model';
import { FolderTypeName, showConfirm, RouteMap, popbox, dataEncode, dataDecode } from './../../shared/common';
import { Component, OnInit, OnDestroy, NgZone, ViewChild, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FolderTreeService } from '../../core/service/folder-tree.service';
import { showInfo, loadingHide, MessageType, loadingShow, FolderType, isMobile} from '../../shared/common';
import { RespMessage, FolderContent, DocumentFolder, DocumentDto, DataRow } from '../../core/model/data-model';
import { DocumentService } from '../../core/service/document.service';
import { PagedListComponent } from '../../shared/component/paged-list/paged-list.component';
import { FolderService } from '../../core/service/folder.service';
import { PickFavoriteFolderComponent } from '../../shared/component/pick-favorite-folder/pick-favorite-folder.component';
import { BaseFolderComponent } from '../base-folder/base-folder.component';
import { TreeViewComponent } from '../../shared/component/tree-view/tree-view.component';
import { FolderCanWriteTreeService } from '../../core/service/folder-can-write-tree.service';
import { BreadcrumbsComponent } from '../../shared/component/breadcrumbs/breadcrumbs.component';
import { DocumentContextmenuService } from '../../core/service/document-contextmenu.service';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DownloadService } from 'app/core/service';
import { FolderDocumentService } from 'app/core/service/folder-document.service';


@Component({
  selector: 'doc-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.css']
})
export class SearchResultComponent extends BaseFolderComponent implements OnInit, OnDestroy {
  childFolders: DocumentFolder[];
  documents: DocumentDto[];
  private search$UnSubscribe: Subject<any> = new Subject<any>();
  @ViewChild('headerPagedList', { static: true }) headerPagedList: PagedListComponent;
  showContextMenu = false;
  contextMenuX = 0;
  contextMenuY = 0;

  constructor(protected folderTreeService: FolderTreeService, protected folderCanWriteTreeService: FolderCanWriteTreeService
    , protected documentService: DocumentService, protected folderService: FolderService
    , protected folderDocumentService: FolderDocumentService, protected downloadService: DownloadService
    , protected contextmenuService: DocumentContextmenuService
    , protected router: Router, protected route: ActivatedRoute, protected zone: NgZone) {
    super(folderTreeService, folderCanWriteTreeService, folderService, documentService, folderDocumentService, downloadService, contextmenuService, router);
    this.folderId = 0;
    this.folderType = FolderType.PRIVATE_FOLDER;
    this.folderTypeName = FolderTypeName.PRI;
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.pageNum = 1;
      this.sortBy = '';
      this.sortType = '';
      this.sortByCols = {
        'typeCss': '',
        'name': '',
        'modifyTime': '',
        'readCount': '',
      };
      // prevent duplicate subscribe
      this.search$UnSubscribe.next();
      this.documentService.searchResult$
        .pipe(takeUntil(this.search$UnSubscribe))
        .subscribe((resp: RespMessage<FolderContent>) => {
          if (resp && resp.state === 'Ok') {
            this.dataCount = resp.dataCount;
            this.updatePageList();
            this.resultList = [];
            this.folderContent = resp.result;
            this.folderContent.childFolders = this.folderContent.childFolders || [];
            this.folderContent.documents = this.folderContent.documents || [];
            this.folderContent.childFolders.map(
              fol => this.resultList.push(this.getFolderDataRow(fol))
            );
            this.folderContent.documents.map(
              doc => this.resultList.push(this.getDocDataRow(doc))
            );
            this.resultList.forEach(row => row.typeCss = this.getTypeCss(row.data));
            this.pageList = this.resultList;
          }
        },
        error => {
          showInfo(error.message || 'error', 3000, MessageType.error);
          console.error('documentService.searchResult$::', JSON.stringify(error));
        });
    });
  }

  query(sortBy, sortType, pageSize, pageNum) {
    this.documentService.search(this.documentService.queryVo, sortBy, sortType, pageSize, pageNum);
  }

  action(data: DataRow) {
    if (data.type === 'folder') {
      const folder = data.data as DocumentFolder;
      var url = '/#' + RouteMap[folder.folderType];
      // this.router.navigate([url, { data: this.dataEncode({ folderId: folder.folderId }) }]);
      if (location.hostname === 'localhost') {
        url = 'http://localhost:4200' + url;
      }
      url += ';data=' + this.dataEncode({ folderId: folder.folderId })

      window.open(url, '_blank');
      window.focus();
    } else {
      const doc = data.data as DocumentDto;
      this.openDoc(doc);
    }
  }

  ngOnDestroy() {
    this.search$UnSubscribe.next();
    this.search$UnSubscribe.complete();
    console.log('SearchResultComponent.....ngOnDestroy');
  }

}
