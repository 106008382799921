import { Component, OnInit } from '@angular/core';
import { PagedListComponent } from '../paged-list/paged-list.component';

@Component({
  selector: 'doc-modal-paged-list',
  templateUrl: './modal-paged-list.component.html',
  styleUrls: ['./modal-paged-list.component.css']
})
export class ModalPagedListComponent extends PagedListComponent implements OnInit {

  constructor() {
    super();
  }

  /// <summary>
  /// 處理分頁使用
  /// </summary>
  getPageArray(pageCount: Number): Number[] {
    return new Array(pageCount);
  }

}
