import { HttpHeaders, HttpParams } from '@angular/common/http';
import { FolderType } from 'app/shared/common';

export interface HttpOptions {
  headers?: HttpHeaders | {
    [header: string]: string | string[];
  };
  observe?: 'body';
  params?: HttpParams | {
    [param: string]: string | string[];
  };
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
}

export class RespMessage<T = any> {
  /// <summary>
  /// 每頁幾筆
  /// </summary>
  pageSize?: number;
  /// <summary>
  /// Result 共幾筆資料
  /// </summary>
  dataCount?: number;
  /// <summary>
  /// 第幾頁資料
  /// </summary>
  pageNum?: number;
  /// <summary>
  /// 共幾頁
  /// </summary>
  pageCount?: number;
  result: T;
  state: string;
  message: string;
  user?: User;
}

export interface Validator {
  validate(): ValidationResult;
}
export interface ValidationResult {
  isValid: boolean;
  message?: string;
}

export interface JwtAuthObject {
  type: string;
  expires: Date;
  token: string;
  user: User;
}
// export interface User {
//   userId: number;
//   userName: string;
//   userEngName: string;
//   workId: string;
//   email: string;
// }

export interface User {
  userId: number;
  userName: string;
  userEngName: string;
  password?: any;
  mD5Password?: any;
  email?: any;
  birthday?: any;
  homeTel?: any;
  mobile?: any;
  extension?: any;
  address?: any;
  deptSerial?: any;
  deptName?: any;
  photo?: any;
  agentSerial?: any;
  mnSerial?: any;
  active: number;
  authentication: number;
  domain?: any;
  workId?: any;
  staffId: number;
  staffName?: any;
  startDate?: any;
  endDate?: any;
  company: number;
  type?: any;
  validDate?: any;
  permanent?: any;
  syncTV?: any;
  tVId: number;
  updateTime: Date;
  checked?: boolean;
  guid?: string;
}

export interface EipNews {
  display?: any;
  url: string;
  title: string;
  Created: string;
}

export interface NewestDocumentDto {
  eipNewsList: EipNews[];
  newestDocumentList: DocumentDto[];
}

export interface TreeNode {
  node_id: number;
  node_name: string;
  type: string;
  have_child: string;
  parentNode?: TreeNode;
  childNode?: TreeNode[];
  folderTyp?: string;
  dept_id?: string;
  canNewDoc: boolean;
}

export interface UserNode {
  folderId: number;
  myDocumentsSize?: number;
  private_treenode_root: TreeNode;
  public_treenode_root: TreeNode;
  dept_treenode_root: TreeNode;
  system_treenode_root: TreeNode;
  shareWithMe_treenode_root: TreeNode;
  recentAccess_treenode_root: TreeNode;
  favorite_treenode_root: TreeNode;
}


export interface FavoriteDocument {
  favoriteId: number;
  documentId: number;
  user: User;
  document: DocumentDto;
  folder?: DocumentFolder;
  addFrom: string;
}

export interface FolderPermission {
  permissionId: number;
  folder: DocumentFolder;
  userId: number;
  groupId: number;
  readPermission: string;
  writePermission: string;
  adminPermission: string;
  readAllPermission: string;
}

export interface DocumentPermission {
  permissionId: number;
  document: DocumentDto;
  userId: number;
  groupId: number;
  documentId: number; // for query only
  type: string;
  readPermission: string;
  writePermission: string;
  adminPermission: string;
  isPermanent: string;
  validStartDate?: Date;
  validEndDate?: Date;
  reason: string;
  ifSent: string;
}

export interface DocumentFolder {
  folderId: number;
  folderName: string;
  folderType: string;
  owner: User;
  parentFolder: DocumentFolder;
  departmentId: string;
  createTime: Date;
  modifyTime: Date;
  isSystemFolder: string;
  systemFolderKey: string;
  childFolders: DocumentFolder[];
  documents: DocumentDto[];
  pubDocuments: DocumentDto[];
  favoriteDocuments: FavoriteDocument[];
  folderPermissions: FolderPermission[];
  idHierarchy: string[];
  nameHierarchy: string[];
  canWrite: boolean;
  canAdmin: boolean;
  haveChild: string;
  folderHier?: string;
  vFolderType?: string;
}

export interface FolderHierarchy {
  folder_id: number;
  folder_name: string;
  seq: number;
  deptId?: string;
}

export interface DocumentDto {
  documentId: number;
  documentName: string;
  documentContent: string;
  status: string;
  keyword: string;
  description: string;
  isHeadline: string;
  delflg: string;
  link_support: string;
  folder: DocumentFolder;
  publishFolder: DocumentFolder;
  departmentFolder: DocumentFolder;
  createUser: User;
  modifyUser: User;
  publishUser: User;
  departmentId: string;
  linkToDoc: string;
  project: string;
  location: string;
  language: string;
  productType: string;
  modelSeries: string;
  model: string;
  property: string;
  productTypeName: string;
  modelSeriesName: string;
  modelName: string;
  ecNo: string;
  ecVersion: string;
  folderHier: string;
  documentType: string;
  belongTo: string;
  createTime: Date;
  modifyTime: Date;
  publishTime: Date;
  readCount: number;
  hasFile: number;
  inFavorite: boolean;
  documentFiles: DocumentFile[];
  documentPermissions: DocumentPermission[];
  users: User[];
  favoriteDocuments: FavoriteDocument[];
  canWrite: boolean;
  canAdmin: boolean;
  isArchive: string;
  folderId?: number;
  typeCss?: string;
  vFolderType?: string;
}

export interface DocumentFile {
  fileId: number;
  documentId?: number;
  document?: DocumentDto;
  fileName: string;
  diskFileName?: string;
  serverPath?: string;
  eUServerPath?: string;
  originClientPath?: string;
  downloadCount: number;
  fileExtension: string;
  fileSize: number;
  status?: string;
  guid?: string;
  file?: File;
  exists?: boolean;
  actionUrl?: string;
}

export interface FolderContent {
  folderTyp?: string;
  folderId?: string;
  canNewFolder: boolean;
  canNewDoc: boolean;
  canWrite: boolean;
  canAdmin: boolean;
  cascadeRead?: string;
  cascadeWrite?: string;
  cascadeWriteAll?: string;
  cascadeAdmin?: string;
  cascadeReadAll?: string;
  newFolderLink: boolean;
  folder: DocumentFolder;
  favoriteDocuments: FavoriteDocument[];
  folderHierarchy: FolderHierarchy[];
  childFolders: DocumentFolder[];
  documents: DocumentDto[];
  customerAccountList?: any;
  sessionDocumentPermission: SessionDocumentPermission;
}

export interface DataRow {
  type: string;
  typeCss?: string;
  folderType?: string; //  DocumentDto.belongTo || DocumentFolder.folderType
  folderCss?: string;
  name: string;
  user?: User;
  modifyTime: Date;
  readCount: number;
  id?: number;
  isArchive?: boolean;
  isSelected?: boolean;
  data: DocumentFolder | DocumentDto;
}

export interface PermissionVo {
  id: number | string;
  name: string;
  checked?: boolean;
  users?: User;
  department?: DepartmentVo;
}

export interface DepartmentVo {
  serial: number;
  parentSerial: number;
  deptId: string;
  deptName: string;
  deptAlias: string;
  managerId: number;
  managerWorkId: string;
  level: number;
  companyCode: string;
  checked?: boolean;
}

export interface SessionDocumentPermission {
  readAllUsers: string;
  readAllGroups: string;
  readAllDepts: string;
  readUsers: string;
  readGroups: string;
  readDepts: string;
  writeUsers: string;
  writeGroups: string;
  writeDepts: string;
  adminUsers: string;
  adminGroups: string;
  adminDepts: string;

  readAllUsersVo: PermissionVo[];
  readAllGroupsVo: PermissionVo[];
  readAllDeptsVo: PermissionVo[];
  readUsersVo: PermissionVo[];
  readGroupsVo: PermissionVo[];
  readDeptsVo: PermissionVo[];
  writeUsersVo: PermissionVo[];
  writeGroupsVo: PermissionVo[];
  writeDeptsVo: PermissionVo[];
  adminUsersVo: PermissionVo[];
  adminGroupsVo: PermissionVo[];
  adminDeptsVo: PermissionVo[];
}

export interface DocumentContent {
  folderId: number;
  folderType?: string;
  docId?: number;
  folder?: any;
  document: DocumentDto;
  folderHierarchy?: FolderHierarchy[];
  documentFiles: DocumentFile[];
  documentPermissions?: DocumentPermission;
  customerAccountList?: any;
  sessionDocumentPermission: SessionDocumentPermission;
}


export interface Group {
  groupId: number;
  groupName: string;
  groupType: string;
  groupModule: string;
  notice: string;
  active: number;
  syncTV?: any;
  tVId: number;
  updateTime: Date;
  groupUsers?: User[];
  checked?: boolean;
}

export interface PickPermissionDto {
  groups: Group[];
  users: User[];
  departments?: DepartmentVo[];
}

export interface BreadcrumbsDto {
  folderId: number;
  folderType: string;
  folderTypeName: string;
  folderHierarchy: FolderHierarchy[];
}

export interface FolderFileSizeVM {
  current: number;
  limit: number;
}

export interface FolderLimitSize {
  type: string;
  limit: number; // GB
}

export interface Env {
  version: string;
  buildDate: string;
  releaseNotes: string;
}

export interface HitCountRecordDto {
  userId: number;
  userName: string;
  recordDate: string;
  ipAddress: string;
}

export interface DownloadCountRecordDto {
  userName: string;
  downloadDate: string;
  downloadFrom: string;
}

export interface NotificationDto {
  notifId: number;
  docId: number;
  folderId: number;
  folderType: string;
  documentName: string;
  folderName: string;
  sharedUserName: string;
  sharedUserEnglishName: string;
  isArchive?: boolean;
  isFolder: boolean;
  resourceType: string;
  createTime: Date;
}

export enum ResourceType {
  folder,
  document,
  file
}

export interface DocumentContextmenuDto {
  clientX: number;
  clientY: number;
  dataRow: DataRow;
}

export interface SearchVo {
  folderId: number;
  folderType: string;
  documentName: string;
  keyword: string;
  author: string;
  description: string;
  content: string;
  createBeginDate: string;
  createEndDate: string;
}

export interface SearchAnyVo {
  lookup: string;
}

export interface PickMulDocVo extends DocumentDto {
  isSelected: boolean;
}

export interface PickMulFolderVo extends DocumentFolder {
  isSelected: boolean;
  folderPath: string;
}

export interface PickMulFolderDropDownVo {
  optionVos: PickMulFolderOptionVo[];
  allVo: PickMulFolderOptionVo;
  selectedText: string;
}

export interface PickMulFolderOptionVo {
  folderIds: number[];
  folderName: string;
  selected: boolean;
}

export interface CapDocumentVo {
  documentId: number;
  documentName: string;
  belongTo: string;
  folderId: number;
  isArchive: boolean;
  modifyTime: string;
  fileGuid: string;
  typeCss?: string;
  modifyUserViewName?: string;
}

export interface CapFolderVo {
  folderId: number;
  parentFolderId: number;
  folderName: string;
  modifyTime: string;
  typeCss?: string;
  modifyUserViewName?: string;
}

export interface CapFolderContentVo {
  folders: CapFolderVo[];
  documents: CapDocumentVo[];
  folderHierarchy: FolderHierarchy[];
}

export interface CapDataRow {
  type: string;
  typeCss: string;
  name: string;
  data: CapFolderVo | CapDocumentVo;
}

export interface AccountSelectVo {
  // id: number | string;
  userId: number;
  groupId: number;
  deptId: string;
  name: string;
  checked: boolean;
  disabled: boolean;
  // users?: User;
  // groups?: Group;
  // department?: DepartmentVo;
}

export interface AccountSelectContent {
  users: AccountSelectVo[];
  groups: AccountSelectVo[];
  depts: AccountSelectVo[];
}

export interface PermissionVo1 {
  permissionId: number;
  userId: number;
  groupId: number;
  deptId: string;
  name: string;
  readOperation: PermissionOperation;
  readAllOperation: PermissionOperation;
  writeOperation: PermissionOperation;
  adminOperation: PermissionOperation;
  oldShowParent: boolean;
  showParent: boolean;
  fixShowParent: boolean;
}

export interface PermissionOperation {
  value: boolean;
  cascade: boolean;
  fixValue?: boolean;
  oldValue: boolean;
}

export interface PermissionContentVo {
  userPermissions: PermissionVo1[];
  groupPermissions: PermissionVo1[];
  deptPermissions: PermissionVo1[];
  userFixShowParentLookup?: { [key: number]: boolean};
  groupFixShowParentLookup?: { [key: number]: boolean};
  deptFixShowParentLookup?: { [key: string]: boolean};
}

export interface FolderContent2 {
  folderType?: string;
  folderId?: string;
  // canNewFolder: boolean;
  // canNewDoc: boolean;
  // canWrite: boolean;
  // canAdmin: boolean;
  // newFolderLink: boolean;
  folder: DocumentFolder;
  favoriteDocuments: FavoriteDocument[];
  folderHierarchy: FolderHierarchy[];
  childFolders: DocumentFolder[];
  documents: DocumentDto[];
  permissionContent: PermissionContentVo;
}

export interface DocumentContent2 {
  folderId: number;
  folderType?: string;
  docId?: number;
  folder?: any;
  document: DocumentDto;
  folderHierarchy?: FolderHierarchy[];
  documentFiles: DocumentFile[];
  permissionContent: PermissionContentVo;
}

export interface DeleteOnePermissionVo {
  permissionId: number;
  parentFolderId: number;
  folderId: number;
  docId: number;
  permissionType: 'read' | 'readAll' | 'write' | 'admin';
  cascade: boolean;
  // showParent: boolean;
  fixShowParent: boolean;
  oldShowParent: boolean;
}

export enum BatchOperation {
  none,
  download,
  delete
}

export interface DeleteResourceMailVo {
  hierachyNames: string[];
  folderIdNames: IdName[];
  documentIdNames: IdName[];
  archiveIdNames: IdName[];
}

export interface IdName {
  id: number;
  name: string;
}
