import { HttpParams } from '@angular/common/http';
import { DocumentFile } from 'app/core/model/data-model';

declare var $: any;
declare var swal: any;
declare var CKEDITOR: any;

export function initCommonJs() {
  // $(function() {

  // 選單中有子選單自動產出icon
  // const $mynav = $("nav ul > li > ul").parent("li").children("a");
  // $mynav.append(' <i class="mdi mdi-menu-right toggle-icon"></i>');

  // // DEMO 用
  // const $myshownav = $("nav ul.show").parent("li").children("a").children(".toggle-icon");
  // $myshownav.addClass("show-icon");

  // $(".tree .toggle-icon").on("click", function (e) {
  //   $(this).toggleClass("show-icon");
  //   $(this).parent("a").next("ul").toggleClass("show");
  //   e.preventDefault();
  // });

  $('.toggle-btn').on('click', function () {
    $(this).toggleClass('hidenext');
    $(this).parent().nextAll('.toggle-main').toggleClass('hidemain');
  });

  $('.toggle-panel-btn').on('click', function () {
    $(this).parents('section').toggleClass('hide-panel-body');
  });

  // tab change
  $('.tab-change li').on('click', function (e) {
    const show = $(this).index();
    $(this).addClass('focus').siblings('.focus').removeClass('focus');
    $(this).parents('.tab-change').next('.tab-change-warp').find('.tab-change-content').removeClass('show').eq(show).addClass('show');
    e.preventDefault();
    // bullHeight();
  });

  // show popbox
  // function popbox(show) {
  //   $(".popbg-fill." + show).addClass("display-block");
  //   $("." + show + " .pop-box").addClass("display-block");
  //   $("body").addClass("overflow-hidden");
  // }

  $('.show-pop').click(function (e) {
    popbox($(this).attr('show-pop'));
    e.preventDefault();
  });

  // close popbox
  $('.close, .close-btn').on('click', function (e) {
    $(this).parents('.popbg-fill').removeClass('display-block');
    $(this).parents('.pop-box').removeClass('display-block');
    if ($('.popbg-fill').is('.display-block') === false) {
      $('body').removeClass('overflow-hidden');
    }
    e.preventDefault();
  });

  // Save
  $('.btn-save').on('click', function (e) {
    $('.popbg-fill.pop-success').stop(false, true).fadeIn(400).delay(1500).fadeOut(400);
    e.preventDefault();
  });

  $('.pop-success').on('click', function (e) {
    $(this).hide();
  });

  // select
  $('.slect-list li.all, .slect-list tr.all').on('click', function () {
    if ($(this).is('.focus')) {
      $(this).nextAll('li, tr').removeClass('focus');
    } else {
      $(this).nextAll('li, tr').addClass('focus');
    }
  });

  $('.slect-list li,.slect-list tr').on('click', function (e) {
    $(this).toggleClass('focus');
    e.preventDefault();
  });

  // select  - li
  $('.select-list-checkbox li input[type=\'checkbox\']').on('click', function () {
    const mycheckbox = $(this).parents('li');

    if ($(this).is(':checked')) {
      mycheckbox.addClass('select');
    } else {
      mycheckbox.removeClass('select');
    }

  });

  $('.select-all input[type=\'checkbox\']').on('click', function () {
    // alert("test");
    const nextli = $(this).parents('.select-all').next('ul.select-list-checkbox').find('li');
    const nextcheckbox = nextli.find('input[type=\'checkbox\']');

    if ($(this).is(':checked')) {
      nextli.addClass('select');
      // nextcheckbox.checked;
      nextcheckbox.prop('checked', true);
    } else {
      nextli.removeClass('select');
      nextcheckbox.prop('checked', false);
    }

  });



  // 卷軸
  $('.scrollbar').mCustomScrollbar({
    axis: 'y',
    theme: 'dark'
  });

  // 日曆
  $('[id^=\'datetimepicker-day\']').datetimepicker({
    useCurrent: true,
    format: 'L'
  });

  // });


  // UI-resize
  //  console.log('initCommonJs..... UI-resize');
  $('.bar').mousedown(function (e) {

    let cancelMove = false;
    const $LeftContainer = $('.article .left');
    const $RightContainer = $('.article .right');

    $(this).addClass('active');
    $($RightContainer).addClass('select-none');
    cancelMove = false;


    $(document).mousemove(function (event) {
      if (cancelMove === false) {
        const $x = event.clientX + 'px';
        $LeftContainer.css('width', $x);
      }
    });

    $(document).mouseup(function (event) {
      const $New_x = $('.article .left').css('width');
      $LeftContainer.css('width', $New_x);
      $('.bar').removeClass('active');
      $($RightContainer).removeClass('select-none');
      cancelMove = true;
    });
  });

  $('.limited-block a.limited-control').click(function () {
    $(this).parents('.limited-block').toggleClass('limited-show');
  });
  console.log('initCommonJs.....');
}

export function alertMsg(msg) {
  alert(msg);
  // $('#idxMsg').text(msg);
  // // $('#commMsg')
  // $('.popbg-fill.pop-msg').stop(false, true).fadeIn(400).delay(1500).fadeOut(400);
}

// loading loading Hide
export function loadingShow(msg = '資料匯入中...') {
  $('.loading-bg-text').text = msg;
  $('.loading-bg').css('display', 'flex');
}

export function loadingHide() {
  $('.loading-bg').hide();
}

// show popbox
export function popbox(show: string) {
  $('.popbg-fill.' + show).addClass('display-block');
  $('.' + show + ' .pop-box').addClass('display-block');
  $('body').addClass('overflow-hidden');
}

export function unpopbox(show: string) {
  $('.popbg-fill.' + show).removeClass('display-block');
  $('.' + show + ' .pop-box').removeClass('display-block');
  if ($('.popbg-fill').is('.display-block') === false) {
    $('body').removeClass('overflow-hidden');
  }

}

export function closePopbox() {

  $(this).parents('.popbg-fill').removeClass('display-block');
  $(this).parents('.pop-box').removeClass('display-block');
  if ($('.popbg-fill').is('.display-block') === false) {
    $('body').removeClass('overflow-hidden');
  }

}

/**
 *
 *
 * @export
 * @param {any} title 顯示標題
 * @param {any} text 顯示文字
 * @param {any} buttonText button文字，預設Ok
 * @param {MessageType} type  Message Type，預設 info
 */
export function showAlert(title, text, buttonText, type: MessageType) {
  return swal({
    title: title || '',
    html: text || '',
    type: type || 'info',
    // showCloseButton: true,
    confirmButtonClass: 'swal2-styled btn btn-sm btn-primary',
    confirmButtonText: buttonText || 'Ok',
    buttonsStyling: false

  });
}

/**
 *
 *showConfirm
 * @export
 * @param {any} title 顯示標題
 * @param {any} text 顯示文字
 * @param {MessageType} type  Message Type，預設 warning
 * @returns {boolean}
 */
export function showConfirm(title, text, type: MessageType) {
  return swal({
    title: title || '',
    html: text || '',
    type: type || 'warning',
    // showCloseButton: true,
    showCancelButton: true,
    confirmButtonClass: 'swal2-styled btn btn-sm btn-danger',
    cancelButtonClass: 'swal2-styled btn btn-sm btn-dark',
    buttonsStyling: false
  });
}

/**
 *
 *showInfo 顯示訊息並在指定時間後自動消失
 * @export
 * @param {any} title 顯示標題
 * @param {number} timer 顯示時間
 * @param {MessageType} type  Message Type，預設 success
 */
export function showInfo(title, timer: number, type: MessageType) {
  return swal({
    title: title || '',
    timer: timer || 1500,
    type: type || 'success',
    showCloseButton: true,
    confirmButtonClass: 'displayNone',
    buttonsStyling: false
    // onOpen: () => {
    //   swal.showLoading();
    // }
  }).then((result) => {
    if (
      // Read more about handling dismissals
      result.dismiss === swal.DismissReason.timer
    ) {
      console.log('I was closed by the timer');
    }
  }).catch(swal.noop);
}


/// 計算兩個整數的百分比值
export function getPercent(vnum, vtotal) {
  const num = parseFloat(vnum);
  const total = parseFloat(vtotal);
  if (isNaN(num) || isNaN(total)) {
    return '0%';
  }
  return total <= 0 ? '0%' : (Math.round(num / total * 10000) / 100.00 + '%');
}

export function sizeFormat(bytes: number) {
  const MEGABYTE = 1048576; // Bytes
  const GIGABYTE = 1024 * MEGABYTE; // Bytes
  if (bytes < GIGABYTE) {
    return (bytes / MEGABYTE).toFixed(3) + 'MB';
  } else {
    return (bytes / GIGABYTE).toFixed(3) + 'GB';
  }
}


// warning, error, success, info, and question
export enum MessageType {
  warning = 'warning',
  error = 'error',
  success = 'success',
  info = 'info',
  question = 'question'
}


const REGEXP = /[xy]/g;
const PATTERN = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';

function replacement(c) {
  const r = Math.random() * 16 | 0;
  const v = c === 'x' ? r : (r & 0x3 | 0x8);
  return v.toString(16);
}

/**
 * Generate a univierally unique identifier
 *
 * @return {String}
 */
export function newGuid(): string {
  return PATTERN.replace(REGEXP, replacement);
}


export function dataEncode(data: any): string {
  // Encode the String
  try {
    const encodedString = encodeURIComponent(btoa(JSON.stringify(data)));
    // console.log('dataEncode', encodedString);
    return encodedString;
  } catch (error) {
    console.error('dataEncode', data);
    return '';
  }
}

export function dataDecode(data: string): any {
  // Decode the String
  try {
    const decodedString = atob(decodeURIComponent(data));
    return JSON.parse(decodedString);
  } catch (error) {
    console.error('dataDecode', data);
    return {};
  }
}

declare var window: any;
export function confirmClose() {
  console.log('window.onbeforeunload ......');
  window.onbeforeunload = function (e) {
    e = e || window.event;
    // 兼容IE8和Firefox 4之前的版本
    if (e) {
      e.returnValue = '關閉提示';
    }
    // Chrome, Safari, Firefox 4+, Opera 12+ , IE 9+
    return '關閉提示';
  };
}

export enum FolderType {
  PUBLIC_FOLDER = 'PUB',
  PRIVATE_FOLDER = 'PRI',
  FAVORITE_FOLDER = 'FAV',
  DEPT_FOLDER = 'DEPT',
  SYS_FOLDER = 'SYS',
  SHARE_WITH_ME = 'SHARE',
  RECENT_ACCESS = 'RECENT',
}

export const RouteMap = {
  '': '/folder/list-newest-document',
  'PRI': '/folder/private-folder',
  'PUB': '/folder/public-folder',
  'DEPT': '/folder/department-folder',
  'SYS': '/folder/system-folder',
  'RECENT': '/folder/recent-access',
  'SHARE': '/folder/share-with-me',
  'FAV': '/folder/favorite-folder',
};

export enum FolderTypeName {
  PUB = 'Public Folder',
  SYS = 'System Folder',
  PRI = 'Private Folder',
  FAV = 'My Favorite',
  DEPT = 'Department Folder',
  SHARE = 'Share With Me',
  RECENT = 'Recent Access'
}

export enum DocTypeName {
  PUB = 'Public Document',
  PRI = 'Private Document',
  FAV = 'My Favorite',
  DEPT = 'Department Document',
  SHARE = 'Share With Me',
  SYS = 'System Document',
  RECENT = 'Recent Access'
}

export function initEditorConfig() {

  // $(function () {
  //   CKEDITOR.editorConfig = function (config) {
  //     config.disallowedContent = 'iframe';
  //     config.removeFormatTags = '';
  //   };
  //   console.warn('initEditorConfig...init');
  // });

}

export function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  const expires = 'expires=' + d.toUTCString();
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}

export function getCookie(cname) {
  const name = cname + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}


export function getUrlParams(search: string) {
  // const parameters = new HttpParams({ fromString: search }) ;
  // const params = {} as any;
  // parameters.keys().forEach((key: string)  => {
  //   params[key] = parameters.get(key);
  // });
  // console.log('getUrlParams', params);
  // return params;
  return `${search}?`
    .split('?')[1]
    .split('&')
    .reduce((params: object, pair: string) => {
      const [key, value] = `${pair}=`
        .split('=')
        .map(str => {
          try {
            return decodeURIComponent(str);
          } catch (error) {
            return str;
          }
        });
      return key.length > 0 ? { ...params, [key]: value } : params;
    },
      {}
    );

}

export function getSSOParams() {
  const sso = getCookie('SSO');
  console.log('sso', sso);
  // const parameters = new HttpParams({ fromString: sso }) ;
  // const params = {} as any;
  // parameters.keys().forEach((key: string)  => {
  //   params[key] = parameters.get(key);
  // });
  // console.log('getSSOParams', params);
  // return params;

  const sSoParams = sso.split('&')
    .reduce((params: object, pair: string) => {
      const [key, value] = `${pair}=`
        .split('=')
        .map(str => {
          try {
            return decodeURIComponent(str);
          } catch (error) {
            return str;
          }
        });
      return key.length > 0 ? { ...params, [key]: value } : params;
    },
      {}
    );
  console.log('querySSoParams', sSoParams);
  return sSoParams;
}

export function getFileExtension(fileName: string): string {
  const fileExtIndex = fileName.lastIndexOf('.');
  return fileExtIndex > 0 ? fileName.substring(fileExtIndex) : '';
}

export function isMobile(): boolean {
  return /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent);
}

export enum TypeCss {
  Folder = 'mdi mdi-folder file-color dot',
  Document = 'mdi mdi-file document-color dot',
}

export function getArchiveTypeCss(fileName: string): string {
  switch (fileName) {
    case ('.rar'):
    case ('.zip'):
    case ('.7z'):
      return 'ico rar';
    case ('.doc'):
    case ('.docx'):
      return 'ico doc';
    case ('.xls'):
    case ('.xlsx'):
      return 'ico xls';
    case ('.pdf'):
      return 'ico pdf';
    case ('.html'):
    case ('.htm'):
    case ('.mhtm'):
    case ('.mhtml'):
      return 'ico html';
    case ('.mp4'):
    case ('.avi'):
    case ('.mpeg'):
    case ('.mov'):
      return 'ico mp4';
    case ('.ppt'):
    case ('.pptx'):
      return 'ico ppt';
    case ('.jpg'):
    case ('.jpeg'):
    case ('.png'):
    case ('.gif'):
    case ('.bmp'):
      return 'ico img';
    case ('.txt'):
      return 'ico txt';
    case ('.sql'):
      return 'ico sql';
    default:
      return 'ico txt';
  }
}

export function isIE() {
  return /MSIE|Trident/.test(navigator.userAgent);
}
