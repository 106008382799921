<body class="login">
  <i class="logo ASUS-logo"></i>
  <div class="login-wrap">
    <article>
      <aside class="left">
        <div class="hgroup">
          <h1>{{ "Welcome Login" | translate }}<b>ASUS Drive</b></h1>
          <h2>Welcome to ASUS Drive</h2>
        </div>
        <section>
          <!-- <form novalidate name="loginForm" #loginForm="ngForm">
            <div class="hgroup">
              <p>非華碩員工登入</p>
              <div class="form-group">
                <label for="domain">網域名稱</label>
                <input type="text" class="form-control input-sm" placeholder="請填入網域名稱" #domain="ngModel" name="domain" [(ngModel)]="account.domain">
                <span *ngIf="domain.errors?.required && domain.touched" class="field-validation-error">{{'Required'|translate}}</span>
              </div>
              <div class="form-group">
                <label for="UserID">{{"Account"|translate}}</label>
                <input type="text" required class="form-control input-sm" placeholder="{{'Your Account'|translate}}" #userID="ngModel" name="userID" [(ngModel)]="account.userID">
                <span *ngIf="userID.errors?.required && userID.touched" class="field-validation-error">{{'Required'|translate}}</span>
              </div>
              <div class="form-group">
                <div class="flex-center-between">
                  <label for="Password">{{"Password"|translate}}</label>
                  <small class="right"><a href="https://mymail.asus.com/owa/auth/expiredpassword.aspx?url=/owa/auth.owa" target="_blank"> 重新設定密碼</a></small>
                </div>
                <input type="password" required class="form-control input-sm" placeholder="{{'Your Password'|translate}}" #password="ngModel" name="password" [(ngModel)]="account.password">
                <span *ngIf="password.errors?.required && password.touched" class="field-validation-error">{{'Required'|translate}}</span>
              </div>
              <div class="form-group">
                <label for="txtCAPTCHA">CAPTCHA</label>
                <div class="input-group-space ConfirmCode">
                  <input type="text" required class="form-control input-sm per-50" name="captcha" #captcha="ngModel" [(ngModel)]="account.captcha">
                  <img src="{{captchaUrl}}&now={{now}}">
                </div>
                <span *ngIf="captcha.errors?.required && captcha.touched" class="field-validation-error">{{'Required'|translate}}</span>
              </div>
              <span *ngIf="errorMag !='' " class="submit-error">{{errorMag}}</span>
              <div class="input-group-space login-btn-group">
                <a href="javascript:void(0)" (click)="login(loginForm)" class="btn btn-sm btn-primary per-50">{{'Login'|translate}}</a>
                <button (click)="refreshCaptcha()" class="btn btn-sm btn-dark per-50">Refresh</button>
                <a *ngIf="false" href="javascript:void(0)" class="text-link per-50 show-pop" show-pop="show-ForgotPassword">忘記密碼?</a>
              </div>
            </div>
          </form> -->
          <!-- <div class="or"><span></span><b>or</b></div> -->
          <div class="hgroup right">
            <!-- <p>ASUS 華碩員工登入</p> -->
            <div class="form-group">
              <!-- <p>{{"ASUS Staff Login" | translate}}</p> -->
              <a href="javascript:void(0)" (click)="ssoLogin()" class="btn btn-block btn-sm btn-primary">{{"Login" | translate}}</a>
            </div>
          </div>
        </section>
      </aside>
      <aside class="right">
        <ul class="lang" title="{{ 'Switch Language' | translate }}">
          <li (click)="changeLang('zh')" [ngClass]="{'focus': uselang === 'zh' }">繁中</li>
          <li (click)="changeLang('en')" [ngClass]="{'focus': uselang === 'en' }">EN</li>
        </ul>
      </aside>
    </article>
    <footer>
      <a href="#" data-toggle="modal" data-target="#ContactWindow">{{"Service Window"| translate }}</a> │
      <a href="https://www.asus.com/Terms_of_Use_Notice_Privacy_Policy/Privacy_Policy">{{"Privacy"|translate}}</a> │ {{"Browser recommended IE11+" | translate}}
      <br>
      <em>©ASUSTeK Computer Inc. All rights reserved.</em>
    </footer>
  </div>

  <!-- 忘記密碼 -->
  <!-- <div class="popbg-fill va-center show-ForgotPassword">
    <div class="pop-box pop-size-350">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close">
            <i class="mdi mdi-close"></i>
          </button>
          <h4 class="modal-title">{{ "Forgot Password" | translate }}</h4>
        </div>
        <div class="modal-body">
          <p>{{ "Enter user id then send email" | translate }}</p>
          <div class="form-group">
            <label for="UserID">{{ "User ID" | translate }}</label>
            <input type="text" class="form-control input-sm" id="UserID">
            <span class="field-validation-error">{{ "Required" | translate }}</span>
          </div>
        </div>
        <div class="modal-footer center">
          <button type="button" class="btn btn-sm btn-default close-btn">{{ "Cancel" | translate }}</button>
          <button type="button" class="swa-success-send btn btn-sm btn-primary">{{ "Submit" | translate }}</button>
        </div>
      </div>
    </div>
  </div> -->


  <!-- 服務窗口 -->
  <div class="modal fade" id="ContactWindow" tabindex="-1">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <a type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="mdi mdi-close"></i>
          </a>
          <h4 class="modal-title">{{ "Service Window" | translate }}</h4>
        </div>
        <div class="modal-body">
          <p>{{ "For operational issues" | translate }}</p>
          <dl class="base pad20-tb">

            <dt>IT {{ "Custome Service" | translate }}</dt>
            <dd>886-2-28943447 #1885 /
              <a href="http://itsm.asus.com/" target="_blank">IT Service Desk {{ "HitRecord" | translate }}</a>
            </dd>

            <dt>ePO客服 - {{ "Suzhou" | translate }}/{{ "Shanghai" | translate }}</dt>
            <dd>吳惠華 / 0512-68787166 #42345 /
              <a href="#">Landy_Wu@asus.com </a>
              <br> 陳大水 / 0512-68787166 #42345 /
              <a href="#">Landy_Wu@asus.com </a>
            </dd>
          </dl>
        </div>
        <div class="modal-footer center">
          <button type="button" class="btn btn-sm btn-default" data-dismiss="modal">{{ "Close" | translate }}</button>
        </div>
      </div>
    </div>
  </div>

</body>
