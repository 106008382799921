import { Component, OnInit, ViewEncapsulation, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DocumentDto } from '../../../core/model/data-model';
declare var $: any;
@Component({
  selector: 'doc-document-content-view',
  templateUrl: './document-content-view.component.html',
  styleUrls: ['./document-content-view.component.css'],
  // encapsulation: ViewEncapsulation.Native   //  ViewEncapsulation.Native
})
export class DocumentContentViewComponent implements OnInit, AfterViewInit {

  @Input() documentDto = {} as DocumentDto;
  @ViewChild('iframe', { static: false }) iframe: ElementRef;
  // @ViewChild('divContent') divContent: ElementRef;
  documentContent = '';
  constructor(protected sanitizer: DomSanitizer) { }
  ngOnInit() {

  }

  ngAfterViewInit() {
    this.showContent();
  }

  showContent() {
    // console.log('this.documentDto', this.documentDto);
    if (this.documentDto != null && 'isArchive' in this.documentDto && this.documentDto.isArchive !== '1') {

      this.documentContent = this.documentDto.documentContent; // this.sanitizer.bypassSecurityTrustHtml(this.documentDto.documentContent);
      this.documentContent.replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/'/g, '&#39;')
      .replace(/"/g, '&#34;');   
      // const contentDocument = $('div[class="main-document-content"]');
      const html = $(this.iframe.nativeElement).contents().find('html');
      const body = $(this.iframe.nativeElement).contents().find('body');
      const iframeEle = $(this.iframe.nativeElement);
      $(iframeEle).height('100%');
      $(iframeEle).width('100%');
      const doc = $(`<div>${this.documentContent}</div>`);
      body.html(doc); // = this.documentContent;
      let cnt = 0;
      let onElementHeightChangeTimer = setTimeout(
        function run() {
          if (onElementHeightChangeTimer) {
            clearTimeout(onElementHeightChangeTimer);
            console.log(' clearTimeout(onElementHeightChangeTimer)');
          }
          if (Math.abs($(iframeEle).height() - html.prop('scrollHeight')) + Math.abs($(iframeEle).width() - html.prop('scrollWidth')) <= 3) {
            if (cnt < 3) {
              onElementHeightChangeTimer = setTimeout(run, 300);
            }
            cnt++;
            console.log('$(iframeEle).height() === html.prop("scrollHeight")');
          } else {
            $(iframeEle).height(html.prop('scrollHeight'));
            $(iframeEle).width(html.prop('scrollWidth'));
            if (cnt < 3) {
              onElementHeightChangeTimer = setTimeout(run, 300);
            }
            cnt++;
          }
          console.log('onElementHeightChangeTimer = setTimeout(run, 300)');
          console.dir('iframe', $(iframeEle));
        }, 300
      );
    } else {
      //   <iframe src="about:blank"></iframe>
      const iframeEle = $(this.iframe.nativeElement);
      iframeEle.prop('src', 'about:blank');
    }
  }
}
