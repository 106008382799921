import { Input, Output, EventEmitter, ViewChild, Directive, ElementRef, OnInit } from '@angular/core';
import { FolderTreeService } from '../../core/service/folder-tree.service';
import { TreeViewComponent } from '../component/tree-view/tree-view.component';

@Directive({
  selector: '[docToggleTreeNode]',
  exportAs: 'docToggleTreeNode'
})
export class ToggleTreeNodeDirective implements OnInit {

  @Input() refTreeViewComponent = null;
  @ViewChild('input', { static: false }) input: ElementRef;
  constructor(private _el: ElementRef, private folderTreeService: FolderTreeService) {
    // console.log('directive init,', this._el);
  }

  ngOnInit() {
    $(this._el.nativeElement).on('click', (event) => {

      // console.log('docToggleTreeNode...');
      const ele = $(event.target);
      if (ele.hasClass('show-icon')) {
        ele.removeClass('show-icon');
      } else {
        ele.addClass('show-icon');
      }

      const pele = $(event.target).parent("a").next("ul");
      if (pele.hasClass('show')) {
        pele.removeClass('show');
        if (this.refTreeViewComponent != null) {
          this.folderTreeService.removeOpenTreeView(this.refTreeViewComponent);
          // console.log(' ele.removeClass("show-icon"); openTreeViewComponents', this.folderTreeService.openTreeViewComponents);
        }
      } else {
        pele.addClass('show');
        if (this.refTreeViewComponent != null) {
          this.folderTreeService.addOpenTreeView(this.refTreeViewComponent);
          // console.log(' ele.addClass("show-icon"); openTreeViewComponents', this.folderTreeService.openTreeViewComponents);
        }
      }
      // if (selected) {.toggleClass("show");
      //   selected.classList.remove('color-selected');
      //   selected.removeAttribute('aria-selected');
      // }

      // selected = document.querySelector(`.toolbar .text-color[data-color="${color}"]`);
      // if (selected) {
      //   selected.classList.add('color-selected');
      //   selected.setAttribute('aria-selected', true);
      // }
      event.preventDefault();
    });
  }

  // toggleIcon(event) {
  //   $(event.target).toggleClass("show-icon");
  //   $(event.target).parent("a").next("ul").toggleClass("show");
  //   event.preventDefault();
  // }

}
