<div class="popbg-fill show-hit va-center va-center-contentScroll-s {{popCss}}">
  <div class="pop-box pop-size-500">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close"><i class="mdi mdi-close"></i></button>
        <h4>{{ "HitRecord" | translate }}</h4>
      </div>
      <div class="pad10-rl">
        <doc-modal-paged-list #headerPagedList [dataCount]="dataCount" [pageSize]="pageSize" [pageNum]="pageNum" [pageCount]="pageCount"
          (pageNumChange)="pageChange($event)" (pageSizeChange)="pageChange($event)"></doc-modal-paged-list>
      </div>
      <div class="modal-body pad0-tb pad10-rl">
        <table class="table table-striped table-hover">
          <tbody>
            <tr>
              <!-- <th class="item">Item</th> -->
              <th class="id">ID</th>
              <th class="ip">IP</th>
              <th class="time">Time</th>
            </tr>
            <tr *ngFor="let record of hitCountRecordDtos.slice( pageSize*(pageNum-1), pageSize*pageNum )">
              <!-- <td class="item">{{ record.userId }}</td> -->
              <td class="id">{{ record.userName }}</td>
              <td class="ip">{{ record.ipAddress }}</td>
              <td class="time">{{ record.recordDate | date:'yyyy-MM-dd HH:mm a'}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
