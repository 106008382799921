<div #upoladBox [hidden]="uploadWorkCount === 0" class="upolad-box ui-draggable">
  <div class="upolad-header">
    <p>{{ ("UploadingItems_0_"| translate).replace("{0}", uploadWorkCount) }}</p>
    <div class="i-wrap">
      <a class="mdi mdi-chevron-down" href="javascript:void(0)"></a>
      <!-- <a class="mdi mdi-close swa-cancel-upload" href="javascript:void(0)"></a> -->
    </div>
  </div>
  <ul class="upolad-content">
    <li *ngFor="let ufile of uploads">
      <p>{{ ufile.name }} </p>
      <div class="upolad-content-r">
        <em> {{ getBytes(ufile.size) }}MB</em>
        <div [class]="getStatusCss(ufile.status)" *ngIf="ufile.status != 'error'">
          <a href="javascript:void(0)" [title]="ufile.status" (click)="doAction(ufile)">
            <i [class]="getStatusIconCss(ufile.status)"></i> {{ ufile.progress }}%
          </a>
          <!-- UploadStatus = 'added' | 'queue' | 'uploading' | 'complete' | 'error' | 'cancelled' | 'paused'; [hidden]="uploadWorkCount === 0" [hidden]="uploadWorkCount != 0" style="left: 70px; top: 248px;z-index: -19;"-->
        </div>
        <div class="status error" *ngIf="ufile.status == 'error'">
          <i class="mdi mdi-alert"></i>
          <a href="javascript:void(0)">{{ "UploadFailed" | translate }}</a><a class="mdi mdi-delete btn" title="移除" (click)="cancel(ufile.uploadId)"></a>
          <a href="javascript:void(0)" class="mdi mdi-restart btn" title="重試" (click)="upload(ufile.uploadId)"></a>
        </div>

      </div>
    </li>
  </ul>
</div>
