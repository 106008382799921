import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { tap, distinctUntilChanged } from 'rxjs/operators';
import { BaseService } from './base.service';
import { UserNode, TreeNode, RespMessage, FolderContent, FolderFileSizeVM, FolderLimitSize, HttpOptions, DocumentFolder } from '../model/data-model';
import { HttpClient } from '@angular/common/http';
import { FolderType, showInfo, MessageType } from '../../shared/common';
import { PickTreeNodeComponent } from '../../shared/component/pick-tree-node/pick-tree-node.component';
import { TreeViewComponent } from '../../shared/component/tree-view/tree-view.component';
import { stringify } from 'querystring';
import { environment } from 'environments/environment';

@Injectable()
export class FolderTreeService extends BaseService {

  private MEGABYTE = 1048576; // Bytes
  private GIGABYTE = 1024 * this.MEGABYTE; // Bytes

  private _currentPickFavoriteTreeNode = {} as TreeNode;
  private _currentNavTreeNode = {} as TreeNode;
  private _pickTreeNode = {} as TreeNode;
  private _currentPickTreeNodeComponent = {} as PickTreeNodeComponent;
  openTreeViewComponents: TreeViewComponent[] = [];

  private userAllNodeSubject = new BehaviorSubject<UserNode>({} as UserNode);
  public userAllNode$ = this.userAllNodeSubject.asObservable().pipe(distinctUntilChanged());

  private myFileSizeSubject = new BehaviorSubject<number>(0);
  public myFileSizeSubject$ = this.myFileSizeSubject.asObservable().pipe(distinctUntilChanged());

  private currentPickTreeNodeSubject = new BehaviorSubject<TreeNode>({} as TreeNode);
  public currentPickTreeNode$ = this.currentPickTreeNodeSubject.asObservable().pipe(distinctUntilChanged());

  constructor(private http: HttpClient) {
    super();
  }

  set currentPickTreeNodeComponent(curPickTreeNodeComponent: PickTreeNodeComponent) {
    this._currentPickTreeNodeComponent = curPickTreeNodeComponent;
  }

  get currentPickTreeNodeComponent() {
    return this._currentPickTreeNodeComponent;
  }

  set currentPickFavoriteTreeNode(curNode: TreeNode) {
    this._currentPickFavoriteTreeNode = curNode;
  }

  get currentPickFavoriteTreeNode() {
    return this._currentPickFavoriteTreeNode;
  }

  set currentNavTreeNode(curNode: TreeNode) {
    this._currentNavTreeNode = curNode;
  }

  get currentNavTreeNode() {
    return this._currentNavTreeNode;
  }

  set pickTreeNode(curNode: TreeNode) {
    this._pickTreeNode = curNode;
    this.currentPickTreeNodeSubject.next(curNode);
  }

  get pickTreeNode() {
    return this._pickTreeNode;
  }

  addOpenTreeView(treeViewComponents: TreeViewComponent) {
    this.openTreeViewComponents.push(treeViewComponents);
    // console.log(' addOpenTreeView', this.openTreeViewComponents);
  }

  removeOpenTreeView(treeViewComponents: TreeViewComponent) {
    this.openTreeViewComponents = this.openTreeViewComponents.filter(com => com !== treeViewComponents);
    // console.log(' removeOpenTreeView', this.openTreeViewComponents);
  }

  getFolderAllRoot() {
    /////// GET /api/FolderTree/GetAllRoot
    this.http.get<RespMessage<UserNode>>(`${environment.apiRoot}/api/FolderTree/GetAllRoot`)
      .subscribe((resp: RespMessage<UserNode>) => {
        if (resp.state === 'Ok') {
          this.userAllNodeSubject.next(resp.result);
          this.myFileSizeSubject.next(resp.result.myDocumentsSize);
          // console.log('getFolderAllRoot', resp.result);
        } else {
          showInfo(resp.message || 'warning', 3000, MessageType.warning);
        }
      },
        error => {
          console.error('getFolderAllRoot ::', JSON.stringify(error));
        });
    // .unsubscribe();
  }


  getMyFileSize() {
    this.http.get<RespMessage<number>>(`${environment.apiRoot}/api/FolderTree/MyFileSize`)
      .subscribe((resp: RespMessage) => {
        if (resp.state === 'Ok') {
          this.myFileSizeSubject.next(resp.result);
        } else {
          showInfo(resp.message || 'warning', 3000, MessageType.warning);
        }
      },
        error => {
          console.error('getFolderAllRoot ::', JSON.stringify(error));
        });
  }

  getTreeNodes(qry: { folderId: number, folderType, curNode: TreeNode, deptId?}) {
    /////// POST /api/FolderTree/getTreeNodes
    // rx6 do -> tap
    return this.http.post<RespMessage<TreeNode>>(`${environment.apiRoot}/api/FolderTree/getTreeNodes`, qry)
      .pipe(
        tap((resp: RespMessage<TreeNode>) => {
          if (resp.state === 'Ok') {
            if (qry.curNode != null) {
              qry.curNode.childNode = [...resp.result.childNode];
              qry.curNode.have_child = resp.result.childNode.length > 0 ? '1' : '0';
            }
            this.userAllNodeSubject.next({ ...this.userAllNodeSubject.value });
            // console.log('getTreeNodes', resp.result);
            // console.log('currentFolderAllRoot', this.currentFolderAllRoot());
          }
          return resp;
        })
      );
    // .unsubscribe();
  }

  getDisplayFolder(qry: { folderId: number, folderType: FolderType }, sortBy = '', sortType = '', pageSize = 100, pageNum = 1, deptId = '') {
    /////// POST /api/FolderTree/DisplayFolder
    qry['sortBy'] = sortBy;
    qry['sortType'] = sortType;
    qry['pageSize'] = pageSize;
    qry['pageNum'] = pageNum;
    qry['deptId'] = deptId;

    return this.http.post<RespMessage<FolderContent>>(`${environment.apiRoot}/api/FolderTree/DisplayFolder`, qry);
  }

  getDisplayAllDocs(qry: { folderId: number, folderType: FolderType, deptId?: string }) {
    return this.http.post<RespMessage<FolderContent>>(`${environment.apiRoot}/api/FolderTree/DisplayAllDocs`, qry);
  }

  getDisplayOnlyFolders(qry: { parentFolderIds: number[], folderType: FolderType, deptId?: string }) {
    return this.http.post<RespMessage<FolderContent[]>>(`${environment.apiRoot}/api/FolderTree/DisplayOnlyFolders`, qry);
  }

  currentFolderAllRoot(): UserNode {
    return this.userAllNodeSubject.value;
  }

  getFolderFileSize(vo: { folderId?: number, folderType: FolderType, deptId?: string, docId?: number }, options?: HttpOptions) {
    return this.http.post<RespMessage<FolderFileSizeVM>>(`${environment.apiRoot}/api/FolderTree/getFolderFileSize/?folderId=${vo.folderId}&folderType=${vo.folderType}`, vo, options);
  }

}
