import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { SharedModule } from './shared/shared.module';
import { LayoutModule } from 'app/layout/layout.module';
import { CoreModule } from './core/core.module';
import { CommonErrorHandler } from './core/common-error-handler';
import { LoginComponent } from './login/login.component';
import { TokenInterceptor } from './core/interceptor/token-interceptor';
import { TranslateService } from '@ngx-translate/core';
import { SsoComponent } from './sso/sso.component';
import { MatomoModule } from 'ngx-matomo';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json?d=' + (new Date().getTime()));
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    SsoComponent,
  ],
  imports: [
    MatomoModule.forRoot({
      scriptUrl: '//analyticsuat.asus.com/piwik.js',
      trackers: [
        {
          trackerUrl: environment.matomoUrl,
          siteId: environment.matomoSiteID
        }
      ],
      routeTracking: {
        enable: true
      }
    }),
    BrowserModule,
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
    LayoutModule,
    CoreModule,
    ServiceWorkerModule.register('./ngsw-worker.js', { enabled: (environment.production && environment.enabledServiceWorker) }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    TranslateService,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: ErrorHandler, useClass: CommonErrorHandler },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
