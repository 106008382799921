<div class="popbg-fill show-share-publicFile">
  <div class="pop-box pop-size-block">
    <div class="modal-content">
      <div class="modal-header">
        <div>
          <button class="close"><i class="mdi mdi-close"> </i></button>
        </div>
        <h4>多檔案/資料夾複製<a class="btn btn-xs btn-dark marg10-rl float-right show-pop close-btn" href="#" show-pop="show-share">返回一般複製</a></h4>
      </div>
      <div class="modal-body pad10-t">
        <ul class="step-vertical-wrap pad30-rl pad30-t">
          <li class="active">
            <div class="hgroup"> 
              <h6>選擇複製的檔案</h6>
            </div>
            <div class="pad30-tb pad50-l">
              <div class="row">
                <div class="col-sm-6">
                  <p class="marg10-b">資料夾路徑 ： 公用文件資料夾<ng-container *ngFor="let folderName of curFolderNameHierarchy"> → {{folderName}}</ng-container></p>
                </div>
                <div class="col-sm-6"></div>
              </div>
              <div class="row">
                <div class="col-sm-6 pad20-b border-right">
                  <div class="table-checkbox-all flex-center-between marg4-b" [ngClass]="{ 'active': hasChosenAllDocs() }">
                    <p class="marg0-b green">相關檔案 - 共 {{docs.length}} 筆 - 已選 {{selectedDocsDataCount}} 筆</p>
                    <a class="btn btn-xs btn-dark btn-select" href="javascript:void(0)" (click)="toggleChooseAllDocs()" data-check="全選" data-checked="已全選"></a>
                  </div>
                  <div class="max-h-30rem overflow-y-auto">
                    <table class="table table-striped table-hover table-checkbox-btn">
                      <tbody>
                        <tr *ngFor="let doc of docs" [ngClass]="{ 'active': doc.isSelected }" (click)="toggleDoc(doc)" >
                          <td class="name">
                            <p class="title">{{doc.documentName}}</p>
                          </td>
                          <td class="text-right">
                            <a class="btn btn-xs btn-dark btn-select" href="javascript:void(0)" data-check="選取" data-checked="已選取"></a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div *ngIf="selectedDocsDataCount === 0" class="alert-danger alert pad4 marg10-t">請至少選擇一筆</div>
                </div>
              </div>
            </div>
          </li>
          <li class="active">
            <div class="hgroup"> 
              <h6>選擇目的地資料夾</h6>
            </div>
            <div class="pad30-tb pad50-l">
              <div class="row">
                <div class="input-group-space-l marg20-b">
                  <select class="form-control  max-w-20rem" disabled>
                    <option>公用文件資料夾</option>
                  </select>
                  <div class="dropdown" *ngFor="let folderDropDownVo of folderDropDownVos; let idx = index;">
                    <input class="form-control max-w-20rem" type="text" placeholder="--- Select ---" data-toggle="dropdown" [ngModel]="folderDropDownVo.selectedText" >
                    <div class="dropdown-menu select-multiple">
                      <form>
                        <ul class="select-multiple max-h-20rem">
                          <li [ngClass]="{'active': folderDropDownVo.allVo.selected}">
                            <a href="javascript:void(0)" (click)="toggleFolderAllVo(folderDropDownVo, idx)">{{folderDropDownVo.allVo.folderName}}</a>
                          </li>
                          <li [ngClass]="{'active': optionVo.selected}" *ngFor="let optionVo of folderDropDownVo.optionVos">
                            <a href="javascript:void(0)" (click)="toggleFolderOptionVo(folderDropDownVo, optionVo, idx)" >{{optionVo.folderName}}</a>
                          </li>
                        </ul>
                      </form>
                    </div>
                  </div>
                  <!-- <input class="form-control" type="search" #qryDoc placeholder="輸入搜尋關鍵字，空白為搜尋全部資料夾" name="qryDoc" [(ngModel)]="lookup" > -->
                  <a class="btn btn-primary" href="javascript:void(0)" (click)="queryFolders()">查詢</a>
                </div>
                <div class="col-sm-6"></div>
              </div>
              <div class="row">
                <div class="col-sm-6 pad20-b border-right">
                  <div class="table-checkbox-all flex-center-between marg4-b" [ngClass]="{ 'active': hasChosenAllFolders() }">
                    <p class="marg0-b green">查詢結果 共 {{folders.length}} 筆 (查詢後才顯示以下欄位)</p>
                    <a class="btn btn-xs btn-dark btn-select" href="javascript:void(0)" (click)="toggleChooseAllFolders()" data-check="全選" data-checked="已全選"></a>
                  </div>
                  <div class="max-h-40rem overflow-y-auto">
                    <table class="table table-striped table-hover table-checkbox-btn">
                      <tbody>
                        <tr *ngFor="let folder of filterFolders" [ngClass]="{ 'active': folder.isSelected }" (click)="toggleFolder(folder)">
                          <td class="name"> 
                            <p class="title">{{folder.folderName}}
                              <!-- <mark>關鍵字</mark>點選整欄選取 -->
                            </p><em class="path">{{folder.folderPath}}</em>
                          </td>
                          <td class="text-right">
                            <a *ngIf="folder.canWrite || folder.canAdmin" class="btn btn-xs btn-dark btn-select" href="javascript:void(0)" data-check="選取" data-checked="已選取"></a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="col-sm-6 pad20-b">
                  <p class="marg0-b green">已選 {{selectedFolders.length}} 筆</p>
                  <div class="max-h-40rem overflow-y-auto">
                    <table class="table table-striped table-hover">
                      <tbody>
                        <tr *ngFor="let folder of selectedFolders">
                          <td class="name"> 
                            <p class="title">{{folder.folderName}}</p><em class="path">{{folder.folderPath}}</em>
                          </td>
                          <td class="text-right"><a class="btn btn-xs btn-danger" href="javascript:void(0)" (click)="unselectFolder(folder)">移除</a></td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="center border pad20" *ngIf="selectedFolders.length === 0">
                      <h4>目前無已選資料夾</h4>
                      <h6 class="marg0-b">請選擇資料夾</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="active">
            <div class="hgroup"> 
              <h6>送出</h6>
            </div>
            <div class="pad20-t pad0-b pad50-l center"> 
              <p>共選擇 {{selectedDocsDataCount}} 個檔案，{{selectedFolders.length}} 個資料夾</p>
              <div>
                <a class="btn btn-sm btn-dark close-btn marg6-r" href="javascript:void(0)"> <i class="mdi mdi-reply"></i>取消返回</a>
                <a class="btn btn-sm btn-primary close-btn swa-share" href="javascript:void(0)" (click)="clone()" [ngClass]="{ inactive: !canClone() }"> <i class="mdi mdi-check"></i>確定複製</a>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
