import { UploadState } from "../../uploadx";

export class Ufile {
  name: string;
  uploadId: string;
  progress: number;
  status: string;
  size: number;
  constructor(ufile: UploadState) {
    this.uploadId = ufile.uploadId;
    this.name = ufile.name;
    this.progress = ufile.progress;
    this.status = ufile.status;
    this.size = ufile.file.size;
  }
}
