

import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { JwtAuthObject } from '../model/data-model';
import { AuthTokenService } from '../service/auth-token.service';
import { Router, ActivatedRoute } from '@angular/router';
import { loadingHide, showInfo, MessageType } from '../../shared/common';
import { throwError, Observable } from 'rxjs';
import { tap, timeout, catchError } from 'rxjs/operators';
import { ClientLogService } from '../service/client-log.service';
import { environment } from 'environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  /**
   * TIMEOUT 30 分鐘
   */
  TIMEOUT = 30 * 60 * 1000;
  constructor(private router: Router, private route: ActivatedRoute,
    private authTokenService: AuthTokenService, private clientLogService: ClientLogService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.withCredentials) {
      if (localStorage.getItem('token') != null && request.url.indexOf('api/login') < 0 && request.headers.get('Authorization') == null) {
        const token: JwtAuthObject = JSON.parse(localStorage.getItem('token'));
        const jwttoken = `Bearer ${token.token}`;
        request = request.clone({ setHeaders: { Authorization: jwttoken } });
      }
      // console.log(request.headers.get('Authorization'));
    }

    // rx6 do -> tap
    return next.handle(request)
      .pipe(
        // timeout(this.TIMEOUT),
        tap((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // do stuff with response if you want
          }
        }),
        catchError((err: any) => {
          const errMsg = this.errorHandle(err);
          this.logErrorToServer(err);
          return throwError(errMsg);
          // return throwError(err);
        })
      );
  }

  private errorHandle(err: any) {
    let errMsg = {};
    console.log('HttpErrorResponse :' + JSON.stringify(err));
    if (err instanceof HttpErrorResponse) {
      loadingHide();
      switch (err.status) {
        case 401:
          if (this.isShortTermTokenUrl()) {
            this.router.navigate(['/error/expired']);
          } else {
            this.authTokenService.logout();
            // token type or url 判斷是否是 short term token 返回 404
            this.router.navigate(['/login']);
            if ('message' in err.error) {
              errMsg = { message: err.error.message };
            } else {
              errMsg = { message: err.message };
            }
          }
          break;
        case 403:
          // 您沒有使用本功能的權限
          this.router.navigate(['/error/403']);
          if ('message' in err.error) {
            errMsg = { message: err.error.message };
          } else {
            errMsg = { message: '您沒有使用本功能的權限' };
          }
          break;
        case 404:
          errMsg = { message: 'HTTP 404 Page Not Found' };
          // this.router.navigate(['/error/404']);
          break;
        case 503:
          // this.router.navigate(['/error/503']);
          errMsg = { message: 'Service Unavailable' };
          break;
        default:
          // "error":{"message":"Unauthorized Access This Folder"}
          errMsg = { message: 'Error' };
          break;
      }
    } else {
      errMsg = { message: 'Error' };
    }
    return errMsg;
  }

  private isShortTermTokenUrl(): boolean {
    const url = location.href;
    console.log('token-interceptor errorHandle', url, url.indexOf('upload-content') > -1 || url.indexOf('view-content-s') > -1);
    return url.indexOf('upload-content') > -1 || url.indexOf('view-content-s') > -1;
  }

  private logErrorToServer(err: any) {
    try {
      if (err.message === 'Http failure response for (unknown url): 0 Unknown Error') {
        return;
      }
      if (err instanceof HttpErrorResponse && err.status === 401) {
        return;
      }
      const message = `version: ${environment.version}, buildDate: ${environment.buildDate}, ${JSON.stringify(err)}`;
      this.clientLogService.errorLog(message)
        .subscribe(resp => {
        }, error => {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
  }
}
