import { JwtAuthObject } from '../model/data-model';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, empty, EMPTY, of } from 'rxjs';
import {  map, filter  } from 'rxjs/operators';
import { RespMessage } from 'app/core/model/data-model';
import { AuthTokenService } from '../service/auth-token.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class AuthLoginGuard implements CanActivate {
  constructor(private authTokenService: AuthTokenService, private router: Router) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    if (location.pathname.indexOf('/cp') === 0) {
      location.href = `${location.origin}/apps/#/login`;
      // return of(false).pipe(filter(v => v));
      return false;
    }
    return true;
  }
}
