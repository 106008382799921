<div class="notification-wrap dropdown">
  <span class="notification dropdown-toggle" data-toggle="dropdown" title="{{ notificationDtos?.length > 0 ? '' : '沒有新通知' }}">
    <i class="mdi mdi-bell"></i>
    <span class="badge" *ngIf="notificationDtos?.length > 0">{{ notificationDtos.length }}</span>
  </span>
  <ul class="dropdown-menu" [ngClass]="{'empty-dropdown':notificationDtos?.length==0}">
    <ng-container *ngFor="let item of notificationDtos">
      <li>
        <a href="javascript:void(0)" (click)="openNotification(item)">
          <div class="hgroup">
            <b>{{ item.sharedUserEnglishName }}({{ item.sharedUserName }}) 分享了一個 {{getResourceType(item) | translate }}</b>
            <time>{{ item.createTime | date: 'yyyy/MM/dd HH:mm' }}</time>
          </div>
          <p>{{ item.isFolder ? item.folderName : item.documentName }}</p>
          <span class="path" *ngIf="!item.isFolder && item.folderName!=''"> {{item.folderName}} </span>
        </a>
      </li>
    </ng-container>
    <ng-container *ngIf="notificationDtos?.length > 0">
      <a class="allread" href="javascript:void(0)" (click)="readAll()">{{"AllRead" | translate }}</a>
    </ng-container>
    <ng-container *ngIf="notificationDtos?.length === 0">
      <img class="empty" src="assets/img/empty.svg">
      <p>{{"No New Notifications" | translate }}</p>
    </ng-container>
  </ul>


</div>
<doc-pick-favorite-folder #pickFavoriteFolder [popCss]='popFavCss'></doc-pick-favorite-folder>
<doc-document-view #documentView [popCss]='popDocCss' [popDownloadCss]='popDownloadCss' (onAddfav)="onAddfav($event,pickFavoriteFolder)"></doc-document-view>
