<section class="panel panel-default panel-article">
  <div class="panel-heading">
    <h3 class="panel-title"><i class="circle mdi mdi-account-settings-variant"></i>權限設定</h3>
    <div>
      <a class="btn btn-primary show-pop" show-pop="show-select-account-permission" href="javascript:void(0)"
        (click)="popbox('show-select-account-permission')"><i class="mdi mdi-plus"></i>新增人員</a>
      <div class="toggle-panel-btn"><i class="mdi mdi-chevron-up"></i></div>
    </div>
  </div>
  <div class="panel-body">
    <div class="table-header-fix" style="max-height:calc(100vh - 200px); overflow-y:auto;">
      <table class="table table-striped table-hover table-btn table-Permission table-fix-outline">
        <tbody>
          <tr>
            <th>人員/群組
              <!-- ↑↓ -->
              <input class="form-control marg2-t max-w-20rem d-inline-block" type="text" placeholder="輸入關鍵字過濾" *ngIf="needFilter" [(ngModel)]="nameFilter"
                (ngModelChange)="onNameFilterChange($event)">
            </th>
            <th>{{"Permission"| translate }}
              <!-- ↑↓ -->
              <select class="form-control marg2-t max-w-20rem d-inline-block" [(ngModel)]="permissionTypeFilter" *ngIf="needFilter">
                <option value="">{{ 'All' | translate }}</option>
                <option value="read">{{ i18nPrefix + '.read' | translate}}</option>
                <option value="readAll" *ngIf="needReadAll">{{ i18nPrefix + '.readAll' | translate}}</option>
                <option value="write">{{ i18nPrefix + '.write' | translate}}</option>
                <option value="admin">{{ i18nPrefix + '.admin' | translate}}</option>
              </select>
            </th>
            <th class="center" *ngIf="needCascade">套用至子資料夾<br>(依權限)
              <div *ngIf="needFilter">
                <input type="checkbox" [(ngModel)]="cascadeSelectAll" (ngModelChange)="onCascadeSelectAllChange($event)">
                <label>{{'Select All' | translate}}</label>
              </div>
            </th>
            <th class="center">顯示上層資料夾<br>(依人員群組)
              <div *ngIf="needFilter">
                <input type="checkbox" [(ngModel)]="showParentSelectAll" (ngModelChange)="onShowParentSelectAllChange($event)">
                <label>{{'Select All' | translate}}</label>
              </div>
            </th>
            <th class="center">編輯</th>
          </tr>
          <ng-container *ngFor="let vo of vAllPermissionVos">
            <tr [ngClass]="{ 'newAdd': !vo.readOperation.oldValue }" *ngIf="(permissionTypeFilter === '' || permissionTypeFilter === 'read') && vo.readOperation.value">
              <td>{{vo.name}}</td>
              <td>{{ i18nPrefix + '.read' | translate }}</td>
              <td class="center" *ngIf="needCascade">
                <input type="checkbox" [(ngModel)]="vo.readOperation.cascade">
              </td>
              <td class="center">
                <input type="checkbox" [(ngModel)]="vo.showParent" [disabled]="vo.fixShowParent">
              </td>
              <td class="center">
                <a class="btn btn-danger btn-xs" href="javascript:void(0)" (click)="removePermissionWithConfirm(vo, vo.readOperation, 'read')"><i class="mdi mdi-delete"></i></a>
                <a class="btn btn-primary btn-xs show-pop" href="javascript:void(0)" (click)="openPermissionEditting(vo)"
                  show-pop="show-select-account-permission-1"><i class="mdi mdi-pencil"></i></a>
              </td>
            </tr>
            <tr [ngClass]="{ 'newAdd': !vo.readAllOperation.oldValue }" *ngIf="needReadAll && (permissionTypeFilter === '' || permissionTypeFilter === 'readAll')  && vo.readAllOperation.value">
              <td>{{vo.name}}</td>
              <td>{{ i18nPrefix + '.readAll' | translate }}</td>
              <td class="center" *ngIf="needCascade">
                <input type="checkbox" [(ngModel)]="vo.readAllOperation.cascade">
              </td>
              <td class="center">
                <input type="checkbox" [(ngModel)]="vo.showParent" [disabled]="vo.fixShowParent">
              </td>
              <td class="center">
                <a class="btn btn-danger btn-xs" href="javascript:void(0)" (click)="removePermissionWithConfirm(vo, vo.readAllOperation, 'readAll')"><i class="mdi mdi-delete"></i></a>
                <a class="btn btn-primary btn-xs show-pop" href="javascript:void(0)" (click)="openPermissionEditting(vo)"
                  show-pop="show-select-account-permission-1"><i class="mdi mdi-pencil"></i></a>
              </td>
            </tr>
            <tr [ngClass]="{ 'newAdd': !vo.writeOperation.oldValue }" *ngIf="(permissionTypeFilter === '' || permissionTypeFilter === 'write')  && vo.writeOperation.value">
              <td>{{vo.name}}</td>
              <td>{{ i18nPrefix + '.write' | translate }}</td>
              <td class="center" *ngIf="needCascade">
                <input type="checkbox" [(ngModel)]="vo.writeOperation.cascade">
              </td>
              <td class="center">
                <input type="checkbox" [(ngModel)]="vo.showParent" [disabled]="vo.fixShowParent">
              </td>
              <td class="center">
                <a class="btn btn-danger btn-xs" href="javascript:void(0)" (click)="removePermissionWithConfirm(vo, vo.writeOperation, 'write')"><i class="mdi mdi-delete"></i></a>
                <a class="btn btn-primary btn-xs show-pop" href="javascript:void(0)" (click)="openPermissionEditting(vo)"
                  show-pop="show-select-account-permission-1"><i class="mdi mdi-pencil"></i></a>
              </td>
            </tr>
            <tr [ngClass]="{ 'newAdd': !vo.adminOperation.oldValue }" *ngIf="(permissionTypeFilter === '' || permissionTypeFilter === 'admin')  && vo.adminOperation.value">
              <td>{{vo.name}}</td>
              <td>{{ i18nPrefix + '.admin' | translate }}</td>
              <td class="center" *ngIf="needCascade">
                <input type="checkbox" [(ngModel)]="vo.adminOperation.cascade">
              </td>
              <td class="center">
                <input type="checkbox" [(ngModel)]="vo.showParent" [disabled]="vo.fixShowParent">
              </td>
              <td class="center">
                <a class="btn btn-danger btn-xs" href="javascript:void(0)" (click)="removePermissionWithConfirm(vo, vo.adminOperation, 'admin')"><i class="mdi mdi-delete"></i></a>
                <a class="btn btn-primary btn-xs show-pop" href="javascript:void(0)" (click)="openPermissionEditting(vo)"
                  show-pop="show-select-account-permission-1"><i class="mdi mdi-pencil"></i></a>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</section>
<doc-account-select2 [needReadAll]="needReadAll" [needCascade]="needCascade" [i18nPrefix]="i18nPrefix" [permissionContent]="_permissionContent"
  [folderType]="folderType" (saveChang)="add($event)">
</doc-account-select2>
<div class="popbg-fill show-select-account-permission-1 va-center">
  <div class="pop-box pop-size-700">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close"><i class="mdi mdi-close"></i></button>
        <h4>選擇權限</h4>
      </div>
      <div class="modal-body pad10-t">
        <doc-permission-edit [needCascade]="needCascade" [needReadAll]="needReadAll" [i18nPrefix]='i18nPrefix' [(showParent)]="edittingShowParent"
          [fixShowParent]="edittingFixShowParent" [readOperation]="edittingReadOperation" [readAllOperation]="edittingReadAllOperation"
          [writeOperation]="edittingWriteOperation" [adminOperation]="edittingAdminOperation">
        </doc-permission-edit>
        <div class="center pad10-tb">
          <a class="btn btn-sm btn-dark close-btn marg6-r" href="#"><i class="mdi mdi-reply"></i>取消</a>
          <a class="btn btn-sm btn-primary close-btn" href="javascript:void(0)" (click)="savePermissionEditting()">
            <i class="mdi mdi-check"></i>確認 </a>
        </div>
      </div>
    </div>
  </div>
</div>