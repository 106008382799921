import { User, DepartmentVo } from './../model/data-model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { FolderType } from '../../shared/common';
import { RespMessage, PickPermissionDto, Group } from '../model/data-model';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Injectable()
export class PermissionService extends BaseService {

  private catchGroupUser: any = {};
  // private allUserGroupSubject = new BehaviorSubject<PickPermissionDto>({} as PickPermissionDto);
  // public allUserGroup$ = this.allUserGroupSubject.asObservable().pipe(distinctUntilChanged());

  constructor(private http: HttpClient) {
    super();
  }

  loadEmptyUserGroup(vo: { folderId?: number, folderType: FolderType, docId?: number }): Observable<RespMessage<PickPermissionDto>> {
    // replace loadPickUserGroup temporarily
    const dto = new RespMessage<PickPermissionDto>();
    dto.state = 'Ok';
    dto.result = { groups: [], users: [] };
    return of(dto);
  }

  loadPickUserGroup(vo: { folderId?: number, folderType: FolderType, docId?: number }) {
    // api/Permission/LoadPickUserGroup
    return this.http.post<RespMessage<PickPermissionDto>>(`${environment.apiRoot}/api/Permission/LoadPickUserGroup`, vo);
    // .pipe(distinctUntilChanged())
    // .subscribe((resp: RespMessage) => {
    //   console.log('post  api/Permission/LoadPickUserGroup');
    //   if (resp.state === 'Ok') {
    //     this.allUserGroupSubject.next(resp.result);
    //   }
    // },
    //   error => {
    //     console.log(error);
    //   });
  }

  queryUsers(userName: string, isExternal = false, sysName: string) {
    return this.http.get<RespMessage<User[]>>(`${environment.apiRoot}/api/Permission/queryUsers?userName=${userName}&sysName=${sysName}&isExternal=${isExternal}`);
  }

  queryGroups(groupName: string) {
    return this.http.get<RespMessage<Group[]>>(`${environment.apiRoot}/api/Permission/queryGroups?groupName=${groupName}`);
  }

  queryDepts(deptName: string ) {
    return this.http.post<RespMessage<DepartmentVo[]>>(`${environment.apiRoot}/api/Permission/queryDepts`, { deptName: deptName });
  }

  queryDeptsByMailGroup(mailGroup: string) {
    return this.http.post<RespMessage<DepartmentVo[]>>(`${environment.apiRoot}/api/Permission/queryDeptsByMailGroup`, { mailGroup: mailGroup });
  }

  queryDeptsByAny(name: string) {
    return this.http.post<RespMessage<DepartmentVo[]>>(`${environment.apiRoot}/api/Permission/queryDeptsByAny`, { name });
  }

  getDeptUsers(deptId: string ) {
    //  [Route("GetDeptUsers/{deptId}")]
    return this.http.get<RespMessage<User[]>>(`${environment.apiRoot}/api/Permission/getDeptUsers/${deptId}`);
  }

  getGroupUsers(vo: { groupId: number }) {
    const str_groupId: string = vo.groupId.toString();
    if (str_groupId in this.catchGroupUser) {
      return of(this.catchGroupUser[str_groupId]);
    } else {
      /// api/Permission/GetGroupUsers
      return this.http.post<RespMessage<Group>>(`${environment.apiRoot}/api/Permission/GetGroupUsers`, vo)
        .pipe(
          distinctUntilChanged(),
          map((resp: RespMessage) => {
            // console.log('post  api/Permission/GetGroupUsers');
            if (resp.state === 'Ok') {
              this.catchGroupUser[str_groupId] = resp.result;
              // console.log('post  api/Permission/GetGroupUsers', resp.result);
              return resp.result;
            }
          },
            error => {
              console.error('getGroupUsers ::', JSON.stringify(error));
            })
        );
    }
  }

}
