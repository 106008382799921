<div class="main scm-iframe">

  <section class="panel panel-default panel-article">
    <div class="panel-heading">
      <h3 class="panel-title" title='{{ folderName | translate }}'>
        <i class="circle mdi mdi-format-list-bulleted"></i> {{ folderName | translate }}</h3>
      <doc-breadcrumbs [folderHierarchy]="folderContent?.folderHierarchy" [folderType]="folderType" [folderTypeName]="folderTypeName"
        [viewType]="'content'"></doc-breadcrumbs>
      <div>
        <doc-paged-list #headerPagedList [dataCount]="dataCount" [pageSize]="pageSize" [pageNum]="pageNum" (pageNumChange)="pageChange($event)"
        [showPageNum]="false" (pageSizeChange)="pageChange($event)"></doc-paged-list>
        <!-- <a class="btn btn-primary marg6-l" target="_blank" [routerLink]="[ '/folder/system-folder',  {data:dataEncode({folderType:folderType,folderId:folderId})} ]">
          <i class="mdi mdi-open-in-new"></i>{{ "New Window" | translate }}</a> -->
      </div>
    </div>
    <div class="panel-body">
      <table class="table table-striped table-hover table-btn table-rwd">
        <tbody>
          <tr>
            <th class="type click" (click)="sortChange('typeCss')">{{"Type"|translate}}
              <em *ngIf="sortByCols['typeCss']=='ASC'; else typeCssAsc">↑</em>
              <ng-template #typeCssAsc>↑</ng-template>
              <em *ngIf="sortByCols['typeCss']=='DESC'; else typeCssDesc">↓</em>
              <ng-template #typeCssDesc>↓</ng-template>
            </th>
            <th class="name click" (click)="sortChange('name')">{{"Name"|translate}}
              <em *ngIf="sortByCols['name']=='ASC'; else nameAsc">↑</em>
              <ng-template #nameAsc>↑</ng-template>
              <em *ngIf="sortByCols['name']=='DESC'; else nameDesc">↓</em>
              <ng-template #nameDesc>↓</ng-template>
            </th>
            <th class="date click" (click)="sortChange('modifyTime')">{{"Modified Date"|translate}}
              <em *ngIf="sortByCols['modifyTime']=='ASC'; else modifyTimeAsc">↑</em>
              <ng-template #modifyTimeAsc>↑</ng-template>
              <em *ngIf="sortByCols['modifyTime']=='DESC'; else modifyTimeDesc">↓</em>
              <ng-template #modifyTimeDesc>↓</ng-template>
            </th>
          </tr>
          <tr *ngFor="let item of pageList">
            <td class="type">
              <i class="mdi mdi-paperclip " [ngClass]="{empty:!(item.type == 'doc' && item.data['hasFile'] == '1')}"></i>
              <i class="{{item.typeCss}}"></i>
            </td>
            <td class="name">
              <a href="javascript:void(0)" (click)="action(item)"><p class="title">{{item.name}}</p></a>
            </td>
            <td class="date">{{item.modifyTime | date:'yyyy-MM-dd HH:mm a'}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="panel-footer">
    </div>
  </section>
</div>
