import { JwtAuthObject, RespMessage } from './../../core/model/data-model';
import {
  NavigationStart, Router, NavigationEnd, ActivationStart,
  ActivationEnd, ChildActivationEnd, ActivatedRoute, RoutesRecognized,
  GuardsCheckStart, ChildActivationStart, GuardsCheckEnd, ResolveStart, ResolveEnd
} from '@angular/router';
import { Component, OnInit, NgZone, AfterViewInit } from '@angular/core';
import { AuthTokenService } from './../../core/service/auth-token.service';
import { showAlert, MessageType, showInfo, showConfirm, initCommonJs, confirmClose, loadingShow, loadingHide } from '../../shared/common';
import { FolderCanWriteTreeService } from '../../core/service/folder-can-write-tree.service';
import { BaseComponent } from '../../shared/component/base/base.component';
import { UiService } from 'app/core/service';

@Component({
  selector: 'doc-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent extends BaseComponent implements OnInit, AfterViewInit {
  constructor(private router: Router, private authTokenService: AuthTokenService,
    private uiService: UiService,
    protected folderCanWriteTreeService: FolderCanWriteTreeService, private zone: NgZone) {
    super();
  }
  contentOnly = 'false';
  isNav = false; // rwd navigator
  ngOnInit() {
    this.uiService.isNav$.subscribe(isNav => { this.isNav = isNav; });
    this.contentOnly = sessionStorage.getItem('contentOnly') || 'false';
    console.log('this.contentOnly', this.contentOnly);
    this.folderCanWriteTreeService.getFolderCanWriteAllRoot();
    setInterval(
      () => {
        const tokenSerialized = localStorage.getItem('token');
        if (!tokenSerialized) { return; }
        const tokenObj: JwtAuthObject = JSON.parse(tokenSerialized);
        if (this.needRefreshToken(tokenObj)) {
          // this.authTokenService.setLoggedIn(true, token.user);
          this.authTokenService.refreshToken(tokenObj.token);
        }
      }, (30 * 60 * 1000));
  }

  needRefreshToken(tokenObj: JwtAuthObject): boolean {
    // 到期前40分鐘自動更新token
    const isExpiring = (new Date(tokenObj.expires).getTime() - new Date().getTime()) < 40 * 60 * 1000;
    const tokenType = tokenObj.type;
    const userType = tokenObj.user.type;
    const needRefresh = isExpiring && tokenType.toLowerCase() === 'sso' && userType.toLowerCase() === 'asus';
    console.log('auto refreshToken', needRefresh);
    return needRefresh;
  }

  ngAfterViewInit() {
    this.zone.runOutsideAngular(() => {
      initCommonJs();
    });
  }

  showAlert(msg) {
    showAlert(msg, msg, 'Ok', MessageType.warning).then(
      result => {
        console.log(`showConfirm:`, JSON.stringify(result));
      },
      reject => {
        console.log(`showConfirm:`, JSON.stringify(reject));
      }
    );
  }

  showMsg(msg) {
    showInfo(msg, 1000, MessageType.info);
  }

  confirmMsg(msg) {
    showConfirm(msg, msg, MessageType.question).then(
      result => {
        console.log(`showConfirm:${result}`);
      },
      reject => {
        console.log(`showConfirm:${reject}`);
      }
    );
  }

  toggleNav() {
    this.uiService.toggleNav();
  }

}
