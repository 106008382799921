
import { Component, OnInit, NgZone, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { DocumentDto, RespMessage, PermissionVo, DocumentFile, FolderFileSizeVM, DocumentContent2, PermissionContentVo, ResourceType } from './../../core/model/data-model';
import { popbox, FolderType, loadingHide, loadingShow, FolderTypeName, showInfo, MessageType, RouteMap, initEditorConfig, showConfirm } from '../../shared/common';
import { DocumentService, PermissionService, UploadxService, FolderTreeService } from '../../core/service';
import { DepartmentSelectComponent, AccountSelectComponent, BaseComponent } from '../../shared/component';
import { DocumentPreviewComponent } from '../document-preview/document-preview.component';

@Component({
  selector: 'doc-document-add2',
  templateUrl: './document-add2.component.html',
  styleUrls: ['./document-add2.component.css']
})
export class DocumentAdd2Component extends BaseComponent implements OnInit, AfterViewInit {
  resourceType = ResourceType.document;
  folderId = 0;
  folderType: FolderType;
  folderTypeName = FolderTypeName;
  deptId = '';
  MEGABYTE = 1048576; // Bytes
  documentContent = {} as DocumentContent2;
  documentDto = {} as DocumentDto;
  strfileList: string[] = [];
  fileList: File[] = [];
  onDrag = false;
  fileSize: FolderFileSizeVM;
  hash = '';
  permissionContent: PermissionContentVo;
  @ViewChild('uploadFiles', { static: false }) uploadFiles: ElementRef;
  @ViewChild('accountSelect', { static: false }) accountSelect: AccountSelectComponent;
  @ViewChild('deptSelect', { static: false }) deptSelect: DepartmentSelectComponent;
  @ViewChild(DocumentPreviewComponent, { static: false }) documentPreview: DocumentPreviewComponent;

  constructor(private uploadService: UploadxService,
    private documentService: DocumentService,
    private folderTreeService: FolderTreeService,
    private router: Router, private route: ActivatedRoute,
    private zone: NgZone) {
    super();
  }

  ngOnInit() {

    this.route.params.subscribe((params) => {

      const data = this.dataDecode(params['data']);
      if (data['folderType']) {
        this.folderId = data['folderId'] || 0;
        this.folderType = data['folderType'];
        this.deptId = data['deptId'] || '';
        this.hash = data['hash'] || '';
        if (this.hash.indexOf(';') > 0) {
          this.hash = this.hash.substring(0, this.hash.indexOf(';'));
        }
        console.log('folderId', this.folderId, 'folderType', this.folderType, 'deptId', this.deptId);
        this.folderTypeName = FolderTypeName['' + this.folderType];
        loadingShow();
        this.loadData()
          .subscribe((arr: RespMessage[]) => {
            loadingHide();
            let resp: RespMessage = arr[0];
            if (resp.state === 'Ok') {
              this.documentContent = resp.result;
              this.documentDto = this.documentContent.document;
              this.permissionContent = this.documentContent.permissionContent;
              // console.log(this.documentContent);
            } else {
              showInfo(resp.message, 2000, MessageType.error);
            }
            resp = arr[1];
            if (resp.state === 'Ok') {
              this.fileSize = resp.result;
            } else {
              showInfo(resp.message, 2000, MessageType.error);
            }
          }, error => {
            loadingHide();
            showInfo(error.message || 'error', 3000, MessageType.error);
            console.error('loadingHide ::', JSON.stringify(error));
          });
      }
    });
  }


  ngAfterViewInit() {
    this.zone.runOutsideAngular(() => {
      $('.tab-panel li').on('click', function () {
        const $show = $(this).index();
        $(this).addClass('focus').siblings('.focus').removeClass('focus');
        $(this).parents('.panel-article').find('.tab-panel-boddy').find('.panel-body').eq($show).removeClass('hide').siblings('.panel-body').addClass('hide');
      });
      initEditorConfig();
    });
  }

  loadData() {
    return forkJoin([
      this.documentService.getCreateDoc2({ folderId: this.folderId, folderType: this.folderType }),
      this.folderTreeService.getFolderFileSize({ folderId: this.folderId, folderType: this.folderType, deptId: this.deptId, docId: 0 })
    ]).pipe(
      map((data: any[]) => {
        return data;
      }));
  }

  showPopSelect(type) {
    (this.folderType !== FolderType.DEPT_FOLDER) ? this.accountSelect.show(type) : this.deptSelect.show(type);
  }

  showPermissionList(usersVo: PermissionVo[], groupsVo: PermissionVo[], deptVo: PermissionVo[]) {

    let str = '';
    if (usersVo && usersVo.length > 0) {
      str += 'User :';
      usersVo.map(u => str += ` ${u.name};`);
    }
    if (str !== '') {
      str += ' ';
    }
    if (groupsVo && groupsVo.length > 0) {
      str += 'Group :';
      groupsVo.map(u => str += ` ${u.name};`);
    }

    if (deptVo && deptVo.length > 0) {
      str += 'Department :';
      deptVo.map(u => str += ` ${u.name};`);
    }

    if (str === '') {
      str = 'Select User/Group';
    }
    return str;
  }

  removeFile(_file: File) {
    const idx = this.fileList.findIndex(f => f.name === _file.name);
    this.fileList.splice(idx, 1);
  }

  selFiles() {
    const _fileList: FileList = this.uploadFiles.nativeElement.files;
    Array.from(_fileList).map(f => {
      if (this.fileList.findIndex(fo => fo.name === f.name) < 0) {
        this.fileList.push(f);
      }
    }); // .forEach(f => this.fileList.push(f));
    const size = this.calcuFileLimit();
    console.log('all size', size);
  }

  dropFiles(event) {
    event.preventDefault(); // 防止瀏覽器執行預設動作
    const files: FileList = event.dataTransfer.files; // 擷取拖曳的檔案
    Array.from(files).map(f => {
      if (this.fileList.findIndex(fo => fo.name === f.name) < 0) {
        this.fileList.push(f);
      }
    }); //
    // console.log('this.fileList', this.fileList);
    const size = this.calcuFileLimit();
    console.log('all size', size);
    this.onDrag = false;
  }

  calcuFileLimit() {
    let all = 0; // MB
    if (this.fileList != null && this.fileList.length > 0) {
      this.fileList.map(f => all += f.size);
      // all = all / this.MEGABYTE;
    }
    if (this.fileSize != null && this.fileSize.limit > 0) {
      if (this.fileSize.current + all > this.fileSize.limit) {
        showInfo('超過限制大小無法新增', 2000, MessageType.error);
        return false;
      }
    }
    return true;
  }

  dragHandler(event) {
    this.onDrag = true;
    event.preventDefault(); // 防止瀏覽器執行預設動作
  }

  getBytes(num) {
    return (num / this.MEGABYTE).toFixed(3);
  }


  showPreview() {
    this.documentPreview.showContent();
    popbox('show-document-preview');
  }

  goBack() {
    const url = RouteMap[this.folderType];
    this.router.navigate([url, { data: this.dataEncode({ folderId: this.folderId, deptId: this.deptId }) }]);
  }

  confirmSave(form: NgForm) {
    if (form.invalid) {
      Object.values(form.controls).forEach(ctl => {
        ctl.markAsTouched();
      });
      return;
    }
    if (this.isDocumentContentTooLong()) {
      showInfo('文件內容太長', 2000, MessageType.warning);
      return;
    }
    if (form.invalid || !this.calcuFileLimit()) { return; }
    this.save(form);
  }


  save(form: NgForm) {
    if (this.fileList != null && this.fileList.length > 0) {
      const documentFile: DocumentFile[] = this.fileList.map(file => {
        return {
          fileId: 0,
          fileName: file.name,
          fileExtension: file.name.lastIndexOf('.') > 0 ? file.name.substring(file.name.lastIndexOf('.')) : '',
          fileSize: file.size,
          downloadCount: 0,
        };
      }// new DocumentFile
      );
      this.documentContent.documentFiles = documentFile;
    }
    const _folderType = this.folderType === FolderType.SHARE_WITH_ME ? FolderType.PRIVATE_FOLDER : this.folderType;
    this.documentContent.permissionContent = this.permissionContent;
    // console.log('save', this.documentContent);
    loadingShow();
    this.documentService.add2({ folderId: this.folderId, folderType: _folderType, documentContent: this.documentContent })
      .subscribe((resp: RespMessage) => {
        loadingHide();
        if (resp.state === 'Ok') {
          this.documentContent = resp.result;
          this.documentDto = this.documentContent.document;
          this.permissionContent = this.documentContent.permissionContent;
          const documentFiles = this.documentContent.documentFiles || [];
          if (this.fileList != null && this.fileList.length > 0) {
            this.fileList.forEach(file => {
              const fname = file.name;
              const fileDto = documentFiles.find(fo => fo.fileName === fname);
              this.uploadService.handleFile({ ...fileDto, file: file, actionUrl: this.hash });
              if (this.folderType === FolderType.PRIVATE_FOLDER || this.folderType === FolderType.SHARE_WITH_ME) {
                this.folderTreeService.getMyFileSize();
              }
            });
          }
          // console.log('save resp', this.documentContent);
          showInfo('Success', 2000, MessageType.success);
          this.goBack();
        } else {
          showInfo(resp.message, 2000, MessageType.error);
        }
      }, error => {
        loadingHide();
        showInfo(error.message || 'error', 3000, MessageType.error);
        console.error('documentService.add ::', JSON.stringify(error));
      });

  }

  private isDocumentContentTooLong() {
    return this.documentContent &&
      this.documentContent.document &&
      this.documentContent.document.documentContent &&
      this.documentContent.document.documentContent.length > 1024 * 1024 * 10;
  }
}
