import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { FolderType, loadingHide, loadingShow, FolderTypeName, showInfo, MessageType, RouteMap, showConfirm } from '../../shared/common';
import { RespMessage, DocumentFolder, PermissionVo1, FolderContent2, PermissionContentVo, ResourceType } from './../../core/model/data-model';
import { FolderService } from '../../core/service';
import { BaseComponent } from '../../shared/component';

@Component({
  selector: 'doc-folder-edit2',
  templateUrl: './folder-edit2.component.html',
  styleUrls: ['./folder-edit2.component.css']
})
export class FolderEdit2Component extends BaseComponent implements OnInit {
  resourceType = ResourceType.folder;
  parentId = -1;
  folderId = 0;
  folderType: FolderType;
  folderTypeName = FolderTypeName;
  deptId = '';
  MEGABYTE = 1048576; // Bytes
  folderContent = {} as FolderContent2;
  documentFolder = {} as DocumentFolder;
  withPermission = false;
  permissionContent: PermissionContentVo;

  constructor(private folderService: FolderService,
    private router: Router, private route: ActivatedRoute) {
    super();
  }

  ngOnInit() {

    this.route.params.subscribe((params) => {
      const data = this.dataDecode(params['data']);
      if (data['folderType']) {
        this.parentId = data['parentId'] >= 0 ? data['parentId'] : -1;
        this.folderId = data['folderId'] || 0;
        this.folderType = data['folderType'];
        this.deptId = data['deptId'] || '';
        this.folderTypeName = FolderTypeName['' + this.folderType];
        loadingShow();


        this.folderService.getEditFolder2({ folderId: this.folderId, folderType: this.folderType })
          .subscribe((resp: RespMessage) => {
            loadingHide();
            if (resp.state === 'Ok') {
              this.folderContent = resp.result;
              this.documentFolder = this.folderContent.folder;
              this.permissionContent = this.folderContent.permissionContent;
              this.withPermission = this.folderType !== 'FAV' && this.documentFolder.canAdmin;
            } else {
              showInfo(resp.message, 2000, MessageType.error);
            }
          }, error => {
            loadingHide();
            showInfo(error.message || 'error', 3000, MessageType.error);
            console.error('loadData ::', JSON.stringify(error));
          });
      }
    });
  }

  goBack() {
    let parentId = 0;
    if (this.parentId !== -1) {
      parentId = this.parentId;
    } else if (this.folderContent.folder != null && this.folderContent.folder.parentFolder != null) {
      parentId = this.folderContent.folder.parentFolder.folderId;
    }
    const url = RouteMap[this.folderType];
    this.router.navigate([url, { data: this.dataEncode({ folderId: parentId, deptId: this.deptId }) }]);
  }

  confirmSave(form: NgForm) {
    if (form.invalid) { return; }
    if (this.withPermission) {
      showConfirm('您確定要變更權限?', '', MessageType.question)
        .then(result => {
          if (!result.value) { return; }
          this.save(form);
        });
    } else {
      this.save(form);
    }
  }

  private save(form: NgForm) {
    const _folderType = this.folderType === FolderType.SHARE_WITH_ME ? FolderType.PRIVATE_FOLDER : this.folderType;
    this.folderContent.permissionContent = this.permissionContent;
    this.folderContent.folder.folderName = this.folderContent.folder.folderName.trim();
    loadingShow();
    const save$ = this.withPermission ?
      this.folderService.saveWithPerm({ folderId: this.folderId, folderType: _folderType, folderContent: this.folderContent }) :
      this.folderService.save2({ folderId: this.folderId, folderType: _folderType, folderContent: this.folderContent });
    save$.subscribe((resp: RespMessage) => {
      loadingHide();
      if (resp.state === 'Ok') {
        this.folderContent = resp.result;
        this.permissionContent = this.folderContent.permissionContent;
        this.documentFolder = this.folderContent.folder;
        showInfo('Success', 2000, MessageType.success);
        this.goBack();
      } else {
        showInfo(resp.message, 2000, MessageType.error);
      }
    }, error => {
      loadingHide();
      showInfo(error.message || 'error', 3000, MessageType.error);
      console.error('folderService.save ::', JSON.stringify(error));
    });
  }

  isCentralFolder(): boolean {
    if (this.folderContent && this.folderContent.folderHierarchy && this.folderContent.folderHierarchy.length >= 1) {
      return this.folderType === 'PUB' && this.folderContent.folderHierarchy[0].folder_name === 'ASUS_Document';
    }
    return false;
  }

  isFolderNameReadOnly(): boolean {
    return !this.documentFolder.canAdmin && this.isCentralFolder();
  }
}

