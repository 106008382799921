import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { DownloadCountRecordDto } from 'app/core/model/data-model';
import { PageComponent } from '../page/page.component';
import { loadingShow, loadingHide, showInfo, popbox, MessageType } from 'app/shared/common';
import { ModalPagedListComponent } from '../modal-paged-list/modal-paged-list.component';
import { DocumentService } from 'app/core/service';

@Component({
  selector: 'doc-download-count-record',
  templateUrl: './download-count-record.component.html',
  styleUrls: ['./download-count-record.component.css']
})
export class DownloadCountRecordComponent extends PageComponent implements OnInit {

  downloadCountRecordDtos: DownloadCountRecordDto[] = [];
  @Input() popCss = 'pop-download';
  @ViewChild('headerPagedList', { static: false }) headerPagedList: ModalPagedListComponent;

  constructor(protected documentService: DocumentService) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  pageChange(vo: { pageSize: number, pageNum: number, pageCount: number }) {
    this.pageSize = vo.pageSize;
    this.pageNum = vo.pageNum;
    this.pageCount = vo.pageCount;
  }

  showRecord(vo: { fileId: number }) {
    loadingShow();
    this.documentService.getDownloadCountRecord(vo)
      .subscribe(resp => {
        loadingHide();
        if (resp.state === 'Ok') {
          this.downloadCountRecordDtos = resp.result;
          this.dataCount = resp.result.length;
          this.pageCount = this.calulatePageCount(this.dataCount, this.pageSize);
          popbox(this.popCss);
        } else {
          showInfo(resp.message || 'warning', 3000, MessageType.warning);
          console.warn(JSON.stringify(resp));
        }
      }, error => {
        loadingHide();
        showInfo(error.message || 'error', 3000, MessageType.error);
        console.error(JSON.stringify(error));
      });
  }
}
