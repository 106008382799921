<div class="page-wrap input-group-space marg6-tb">
  <span class="total text-nowrap">{{ ("TotalCounts_0_"|translate).replace("{0}", dataCount) }}，
    {{ "Rows per Page" | translate }}</span>
  <select class="form-control number" [(ngModel)]="pageSize" (change)="emitPageSize()">
    <option value="100000">{{ "All" | translate }}</option>
    <option value="10">10</option>
    <option value="20">20</option>
    <option value="30">30</option>
    <option value="40">40</option>
    <option value="50">50</option>
    <option value="100">100</option>
  </select>
  <span class="text-nowrap">目前位於</span>
  <select class="form-control number" [(ngModel)]="pageNum" (change)="emitPageNum($event.target.value);">
      <option *ngFor="let item of getPageArray(pageCount); let idx = index " value="{{idx+1}}">{{ ("Page_0_"|translate).replace('{0}', idx+1) }}</option>
  </select>
</div>
