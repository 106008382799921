<div class="hotkey" style="position:fixed" [ngClass]="{ 'display-hotkey': isMobile }" [ngStyle]="{ 'left': clientX+'px', 'top': clientY+'px' }" [style.display]="isVisible ? 'block' : 'none'" (contextmenu)="onContextmenu($event)">
  <ng-container *ngIf="isSingleSelected; else batch ">
    <ul *ngIf="folderContent?.canNewFolder || folderContent?.canNewDoc" (click)="hideContextmenuAndUnselectAll()">
      <li *ngIf="folderContent?.canNewFolder"><a [routerLink]="['/folder/folder-add', {data:dataEncode( {folderType: folderType, folderId: folderId, deptId: deptId }) }]"><i class="mdi mdi-folder"></i>新增資料夾</a></li>
      <li *ngIf="folderContent?.canNewDoc"><a [routerLink]="[ '/folder/document-add', {data:dataEncode( {folderType: folderType, folderId: folderId, deptId: deptId }), hash: hash }]"><i class="mdi mdi-file"></i>新增文件</a></li>
      <li *ngIf="folderContent?.canNewDoc" class="input-file-btn">
        <input type="file" #uploadFilesToFolder multiple (change)="upload()"><a href="javascript:void(0)"><i class="mdi mdi-upload"></i>上傳檔案</a>
      </li>
    </ul>
    <ng-container *ngIf="resource === Resource.folder">
      <ul *ngIf="item.data.canWrite || checkCanDelete()" (click)="hideContextmenuAndUnselectAll()">
        <li *ngIf="item.data.canWrite"><a href="javascript:void(0)" (click)="emitEdit()"><i class="mdi mdi-pencil"></i>編輯資料夾</a></li>
        <li *ngIf="checkCanDelete()"><a href="javascript:void(0)" (click)="emitDelete()" class="swa-delete-file"><i class="mdi mdi-delete"></i>刪除資料夾</a></li>
      </ul>
      <ul *ngIf="item.data.canAdmin" (click)="hideContextmenuAndUnselectAll()">
        <li *ngIf="item.data.canAdmin"><a href="javascript:void(0)" (click)="emitEdit()"><i class="mdi mdi-account-plus"></i>共用設定</a></li>
      </ul>
      <ul (click)="hideContextmenuAndUnselectAll()">
        <li *ngIf="folderType === 'PRI'"><a href="javascript:void(0)" (click)="showShortTermUploadQRCode()" class="show-pop" show-pop="pop-qrcode"><i class="mdi mdi-link"></i>取得上傳連結(10 mins)</a></li>
      </ul>
    </ng-container>
    <ng-container *ngIf="resource === Resource.document">
      <ul (click)="hideContextmenuAndUnselectAll()">
        <li *ngIf="item.data.canWrite"><a href="javascript:void(0)" (click)="emitEdit()"><i class="mdi mdi-pencil"></i>編輯文件</a></li>
        <li><a href="javascript:void(0)" (click)="emitCopyDoc()" class="show-pop" show-pop="show-share"><i class="mdi mdi-file-multiple"></i>複製文件 </a></li>
        <li *ngIf="checkCanDelete()"><a href="javascript:void(0)" (click)="emitDelete()" class="swa-delete-file"><i class="mdi mdi-delete"></i>刪除文件</a></li>
      </ul>
      <ul (click)="hideContextmenuAndUnselectAll()">
        <li *ngIf="item.data.canAdmin"><a href="javascript:void(0)" (click)="emitEdit()"><i class="mdi mdi-account-plus"></i>共用設定</a></li>
      </ul>
      <ul (click)="hideContextmenuAndUnselectAll()">
        <li><a href="javascript:void(0)" (click)="showQRCode()" class="show-pop" show-pop="pop-qrcode"><i class="mdi mdi-link"></i>取得共用連結</a></li>
        <li><a href="javascript:void(0)" (click)="showShortTermDocQRCode()" class="show-pop" show-pop="pop-qrcode"><i class="mdi mdi-link"></i>取得公開連結(10 mins)</a></li>
        <li *ngIf="folderType === 'PRI'"><a href="javascript:void(0)" (click)="showShortTermUploadQRCode()" class="show-pop" show-pop="pop-qrcode"><i class="mdi mdi-link"></i>取得上傳連結(10 mins)</a></li>
      </ul>
      <ul (click)="hideContextmenuAndUnselectAll()">
        <li><a href="javascript:void(0)" (click)="emitAddFav()" class="show-pop" show-pop="show-addfav">
          <i class="mdi mdi-star" [ngClass]="{'yellow':item.data['inFavorite']}"></i>收藏至</a>
        </li>
      </ul>
    </ng-container>
    <ng-container *ngIf="resource === Resource.file">
      <ul (click)="hideContextmenuAndUnselectAll()">
        <li><a href="javascript:void(0)" (click)="download()" ><i class="mdi mdi-download"></i>下載檔案</a></li>
        <li *ngIf="item.data.canWrite"><a href="javascript:void(0)" (click)="emitEdit()"><i class="mdi mdi-pencil"></i>編輯檔案</a></li>
        <li><a href="javascript:void(0)" (click)="emitCopyDoc()" class="show-pop" show-pop="show-share"><i class="mdi mdi-file-multiple"></i>複製檔案 </a></li>
        <li *ngIf="checkCanDelete()"><a href="javascript:void(0)" (click)="emitDelete()"><i class="mdi mdi-delete"></i>刪除檔案</a></li>
      </ul>
      <ul (click)="hideContextmenuAndUnselectAll()">
        <li *ngIf="item.data.canAdmin"><a href="javascript:void(0)" (click)="emitEdit()"><i class="mdi mdi-account-plus"></i>共用設定</a></li>
      </ul>
      <ul (click)="hideContextmenuAndUnselectAll()">
        <li><a href="javascript:void(0)" (click)="showQRCode()" class="show-pop" show-pop="pop-qrcode"><i class="mdi mdi-link"></i>取得共用連結</a></li>
        <li><a href="javascript:void(0)" (click)="showShortTermDocQRCode()" class="show-pop" show-pop="pop-qrcode"><i class="mdi mdi-link"></i>取得公開連結(10 mins)</a></li>
        <li *ngIf="folderType === 'PRI'"><a href="javascript:void(0)" (click)="showShortTermUploadQRCode()" class="show-pop" show-pop="pop-qrcode"><i class="mdi mdi-link"></i>取得上傳連結(10 mins)</a></li>
      </ul>
      <ul (click)="hideContextmenuAndUnselectAll()">
        <li><a href="javascript:void(0)" (click)="emitAddFav()" class="show-pop" show-pop="show-addfav">
          <i class="mdi mdi-star" [ngClass]="{'yellow':item.data['inFavorite']}"></i>收藏至</a>
        </li>
      </ul>
    </ng-container>
  </ng-container>
  <ng-template #batch>
    <ul (click)="hideContextmenuAndUnselectAll()">
      <li><a href="javascript:void(0)" (click)="emitBatchDownload()" ><i class="mdi mdi-download"></i>批次下載</a></li>
      <li><a href="javascript:void(0)" (click)="emitBatchDelete()"><i class="mdi mdi-delete"></i>批次刪除</a></li>
    </ul>
  </ng-template>
</div>
<div #cloneContextmenu class="hotkey" style="position:fixed;visibility:hidden;">
  <ng-container *ngIf="isSingleSelected; else batchClone ">
    <ul *ngIf="folderContent?.canNewFolder || folderContent?.canNewDoc">
      <li *ngIf="folderContent?.canNewFolder"><a><i class="mdi mdi-folder"></i>新增資料夾</a></li>
      <li *ngIf="folderContent?.canNewDoc"><a><i class="mdi mdi-file"></i>新增文件</a></li>
      <li *ngIf="folderContent?.canNewDoc" class="input-file-btn"><input type="file"><a href="javascript:void(0)"><i class="mdi mdi-upload"></i>上傳檔案</a></li>
    </ul>
    <ng-container *ngIf="resource === Resource.folder">
      <ul *ngIf="item.data.canWrite || checkCanDelete()">
        <li *ngIf="item.data.canWrite"><a href="javascript:void(0)"><i class="mdi mdi-pencil"></i>編輯資料夾</a></li>
        <li *ngIf="checkCanDelete()"><a href="javascript:void(0)" class="swa-delete-file"><i class="mdi mdi-delete"></i>刪除資料夾</a></li>
      </ul>
      <ul *ngIf="item.data.canWrite">
        <li *ngIf="item.data.canWrite"><a href="javascript:void(0)"><i class="mdi mdi-account-plus"></i>共用設定</a></li>
      </ul>
      <ul>
        <li *ngIf="folderType === 'PRI'"><a href="javascript:void(0)"><i class="mdi mdi-link"></i>取得上傳連結(10 mins)</a></li>
      </ul>
    </ng-container>
    <ng-container *ngIf="resource === Resource.document">
      <ul>
        <li *ngIf="item.data.canWrite"><a href="javascript:void(0)" ><i class="mdi mdi-pencil"></i>編輯文件</a></li>
        <li><a href="javascript:void(0)" class="show-pop" show-pop="show-share"><i class="mdi mdi-file-multiple"></i>複製文件 </a></li>
        <li *ngIf="checkCanDelete()"><a href="javascript:void(0)" class="swa-delete-file"><i class="mdi mdi-delete"></i>刪除文件</a></li>
      </ul>
      <ul>
        <li *ngIf="item.data.canWrite"><a href="javascript:void(0)"><i class="mdi mdi-account-plus"></i>共用設定</a></li>
      </ul>
      <ul>
        <li><a href="javascript:void(0)"><i class="mdi mdi-link"></i>取得共用連結</a></li>
        <li><a href="javascript:void(0)"><i class="mdi mdi-link"></i>取得公開連結(10 mins)</a></li>
        <li *ngIf="folderType === 'PRI'"><a href="javascript:void(0)"><i class="mdi mdi-link"></i>取得上傳連結(10 mins)</a></li>
      </ul>
      <ul>
        <li><a href="javascript:void(0)" class="show-pop" show-pop="show-addfav"><i class="mdi mdi-star yellow"></i>收藏至 </a></li>
      </ul>
    </ng-container>
    <ng-container *ngIf="resource === Resource.file">
      <ul>
        <li><a href="javascript:void(0)"><i class="mdi mdi-download"></i>下載檔案 </a></li>
        <li *ngIf="item.data.canWrite"><a href="javascript:void(0)"><i class="mdi mdi-pencil"></i>編輯檔案</a></li>
        <li><a href="javascript:void(0)" class="show-pop" show-pop="show-share"><i class="mdi mdi-file-multiple"></i>複製檔案</a></li>
        <li *ngIf="checkCanDelete()"><a href="javascript:void(0)"><i class="mdi mdi-delete"></i>刪除檔案</a></li>
      </ul>
      <ul>
        <li *ngIf="item.data.canWrite"><a href="javascript:void(0)"><i class="mdi mdi-account-plus"></i>共用設定</a></li>
      </ul>
      <ul>
        <li><a href="javascript:void(0)"><i class="mdi mdi-link"></i>取得共用連結</a></li>
        <li><a href="javascript:void(0)"><i class="mdi mdi-link"></i>取得公開連結(10 mins)</a></li>
        <li *ngIf="folderType === 'PRI'"><a href="javascript:void(0)"><i class="mdi mdi-link"></i>取得上傳連結(10 mins)</a></li>
      </ul>
      <ul>
        <li><a href="javascript:void(0)" class="show-pop" show-pop="show-addfav"><i class="mdi mdi-star"></i>收藏至 </a></li>
      </ul>
    </ng-container>
  </ng-container>
  <ng-template #batchClone>
    <ul>
      <li><a href="javascript:void(0)" (click)="emitBatchDownload()" ><i class="mdi mdi-download"></i>批次下載</a></li>
      <li><a href="javascript:void(0)" (click)="emitBatchDelete()"><i class="mdi mdi-delete"></i>批次刪除</a></li>
    </ul>
  </ng-template>
</div>

<div class="popbg-fill va-center pop-qrcode" style="z-index: 99;">
  <div class="pop-box pop-size-350">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close"><i class="mdi mdi-close"></i></button>
        <h4>取得連結</h4>
      </div>
      <div class="modal-body">
        <p class="center gray marg4-b">掃描 QR Code 取得連結</p>
        <div class="QRcode-position">
          <!-- <doc-qrcode-ex [qrdata]="qrdata" [width]="192" [allowEmptyString]="true" ></doc-qrcode-ex> -->
          <qrcode #foo [qrdata]="qrdata" [width]="224" [errorCorrectionLevel]="'M'" [allowEmptyString]="true"></qrcode>
        </div>
        <p class="center gray marg4-b marg20-t">或點擊按鈕取得連結</p>
        <div class="center">
          <a class="btn btn-primary btn-sm copyReadyBtn" href="javascript:void(0)" (click)="copyQRCodeUrl($event)" >複製連結</a>
          <p class="copyReady"><span>已將連結複製到剪貼簿</span><i class="mdi mdi-close" (click)="onCopyReadyClose($event)"></i></p>
        </div>
      </div>
    </div>
  </div>
</div>
