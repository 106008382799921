<div class="popbg-fill va-center show-addfav {{popCss}}" style="z-index: 99;" >
  <div class="pop-box pop-size-450">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close">
          <i class="mdi mdi-close"></i>
        </button>
        <h4>{{ "Add to the following folders" | translate }}</h4>
      </div>
      <div class="modal-body">
        <div class="tree-wrap">
          <nav class="tree">
            <ul class="nav-main favFile">
              <li>
                <a class="main-title" href="javascript:void(0)" (click)="pickTreeNode(userAllNode?.favorite_treenode_root,'FAV')">{{ "My Favorite" | translate}}
                  <i class="mdi mdi-menu-right toggle-icon show-icon" docToggleTreeNode (click)="getTreeNodes(userAllNode?.favorite_treenode_root,'FAV')"></i>
                </a>
                <ul class="show" *ngIf="userAllNode?.favorite_treenode_root?.childNode.length>0 ">
                  <doc-pick-tree-node *ngFor="let item of userAllNode?.favorite_treenode_root?.childNode" [folderId]="item.node_id" folderType='FAV'
                    [curNode]='item'></doc-pick-tree-node>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div class="modal-footer center">
          {{pickNode}}
        <br>
        <a class="btn btn-sm btn-dark close-btn marg6-r" href="javascript:void(0)" (click)="form.reset()">
          <i class="mdi mdi-reply"></i>{{ "Cancel" | translate }}</a>
        <a class="btn btn-sm btn-info marg6-r show-pop" (click)="showNewFolder()">
          <i class="mdi mdi-folder-plus"></i>{{ "Create Subfolder" | translate }} </a>
        <a class="btn btn-sm btn-primary close-btn marg6-r" (click)="addFavorite()" >
          <i class="mdi mdi-star"></i>{{ "Add to Favorite" | translate }} </a>
      </div>
    </div>
  </div>
</div>

<div class="popbg-fill va-center show-newfile" style="z-index: 199;">
  <div class="pop-box pop-size-450">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close">
          <i class="mdi mdi-close"></i>
        </button>
        <h4>{{ "Enter Folder Name" | translate }}</h4>
      </div>
      <div class="modal-body">
        <form #form="ngForm">
          <dl>
            <dt class="required-title">{{ "Enter Folder Name" | translate }}</dt>
            <dd>
              <input class="form-control" [ngClass]="{'input-validation-error':(foldername.errors?.required)}"  maxlength="100" required name="folderName"
                #foldername="ngModel" ngModel [(ngModel)]="folderName">
              <span *ngIf="foldername?.errors?.required && foldername.touched" class="field-validation-error">{{ "Required" | translate }}</span>
            </dd>
          </dl>
        </form>
      </div>
      <div class="modal-footer center">
        <a class="btn btn-sm btn-dark close-btn marg6-r" (click)="form.reset()">
          <i class="mdi mdi-reply"></i>{{ "Cancel" | translate }}</a>
        <a class="btn btn-sm btn-primary close-btn" (click)="newFolder(form)">
          <i class="mdi mdi-plus"></i>{{ "Create1" | translate }} </a>
      </div>
    </div>
  </div>
</div>
