import { TreeNode, DocumentContent, DeleteResourceMailVo} from './../../core/model/data-model';
import { FolderTypeName, showConfirm, RouteMap, popbox } from './../../shared/common';
import { Component, OnInit, OnDestroy, NgZone, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { showInfo, loadingHide, MessageType, loadingShow, FolderType } from '../../shared/common';
import { RespMessage, FolderContent, DocumentFolder, DocumentDto, DataRow } from '../../core/model/data-model';
import { PagedListComponent } from '../../shared/component/paged-list/paged-list.component';
import { PickFavoriteFolderComponent } from '../../shared/component/pick-favorite-folder/pick-favorite-folder.component';
import { TreeViewComponent } from '../../shared/component/tree-view/tree-view.component';
import { FolderCanWriteTreeService, FolderService, DocumentService, FolderTreeService, DownloadService } from '../../core/service';
import { PickTreeCloneDocComponent } from '../../shared/component/pick-tree-clone-doc/pick-tree-clone-doc.component';
import { DocumentViewComponent } from '../document-view/document-view.component';
import { BreadcrumbsComponent } from '../../shared/component/breadcrumbs/breadcrumbs.component';
import { PageComponent } from '../../shared/component/page/page.component';
import { HitCountRecordComponent } from 'app/shared/component';
import { DocumentContextmenuService } from '../../core/service/document-contextmenu.service';
import { Observable } from 'rxjs';
import { FolderDocumentService } from 'app/core/service/folder-document.service';

@Component({
  selector: 'doc-base-folder',
  templateUrl: './base-folder.component.html',
  styleUrls: ['./base-folder.component.css']
})
export class BaseFolderComponent extends PageComponent implements OnInit {

  folderId = 0;
  folderType: FolderType;
  folderTypeName: FolderTypeName;
  folderName = '';
  pickTreeNode: TreeNode;
  pickTreeNodeCloneDoc: TreeNode;
  folderContent: FolderContent;
  sortBy = '';
  sortType = '';
  sortByCols = {
    'typeCss': '',
    'name': '',
    'modifyTime': '',
    'readCount': '',
  };
  pickDocument: DocumentDto;
  resultList: DataRow[] = [];
  pageList: DataRow[] = [];
  hash = location.hash;
  isFloatActed = false;

  @ViewChild('documentView', { static: false }) documentViewComponent: DocumentViewComponent;
  @ViewChild('headerPagedList', { static: false }) headerPagedList: PagedListComponent;
  @ViewChild(BreadcrumbsComponent, { static: false }) breadcrumbsComponent: BreadcrumbsComponent;
  constructor(protected folderTreeService: FolderTreeService, protected folderCanWriteTreeService: FolderCanWriteTreeService,
    protected folderService: FolderService, protected documentService: DocumentService,
    protected folderDocumentService: FolderDocumentService, protected downloadService: DownloadService,
    protected contextmenuService: DocumentContextmenuService, protected router: Router) {
    super();
  }

  ngOnInit() {
    this.contextmenuService.preDataRow = null;
  }


  query(sortBy = '', sortType = '', pageSize = 100, pageNum = 1) {
    loadingShow();
    if(sortBy == ''){
      sortBy = 'name';
      sortType = 'ASC';
    }
    this.folderTreeService.getDisplayFolder({ folderId: this.folderId, folderType: this.folderType }, sortBy, sortType, pageSize, pageNum)
      .subscribe(re => {
        loadingHide();
        const resp: RespMessage = re;
        if (resp.state === 'Ok') {
          this.dataCount = resp.dataCount;
          this.updatePageList();
          this.resultList = [];
          this.folderContent = resp.result;
          if (this.folderContent.folder == null) {
            this.folderName = this.folderTypeName;
          } else {
            this.folderName = this.folderContent.folder.folderName;
          }
          this.folderContent.childFolders.map(
            fol => this.resultList.push(this.getFolderDataRow(fol))
          );
          this.folderContent.documents.map(
            doc => this.resultList.push(this.getDocDataRow(doc))
          );
          this.resultList.forEach(row => row.typeCss = this.getTypeCss(row.data));
          this.pageList = this.resultList;
          // this.sortBy = '';
          // this.sortType = '';
          // this.showList();
          this.breadcrumbsComponent.updateBreadcrumbsInfo({ folderId: this.folderId, folderType: this.folderType, folderTypeName: this.folderTypeName, folderHierarchy: this.folderContent.folderHierarchy });
          // console.log('getDisplayFolder', this.folderContent);
        } else {
          showInfo(resp.message || 'warning', 3000, MessageType.warning);
        }
      }, error => {
        loadingHide();
        showInfo(error.message || 'error', 3000, MessageType.error);
        console.error('folderTreeService.getDisplayFolder::', JSON.stringify(error));
      });
  }

  pageChange(val: { pageSize: number, pageNum: number, pageCount: number }) {
    // console.log('pageChange', val);
    this.pageSize = val.pageSize;
    this.pageNum = val.pageNum;
    this.pageCount = val.pageCount;
    // this.updatePageList();
    this.query(this.sortBy, this.sortType, this.pageSize, this.pageNum);
    // this.showList();z
  }

  sortChange(sortByCol) {
    for (const col in this.sortByCols) {
      if (sortByCol === col) {
        if (this.sortByCols[sortByCol] === '' || this.sortByCols[sortByCol] === 'DESC') {
          this.sortByCols[sortByCol] = 'ASC';
        } else {
          this.sortByCols[sortByCol] = 'DESC';
        }
        this.sortBy = sortByCol;
        this.sortType = this.sortByCols[sortByCol];
      } else {
        this.sortByCols[col] = '';
      }
    }
    // this.sortByCols = { ...this.sortByCols };
    console.log('pageCount', this.pageCount);
    if (this.pageCount > 1) {
      this.query(this.sortBy, this.sortType, this.pageSize, this.pageNum);
    } else {
      this.showList();
    }
  }

  showList() {
    if (this.pageNum === 0) {
      this.pageNum = 1;
    }
    const start = (this.pageNum - 1) * this.pageSize;
    const end = start + this.pageSize;
    this.resultList = this.resultListSort(this.resultList);
    this.pageList = this.resultList.slice(start, end);
  }

  updatePageList() {
    this.headerPagedList.dataCount = this.dataCount;
    this.headerPagedList.pageNum = this.pageNum;
    this.headerPagedList.pageSize = this.pageSize;
    this.headerPagedList.updatePagedListInfo();
    this.pageCount = this.headerPagedList.pageCount;
  }

  protected checkCanDelete(data: DataRow) {
    return data.data.canAdmin;
    // if (data.type === 'folder') {
    //   const folder = data.data as DocumentFolder;
    //   const haveChild = folder.haveChild || '0';
    //   return data.data.canAdmin && haveChild.trim() === '0';
    // } else {
    //   const doc = data.data as DocumentDto;
    //   return data.data.canAdmin;
    // }
  }

  action(data: DataRow, event: MouseEvent) {
    // few folder component action method has no event param
    // enter folder or open doc file when click without ctrl or shift, contextmenu invisible, no data selected
    if (event && (event.ctrlKey || event.shiftKey)) { return; }
    if (this.contextmenuService.isVisible || this.pageList.some(m => m.isSelected)) { return; }

    if (data.type === 'folder') {
      const folder = data.data as DocumentFolder;
      const url = RouteMap[this.folderType];
      this.router.navigate([url, { data: this.dataEncode({ folderId: folder.folderId, deptId: folder.departmentId }) }]);
    } else {
      const doc = data.data as DocumentDto;
      this.openDoc(doc);
    }
  }

  hideContextmenuAndUnselectAll(event: MouseEvent) {
    // if (event.ctrlKey || event.shiftKey) { return; }
    if (this.contextmenuService.isVisible) {
      this.contextmenuService.contextmenuDtoSubject.next(null);
      this.contextmenuService.preDataRow = null;
    }
    if (!event.ctrlKey && !event.shiftKey) {
      this.contextmenuService.preDataRow = null;
      this.pageList
        .filter(m => m.isSelected)
        .forEach(m => { m.isSelected = false; });
    }
  }

  openDoc(doc: DocumentDto) {
    loadingShow();
    this.documentService.openDoc({ folderId: this.folderId, folderType: this.folderType, docId: doc.documentId })
      .subscribe((resp: RespMessage) => {
        loadingHide();
        if (resp.state === 'Ok') {
          const documentContent: DocumentContent = resp.result;
          this.documentViewComponent.show(documentContent);
          popbox(this.documentViewComponent.popCss);
        } else {
          showInfo(resp.message, 2000, MessageType.error);
        }
      }, error => {
        loadingHide();
        showInfo(error.message || 'error', 3000, MessageType.error);
        console.error('documentService.openDoc ::', JSON.stringify(error));
      });
  }

  edit(data: DataRow) {
    if (data.type === 'folder') {
      const folder = data.data as DocumentFolder;
      this.router.navigate([`/folder/folder-edit`,
        { data: this.dataEncode({ folderType: this.folderType, folderId: folder.folderId }) }]);
    } else {
      const doc = data.data as DocumentDto;
      if (data.type === 'doc' && doc.isArchive === '1') {
        this.router.navigate([`/folder/archives-edit`,
          { data: this.dataEncode({ folderType: this.folderType, folderId: this.folderId, docId: doc.documentId }), hash: this.hash }]);
      } else {
        this.router.navigate([`/folder/document-edit`,
          { data: this.dataEncode({ folderType: this.folderType, folderId: this.folderId, docId: doc.documentId }), hash: this.hash }]);
      }
    }
  }

  delete(data: DataRow) {
    showConfirm(`資料刪除後將無法喚回`, `您確定要刪除${data.name}?`, MessageType.question)
      .then(result => {
        if (!result.value) { return; }

        let del$: Observable<RespMessage<boolean>>;
        if (data.type === 'folder') {
          const folder = data.data as DocumentFolder;
          // del$ = this.folderService.delete({ folderId: folder.folderId, folderType: this.folderType });
          del$ = this.folderService.deleteAll({ folderId: folder.folderId, folderType: this.folderType });
        } else {
          const doc = data.data as DocumentDto;
          del$ = this.documentService.delete({ folderId: this.folderId, folderType: this.folderType, docId: doc.documentId });
        }

        loadingShow();
        del$.subscribe((resp: RespMessage) => {
          loadingHide();
          if (resp.state === 'Ok') {
            this.resultList = this.resultList.filter(r => r !== data);
            this.dataCount--;
            this.updatePageList();
            this.showList();
            this.updateTreeView();
            this.afterDelete();
            showInfo('Success', 2000, MessageType.success);
          } else if (resp.state === 'Bad') {
            showInfo(resp.message, 5000, MessageType.warning);
          } else {
            showInfo(resp.message, 3000, MessageType.error);
          }
        }, error => {
          loadingHide();
          showInfo(error.message || 'error', 3000, MessageType.error);
          const errTitle = data.type === 'folder' ? 'folderService.delete::' : 'documentService.delete::';
          console.error(errTitle, JSON.stringify(error));
        });
      });
  }

  protected afterDelete() {
    // donothing, but private folder getMyFileSize
  }

  showAddfav(data: DataRow, pickFavoriteFolder: PickFavoriteFolderComponent) {
    const doc = data.data as DocumentDto;
    if (doc.inFavorite) {
      this.delfav(data);
    } else {
      pickFavoriteFolder.documentDto = doc;
      pickFavoriteFolder.show();
    }
  }

  public onAddfav(doc: DocumentDto, pickFavoriteFolder: PickFavoriteFolderComponent) {
    const data = { type: 'doc', name: doc.documentName, readCount: doc.readCount, modifyTime: doc.modifyTime, data: doc };
    this.showAddfav(data, pickFavoriteFolder);
  }

  protected delfav(data: DataRow) {
    const doc = data.data as DocumentDto;
    showConfirm(`移除收藏`, `您確定要刪除${doc.documentName}?`, MessageType.question)
      .then(
        result => {
          if (result.value) {
            loadingShow();
            this.folderService.deleteFavoriteByDocId({ docId: doc.documentId })
              .subscribe((resp: RespMessage) => {
                loadingHide();
                if (resp.state === 'Ok') {
                  doc.inFavorite = false;
                  showInfo('Success', 2000, MessageType.success);
                } else {
                  showInfo(resp.message, 2000, MessageType.error);
                }
              }, error => {
                loadingHide();
                showInfo(error.message || 'error', 3000, MessageType.error);
                console.error('folderService.deleteFavoriteByDocId ::', JSON.stringify(error));
              });
          }
        },
        reject => { }
      );
  }

  public getPickTreeNode(selected: boolean) {
    if (selected) {
      this.pickTreeNode = this.folderTreeService.pickTreeNode;
    }
  }

  public getPickTreeNodeCloneDoc(treeNode: TreeNode) {
    this.pickTreeNodeCloneDoc = treeNode;
    if (this.pickTreeNodeCloneDoc != null && this.pickDocument != null && this.pickTreeNodeCloneDoc.node_id > 0 && this.pickDocument.documentId > 0) {
      const folderType = <FolderType>treeNode.folderTyp;
      loadingShow();
      this.documentService.clone2Folder({ folderId: this.pickTreeNodeCloneDoc.node_id, folderType: folderType, docId: this.pickDocument.documentId })
        .subscribe((resp: RespMessage) => {
          loadingHide();
          if (resp.state === 'Ok') {
            showInfo('Success', 2000, MessageType.success);
          } else {
            showInfo(resp.message, 2000, MessageType.error);
          }
        }, error => {
          loadingHide();
          showInfo(error.message || 'error', 3000, MessageType.error);
          console.error('documentService.clone2Folder ::', JSON.stringify(error));
        });
    }
  }

  protected goBack() {
    let parentId = 0;
    if (this.folderContent.folder != null && this.folderContent.folder.parentFolder != null) {
      parentId = this.folderContent.folder.parentFolder.folderId;
    }
    const url = RouteMap[this.folderType];
    this.router.navigate([url, { data: this.dataEncode({ folderId: parentId }) }]);
  }

  protected updateTreeView() {
    let parentId = 0;
    let refNode: TreeNode;
    if (this.folderContent.folder == null || this.folderContent.folder.parentFolder == null) {
      const allRoot = this.folderTreeService.currentFolderAllRoot();
      switch (this.folderType) {
        case FolderType.PRIVATE_FOLDER:
          refNode = allRoot.private_treenode_root;
          break;
        case FolderType.DEPT_FOLDER:
          refNode = allRoot.dept_treenode_root;
          break;
        case FolderType.SYS_FOLDER:
          refNode = allRoot.system_treenode_root;

          break;
        case FolderType.SHARE_WITH_ME:
          refNode = allRoot.shareWithMe_treenode_root;

          break;
        case FolderType.PUBLIC_FOLDER:
          refNode = allRoot.public_treenode_root;

          break;
        case FolderType.FAVORITE_FOLDER:
          refNode = allRoot.favorite_treenode_root;
          break;

        default:
          break;
      }
      if (refNode != null) {
        this.folderTreeService.getTreeNodes({ folderId: parentId, folderType: this.folderType, curNode: refNode })
          .subscribe(_ => { });
      }
    } else {
      parentId = this.folderContent.folder.parentFolder.folderId;
      const comp = this.folderTreeService.openTreeViewComponents.find(com => com instanceof TreeViewComponent && com.curNode.node_id === parentId);
      if (comp != null) {
        comp.getTreeNodes(comp.curNode, comp.folderType);
      }
    }
  }


  resultListSort(resultList: DataRow[]) {
    // DataRow type: 'folder' 'doc'
    const compareTypeFn = (cur: DataRow, nex: DataRow) => {
      return cur.type === nex.type ? 0 : (cur.type === 'folder' ? -1 : 1);
    };
    const compareColFn = (cur: DataRow, nex: DataRow) => {
      return this.getCompareVal(cur) < this.getCompareVal(nex) ? -1 : 1;
    };
    const direction = this.sortType === 'ASC' ? 1 : -1;
    return resultList.sort((cur, nex) => compareTypeFn(cur, nex) || (compareColFn(cur, nex) * direction));
  }

  showPickTree(data: DataRow, pickTree: PickTreeCloneDocComponent) {
    this.pickDocument = data.data as DocumentDto;
    pickTree.show();
  }

  showHitCountRecord(data: DataRow, hitCountRecord: HitCountRecordComponent) {
    if (data.type !== 'folder') {
      const dto = data.data as DocumentDto;
      hitCountRecord.showRecord({ docId: dto.documentId, folderId: this.folderId });
    }
  }

  toggleFloatAction() {
    this.isFloatActed = !this.isFloatActed;
  }

  onDocumentViewHitCountClick(dto: DocumentDto, hitCountRecord: HitCountRecordComponent) {
    hitCountRecord.showRecord({ docId: dto.documentId, folderId: this.folderId });
  }

  openContextmenu(e: MouseEvent, dataRow: DataRow) {
    e.stopPropagation();
    this.contextmenuService.contextmenuDtoSubject.next({ dataRow: dataRow, clientX: e.clientX, clientY: e.clientY });
  }

  protected getFolderDataRow(fol: DocumentFolder): DataRow {
    return { type: 'folder', folderType: fol.folderType, name: fol.folderName, isArchive: false, user: fol.owner, modifyTime: fol.modifyTime, readCount: -1, data: fol };
  }

  protected getDocDataRow(doc: DocumentDto): DataRow {
    return { type: 'doc', folderType: doc.belongTo, name: doc.documentName, isArchive: doc.isArchive === '1', user: doc.createUser, modifyTime: doc.modifyTime, readCount: doc.readCount, data: doc };
  }

  private getCompareVal(cur: DataRow): any {
    switch (this.sortBy) {
      case 'typeCss':
        return cur.typeCss;
      case 'modifyTime':
        return new Date(cur.modifyTime).getTime();
      case 'readCount':
        return cur.readCount;
      case 'name':
        return cur.name;
      default:
        return new Date(cur.modifyTime).getTime();
    }
  }

  batchDownload() {
    // this.folderContent.folder may null
    const docIds = this.pageList
      .filter(m => m.isSelected && m.isArchive)
      .map(m => {
        const doc = m.data as DocumentDto;
        return doc.documentId;
      });
    if (docIds.length === 0) { return; }
    const nameHierarchy = (this.folderContent.folder && this.folderContent.folder.nameHierarchy) || [];
    const zipName = nameHierarchy[nameHierarchy.length - 1] || 'archive';
    this.downloadService.batchDownload(this.folderType, docIds, `${zipName}.zip`);
  }

  confirmBatchDelete() {
    const selectedItems = this.pageList.filter(m => m.isSelected && m.data.canAdmin);
    if (selectedItems.length === 0) { return; }
    const concatName = selectedItems.map(m => m.name).join('<br/>');
    showConfirm(`資料刪除後將無法喚回`, `您確定要刪除?<br/>${concatName}`, MessageType.question)
      .then(result => {
        if (!result.value) { return; }
        this.batchDelete(selectedItems);
      }, error => {
        loadingHide();
        showInfo(error.message || 'error', 3000, MessageType.error);
        console.error('batchDelete', JSON.stringify(error));
      });
  }

  batchDelete(selectedItems: DataRow[]) {
    const selectedFolders = selectedItems.filter(m => m.type === 'folder');
    const selectedDocs = selectedItems.filter(m => m.type === 'doc');
    const folderIds = selectedFolders
      .map(m => (m.data as DocumentFolder).folderId);
    const docIds = selectedDocs
      .map(m => (m.data as DocumentDto).documentId);
    const deleteResourceMailVo = this.getDeleteResourceMailVO(selectedFolders, selectedDocs);

    loadingShow();
    this.folderDocumentService.batchDelete({ folderIds, docIds, folderType: this.folderType, deleteResourceMailVo })
      .subscribe(resp => {
        loadingHide();
        if (resp.state === 'Ok') {
          const selectedSet = new Set(selectedItems);
          this.resultList = this.resultList.filter(m => !selectedSet.has(m));
          this.dataCount -= selectedItems.length;
          this.updatePageList();
          this.showList();
          this.updateTreeView();
          showInfo('Success', 2000, MessageType.success);
        } else {
          showInfo('批次刪除失敗', 3000, MessageType.error);
          setTimeout(() => { this.refresh(); }, 3000);
        }
      }, err => {
        loadingHide();
        showInfo('批次刪除失敗', 3000, MessageType.error);
        setTimeout(() => { this.refresh(); }, 3000);
        console.error(err);
      });
  }

  private getDeleteResourceMailVO(selectedFolders: DataRow[], selectedDocs: DataRow[]) {
    // thie information of mail when delete folder, document, archive
    const hierachyNames = (this.folderContent.folderHierarchy && this.folderContent.folderHierarchy.map(m => m.folder_name)) || [];
    const folderIdNames = selectedFolders.map(
      m => ({
        id: (m.data as DocumentFolder).folderId,
        name: (m.data as DocumentFolder).folderName
      }));
    const documentIdNames = selectedDocs
      .filter(m => !m.isArchive)
      .map(m => ({
        id: (m.data as DocumentDto).documentId,
        name: (m.data as DocumentDto).documentName
      }));
    const archiveIdNames = selectedDocs
      .filter(m => m.isArchive)
      .map(m => ({
        id: (m.data as DocumentDto).documentId,
        name: (m.data as DocumentDto).documentName
      }));
    const vo: DeleteResourceMailVo = {
      hierachyNames: hierachyNames,
      folderIdNames: folderIdNames,
      documentIdNames: documentIdNames,
      archiveIdNames: archiveIdNames
    };
    return vo;
  }

  selectAllChange(value: boolean) {
    this.pageList.forEach(v => {
      v.isSelected = value;
    });
  }

  refresh() {
    const url = RouteMap[this.folderType];
    this.router.navigate([url, { data: this.dataEncode({ folderId: this.folderId }) }]);
  }
}
