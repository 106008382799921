import { Component, OnInit, Input } from '@angular/core';
// import { popbox, closePopbox } from 'app/shared/common';
import { DocumentService, FolderTreeService } from 'app/core/service';
import { loadingShow, loadingHide, showInfo, MessageType, dataDecode, FolderType } from 'app/shared/common';
import { DocumentDto, PickMulDocVo, PickMulFolderDropDownVo, PickMulFolderVo, PickMulFolderOptionVo, FolderContent } from 'app/core/model/data-model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'doc-pick-mul-clone-doc',
  templateUrl: './pick-mul-clone-doc.component.html',
  styleUrls: ['./pick-mul-clone-doc.component.css'],
})
export class PickMulCloneDocComponent implements OnInit {
  // @Input() pickCss = 'pop-share';
  // @Input() pickMulCss = 'pop-share-mul';
  @Input() folderType: FolderType;
  @Input() curFolderNameHierarchy: string[] = [];

  private curFolderId = 0;
  // lookup: string;
  docs: PickMulDocVo[] = [];
  selectedDocsDataCount = 0;

  folderDropDownVos: PickMulFolderDropDownVo[] = [];
  get folderDropDownVo(): PickMulFolderDropDownVo {
    return this.folderDropDownVos[this.folderDropDownVos.length - 1];
  }

  folderss: PickMulFolderVo[][] = [];
  get folders(): PickMulFolderVo[] {
    return this.folderss[this.folderss.length - 1] || [];
  }

  get filterFolders(): PickMulFolderVo[] {
    const folders = this.folders;
    const folderDropDownVo = this.folderDropDownVo;
    if (!folderDropDownVo) {
      return [];
    }
    if (folderDropDownVo.allVo.selected) {
      return folders;
    }
    const folderNames = folderDropDownVo.optionVos.filter(v => v.selected).map(v => v.folderName);
    if (folderNames.length === 0) {
      return folders;
    }
    const filterFolders = folders.filter(v => folderNames.indexOf(v.folderName) > -1);
    return filterFolders;
  }

  selectedFolders: PickMulFolderVo[] = [];
  constructor(private documentService: DocumentService, private folderTreeService: FolderTreeService, private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      const data = dataDecode(params['data']);
      if ('folderId' in data) {
        this.curFolderId = data['folderId'];
        this.queryDocs();
      }
    });
  }

  queryDocs() {
    this.folderTreeService.getDisplayAllDocs({ folderId: this.curFolderId, folderType: this.folderType })
      .subscribe(resp => {
        if (resp.state === 'Ok') {
          this.docs = resp.result.documents.map(v => ({ isSelected: false, ...v }));
          console.log('resp.result.documents', resp.result.documents);
        } else {
          showInfo(resp.message, 2000, MessageType.error);
        }
      }, error => {
        showInfo(error.message || 'error', 3000, MessageType.error);
        console.error('folderTreeService.getDisplayAllDocs::', JSON.stringify(error));
      });
  }

  queryFolders() {
    if (this.folderDropDownVo && this.folderDropDownVo.optionVos.filter(v => v.selected).length === 0) {
      return;
    }
    const parentFolderIds = this.getSelectedFolderIds();
    console.log('queryFolders::parentFolderIds', parentFolderIds);
    this.folderTreeService.getDisplayOnlyFolders({ parentFolderIds: parentFolderIds, folderType: this.folderType })
      .subscribe(resp => {
        if (resp.state === 'Ok') {
          const newFolder = this.createFolders(resp.result, this.folders);
          if (newFolder.length > 0) {
            const newSelectVo = this.createSelectVo(newFolder);
            this.folderss.push(newFolder);
            this.folderDropDownVos.push(newSelectVo);
          } else {
            showInfo('查無資料', 2000, MessageType.success);
          }
          // console.log('folders', this.folders);
          // console.log('folderDropDownVo', this.folderDropDownVo);
        } else {
          showInfo(resp.message, 2000, MessageType.error);
        }
      }, error => {
        showInfo(error.message || 'error', 3000, MessageType.error);
        console.error('folderTreeService.getDisplayAllDocs::', JSON.stringify(error));
      });
  }

  clone() {
    if (!this.canClone()) {
      return;
    }
    const docIds = this.docs.filter(v => v.isSelected).map(v => v.documentId);
    const folderIds = this.selectedFolders.map(v => v.folderId);
    loadingShow();
    this.documentService.clone2Folders({ docIds: docIds, folderIds: folderIds })
      .subscribe(resp => {
        loadingHide();
        if (resp.state === 'Ok') {
          showInfo('Success', 2000, MessageType.success);
        } else {
          showInfo(resp.message, 2000, MessageType.error);
        }
      }, error => {
        loadingHide();
        showInfo(error.message || 'error', 3000, MessageType.error);
        console.error('documentService.clone2Folder ::', JSON.stringify(error));
      });
  }

  canClone() {
    return this.selectedDocsDataCount > 0 && this.selectedFolders.length > 0;
  }

  backDefault() {
    this.selectedDocsDataCount = 0;
    this.docs = [];
    this.folderDropDownVos = [];
    this.folderss = [];
    this.selectedFolders = [];
  }

  toggleDoc(doc: PickMulDocVo) {
    doc.isSelected = !doc.isSelected;
    this.selectedDocsDataCount += (doc.isSelected ? 1 : -1);
  }

  toggleFolder(folder: PickMulFolderVo) {
    if (!folder.canWrite && !folder.canAdmin) {
      return;
    }
    folder.isSelected = !folder.isSelected;
    if (folder.isSelected) {
      this.selectFolder(folder);
    } else {
      this.unselectFolder(folder);
    }
  }

  selectFolder(folder: PickMulFolderVo) {
    folder.isSelected = true;
    this.selectedFolders.push(folder);
  }

  unselectFolder(folder: PickMulFolderVo) {
    folder.isSelected = false;
    const index = this.selectedFolders.indexOf(folder);
    if (index !== -1) {
      this.selectedFolders.splice(index, 1);
    }
  }

  toggleFolderAllVo(folderDropDownVo: PickMulFolderDropDownVo, idx: number) {
    this.folderDropDownVos.splice(idx + 1);
    this.folderss.splice(idx + 1);
    folderDropDownVo.allVo.selected = !folderDropDownVo.allVo.selected;
    folderDropDownVo.optionVos.forEach(v => {
      v.selected = folderDropDownVo.allVo.selected;
    });
    folderDropDownVo.selectedText = this.getSelectedText(folderDropDownVo);
    console.log('toggleFolderAllVo', folderDropDownVo, 'idx', idx);
  }

  toggleFolderOptionVo(folderDropDownVo: PickMulFolderDropDownVo, optionVo: PickMulFolderOptionVo, idx: number) {
    this.folderDropDownVos.splice(idx + 1);
    this.folderss.splice(idx + 1);
    optionVo.selected = !optionVo.selected;
    const selectedLength = folderDropDownVo.optionVos.filter(v => v.selected).length;
    folderDropDownVo.allVo.selected = folderDropDownVo.optionVos.length === selectedLength;
    folderDropDownVo.selectedText = this.getSelectedText(folderDropDownVo);
    console.log('toggleFolderOptionVo', folderDropDownVo, 'PickMulFolderOptionVo', optionVo, 'idx', idx);
  }

  toggleChooseAllDocs() {
    const hasChosenAll = this.hasChosenAllDocs(); // true: unselect, false: select
    const docs = this.docs || [];
    docs.forEach(doc => {
      doc.isSelected = !hasChosenAll;
    });
    this.selectedDocsDataCount = hasChosenAll ? 0 : docs.length;
  }

  toggleChooseAllFolders() {
    const hasChosenAll = this.hasChosenAllFolders();
    const folders = this.filterFolders.filter(folder => folder.canWrite || folder.canAdmin);
    folders.forEach(folder => {
      if (hasChosenAll && folder.isSelected) {
        this.unselectFolder(folder);
      }
      if (!hasChosenAll && !folder.isSelected) {
        this.selectFolder(folder);
      }
    });
  }

  hasChosenAllDocs(): boolean {
    return this.docs && this.docs.length > 0 && this.docs.filter(v => !v.isSelected).length === 0;
  }

  hasChosenAllFolders(): boolean {
    const folders = this.filterFolders;
    return folders && folders.length > 0 && folders.filter(v => !v.isSelected).length === 0;
  }

  private getSelectedText(folderDropDownVo: PickMulFolderDropDownVo): string {
    return folderDropDownVo.allVo.selected ? folderDropDownVo.allVo.folderName : folderDropDownVo.optionVos.filter(v => v.selected).map(v => v.folderName).join(',');
  }

  private createFolders(folderContents: FolderContent[], oldFolders: PickMulFolderVo[]) {
    const folderPathDict = oldFolders.reduce((acc, cur) => {
      acc[cur.folderId] = cur.folderPath;
      return acc;
    }, {});
    const newFolders = folderContents.map(folderContent => {
      const parentFolderId = folderContent.folder && folderContent.folder.folderId;
      const parentPath = folderPathDict[parentFolderId] || '';
      const folderVos: PickMulFolderVo[] = folderContent.childFolders
        .map(childFolder => ({ isSelected: false, folderPath: `${parentPath}/${childFolder.folderName}`, ...childFolder }));
      return folderVos;
    }).reduce((acc, cur) => acc.concat(cur), [])
      .sort((a, b) => a.folderName === b.folderName ? 0 : (a.folderName < b.folderName ? -1 : 1));
    return newFolders;
  }

  private createSelectVo(folders: PickMulFolderVo[]): PickMulFolderDropDownVo {
    const folderIdGrp = folders.reduce((acc, cur) => {
      acc[cur.folderName] = acc[cur.folderName] || [];
      acc[cur.folderName].push(cur.folderId);
      return acc;
    }, {} as { [key: string]: number[] });
    const optionVos = Object.keys(folderIdGrp).reduce((acc: PickMulFolderOptionVo[], key) => {
      acc.push({ folderName: key, folderIds: folderIdGrp[key], selected: false });
      return acc;
    }, []);
    const allFolderIds = folders.map(v => v.folderId);
    const allVo = { folderName: 'all', folderIds: allFolderIds, selected: false };
    const selectVo = { optionVos, allVo, selectedText: '' };
    return selectVo;
  }

  private getSelectedFolderIds() {
    let parentFolderIds = [0];
    if (this.folderDropDownVo) {
      if (this.folderDropDownVo.allVo.selected) {
        parentFolderIds = this.folderDropDownVo.allVo.folderIds;
      } else {
        parentFolderIds = this.folderDropDownVo.optionVos
          .filter(v => v.selected)
          .reduce((acc: number[], cur) => acc.concat(cur.folderIds), []);
      }
    }
    return parentFolderIds;
  }

}
