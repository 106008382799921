import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { AuthTokenService } from 'app/core/service/auth-token.service';

@Component({
  selector: 'doc-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(private router: Router, private authTokenService: AuthTokenService) { }

  ngOnInit() {
    this.authTokenService.currentUser$
      .subscribe(user => {
        if (user) {
          switch (user.type) {
            case 'ASUS':
              this.router.navigate(['/folder/list-newest-document']);
              break;
            case 'SCM':
              this.router.navigate(['/system']);
              break;
            case 'CAP':
              this.router.navigate(['/cp']);
              break;
            default:
              break;
          }
        }
      });
  }

}
