import { Component, OnInit, OnDestroy, NgZone, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { showInfo, loadingHide, MessageType, loadingShow, FolderType, FolderTypeName, showConfirm, RouteMap, popbox } from '../../shared/common';
import { RespMessage, FolderContent, DocumentFolder, DocumentDto, DataRow, TreeNode } from '../../core/model/data-model';
import { FolderTreeService, FolderService, DocumentService, FolderCanWriteTreeService, DownloadService } from '../../core/service';
import { PagedListComponent, PickFavoriteFolderComponent, BreadcrumbsComponent } from '../../shared/component';
import { BaseFolderComponent } from '../base-folder/base-folder.component';
import { DocumentContextmenuService } from '../../core/service/document-contextmenu.service';
import { FolderDocumentService } from 'app/core/service/folder-document.service';
import { MatomoTracker  } from 'ngx-matomo';
import { AuthTokenService } from 'app/core/service/auth-token.service';

@Component({
  selector: 'doc-system-folder',
  templateUrl: './system-folder.component.html',
  styleUrls: ['./system-folder.component.css']
})
export class SystemFolderComponent extends BaseFolderComponent implements OnInit, OnDestroy {

  childFolders: DocumentFolder[];
  documents: DocumentDto[];
  pickTreeNode: TreeNode;

  constructor(private matomoTracker: MatomoTracker,protected folderTreeService: FolderTreeService, protected folderCanWriteTreeService: FolderCanWriteTreeService
    , protected documentService: DocumentService, protected folderService: FolderService
    , protected folderDocumentService: FolderDocumentService, protected downloadService: DownloadService
    , protected contextmenuService: DocumentContextmenuService
    , protected router: Router, protected route: ActivatedRoute, protected zone: NgZone
    , private authTokenService: AuthTokenService) {
    super(folderTreeService, folderCanWriteTreeService, folderService, documentService, folderDocumentService, downloadService, contextmenuService, router);
    this.folderId = 0;
    this.folderType = FolderType.SYS_FOLDER;
    this.folderTypeName = FolderTypeName.SYS;
  }
  ngOnInit() {
    super.ngOnInit();
    this.authTokenService.currentUser$
      .subscribe(user => {
        if (user) {
          this.matomoTracker.setUserId(user.userName);
          this.matomoTracker.trackPageView('page_system');
          this.matomoTracker.setCustomVariable(1, "WorkId", user.workId, "page")
        }
      });

    this.route.params.subscribe((params) => {
      const data = this.dataDecode(params['data']);
      if ('folderId' in data) {
        this.folderId = data['folderId'];
        this.resultList = [];
        this.sortByCols = {
          'typeCss': '',
          'name': '',
          'modifyTime': '',
          'readCount': '',
        };
        this.sortBy = '';
        this.sortType = '';
        this.query();
      }
    });
  }

  ngOnDestroy() {
    console.log('SystemFolderComponent.....ngOnDestroy');
  }
}
