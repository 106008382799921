<li *ngIf="curNode.have_child != '1' else havechildas1">
    <a href="javascript:void(0)" [ngClass]="{'main-title':false, 'readonly': !canCloneDoc()}" title="{{anchorTitle|translate}}" (click)="pickTreeNode(curNode)">{{ curNode.node_name | translate}} </a>
  </li>
  <ng-template #havechildas1>
    <li>
      <a href="javascript:void(0)" (click)="pickTreeNode(curNode)"  [ngClass]="{'readonly': !canCloneDoc() }" title="{{anchorTitle|translate}}">{{curNode.node_name | translate}}
        <i class="mdi mdi-menu-right toggle-icon" docToggleTreeNode (click)="getTreeNodes(curNode,folderType)"></i>
      </a>
      <ul>
        <ng-container *ngIf="childrenNode!=null && folderType != ''">
          <doc-pick-tree-clone-doc-node *ngFor="let item of childrenNode" [folderId]="item.node_id" [folderType]='folderType' [curNode]='item'></doc-pick-tree-clone-doc-node>
        </ng-container>
      </ul>
    </li>
  </ng-template>
