<div class="main">
 <doc-breadcrumbs [folderHierarchy]="documentContent?.folderHierarchy" [folderType]="folderType" [folderTypeName]="folderTypeName"></doc-breadcrumbs>
  <form #form="ngForm" (keydown.enter)="false">
    <section class="panel panel-default panel-article">
      <div class="panel-heading">
        <h3 class="panel-title">
          <i class="circle mdi mdi-database-plus"></i>{{ "Edit File" | translate }}</h3>
        <div>
          <a class="btn btn-dark marg6-r" href="javascript:void(0)" (click)="goBack()">
            <i class="mdi mdi-reply"></i>{{ "Cancel" | translate }}</a>
          <a class="btn btn-primary" href="javascript:void(0)" (click)="confirmSave(form)">
            <i class="mdi mdi-content-save"></i>{{ "Save" | translate }}</a>
        </div>
      </div>
      <div class="panel-body">
        <div class="panel-body">
          <dl class="pad10 form-v">
            <dt class="required-title">{{ "FileName1" | translate }}</dt>
            <dd>
              <ng-container *ngIf="!documentDto.canWrite; else documentNameComp">
                <a href="javascript:void(0)">{{documentDto.documentName}}</a>
              </ng-container>

              <ng-template #documentNameComp>
                <input class="form-control" [ngClass]="{'input-validation-error':(documentName.errors?.required)}"
                  maxlength="100" required name="documentName" #documentName="ngModel" ngModel
                  [(ngModel)]="documentDto.documentName">
                <span *ngIf="documentName?.errors?.required && documentName.touched"
                  class="field-validation-error">{{ "Required" | translate }}</span>
              </ng-template>

            </dd>
          </dl>
        </div>
      </div>
    </section>
    <doc-permission-setting *ngIf="documentDto.canAdmin" [folderType]="folderType" [folderId]="documentContent?.folderId"
      [documentId]="documentContent?.docId" [resourceType]="resourceType" [needCascade]="false" [needReadAll]="false"
      [permissionContent]="permissionContent">
    </doc-permission-setting>
  </form>
  <div class="center pad20-b">
    <a class="btn btn-sm btn-dark marg6-r" href="javascript:void(0)" (click)="goBack()">
      <i class="mdi mdi-reply"></i>{{ "Cancel" | translate }}</a>
    <a class="btn btn-sm btn-primary" href="javascript:void(0)" (click)="confirmSave(form)">
      <i class="mdi mdi-content-save"></i>{{ "Save" | translate }}</a>
  </div>
</div>
