import { Component, OnInit, Input, Output, EventEmitter, NgZone, AfterViewInit, HostListener } from '@angular/core';
import { PermissionService } from '../../../core/service/permission.service';
import { User, RespMessage, PermissionContentVo, PermissionVo1, PermissionOperation, AccountSelectVo, Group, DepartmentVo } from '../../../core/model/data-model';
import { FolderType, popbox, loadingShow, loadingHide, closePopbox, showInfo, MessageType, unpopbox } from '../../common';
import { BaseComponent } from '../base/base.component';
import { concat, forkJoin, Observable, of } from 'rxjs';

@Component({
  selector: 'doc-account-select2',
  templateUrl: './account-select2.component.html',
  styleUrls: ['./account-select2.component.css']
})
export class AccountSelect2Component extends BaseComponent implements OnInit, AfterViewInit {

  // _permissionContent: PermissionContentVo;
  // @Input() set permissionContent(val: PermissionContentVo) {
  //   this._permissionContent = val;
  // }
  @Input() permissionContent = {} as PermissionContentVo;
  get userPermissions(): PermissionVo1[] {
    return this.permissionContent.userPermissions;
  }
  get deptPermissions(): PermissionVo1[] {
    return this.permissionContent.deptPermissions;
  }
  get groupPermissions(): PermissionVo1[] {
    return this.permissionContent.groupPermissions;
  }
  @Input() i18nPrefix: boolean;
  @Input() needCascade: boolean;
  @Input() needReadAll: boolean;
  @Input() folderType: FolderType;
  @Output() saveChang: EventEmitter<PermissionContentVo> = new EventEmitter<PermissionContentVo>();
  isDept: boolean;
  queryName = '';
  queryDeptName = '';
  pickType = '';
  isExternal = false;
  queryDepts: AccountSelectVo[] = [];
  queryGroups: AccountSelectVo[] = [];
  queryUsers: AccountSelectVo[] = [];
  pickDepts: AccountSelectVo[] = [];
  pickGroups: AccountSelectVo[] = [];
  pickUsers: AccountSelectVo[] = [];
  readOperation: PermissionOperation = this.initPermissionOperation();
  readAllOperation: PermissionOperation = this.initPermissionOperation();
  writeOperation: PermissionOperation = this.initPermissionOperation();
  adminOperation: PermissionOperation = this.initPermissionOperation();
  sysName: 'ASUS' | 'CAP' | 'SCM' = 'ASUS';
  showAccountValidation = false;
  showPermissionValidation = false;
  showParent: boolean;
  showName: string;
  showUsers: User[] = [];


  constructor(private permissionService: PermissionService
    , private zone: NgZone) {
    super();
  }

  ngOnInit() {
    this.isDept = this.folderType === FolderType.DEPT_FOLDER;
  }

  ngAfterViewInit() {
    this.zone.runOutsideAngular(() => {
      // close popbox
      $('.close, .close-btn').on('click', function (e) {
        $(this).parents('.popbg-fill').removeClass('display-block');
        $(this).parents('.pop-box').removeClass('display-block');
        if ($('.popbg-fill').is('.display-block') === false) {
          $('body').removeClass('overflow-hidden');
        }
        e.preventDefault();
      });
    });
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    // console.log(event);
    // code : 'Enter'
    //  keyCode : 13
    const focusElement = document.activeElement;
    if (event.keyCode === 13 && focusElement.getAttribute('name') === 'queryName') {
      this.query(this.queryName);
    }
  }

  closeAaccountList() {
    closePopbox();
  }

  closeSelectAccount() {
    closePopbox();
  }

  saveSelectAccount() {
    // validation
    this.requireAccount();
    this.requirePermission();
    if (this.showAccountValidation || this.showPermissionValidation) {
      return;
    }
    const existedDeptIds = new Set(this.deptPermissions.map(p => p.deptId));
    const existedGroupIds = new Set(this.groupPermissions.map(p => p.groupId));
    const existedUserIds = new Set(this.userPermissions.map(p => p.userId));
    const contentVo: PermissionContentVo = {
      userPermissions: this.pickUsers
        .filter(p => !existedUserIds.has(p.userId))
        .map(p => this.toPermissionVo(p, this.permissionContent.userFixShowParentLookup[p.userId])),
      groupPermissions: this.pickGroups
        .filter(p => !existedGroupIds.has(p.groupId))
        .map(p => this.toPermissionVo(p, this.permissionContent.groupFixShowParentLookup[p.groupId])),
      deptPermissions: this.pickDepts
        .filter(p => !existedDeptIds.has(p.deptId))
        .map(p => this.toPermissionVo(p, this.permissionContent.deptFixShowParentLookup[p.deptId]))
    };
    this.saveChang.emit(contentVo);
    unpopbox('show-select-account-permission');
    this.setVariablesAsDefault();
  }

  private toPermissionVo(p: AccountSelectVo, fixShowParent: boolean): PermissionVo1 {
    // oldShowParent: true when fixShowParent is true or false, false when fixShowParent undefined
    // showParent: oldShowParent when fixShowParent is true, otherwise checkbox value
    // fixShowParent: set undefined to false
    const oldShowParent = fixShowParent !== undefined;
    const showParent = fixShowParent ? oldShowParent : this.showParent;
    return {
      ...p,
      permissionId: 0,
      readOperation: this.readOperation,
      readAllOperation: this.readAllOperation,
      writeOperation: this.writeOperation,
      adminOperation: this.adminOperation,
      oldShowParent: oldShowParent,
      showParent: showParent,
      fixShowParent: fixShowParent || false
    };
  }

  private setVariablesAsDefault() {
    this.pickUsers = [];
    this.pickGroups = [];
    this.pickDepts = [];
    this.queryUsers = [];
    this.queryGroups = [];
    this.queryDepts = [];
    this.readOperation = this.initPermissionOperation();
    this.readAllOperation = this.initPermissionOperation();
    this.writeOperation = this.initPermissionOperation();
    this.adminOperation = this.initPermissionOperation();
    this.showParent = false;
  }

  getGroupUsers(groupId, groupName, showAccountList) {
    this.permissionService.getGroupUsers({ groupId: groupId })
      .subscribe(group => {
        this.showName = groupName;
        this.showUsers = group.groupUsers;
        popbox('show-account-list');
        $(showAccountList).show();
      });
  }

  getDeptUsers(deptId, deptName, showAccountList) {
    this.permissionService.getDeptUsers(deptId)
      .subscribe(depts => {
        this.showName = deptName;
        this.showUsers = depts.result;
        popbox('show-account-list');
        $(showAccountList).show();
      });
  }

  addAcc() {
    const pickedDeptIds = new Set(this.pickDepts.map(p => p.deptId));
    const pickedUserIds = new Set(this.pickUsers.map(p => p.userId));
    const pickedGroupIds = new Set(this.pickGroups.map(p => p.groupId));

    const selGroups = this.queryGroups
      .filter(g => g.checked && !g.disabled && !pickedGroupIds.has(g.groupId))
      .map(g => ({ ...g, checked: false }));
    this.queryGroups = this.queryGroups.filter(g => !g.checked);
    this.pickGroups = this.pickGroups.concat(selGroups);

    const selUsers = this.queryUsers
      .filter(g => g.checked && !g.disabled && !pickedUserIds.has(g.userId))
      .map(u => ({ ...u, checked: false }));
    this.queryUsers = this.queryUsers.filter(g => !g.checked);
    this.pickUsers = this.pickUsers.concat(selUsers);

    const selDepts = this.queryDepts
      .filter(g => g.checked && !g.disabled && !pickedDeptIds.has(g.deptId))
      .map(d => ({ ...d, checked: false }));
    this.queryDepts = this.queryDepts.filter(g => !g.checked);
    this.pickDepts = this.pickDepts.concat(selDepts);
    this.requireAccount();
  }

  delAcc() {
    const queryDeptIds = new Set(this.queryDepts.map(p => p.deptId));
    const queryUserIds = new Set(this.queryUsers.map(p => p.userId));
    const queryGroupIds = new Set(this.queryGroups.map(p => p.groupId));

    const selGroups = this.pickGroups
      .filter(p => p.checked && !queryGroupIds.has(p.groupId))
      .map(g => ({ ...g, checked: false }));
    this.pickGroups = this.pickGroups.filter(p => !p.checked);
    this.queryGroups = this.queryGroups.concat(selGroups);

    const selUsers = this.pickUsers
      .filter(p => p.checked && !queryUserIds.has(p.userId))
      .map(u => ({ ...u, checked: false }));
    this.pickUsers = this.pickUsers.filter(p => !p.checked);
    this.queryUsers = this.queryUsers.concat(selUsers);

    const selDepts = this.pickDepts
      .filter(p => p.checked && !queryDeptIds.has(p.deptId))
      .map(d => ({ ...d, checked: false }));
    this.pickDepts = this.pickDepts.filter(p => !p.checked);
    this.queryDepts = this.queryDepts.concat(selDepts);
  }

  query(qryName: string) {
    if (!qryName || qryName.length < 2) { return; }
    qryName = qryName.trim();
    let user$: Observable<RespMessage<User[]>> = of({ result: [], state: 'Ok', message: '' });
    let group$: Observable<RespMessage<Group[]>> = of({ result: [], state: 'Ok', message: '' });
    let dept$: Observable<RespMessage<DepartmentVo[]>> = of({ result: [], state: 'Ok', message: '' });
    switch (this.sysName) {
      case 'ASUS':
        user$ = this.permissionService.queryUsers(qryName, this.isExternal, this.sysName);
        group$ = this.permissionService.queryGroups(qryName);
        if ((this.folderType === 'PUB' || this.folderType === 'DEPT') && this.sysName === 'ASUS') {
          dept$ = this.permissionService.queryDeptsByAny(qryName);
        }
        break;
      case 'SCM':
        user$ = this.permissionService.queryUsers(qryName, this.isExternal, this.sysName);
        if (!this.isExternal) {
          group$ = this.permissionService.queryGroups(qryName);
        }
        break;
      case 'CAP':
        user$ = this.permissionService.queryUsers(qryName, this.isExternal, this.sysName);
        break;
      default:
        break;
    }
    loadingShow('loading....');
    forkJoin([dept$, group$, user$])
      .subscribe((arr) => {
        loadingHide();
        // filter existed and picked
        const respDept = arr[0];
        if (respDept.state === 'Ok') {
          const existedDeptIds = new Set(this.deptPermissions.map(p => p.deptId));
          const pickedDeptIds = new Set(this.pickDepts.map(p => p.deptId));
          this.queryDepts = respDept.result
            .filter(p => !pickedDeptIds.has(p.deptId))
            .map(d => this.getDeptPermissionVo(d, existedDeptIds));
        } else {
          showInfo(respDept.message, 2000, MessageType.error);
        }
        const respGroup = arr[1];
        if (respGroup.state === 'Ok') {
          const existedGroupIds = new Set(this.groupPermissions.map(p => p.groupId));
          const pickedGroupIds = new Set(this.pickGroups.map(p => p.groupId));
          this.queryGroups = respGroup.result
            .filter(p => !pickedGroupIds.has(p.groupId))
            .map(g => this.getGroupPermissionVo(g, existedGroupIds));
        } else {
          showInfo(respGroup.message, 2000, MessageType.error);
        }
        const respUser = arr[2];
        if (respUser.state === 'Ok') {
          const existedUserIds = new Set(this.userPermissions.map(p => p.userId));
          const pickedUserIds = new Set(this.pickUsers.map(p => p.userId));
          this.queryUsers = respUser.result
            .filter(p => !pickedUserIds.has(p.userId))
            .map(u => this.getUserPermissionVo(u, existedUserIds));
        } else {
          showInfo(respUser.message, 2000, MessageType.error);
        }
      }, error => {
        loadingHide();
        showInfo(error.message || 'error', 3000, MessageType.error);
        console.error('loadData ::', JSON.stringify(error));
      });
  }

  queryDeptsOnly(qryName: string) {
    if (qryName.length < 2) { return; }
    loadingShow('loading....');
    this.permissionService.queryDepts(qryName)
      .subscribe((resp) => {
        loadingHide();
        if (resp.state === 'Ok') {
          const existedDeptIds = new Set(this.deptPermissions.map(p => p.deptId).concat(this.pickDepts.map(p => p.deptId)));
          const pickedDeptIds = new Set(this.pickDepts.map(p => p.deptId));
          this.queryDepts = resp.result
            .filter(p => !pickedDeptIds.has(p.deptId))
            .map(d => this.getDeptPermissionVo(d, existedDeptIds));
        } else {
          showInfo(resp.message, 2000, MessageType.error);
        }
      }, error => {
        loadingHide();
        showInfo(error.message || 'error', 3000, MessageType.error);
        console.error('loadData ::', JSON.stringify(error));
      });
  }


  private initPermissionOperation(): PermissionOperation {
    return { value: false, oldValue: false, cascade: false };
  }

  private getUserPermissionVo(u: User, existedUserIds: Set<number>): AccountSelectVo {
    const name = u.userEngName ? `${u.userName} (${u.userEngName})` : u.userName;
    return { userId: u.userId, groupId: 0, deptId: null, name: name, checked: false, disabled: existedUserIds.has(u.userId) };
  }

  private getGroupPermissionVo(g: Group, existedGroupIds: Set<number>): AccountSelectVo {
    return { userId: 0, groupId: g.groupId, deptId: null, name: g.groupName, checked: false, disabled: existedGroupIds.has(g.groupId) };
  }

  private getDeptPermissionVo(d: DepartmentVo, existedDeptIds: Set<string>): AccountSelectVo {
    return { userId: 0, groupId: 0, deptId: d.deptId, name: d.deptName, checked: false, disabled: existedDeptIds.has(d.deptId) };
  }

  requireAccount() {
    this.showAccountValidation = this.pickUsers.length === 0 && this.pickGroups.length === 0 && this.pickDepts.length === 0;
  }

  requirePermission() {
    this.showPermissionValidation = !(this.readOperation.value || this.readAllOperation.value || this.writeOperation.value || this.adminOperation.value);
  }
}
