<!--header-->
<doc-header *ngIf="contentOnly === 'false'"></doc-header>
<article class="article">
  <aside *ngIf="contentOnly === 'false'" class="left" [ngClass]="{ 'show-nav': isNav }">
    <doc-search-document *ngIf="contentOnly  === 'false'"></doc-search-document>
    <doc-tree-view-nav *ngIf="contentOnly  === 'false'"></doc-tree-view-nav>
  </aside>
  <aside *ngIf="contentOnly === 'false'" class="bar"> </aside>
  <aside class="right">
    <!-- Page content-->
    <router-outlet></router-outlet>
    <!-- <div class="btn-group">
            <a class="swa-success-edit btn btn-primary" (click)="showMsg('編輯成功')">編輯成功</a>
            <a class="swa-success-send btn btn-primary" (click)="showMsg('送出成功')">送出成功</a>
            <a class="swa-success-save btn btn-primary" (click)="showAlert('儲存成功')">儲存成功</a>
        </div>
        <div class="btn-group">
            <a class="swa-delete btn btn-primary" (click)="confirmMsg('確認要刪除')">刪除</a>
        </div> -->
    <!-- Page footer-->
    <doc-footer *ngIf="contentOnly  ===  'false'"></doc-footer>

    <!-- <a class="swa-success-save btn btn-primary" (click)="showAlert('儲存成功')">儲存成功</a>
    <a class="btn btn-warning" [routerLink]="[ '/folder/system-folder', {data:dataEncode({folderType:'SYS',folderId:0}),mod:'content'} ]">Content Only</a> -->
  </aside>
  <div class="treebg-fill" [ngClass]="{ 'show-nav': isNav }" (click)="toggleNav()"></div>
</article>
<doc-ie-popup></doc-ie-popup>
