import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'doc-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.css']
})
export class PageComponent extends BaseComponent implements OnInit {

  /// <summary>
  /// 每頁幾筆
  /// </summary>
  pageSize = 100;
  /// <summary>
  /// Result 共幾筆資料
  /// </summary>
  dataCount = 0;
  /// <summary>
  /// 第幾頁資料
  /// </summary>
  pageNum = 1;
  /// <summary>
  /// 共幾頁
  /// </summary>
  pageCount = 0;

  constructor() {
    super();
  }

  ngOnInit() {
    if (localStorage.getItem('pageSize') != null) {
      this.pageSize = parseInt(localStorage.getItem('pageSize'), 10);
    }
  }

  calulatePageCount(dataCount: number, pageSize: number): number {
    const pageCount = dataCount === 0 ? 1 : Math.floor((dataCount - 1) / pageSize) + 1;
    return pageCount;
  }
}
