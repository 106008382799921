import { Component, OnInit, OnDestroy } from '@angular/core';
import { environment } from 'environments/environment';
import { InfoService } from '../../core/service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'doc-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit, OnDestroy {

  version = '';
  ptrInterval = undefined;
  onlineUserCount = 1;
  private ngUnSubscribe: Subject<any> = new Subject();

  constructor(private infoService: InfoService) { }

  ngOnInit() {
    this.version = `ver:${environment.version} --prod:${environment.production} build:${environment.buildDate}`;

    this.infoService.onlineUser$
      .pipe(
        takeUntil(this.ngUnSubscribe)
      )
      .subscribe(
        (count: number) => {
          this.onlineUserCount = count;
        }
        , error => console.error('onlineUser:error', error)
      );

    this.ptrInterval = setInterval(() => {
      this.infoService.loadOnlineUsers();
    }, 60 * 1000);

    this.infoService.loadOnlineUsers();

  }


  ngOnDestroy(): void {
    this.ngUnSubscribe.next();
    this.ngUnSubscribe.complete();
    clearInterval(this.ptrInterval);

  }

}
