<!--header-->
<!-- <doc-header [showUserInfo]="false"></doc-header> -->
<header>
  <div class="wrap justify-center">
    <a class="logo" title="{{ 'Back to Home' | translate }}">
      <i class="ASUS-logo"> </i>
      <b>Drive</b>
    </a>
  </div>
</header>
<article class="article">
  <aside class="right">
    <div class="main">
      <section class="panel panel-default panel-article">
        <div class="panel-heading" title='{{ documentDto?.documentName }}'>
          <h3 class="panel-title" *ngIf="documentDto?.isArchive !== '1'">
            <i class="circle mdi mdi-file-document"></i>{{documentDto?.documentName}}</h3>
          <h3 class="panel-title" *ngIf="documentDto?.isArchive === '1'">
            <i class="circle mdi mdi-file-document"></i>{{ "Download File" | translate }}</h3>
        </div>
        <div class="tab-panel-boddy">
          <div class="panel-body">
            <div class="detail-info-right">
              <i class="mdi mdi-clock"> </i>{{documentDto?.createTime | date:'yyyy-MM-dd HH:mm a'}}
              <i class="mdi mdi-account-circle"> </i>{{documentDto?.createUser?.userName}}({{documentDto?.createUser?.userEngName}})
              {{ "Publish" | translate }}
            </div>
            <ng-container *ngIf="documentDto?.isArchive !== '1'">
              <div class="detail-download" *ngIf="documentFiles?.length> 0">
                {{ "Download Attachment" | translate }} :
                <ng-container *ngFor="let item of documentFiles">
                  <a *ngIf="item.exists" href="javascript:void(0)" (click)="downloadFile(item)">
                    <i class="mdi mdi-paperclip"> </i>{{item.fileName}}
                    <ng-container *ngIf="!item.exists">({{ "File Not Exist" | translate }})</ng-container> </a>
                </ng-container>
              </div>
            </ng-container>
            <ng-container *ngIf="documentDto?.isArchive === '1'">
              <div class="detail-download marg20-tb center">
                {{ "FileName1" | translate }} :
                <br>
                <ng-container *ngFor="let item of documentFiles">
                  <a *ngIf="item.exists" href="javascript:void(0)" (click)="downloadFile(item)">
                    <i class="mdi mdi-paperclip"> </i>{{item.fileName}}
                    <ng-container *ngIf="!item.exists">({{ "File Not Exist" | translate }})</ng-container> </a>
                </ng-container>
              </div>

              <div class="center pad10-tb">
                <a *ngIf="documentDto?.isArchive === '1' && documentFiles?.length> 0 && documentFiles[0].exists"
                  (click)="downloadFile(documentFiles[0])" class="btn btn-sm btn-primary close-btn marg6-r btn-min-w100"
                  href="javascript:void(0)">
                  <i class="mdi mdi-cloud-download"></i>{{ "Download" | translate }} </a>
              </div>
            </ng-container>
            <div class="main-document-content">
              <doc-document-content-view #contentView [documentDto]="documentDto"></doc-document-content-view>
            </div>
          </div>
        </div>
      </section>

    </div>
    <doc-pick-favorite-folder #pickFavoriteFolder></doc-pick-favorite-folder>
    <!-- <doc-footer></doc-footer> -->
  </aside>

</article>
