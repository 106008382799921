import { Pipe, PipeTransform } from '@angular/core';
import { DocTypeName, FolderType } from '../common';

@Pipe({
  name: 'docTypeName'
})
export class DocTypeNamePipe implements PipeTransform {

  transform(value: any, args: any[]): any {
    if (args != null && args.length > 0) {
      switch (value) {
        case FolderType.DEPT_FOLDER: {
          return DocTypeName.DEPT;
        }
        case FolderType.FAVORITE_FOLDER: {
          return DocTypeName.FAV;
        }
        case FolderType.PUBLIC_FOLDER: {
          return DocTypeName.PUB;
        }
        case FolderType.SYS_FOLDER: {
          return DocTypeName.SYS;
        }
        case FolderType.FAVORITE_FOLDER: {
          return DocTypeName.FAV;
        }
        case FolderType.PRIVATE_FOLDER: {
          return DocTypeName.PRI;
        }
        case FolderType.SHARE_WITH_ME: {
          return DocTypeName.SHARE;
        }
        default: {
          return "";
        }
      }
    }
  }

}
