import { Component, OnInit, NgZone, AfterViewInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { FolderType, loadingHide, loadingShow, FolderTypeName, showInfo, MessageType, dataDecode, showConfirm, initCommonJs } from '../../shared/common';
import { DocumentService, FolderService, FolderTreeService, DownloadService } from '../../core/service';
import { DocumentContent, DocumentDto, RespMessage, DocumentFile, HttpOptions } from './../../core/model/data-model';
import { PickFavoriteFolderComponent, BaseComponent, DocumentContentViewComponent } from '../../shared/component';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { environment } from 'environments/environment';

@Component({
  selector: 'doc-document-content',
  templateUrl: './document-content.component.html',
  styleUrls: ['./document-content.component.css']
})
export class DocumentContentComponent extends BaseComponent implements OnInit, AfterViewInit {

  folderId = 0;
  docId = 0;
  folderType: FolderType;
  folderTypeName = FolderTypeName;
  MEGABYTE = 1048576; // Bytes
  documentContent = {} as DocumentContent;
  documentDto = {} as DocumentDto;
  documentFiles: DocumentFile[] = [];
  goBack = '';
  auth = '';
  httpOptions: HttpOptions; // allow undefined
  @ViewChild('contentView', { static: false }) contentView: DocumentContentViewComponent;

  constructor(
    private documentService: DocumentService,
    private folderService: FolderService,
    private folderTreeService: FolderTreeService,
    private route: ActivatedRoute,
    protected sanitizer: DomSanitizer,
    private downloadService: DownloadService,
    private http: HttpClient,
    private zone: NgZone) {
    super();
  }

  ngOnInit() {
    // this.folderTreeService.getFolderAllRoot();
    this.route.params.subscribe(params => {
      const data = dataDecode(params['data']);
      if (data['folderType'] != null && data['docId'] != null) {
        this.folderId = data['folderId'] || 0;
        this.folderType = data['folderType'];
        this.folderTypeName = FolderTypeName['' + this.folderType];
        this.docId = data['docId'];
        this.goBack = data['back'] || '';
        this.auth = params['auth'] || '';
        if (this.auth !== '') {
          this.httpOptions =  { 'headers': new HttpHeaders({ 'Authorization': `Bearer ${this.auth}` }) };
        }
        console.log('auth', this.auth);
        const vo = { folderId: this.folderId, folderType: this.folderType, docId: this.docId };
        loadingShow();
        this.documentService.openDoc(vo, this.httpOptions).subscribe((resp: RespMessage) => {
          loadingHide();
          if (resp.state === 'Ok') {
            this.documentContent = resp.result;
            this.documentDto = this.documentContent.document;
            this.documentFiles = this.documentContent.documentFiles;
            this.contentView.documentDto = this.documentDto;
            this.contentView.showContent();
            // console.log(this.documentContent);
          } else {
            showInfo(resp.message, 2000, MessageType.error);
          }
        }, error => {
          loadingHide();
          showInfo(error.message || 'error', 3000, MessageType.error);
          console.error('openDoc ::', JSON.stringify(error));
        });
      } else {
        showInfo('參數錯誤', 2000, MessageType.error);
      }
    }, error => {
      loadingHide();
      showInfo(error.message || 'error', 3000, MessageType.error);
      console.error('documentService.openDoc ::', JSON.stringify(error));
    });
  }


  ngAfterViewInit() {
    this.zone.runOutsideAngular(() => {
      initCommonJs();
    });
  }

  openDoc(vo: { folderId?: number, folderType, docId: number }, auth: string) {
    const headers = new HttpHeaders({ 'Authorization': `Bearer ${auth}` });
    return this.http.post<RespMessage<DocumentContent>>(`${environment.apiRoot}/api/Document/openDoc`, vo, { 'headers': headers });
  }

  showAddfav(doc: DocumentDto, pickFavoriteFolder: PickFavoriteFolderComponent) {
    if (doc.inFavorite) {
      this.delfav(doc);
    } else {
      pickFavoriteFolder.documentDto = doc;
      pickFavoriteFolder.show();
    }
  }

  delfav(doc: DocumentDto) {
    showConfirm(`移除收藏`, `您確定要刪除${doc.documentName}?`, MessageType.question)
      .then(
        result => {
          // console.log(`showConfirm:${result}`);
          if (result.value) {
            loadingShow();
            this.folderService.deleteFavoriteByDocId({ docId: doc.documentId })
              .subscribe((resp: RespMessage) => {
                loadingHide();
                if (resp.state === 'Ok') {
                  doc.inFavorite = false;
                  showInfo('Success', 2000, MessageType.success);
                } else {
                  showInfo(resp.message, 2000, MessageType.error);
                }
              }, error => {
                loadingHide();
                showInfo(error.message || 'error', 3000, MessageType.error);
                console.log('error', error);
              });
          }
        },
        reject => {
          console.log(`showConfirm:${reject}`);
        }
      );
  }

  downloadFile(item: DocumentFile) {
    const auth = this.auth !== '' ? this.auth : this.currentUserToken;
    let url = `${environment.apiRoot}/api/DownloadFile/Download?auth=${auth}&id=${item.guid}&fileName=${encodeURIComponent(item.fileName)}`;
    if (location.hostname === 'localhost') {
      url = 'http://localhost:56999' + url;
    }
    if (item.exists) {
      window.open(url, 'fmDownloadFile');
      //   this.downloadService.getFile(url, item.fileName);
    }
  }

}
