import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'doc-mobile-portal',
  templateUrl: './mobile-portal.component.html',
  styleUrls: ['./mobile-portal.component.css']
})
export class MobilePortalComponent implements OnInit {

  constructor(protected router: Router) { }

  ngOnInit() {
    this.router.navigate(['/']);
  }

}
