import { Component, OnInit, OnDestroy, Output, Input, HostListener, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UploadxOptions, UploadState, UploadItem } from '../../../uploadx';
import { Ufile } from '../../model/ufile';
import { UploadxService } from '../../../core/service/uploadx.service';
import { showInfo, MessageType } from '../../common';
import { BaseComponent } from '../base/base.component';
import { environment } from 'environments/environment';

@Component({
  selector: 'doc-upolad-box',
  templateUrl: './upolad-box.component.html',
  styleUrls: ['./upolad-box.component.css']
})
export class UpoladBoxComponent extends BaseComponent implements OnDestroy, AfterViewInit, OnInit {

  MEGABYTE = 1048576; // Bytes
  state: Observable<UploadState>;
  uploads: Ufile[] = [];
  uploadWorkCount = 0;
  intervalId: any;
  hidden = false;
  @ViewChild('upoladBox', { static: false }) upoladBox: ElementRef;

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private uploadService: UploadxService) {
    super();
  }

  ngOnInit() {

    // console.log('UpoladBoxComponent ....ngOnInit');
    console.log('UpoladBoxComponent ....ngOnInit');
  }

  ngAfterViewInit() {

    // 上傳檔案
    $('.upolad-header .mdi-chevron-down').on('click', function () {
      $(this).parents('.upolad-header').next('.upolad-content').toggleClass('hidden');
      $(this).toggleClass('trans');
    });
    $('.upolad-box').draggable().css('position', 'fixed');
    const options: UploadxOptions = {
      concurrency: 2,
      // allowedTypes: 'image/*,video/*',
      url: `${environment.apiRoot}/api/UploadFile/?parts=test`,
      // url: `http://localhost:3003/upload/?parts=test`,
      //  token: 'someToken',
      autoUpload: false,
      withCredentials: false,
      chunkSize: 10 * this.MEGABYTE // per chunk 10mb
    };
    const self = this;
    this.intervalId = setInterval(() => {
      self.uploadWorkCount = this.uploadService.uploadWorkCount;
      // console.log('uploadWorkCount', self.uploadWorkCount);
    }, 1000);
    const uploadsProgress = this.uploadService.init(options);
    this.onUpload(uploadsProgress);
  }


  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    // console.log('window:beforeunload......');
    if (this.uploadService.uploadWorkCount > 0) {
      $event.returnValue = 'This message is displayed to the user in IE and Edge when uploadWorkers > 0';
    }
    // $event.returnValue = "This message is displayed to the user in IE and Edge when uploadWorkers > 0";
  }

  getWorkCount() {
    return this.uploadService.uploadWorkCount;
  }

  getBytes(num: number) {
    return (num / this.MEGABYTE).toFixed(3);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    clearInterval(this.intervalId);
    // console.warn('UpoladBoxComponent ....ngOnDestroy');
  }

  cancelAll() {
    this.uploadService.control({ action: 'cancelAll' });
  }

  uploadAll() {
    this.uploadService.control({ action: 'uploadAll' });
  }

  pauseAll() {
    this.uploadService.control({ action: 'pauseAll' });
  }

  pause(uploadId: string) {
    this.uploadService.control({ action: 'pause', uploadId });
  }

  upload(uploadId: string) {
    this.uploadService.control({ action: 'upload', uploadId });
  }

  cancel(uploadId: string) {
    this.uploadService.control({ action: 'cancel', uploadId });
  }

  doAction(ufile: Ufile) {
    switch (ufile.status) {
      case 'queue':
      case 'paused':
        return this.upload(ufile.uploadId);
      case 'uploading':
        return this.pause(ufile.uploadId);
      case 'error':
        return this.cancel(ufile.uploadId);
      default:
        break;
    }
  }

  getStatusCss(status) {
    //  UploadStatus = 'added' | 'queue' | 'uploading' | 'complete' | 'error' | 'cancelled' | 'paused'; -->

    switch (status) {
      case 'added':
      case 'queue':
        return 'status waiting';
      case 'uploading':
      case 'paused':
        return 'status';
      case 'complete':
        return 'status success';
      case 'error':
        return 'status error';
      default:
        return '';
    }
  }

  getStatusIconCss(status) {
    //  UploadStatus = 'added' | 'queue' | 'uploading' | 'complete' | 'error' | 'cancelled' | 'paused'; -->

    switch (status) {
      case 'added':
      case 'queue':
        return 'mdi mdi-minus-circle';
      case 'uploading':
      case 'paused':
        return 'mdi mdi-loading mdi-spin';
      case 'complete':
        return 'mdi mdi-check-circle';
      case 'error':
        return 'mdi mdi-alert';
      default:
        return '';
    }
  }

  onUpload(uploadsOutStream: Observable<UploadState>) {
    this.state = uploadsOutStream;
    uploadsOutStream
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((item: UploadState) => {
        const index = this.uploads.findIndex(f => f.uploadId === item.uploadId);
        if (item.status === 'added') {
          const date = new Date(item.file.lastModified);
          const cfg: UploadItem = {
            headers: { 'Content-Disposition': `filename=${encodeURI(item.file.name)}` },
            metadata: { size: item.file.size, lastModifiedDate: date },
          };
          this.uploadService.control({ action: 'upload', itemOptions: cfg, uploadId: item.uploadId });
          this.uploads.unshift(new Ufile(item));
        } else {
          if (this.uploads[index] != null) {
            this.uploads[index].progress = item.progress;
            this.uploads[index].status = item.status;
          }
        }

        if (this.uploadService.uploadWorkCount === 0) {
          showInfo('所有檔案上傳完成!!!', 2000, MessageType.info);
          this.uploads = [];
          // this.upoladBox.nativeElement.style = "left: 707px; top: 248px;";
        }

      });
  }

}
