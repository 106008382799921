
import { TreeNode } from './../../core/model/data-model';
import { FolderTypeName, showConfirm, RouteMap, popbox } from './../../shared/common';
import { Component, OnInit, OnDestroy, NgZone, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FolderTreeService } from '../../core/service/folder-tree.service';
import { showInfo, loadingHide, MessageType, loadingShow, FolderType } from '../../shared/common';
import { RespMessage, FolderContent, DocumentFolder, DocumentDto, DataRow } from '../../core/model/data-model';
import { DocumentService } from '../../core/service/document.service';
import { PagedListComponent } from '../../shared/component/paged-list/paged-list.component';
import { FolderService } from '../../core/service/folder.service';
import { PickFavoriteFolderComponent } from '../../shared/component/pick-favorite-folder/pick-favorite-folder.component';
import { BaseFolderComponent } from '../base-folder/base-folder.component';
import { FolderCanWriteTreeService } from '../../core/service/folder-can-write-tree.service';
import { BreadcrumbsComponent } from '../../shared/component/breadcrumbs/breadcrumbs.component';
import { DocumentContextmenuService } from '../../core/service/document-contextmenu.service';
import { DownloadService } from 'app/core/service';
import { FolderDocumentService } from 'app/core/service/folder-document.service';
import { MatomoTracker  } from 'ngx-matomo';
import { AuthTokenService } from 'app/core/service/auth-token.service';

@Component({
  selector: 'doc-share-with-me',
  templateUrl: './share-with-me.component.html',
  styleUrls: ['./share-with-me.component.css']
})
export class ShareWithMeComponent extends BaseFolderComponent implements OnInit, OnDestroy {

  ///// 從別人 private-folder 分享給我的文件，檔案，資料夾...////
  childFolders: DocumentFolder[];
  documents: DocumentDto[];

  constructor(private matomoTracker: MatomoTracker,protected folderTreeService: FolderTreeService, protected folderCanWriteTreeService: FolderCanWriteTreeService
    , protected documentService: DocumentService, protected folderService: FolderService
    , protected folderDocumentService: FolderDocumentService, protected downloadService: DownloadService
    , protected contextmenuService: DocumentContextmenuService
    , protected router: Router, protected route: ActivatedRoute, protected zone: NgZone
    , private authTokenService: AuthTokenService) {
    super(folderTreeService, folderCanWriteTreeService, folderService, documentService, folderDocumentService, downloadService, contextmenuService, router);
    this.folderId = 0;
    this.folderType = FolderType.SHARE_WITH_ME;
    this.folderTypeName = FolderTypeName.SHARE;
  }
  ngOnInit() {
    super.ngOnInit();
    this.authTokenService.currentUser$
      .subscribe(user => {
        if (user) {
          this.matomoTracker.setUserId(user.userName);
          this.matomoTracker.trackPageView('page_share');
          this.matomoTracker.setCustomVariable(1, "WorkId", user.workId, "page")
        }
      });
    this.route.params.subscribe((params) => {
      const data = this.dataDecode(params['data']);
      if ('folderId' in data) {
        this.folderId = data['folderId'];
        this.resultList = [];
        this.sortByCols = {
          'typeCss': '',
          'name': '',
          'modifyTime': '',
          'readCount': '',
        };
        this.sortBy = '';
        this.sortType = '';
        this.query();
      }
    });
  }

  edit(data: DataRow) {
    if (data.type === 'folder') {
      const folder = data.data as DocumentFolder;
      console.log('share with me parentId',this.folderId);
      this.router.navigate([`/folder/folder-edit`,
        { data: this.dataEncode({ folderType: this.folderType, folderId: folder.folderId, parentId: this.folderId }) }]);
    } else {
      super.edit(data);
    }
  }

  ngOnDestroy() {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    console.log('PrivateFolderComponent.....ngOnDestroy');
  }
}
