import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { BaseService } from './base.service';
import { RespMessage, JwtAuthObject, User } from './../model/data-model';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { loadingShow, loadingHide, showInfo, MessageType, newGuid } from '../../shared/common';
import { SwUpdateService } from './sw-update.service';
import { environment } from '../../../environments/environment';
import { EnvService } from './env.service';
// import {Location} from '@angular/common';
import { DOCUMENT } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable()
export class AuthTokenService extends BaseService {
  // ssoHost = environment.apiRoot; // 'https://idrive.asus.com';
  // Create a stream of logged in status to communicate throughout app

  private currentUserSubject = new BehaviorSubject<User>({} as User);
  public currentUser$ = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());

  loggedIn = false;
  private loggedInSubject = new BehaviorSubject<boolean>(this.loggedIn);
  public loggedIn$ = this.loggedInSubject.asObservable().pipe(distinctUntilChanged());

  private isJumping = false;

  constructor(private router: Router,
    @Inject(DOCUMENT) private document: Document,
    private http: HttpClient,
    private envService: EnvService,
    private swUpdateService: SwUpdateService,
    protected sanitizer: DomSanitizer) {
    super();
    // If authenticated, set local profile property and update login status subject
    if (localStorage.getItem('token') != null) {
      const token: JwtAuthObject = JSON.parse(localStorage.getItem('token'));
      // todo verify token
      const expires = new Date(token.expires);
      if (expires.getTime() > new Date().getTime()) {
        if (!this.jumpRootOrCP(token)) {
          this.setLoggedIn(true, token.user);
        }
      } else {
        localStorage.removeItem('token');
        localStorage.removeItem('apKey');
      }
    }
  }

  setLoggedIn(value: boolean, user: User) {
    // Update login status subject
    this.loggedInSubject.next(value);
    this.currentUserSubject.next(user);
    this.loggedIn = value;
  }

  localStorageLogin(): boolean {
    const tokenJson = localStorage.getItem('token');
    if (tokenJson != null) {
      const token: JwtAuthObject = JSON.parse(tokenJson);
      const expires = new Date(token.expires);
      if (expires.getTime() > new Date().getTime()) {
        this.setLoggedIn(true, token.user);
        return true;
      } else {
        localStorage.removeItem('token');
        localStorage.removeItem('apKey');
        return false;
      }
    }
    return false;
  }

  adfsLogin() {
    // window.location.href = "https://ssoadfs.asus.com/SSO/?RANDOM={apKey}&R_URL={successPage}&AP={apId}"
    // 最後放上DB_NAME=eteamqc1 or eteamqa8把資料寫到測試機進行測試。
    const apKey = newGuid();
    const rUrl = encodeURIComponent(environment.redirectUrl);
    const host = location.host.toLocaleLowerCase();
    const apId = environment.apId[host]?environment.apId[host]:'282';
    const href = `${environment.adfsSSO}/?RANDOM=${apKey}&R_URL=${rUrl}&AP=${apId}`;
    localStorage.setItem('apKey', apKey);
    console.log('adfsLogin', href);
    localStorage.removeItem('token');
    this.setLoggedIn(false, null);
    location.href = href;
  }

  ssoLogin() {
    loadingShow();
    this.http.get<RespMessage>(`${environment.apiRoot}/sso/api/sso/token`, { withCredentials: true })
      .subscribe((resp: RespMessage<JwtAuthObject>) => {
        loadingHide();
        console.log('ssoLogin :: Ok');
        this.handleResp(resp);
      }, error => {
        loadingHide();
        console.error('ssoLogin ::', JSON.stringify(error));
        this.router.navigate(['/login']);
      });
  }

  mPortalLogin(mPortalKey: string) {
    loadingShow();
    this.http.post<RespMessage>(`${environment.apiRoot}/api/mportal/login`, { 'key': mPortalKey })
      .subscribe((resp: RespMessage<JwtAuthObject>) => {
        loadingHide();
        if (resp.state === 'Ok') {
          console.log('mportalLogin :: Ok');
          const token: JwtAuthObject = resp.result;
          console.log(new Date(token.expires), 'expires', new Date(), 'now');
          localStorage.setItem('token', JSON.stringify(token));
          this.setLoggedIn(true, token.user);
        } else {
          console.error('mportalLogin ::', resp.message);
          showInfo(resp.message, 3000, MessageType.error);
        }
      }, error => {
        loadingHide();
        console.error('mportalLogin ::', JSON.stringify(error));
        showInfo(JSON.stringify(error), 3000, MessageType.error);
        // this.router.navigate(['/login']);
      });
  }

  // adfs redirection
  ssoVerify(ssoKey: string) {
    // api/ssoverify/{ssoKey}/{apKey}
    const apKey = localStorage.getItem('apKey');
    loadingShow();
    this.http.post<RespMessage>(`${environment.apiRoot}/api/ssoverify`, { 'ssoJwt': ssoKey, 'apKey': apKey })
      .subscribe((resp: RespMessage<JwtAuthObject>) => {
        loadingHide();
        this.handleResp(resp);
      }, error => {
        loadingHide();
        console.error('ssoVerify ::', JSON.stringify(error));
        this.router.navigate(['/login']);
      });
  }

  private handleResp(resp: RespMessage) {
    if (resp.state === 'Ok') {
      console.warn(resp);
      if (resp.result.type === 'redirect') {
        location.href = encodeURI(resp.result.location);
        console.warn('location redirect');
        return;
      }
      const token: JwtAuthObject = resp.result;
      localStorage.setItem('token', JSON.stringify(token));
      if (this.jumpRootOrCP(token)) { return; }
      console.log(new Date(token.expires), 'expires', new Date(), 'now');
      this.setLoggedIn(true, token.user);
      const prev_url = sessionStorage.getItem('prev_url');
      if (prev_url) {
        console.log('prev_url', prev_url);
        sessionStorage.removeItem('prev_url');
        this.router.navigateByUrl(prev_url);
        // router.navigateByUrl uses location.hash.subString(1)
        // location.href = prev_url, not works when canActivate return false
      } else {
        this.router.navigate(['/home']);
      }
    } else {
      // showInfo(resp.message, 2000, MessageType.error);
      this.router.navigate(['/login']);
    }
  }

  refreshToken(auth: string) {
    auth = auth || '';
    console.log('/system;auth=', auth);
    if (auth === '') { return; }
    this.http.get<RespMessage>(`${environment.apiRoot}/api/refreshToken?auth=${auth}`)
      .subscribe((resp: RespMessage<JwtAuthObject>) => {
        console.log('refreshToken(auth)', resp.state);
        if (resp.state === 'Ok') {
          const token: JwtAuthObject = resp.result;
          localStorage.setItem('token', JSON.stringify(token));
          this.setLoggedIn(true, token.user);
        } else {
          // showInfo(resp.message, 2000, MessageType.error);
          console.error('refreshToken ::', resp.message);
        }
      }, error => {
        console.error('refreshToken ::', JSON.stringify(error));
      });
  }

  getShortTermToken$() {
    return this.http.post<RespMessage>(`${environment.apiRoot}/api/shortTermToken`, {});
  }

  verifyShortTermToken$(auth: string): Observable<boolean> {
    auth = auth || '';
    if (auth === '') { return of(false); }
    return this.http.get<RespMessage>(`${environment.apiRoot}/api/shortTermToken/verify?auth=${auth}`)
      .pipe(
        map((resp: RespMessage<boolean>) => {
          if (resp.state === 'Ok') {
            return resp.result;
          } else {
            showInfo(resp.message, 2000, MessageType.error);
            console.error('verifyShortTermToken ::', resp.message);
            return false;
          }
        }
        ));
  }

  verifyToken(auth: string) {
    auth = auth || '';
    if (auth === '') { return of(false); }
    this.http.get<RespMessage>(`${environment.apiRoot}/api/tokenverify?auth=${auth}`)
      .subscribe((resp: RespMessage<JwtAuthObject>) => {
        if (resp.state === 'Ok') {
          const jwtAuthObj: JwtAuthObject = resp.result;
          jwtAuthObj.token = auth;
          localStorage.setItem('token', JSON.stringify(jwtAuthObj));
          this.setLoggedIn(true, jwtAuthObj.user);
        } else {
          // showInfo(resp.message, 2000, MessageType.error);
          console.error('refreshToken ::', resp.message);
          this.router.navigate(['/login']);
        }
      }, error => {
        console.error('refreshToken ::', JSON.stringify(error));
        this.router.navigate(['/login']);
      });
  }

  login(domain: string, userID: string, password: string, code: string, uuid: string) {

    this.http.post<RespMessage>(`${environment.apiRoot}/api/login`,
      { domain: domain, username: userID, password: password, code: code, uuid: uuid })
      .subscribe((resp: RespMessage) => {
        loadingHide();
        this.handleResp(resp);
      }, error => {
        loadingHide();
        console.error('login ::', JSON.stringify(error));
      });
  }

  logout() {
    // Remove tokens and profile and update login status subject
    localStorage.removeItem('token');
    localStorage.removeItem('apKey');
    this.setLoggedIn(false, null);
    // if (this.swUpdateService.checkServiceWorkerdisEnabled()) {
    this.envService.checkReload();
    // }
    this.router.navigate(['/login']);
  }

  get authenticated() {
    // Check if there's an unexpired access token
    if (localStorage.getItem('token') == null) {
      return false;
    } else {
      const now = new Date();
      const token: JwtAuthObject = JSON.parse(localStorage.getItem('token'));
      const expires = new Date(token.expires);
      return expires.getTime() > now.getTime();
    }
  }

  getCurrentUser(): User {
    return this.currentUserSubject.value;
  }

  isJumpingRootOrCP(): boolean {
    return this.isJumping;
  }

  private jumpRootOrCP(token: JwtAuthObject): boolean {
    // prod without token
    // uat with token
    // local do nothing
    if (location.hostname !== 'localhost') {
      if (token.user.type !== 'CAP' && location.pathname.indexOf('/cp') === 0) {
        this.isJumping = true;
        this.document.location.href = location.origin;
        // window.location.href = location.origin;
        // this.router.navigateByUrl(location.origin);
        return true;
      }
      if (token.user.type === 'CAP' && location.pathname.indexOf('/cp') !== 0) {
        this.isJumping = true;
        this.document.location.href = `${location.origin}/cp`;
        // window.location.href = `${location.origin}/cp`;
        // this.router.navigateByUrl(`${location.origin}/cp`);
        return true;
      }
    }
    return false;
  }
}
