import { map, distinctUntilChanged } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { UserNode, TreeNode, RespMessage, FolderContent } from '../model/data-model';
import { HttpClient } from '@angular/common/http';
import { FolderType, showInfo, MessageType } from '../../shared/common';
import { PickTreeNodeComponent } from '../../shared/component/pick-tree-node/pick-tree-node.component';
import { TreeViewComponent } from '../../shared/component/tree-view/tree-view.component';
import { environment } from 'environments/environment';

@Injectable()
export class FolderCanWriteTreeService extends BaseService {

  private _pickTreeNode = {} as TreeNode;

  private userAllNodeSubject = new BehaviorSubject<UserNode>({} as UserNode);
  public userAllNode$ = this.userAllNodeSubject.asObservable().pipe(distinctUntilChanged());

  private pickTreeNodeSubject = new BehaviorSubject<TreeNode>({} as TreeNode);
  public pickTreeNode$ = this.pickTreeNodeSubject.asObservable().pipe(distinctUntilChanged());

  constructor(private http: HttpClient) {
    super();
  }

  set pickTreeNode(curNode: TreeNode) {
    this._pickTreeNode = curNode;
    this.pickTreeNodeSubject.next(curNode);
  }

  get pickTreeNode() {
    return this._pickTreeNode;
  }

  getFolderCanWriteAllRoot() {
    /////// GET /api/FolderTree/GetAllRoot
    this.http.get<RespMessage<UserNode>>(`${environment.apiRoot}/api/FolderTree/GetCanWriteAllRoot`)
      .subscribe((resp: RespMessage) => {
        if (resp.state === 'Ok') {
          this.userAllNodeSubject.next(resp.result);
          // console.log('getFolderAllRoot', resp.result);
        } else {
          showInfo(resp.message || 'warning', 3000, MessageType.warning);
        }
      },
        error => {
          console.error('getFolderCanWriteAllRoot ::', JSON.stringify(error));
        });
    // .unsubscribe();
  }

  getCanWriteTreeNodes(qry: { folderId: number, folderType, curNode: TreeNode, deptId?}) {
    /////// POST /api/FolderTree/getTreeNodes
    return this.http.post<RespMessage<TreeNode>>(`${environment.apiRoot}/api/FolderTree/getCanWriteTreeNodes`, qry)
      .pipe(
        tap((resp: RespMessage<TreeNode>) => {
          if (resp.state === 'Ok') {
            if (qry.curNode != null) {
              resp.result.childNode.forEach(v => {
                 // prevent cycle referecne from rxjs error.
                v.parentNode = { ...qry.curNode };
              });
              qry.curNode.childNode = [...resp.result.childNode];
              qry.curNode.have_child = resp.result.childNode.length > 0 ? '1' : '0';
            }
            this.userAllNodeSubject.next({ ...this.userAllNodeSubject.value });
          }
          return resp;
        })
      );
    // .unsubscribe();
  }

  currentFolderCanWriteAllRoot(): UserNode {
    return this.userAllNodeSubject.value;
  }
}
