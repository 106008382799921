import { FolderTypeName } from './../../shared/common';
import { Component, OnInit, OnDestroy, NgZone, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FolderTreeService } from '../../core/service/folder-tree.service';
import { FolderType } from '../../shared/common';
import { DocumentFolder, DocumentDto, User } from '../../core/model/data-model';
import { DocumentService } from '../../core/service/document.service';
import { FolderService } from '../../core/service/folder.service';
import { BaseFolderComponent } from '../base-folder/base-folder.component';
import { FolderCanWriteTreeService } from '../../core/service/folder-can-write-tree.service';
import { DocumentContextmenuService } from '../../core/service/document-contextmenu.service';
import { DownloadService } from 'app/core/service/download.service';
import { FolderDocumentService } from 'app/core/service/folder-document.service';
import { MatomoTracker  } from 'ngx-matomo';
import { AuthTokenService } from 'app/core/service/auth-token.service';

@Component({
  selector: 'doc-private-folder',
  templateUrl: './private-folder.component.html',
  styleUrls: ['./private-folder.component.css'],
})
export class PrivateFolderComponent extends BaseFolderComponent implements OnInit, OnDestroy {
  childFolders: DocumentFolder[];
  documents: DocumentDto[];
  
  constructor(private matomoTracker: MatomoTracker
    ,protected folderTreeService: FolderTreeService, protected folderCanWriteTreeService: FolderCanWriteTreeService
    , protected documentService: DocumentService, protected folderService: FolderService
    , protected folderDocumentService: FolderDocumentService, protected downloadService: DownloadService
    , protected contextmenuService: DocumentContextmenuService
    , protected router: Router, protected route: ActivatedRoute, protected zone: NgZone
    , private authTokenService: AuthTokenService) {
    super(folderTreeService, folderCanWriteTreeService, folderService, documentService, folderDocumentService, downloadService, contextmenuService, router);
    this.folderId = 0;
    this.folderType = FolderType.PRIVATE_FOLDER;
    this.folderTypeName = FolderTypeName.PRI;
    
  }
  ngOnInit() {
    super.ngOnInit();
    this.authTokenService.currentUser$
      .subscribe(user => {
        if (user) {
          this.matomoTracker.setUserId(user.userName);
          this.matomoTracker.trackPageView('page_private');
          this.matomoTracker.setCustomVariable(1, "WorkId", user.workId, "page")
        }
      });

    this.route.params.subscribe((params) => {
      const data = this.dataDecode(params['data']);
      if ('folderId' in data) {
        this.folderId = data['folderId'];
        this.resultList = [];
        this.sortByCols = {
          'typeCss': '',
          'name': '',
          'modifyTime': '',
          'readCount': '',
        };
        this.sortBy = '';
        this.sortType = '';
        this.query();
      }
    });
  }

  protected afterDelete() {
    this.folderTreeService.getMyFileSize();
  }

  ngOnDestroy() {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    console.log('PrivateFolderComponent.....ngOnDestroy');
  }
}
