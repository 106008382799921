<!--More information-->
<b class="show-info info-storage" data-toggle="popover" data-container="body" data-placement="bottom" data-html="true" data-trigger="hover" id="info" data-html="true"
  (mouseover)="getFolderFileSize()"></b>
<!--popover pattern - More information   -->
<div id="popover-content-info" style="display:none">
  <ul class="info-list">
    <li class="info-item">
      <div class="item-header">
        <i class="ico-storage marg4-r"></i>
        <span>{{ "Storage Space" | translate }}</span>
      </div>
      <div class="item-body">
        <div class="progress info-progress">
          <div class="progress-bar" [style.width]="percent"></div>
        </div>
        <div class="progress-value">
          <div class="value-now">{{ "Used" | translate }}
            <i [style.display]="current==''?'':'none'" class="fa fa-spinner fa-spin marg4-rl"></i>
            <span class="value-current">{{current}}</span>
          </div>
          <div class="value-total">{{ "Total1" | translate }}
            <i [style.display]="limit==''?'':'none'" class="fa fa-spinner fa-spin marg4-rl"></i>
            <span class="value-limit">{{limit}}</span>
          </div>
        </div>
      </div>
    </li>
  </ul>
</div>
