import { Component, OnInit, NgZone, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { DocumentDto, RespMessage, DocumentFile, FolderFileSizeVM, DocumentContent2, PermissionContentVo, ResourceType } from './../../core/model/data-model';
import { FolderType, loadingHide, loadingShow, FolderTypeName, showInfo, MessageType, RouteMap, showConfirm } from '../../shared/common';
import { DocumentService, FolderTreeService } from '../../core/service';
import { BaseComponent } from '../../shared/component';


@Component({
  selector: 'doc-archives-edit2',
  templateUrl: './archives-edit2.component.html',
  styleUrls: ['./archives-edit2.component.css']
})
export class ArchivesEdit2Component extends BaseComponent implements OnInit, AfterViewInit {
  resourceType = ResourceType.file;
  docId = 0;
  folderId = 0;
  folderType: FolderType;
  folderTypeName = FolderTypeName;
  deptId = '';
  MEGABYTE = 1048576; // Bytes
  documentContent = {} as DocumentContent2;
  documentDto = {} as DocumentDto;
  documentFiles: DocumentFile[] = [];
  strfileList: string[] = [];
  fileList: File[] = [];
  fileSize: FolderFileSizeVM;
  @ViewChild('uploadFiles', { static: false })
  uploadFiles: ElementRef;
  onDrag = false;
  permissionContent: PermissionContentVo;

  constructor(private documentService: DocumentService,
    private folderTreeService: FolderTreeService,
    private router: Router, private route: ActivatedRoute,
    private zone: NgZone) {
    super();
  }

  ngOnInit() {

    this.route.params.subscribe((params) => {

      const data = this.dataDecode(params['data']);
      if (data['folderType'] != null && data['docId'] != null) {
        this.folderId = data['folderId'] || 0;
        this.folderType = data['folderType'];
        this.deptId = data['deptId'] || '';
        this.folderTypeName = FolderTypeName['' + this.folderType];
        this.docId = data['docId'];
        loadingShow();
        this.loadData()
          .subscribe((arr: RespMessage[]) => {
            loadingHide();
            let resp: RespMessage = arr[0];
            if (resp.state === 'Ok') {
              this.documentContent = resp.result;
              this.documentDto = this.documentContent.document;
              this.permissionContent = this.documentContent.permissionContent;
              this.documentFiles = this.documentContent.documentFiles;
              // console.log(this.documentContent);
            } else {
              showInfo(resp.message, 2000, MessageType.error);
            }

            resp = arr[1];
            if (resp.state === 'Ok') {
              this.fileSize = resp.result;
            } else {
              showInfo(resp.message, 2000, MessageType.error);
            }
          }, error => {
            loadingHide();
            showInfo(error.message || 'error', 3000, MessageType.error);
            console.error('loadData ::', JSON.stringify(error));
          });
      }
    });
  }

  ngAfterViewInit() {

    this.zone.runOutsideAngular(() => {
      $('.tab-panel li').on('click', function () {
        const $show = $(this).index();
        $(this).addClass('focus').siblings('.focus').removeClass('focus');
        $(this).parents('.panel-article').find('.tab-panel-boddy').find('.panel-body').eq($show).removeClass('hide').siblings('.panel-body').addClass('hide');
      });
    });

  }

  loadData() {
    return forkJoin([
      this.documentService.getEditDoc2({ folderId: this.folderId, folderType: this.folderType, docId: this.docId }),
      this.folderTreeService.getFolderFileSize({ folderId: this.folderId, folderType: this.folderType, deptId: this.deptId, docId: this.docId })
    ]).pipe(
      map((data: any[]) => {
        return data;
      })
    );
  }

  calcuFileLimit() {
    let all = 0; // MB
    if (this.fileList != null && this.fileList.length > 0) {
      this.fileList.map(f => all += f.size);
      // all = all / this.MEGABYTE;
    }
    if (this.fileSize != null && this.fileSize.limit > 0) {
      if (this.fileSize.current + all > this.fileSize.limit) {
        showInfo('超過限制大小無法新增', 2000, MessageType.error);
        return false;
      }
    }
    return true;
  }

  // setSelectAccountClass(usersVo: PermissionVo[], groupsVo: PermissionVo[]) {
  //   if ((usersVo == null || usersVo.length === 0) && (usersVo == null || usersVo.length === 0)) {
  //     return "select-account show-pop";
  //   } else {
  //     return "has-account show-pop";
  //   }
  // }

  removeFile(_file: File) {
    const idx = this.fileList.findIndex(f => f.name === _file.name);
    this.fileList.splice(idx, 1);
  }

  getBytes(num) {
    return (num / this.MEGABYTE).toFixed(3);
  }

  goBack() {
    const url = RouteMap[this.folderType];
    this.router.navigate([url, { data: this.dataEncode({ folderId: this.folderId, deptId: this.deptId }) }]);
  }

  confirmSave(form: NgForm) {
    if (form.invalid || !this.calcuFileLimit()) { return; }
    if (this.documentDto.canAdmin) {
      showConfirm('您確定要變更權限?', '', MessageType.question)
        .then(result => {
          if (!result.value) { return; }
          this.save(form);
        });
    } else {
      this.save(form);
    }
  }

  save(form: NgForm) {
    const _folderType = this.folderType === FolderType.SHARE_WITH_ME ? FolderType.PRIVATE_FOLDER : this.folderType;
    this.documentContent.permissionContent = this.permissionContent;
    console.log('save', this.documentContent);
    loadingShow();
    const save$ = this.documentDto.canAdmin ?
      this.documentService.saveWithPerm({ folderId: this.folderId, folderType: _folderType, documentContent: this.documentContent }) :
      this.documentService.save2({ folderId: this.folderId, folderType: _folderType, documentContent: this.documentContent });
    save$.subscribe((resp: RespMessage) => {
      loadingHide();
      if (resp.state === 'Ok') {
        this.documentContent = resp.result;
        this.permissionContent = this.documentContent.permissionContent;
        this.documentDto = this.documentContent.document;
        showInfo('Success', 2000, MessageType.success);
        this.goBack();
      } else {
        showInfo(resp.message, 2000, MessageType.error);
      }
    }, error => {
      loadingHide();
      showInfo(error.message || 'error', 3000, MessageType.error);
      console.log('error', error);
    });
  }

}
