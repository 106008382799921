import { BaseComponent } from './../base/base.component';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
@Component({
  selector: 'doc-paged-list',
  templateUrl: './paged-list.component.html',
  styleUrls: ['./paged-list.component.css']
})
export class PagedListComponent extends BaseComponent implements OnInit {
  /// <summary>
  /// 每頁幾筆
  /// </summary>
  @Input() pageSize = 100;
  /// <summary>
  /// Result 共幾筆資料
  /// </summary>
  @Input() dataCount: number;
  /// <summary>
  /// 第幾頁資料
  /// </summary>
  @Input() pageNum: number;
  /// <summary>
  /// 共幾頁
  /// </summary>
  @Input() pageCount: number;

  @Output() pageNumChange: EventEmitter<{ pageSize: number, pageNum: number, pageCount: number }> = new EventEmitter<{ pageSize: number, pageNum: number, pageCount: number }>();
  @Output() pageSizeChange: EventEmitter<{ pageSize: number, pageNum: number, pageCount: number }> = new EventEmitter<{ pageSize: number, pageNum: number, pageCount: number }>();
  @Output() pageSizeInit: EventEmitter<{ pageSize: number, pageNum: number, pageCount: number }> = new EventEmitter<{ pageSize: number, pageNum: number, pageCount: number }>();

  @Input() showPageNum = true;
  pageArry: number[] = []; //// 處理分頁使用////

  constructor() {
    super();
  }

  ngOnInit() {
    this.updatePagedListInfo();
    this.pageSizeInit.emit({ pageSize: this.pageSize, pageNum: 1, pageCount: this.pageCount });
  }

  updatePagedListInfo() {
    // console.log('this.dataCount ', this.dataCount );
    this.pageCount = this.dataCount === 0 ? 1 : (this.dataCount / this.pageSize + (this.dataCount % this.pageSize === 0 ? 0 : 1));
    this.pageCount = Math.floor(this.pageCount);
    // console.log('this.dataCount ', this.dataCount, 'this.pageCount', this.pageCount);
    this.pageArry = new Array(this.pageCount);
    this.pageArry = [...this.pageArry];
  }

  emitPageSize() {
    localStorage.setItem('pageSize', this.pageSize.toString());
    this.updatePagedListInfo();
    this.pageSizeChange.emit({ pageSize: this.pageSize, pageNum: 1, pageCount: this.pageCount });
  }

  emitPageNum(num) {
    this.pageNum = num;
    console.log(this.pageNum, this.pageCount);
    this.pageNumChange.emit({ pageSize: this.pageSize, pageNum: this.pageNum, pageCount: this.pageCount });
  }

}
