<div class="main">
  <doc-breadcrumbs [folderHierarchy]="folderContent?.folderHierarchy" [folderType]="folderType" [folderTypeName]="folderTypeName"></doc-breadcrumbs>
  <form #form="ngForm" (keydown.enter)="false">
    <section class="panel panel-default panel-article">
      <div class="panel-heading">
        <h3 class="panel-title">
          <i class="circle mdi mdi-folder-plus"></i>{{ "Edit Folder" | translate }}</h3>
        <div>
          <a class="btn btn-dark marg6-r" href="javascript:void(0)" (click)="goBack()">
            <i class="mdi mdi-reply"></i>{{ "Cancel" | translate }}</a>
          <a class="btn btn-primary" href="javascript:void(0)" (click)="confirmSave(form)">
            <i class="mdi mdi-content-save"></i>{{ "Save" | translate }}</a>
        </div>
      </div>
      <div class="panel-body">
        <dl class="pad10 form-v">
          <dt class="required-title">{{ "Folder Name" | translate }}</dt>
          <dd>
            <ng-container *ngIf="isFolderNameReadOnly(); else folderNameComp">{{documentFolder.folderName}}
            </ng-container>
            <ng-template #folderNameComp>
              <input class="form-control" [ngClass]="{'input-validation-error':(folderName.errors?.required)}" maxlength="100" required
                name="folderName" #folderName="ngModel" ngModel [(ngModel)]="documentFolder.folderName">
              <span *ngIf="folderName?.errors?.required && folderName.touched" class="field-validation-error">{{ "Required" | translate }}</span>
            </ng-template>
          </dd>
        </dl>
      </div>
    </section>
    <doc-permission-setting *ngIf="withPermission" [folderType]="folderType" [folderId]="folderContent?.folderId"
      [parentFolderId]="documentFolder?.parentFolder?.folderId" [needCascade]="true" [needReadAll]="true" [resourceType]="resourceType"
      [permissionContent]="permissionContent"></doc-permission-setting>
    <div class="center pad20-b">
      <a class="btn btn-sm btn-dark marg6-r" href="javascript:void(0)" (click)="goBack()">
        <i class="mdi mdi-reply"></i>{{ "Cancel" | translate }}</a>
      <a class="btn btn-sm btn-primary" href="javascript:void(0)" (click)="confirmSave(form)">
        <i class="mdi mdi-content-save"></i>{{ "Save" | translate }}</a>
    </div>
  </form>
</div>