import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { FolderType, loadingHide, loadingShow } from '../../shared/common';
import { environment } from 'environments/environment';

@Injectable()
export class DownloadService extends BaseService {

  constructor(private http: HttpClient) {
    super();
  }

  getFile(url: string, fileName: string) {
    loadingShow();
    this.http.get(url, { responseType: 'blob' })
      .subscribe(file => {
        loadingHide();
        saveAs(file, fileName);
      },
        error => {
          loadingHide();
          console.error(error);
        }
      );
  }

  batchDownload(folderType: FolderType, docIds: number[], fileName: string) {
    const url = `${environment.apiRoot}/api/DownloadFile/BatchDownload`;
    loadingShow();
    this.http.post(url, { folderType, docIds }, { responseType: 'blob' })
      .subscribe(file => {
        loadingHide();
        saveAs(file, fileName);
      },
        error => {
          loadingHide();
          console.error(error);
        }
      );
  }
}
