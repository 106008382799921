import { Component, OnInit, NgZone, AfterViewInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FolderType, showInfo, MessageType, sizeFormat, getPercent } from '../../common';
import { FolderTreeService } from '../../../core/service';
import { RespMessage, FolderFileSizeVM } from '../../../core/model/data-model';
declare var $: any;

@Component({
  selector: 'doc-folder-file-size-info',
  templateUrl: './folder-file-size-info.component.html',
  styleUrls: ['./folder-file-size-info.component.css']
})
export class FolderFileSizeInfoComponent implements OnInit, AfterViewInit {

  @Input() folderId = 0;
  @Input() folderType: FolderType;
  @Input() deptId = '';
  current = '';
  limit = '';
  percent = '0%';
  // css = { 'fa-spinner': this.current === '', 'fa-spin': this.current === '' };
  // @ViewChildren('sizeInfo1,sizeInfo2') sizeInfos: QueryList<ElementRef>;
  constructor(private folderTreeService: FolderTreeService, private zone: NgZone, private route: ActivatedRoute) { }


  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.current = '';
      this.limit = '';
      this.percent = '0%';
    });
  }

  ngAfterViewInit() {
    this.zone.runOutsideAngular(() => {

      // $("[data-toggle=popover]").each(function () {
      $('#info').popover({
        html: true,
        content: function () {
          const id = $(this).attr("id");
          return $("#popover-content-" + id).html();
        }
      });
      // });

    });

  }

  getFolderFileSize() {
    if (this.limit === '') {
      this.folderTreeService.getFolderFileSize({ folderId: this.folderId, folderType: this.folderType, deptId: this.deptId, docId: 0 })
        .subscribe((resp: RespMessage<FolderFileSizeVM>) => {
          if (resp.state === 'Ok') {
            console.log('getFolderFileSize');
            const result = resp.result;
            this.current = sizeFormat(result.current);
            this.limit = sizeFormat(result.limit);
            this.percent = getPercent(result.current, result.limit);
            console.log(this.current, this.limit, this.percent);
            // <div class="progress-bar" style="width:30%"></div>
            $('div[class="popover fade bottom in"] i[class="fa fa-spinner fa-spin marg4-rl"]').hide();
            $('div[class="popover fade bottom in"] span[class="value-current"]').html(this.current);
            $('div[class="popover fade bottom in"] span[class="value-limit"]').html(this.limit);
            $('div[class="popover fade bottom in"] div[class="progress-bar"]').css("width", this.percent);
            // console.log($('div[class="popover fade bottom in"] div[class="progress-bar"]'));
          } else {
            showInfo(resp.message || 'error', 3000, MessageType.error);
          }

        }, error => {
          showInfo(error.message || 'error', 3000, MessageType.error);
          console.error('folderService.getFolderFileSize::', JSON.stringify(error));
        });
    }
  }

}
