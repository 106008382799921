import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';

import { DatepickerDirective } from './directive/datepicker.directive';
import { ToggleTreeNodeDirective } from './directive/toggle-tree-node.directive';

import { SortPipe } from './pipe/sort.pipe';
import { DocTypeNamePipe } from './pipe/doc-type-name.pipe';
import { DocTypeCssPipe } from './pipe/doc-type-css.pipe';

import { UploadxModule } from '../uploadx';
import { CKEditorModule } from 'ng2-ckeditor';
import { QRCodeModule } from 'angularx-qrcode';

import { SearchDocumentComponent } from './component/search-document/search-document.component';
import { PickTreeNodeComponent } from './component/pick-tree-node/pick-tree-node.component';
import { TreeViewComponent } from './component/tree-view/tree-view.component';
import { TreeViewNavComponent } from './component/tree-view-nav/tree-view-nav.component';
import { PagedListComponent } from './component/paged-list/paged-list.component';
import { BaseComponent } from './component/base/base.component';
import { BreadcrumbsComponent } from './component/breadcrumbs/breadcrumbs.component';
import { UpoladBoxComponent } from './component/upolad-box/upolad-box.component';
import { AccountSelectComponent } from './component/account-select/account-select.component';
import { PickTreeComponent } from './component/pick-tree/pick-tree.component';
import { PickFavoriteFolderComponent } from './component/pick-favorite-folder/pick-favorite-folder.component';
import { PickTreeCloneDocComponent } from './component/pick-tree-clone-doc/pick-tree-clone-doc.component';
import { PickTreeCloneDocNodeComponent } from './component/pick-tree-clone-doc-node/pick-tree-clone-doc-node.component';
import { PageComponent } from './component/page/page.component';
import { DepartmentSelectComponent } from './component/department-select/department-select.component';
import { DocumentContentViewComponent } from './component/document-content-view/document-content-view.component';
import { FolderFileSizeInfoComponent } from './component/folder-file-size-info/folder-file-size-info.component';
import { HitCountRecordComponent } from './component/hit-count-record/hit-count-record.component';
import { ModalPagedListComponent } from './component/modal-paged-list/modal-paged-list.component';
import { DownloadCountRecordComponent } from './component/download-count-record/download-count-record.component';
import { DocumentContextmenuDirective } from './directive/document-contextmenu.directive';
import { DocumentContextmenuModalComponent } from './component/document-contextmenu-modal/document-contextmenu-modal.component';
import { QrcodeExComponent } from './component/qrcode-ex/qrcode-ex.component';
import { PickMulCloneDocComponent } from './component/pick-mul-clone-doc/pick-mul-clone-doc.component';
import { PermissionSettingComponent } from './component/permission-setting/permission-setting.component';
import { CallbackPipe } from './pipe/callback.pipe';
import { AccountSelect2Component } from './component/account-select2/account-select2.component';
import { PermissionEditComponent } from './component/permission-edit/permission-edit.component';
import { DocumentContextmenuOutsideDirective } from './directive/document-contextmenu-outside.directive';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    CKEditorModule,
    QRCodeModule,
    UploadxModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    CKEditorModule,
    QRCodeModule,
    UploadxModule,
    DatepickerDirective,
    SortPipe,
    SearchDocumentComponent,
    PickTreeNodeComponent,
    TreeViewComponent,
    TreeViewNavComponent,
    PagedListComponent,
    BreadcrumbsComponent,
    UpoladBoxComponent,
    DocTypeNamePipe,
    DocTypeCssPipe,
    AccountSelectComponent,
    PickFavoriteFolderComponent,
    ToggleTreeNodeDirective,
    PickTreeComponent,
    PickTreeCloneDocComponent,
    PickTreeCloneDocNodeComponent,
    DepartmentSelectComponent,
    DocumentContentViewComponent,
    FolderFileSizeInfoComponent,
    HitCountRecordComponent,
    ModalPagedListComponent,
    DownloadCountRecordComponent,
    DocumentContextmenuDirective,
    DocumentContextmenuModalComponent,
    QrcodeExComponent,
    PickMulCloneDocComponent,
    PermissionSettingComponent,
    DocumentContextmenuOutsideDirective,
  ],
  declarations: [
    DatepickerDirective,
    SortPipe,
    BaseComponent,
    PageComponent,
    SearchDocumentComponent,
    PickTreeNodeComponent,
    TreeViewComponent,
    TreeViewNavComponent,
    PagedListComponent,
    BreadcrumbsComponent,
    DocTypeNamePipe,
    DocTypeCssPipe,
    UpoladBoxComponent,
    AccountSelectComponent,
    PickTreeComponent,
    PickFavoriteFolderComponent,
    ToggleTreeNodeDirective,
    PickTreeCloneDocComponent,
    PickTreeCloneDocNodeComponent,
    DepartmentSelectComponent,
    DocumentContentViewComponent,
    FolderFileSizeInfoComponent,
    HitCountRecordComponent,
    ModalPagedListComponent,
    DownloadCountRecordComponent,
    DocumentContextmenuDirective,
    DocumentContextmenuModalComponent,
    QrcodeExComponent,
    PickMulCloneDocComponent,
    PermissionSettingComponent,
    CallbackPipe,
    AccountSelect2Component,
    PermissionEditComponent,
    DocumentContextmenuOutsideDirective,
  ],
})
export class SharedModule { }
