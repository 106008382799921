import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[docDocumentContextmenuOutside]'
})
export class DocumentContextmenuOutsideDirective {

  private needSelectStart = false;

  @HostListener('selectstart', ['$event'])
  onSelectStart(e: Event) {
    if (!this.needSelectStart) {
      e.preventDefault();
    }
  }

  @HostListener('keydown.control', ['$event'])
  @HostListener('keydown.shift', ['$event'])
  onKeydown(e: KeyboardEvent) {
    this.needSelectStart = false;
  }

  @HostListener('keyup.control', ['$event'])
  @HostListener('keyup.shift', ['$event'])
  onKeyup(e: KeyboardEvent) {
    this.needSelectStart = true;
  }

}
