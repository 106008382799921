<div class="popbg-fill show-document-view {{ popCss}}" [ngClass]="{
  'va-center-contentScroll':(documentDto?.isArchive !== '1'),
  'show-details':(documentDto?.isArchive !== '1'),
  'show-download':(documentDto?.isArchive === '1'),
  'va-center':(documentDto?.isArchive === '1')}">
  <div class="pop-box" [ngClass]="{'pop-size-500':(documentDto?.isArchive === '1')}">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close">
          <i class="mdi mdi-close"></i>
        </button>
        <h4 *ngIf="documentDto?.isArchive !== '1'">{{documentDto?.documentName}} </h4>
        <h4 *ngIf="documentDto?.isArchive === '1'">{{ "Download File" | translate }} </h4>
      </div>
      <div class="modal-body">
        <div class="detail-info-right">
          <a class="a-close" *ngIf="documentDto?.isArchive !== '1'" href="javascript:void(0)" (click)="openNew($event)">
            <i class="mdi mdi-magnify-plus-outline"></i>{{ "Open New Page" | translate }}</a>
          <a *ngIf="documentDto?.isArchive !== '1' && currentUser?.type ==='ASUS' " class="show-pop" href="javascript:void(0)"
            (click)="addfav()">
            <i class="mdi mdi-star" [ngClass]="{'yellow':documentDto?.inFavorite }"> </i>{{ "Add to Favorite" |
            translate }}</a>
          <i class="mdi mdi-clock"> </i>{{documentDto?.createTime | date:'yyyy-MM-dd HH:mm a'}}
          <i class="mdi mdi-account-circle"> </i>
          {{documentDto?.createUser?.userName}}({{documentDto?.createUser?.userEngName}}) {{ "Publish" | translate }}
          <i class="mdi mdi-eye"> </i>
          <ng-container *ngIf="!documentDto.canAdmin">{{documentDto?.readCount}} {{ "Hit Counts" | translate }}</ng-container>
          <a class="show-pop" *ngIf="documentDto.canAdmin" href="javascript:void(0)" (click)="onHitCountClick()">
            {{documentDto?.readCount}} {{ "Hit Counts" | translate }}</a>
        </div>
        <ng-container *ngIf="documentDto?.isArchive !== '1'">
          <div class="detail-download" *ngIf="documentFiles?.length> 0">{{ "Download Attachment" | translate }} :
            <ng-container *ngFor="let item of documentFiles">
              <a *ngIf="item.exists" href="javascript:void(0)" (click)="downloadFile(item)">
                <i class="mdi mdi-paperclip"></i>{{item.fileName}}
                <ng-container *ngIf="!item.exists">({{ "File Not Exist" | translate }})</ng-container>
              </a>
              <a *ngIf="item.exists"  class="show-pop" show-pop="show-download" href="javascript:void(0)" (click)="onDownloadCountClick(item)">
                ({{ item.downloadCount }}次下載)</a>
            </ng-container>
          </div>
        </ng-container>
        <ng-container *ngIf="documentDto?.isArchive === '1'">
          <div class="detail-download marg20-tb center">{{ "FileName1" | translate }} :<br>
            <ng-container *ngFor="let item of documentFiles">
              <a  *ngIf="item.exists" href="javascript:void(0)" (click)="downloadFile(item)">
                <i class="mdi mdi-paperclip"></i>{{item.fileName}}
                <ng-container *ngIf="!item.exists">({{ "File Not Exist" | translate }})</ng-container>
              </a>
              <a  *ngIf="item.exists" class="show-pop" show-pop="show-download" href="javascript:void(0)" (click)="onDownloadCountClick(item)">
                ({{ item.downloadCount }}次下載)</a>
            </ng-container>
          </div>

          <div class="center pad10-tb">
            <a *ngIf="documentDto?.isArchive === '1' && documentFiles?.length> 0 && documentFiles[0].exists" (click)="downloadFile(documentFiles[0])"
              class="btn btn-sm btn-primary close-btn marg6-r btn-min-w100" href="javascript:void(0)">
              <i class="mdi mdi-cloud-download"></i>{{ "Download" | translate }} </a>
          </div>
        </ng-container>
        <!-- <ng-container *ngIf="documentDto?.isArchive !== '1'"> -->
        <div class="main-document-content">
          <doc-document-content-view #contentView [documentDto]="documentDto"></doc-document-content-view>
        </div>
        <!-- </ng-container> -->
      </div>
    </div>
  </div>
</div>
<doc-download-count-record #downloadCountRecord [popCss]='popDownloadCss'></doc-download-count-record>
