<div class="main">
  <doc-breadcrumbs [folderHierarchy]="documentContent?.folderHierarchy" [folderType]="folderType" [folderTypeName]="folderTypeName"></doc-breadcrumbs>
  <form #form="ngForm" (keydown.enter)="false">
    <section class="panel panel-default panel-article">
      <div class="panel-heading">
        <h3 class="panel-title">
          <ul class="tab-panel">
            <li class="focus">
              <i class="circle mdi mdi-file-plus"></i>{{ "Create Document" | translate }}</li>
            <li>
              <i class="circle mdi mdi-account-settings-variant"></i>{{ "Permission Setting" | translate }}</li>
          </ul>
        </h3>
        <div>
          <a class="btn btn-dark marg6-r" href="javascript:void(0)" (click)="goBack()">
            <i class="mdi mdi-reply"></i>{{ "Cancel" | translate }}</a>
          <a class="btn btn-primary" href="javascript:void(0)" (click)="confirmSave(form)">
            <i class="mdi mdi-content-save"></i>{{ "Save" | translate }}</a>
        </div>
      </div>
      <div class="tab-panel-boddy">
        <div class="panel-body">
          <dl class="pad10 form-v">
            <dt class="required-title">{{ "Document Name" | translate }}</dt>
            <dd>
              <input class="form-control" [ngClass]="{'input-validation-error':(documentName.errors?.required)}" required name="documentName"
                #documentName="ngModel" ngModel [(ngModel)]="documentDto.documentName">
              <span *ngIf="documentName?.errors?.required && documentName.touched" class="field-validation-error">{{ "Required" | translate }}</span>

            </dd>
            <dt>{{ "Document Content" | translate }}
              <a href="javascript:void(0)" (click)="showPreview()">
                (
                <i class="mdi mdi-magnify-plus-outline"></i>{{ "Preview" | translate }})</a>
            </dt>
            <dd>
              <ckeditor name="documentContent" [(ngModel)]="documentDto.documentContent" debounce="500">
              </ckeditor>
            </dd>
            <dt>
              <ul class="upolad-content " [ngClass]="{'drag':onDrag}" (dragover)="dragHandler($event);" (drop)="dropFiles($event)">
                <li>
                  <p></p>
                  <div class="input-file-btn">
                    <input type="file" #uploadFiles multiple name="uploadFiles" (change)="selFiles()"><a class="btn btn-xs btn-primary" href="#"><i
                        class="mdi mdi-format-vertical-align-top"></i>{{ "UploadFiles" | translate }} </a>
                  </div>
                </li>
                <li>
                  <p class="gray">{{ "Save Then Upload" | translate }} Drag and drop files here!</p>
                </li>
                <li *ngFor="let item of fileList">
                  <p>{{item.name}}</p>
                  <div class="upolad-content-r">
                    <em>{{ getBytes(item.size) }}MB</em>
                    <div class="status waiting">
                      <i class="mdi mdi-minus-circle"></i>
                      <p>{{ "Save then Upload" | translate }}</p>
                      <a class="mdi mdi-delete btn" (click)="removeFile(item) "></a>
                    </div>
                  </div>
                </li>
                <li class="empty">
                  <img class="upoladimg" src="assets/img/upload.svg">
                  <h3>{{ "Drag and Drop File" | translate }}</h3>
                  <p class="gray center">{{ "Not close window when uploading file" | translate }}</p>
                </li>
              </ul>
            </dt>
            <dd></dd>
            <dt>{{ "Description1" | translate }}</dt>
            <dd>
              <input class="form-control" name="description" [(ngModel)]="documentDto.description"
                placeholder="{{ 'PlaceHolder of Description' | translate }}">
            </dd>
            <dt>{{ "Keyword" | translate }}</dt>
            <dd>
              <input class="form-control" name="keyword" [(ngModel)]="documentDto.keyword" placeholder="{{ 'PlaceHolder of Keyword' | translate }}">
            </dd>
          </dl>
        </div>
        <div class="panel-body hide">
          <doc-permission-setting [folderType]="folderType" [folderId]="documentContent?.folderId" [documentId]="documentContent?.docId"
            [needCascade]="false" [needReadAll]="false" [resourceType]="resourceType" [permissionContent]="permissionContent">
          </doc-permission-setting>
        </div>
      </div>
    </section>
  </form>
  <div class="center pad20-b">
    <a class="btn btn-sm btn-dark marg6-r" href="javascript:void(0)" (click)="goBack()">
      <i class="mdi mdi-reply"></i>{{ "Cancel" | translate }}</a>
    <a class="btn btn-sm btn-primary" href="javascript:void(0)" (click)="confirmSave(form)">
      <i class="mdi mdi-content-save"></i>{{ "Save" | translate }}</a>
    <!-- {{ form.value | json }} -->
  </div>
</div>
<doc-document-preview [documentDto]="documentDto" [fileList]="fileList"></doc-document-preview>