import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { ResourceType, DataRow, DocumentDto, DocumentFolder, RespMessage, DocumentFile, DocumentContent, FolderContent, DocumentContextmenuDto, JwtAuthObject } from 'app/core/model/data-model';
import { Subscription, Observable, EMPTY, of, forkJoin } from 'rxjs';
import { BaseComponent } from '../base/base.component';
import { FolderType, MessageType, showInfo, getFileExtension, popbox, isMobile } from 'app/shared/common';
import { DocumentService, UploadxService, DocumentContextmenuService, AuthTokenService } from 'app/core/service';
import { map, tap, filter, concatMap, repeat } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Component({
  selector: 'doc-document-contextmenu-modal',
  templateUrl: './document-contextmenu-modal.component.html',
  styleUrls: ['./document-contextmenu-modal.component.css']
})
export class DocumentContextmenuModalComponent extends BaseComponent implements OnInit, OnDestroy {

  private contextmenuSubscription: Subscription;
  readonly Resource = ResourceType;
  resource: ResourceType;
  clientX: number;
  clientY: number;
  item: DataRow;
  isVisible = false;
  isSingleSelected = true;
  isMobile = false;
  qrdata = '';
  hash = location.hash;
  @Input() pageList: DataRow[];
  @Input() folderType: FolderType;
  @Input() folderId = 0;
  @Input() deptId = ''; // todo
  @Input() folderContent: FolderContent;
  @Output() batchDelete: EventEmitter<void> = new EventEmitter<void>();
  @Output() batchDownload: EventEmitter<void> = new EventEmitter<void>();
  @Output() delete: EventEmitter<DataRow> = new EventEmitter<DataRow>();
  @Output() edit: EventEmitter<DataRow> = new EventEmitter<DataRow>();
  @Output() copyDoc: EventEmitter<DataRow> = new EventEmitter<DataRow>();
  @Output() addFav: EventEmitter<DataRow> = new EventEmitter<DataRow>();
  @ViewChild('uploadFilesToFolder', { static: false }) uploadFilesToFolder: ElementRef;
  @ViewChild('cloneContextmenu', { static: false }) cloneContextmenu: ElementRef; // measure offsetWidth and offsetHeight

  constructor(private contextmenuService: DocumentContextmenuService, private documentService: DocumentService,
    private uploadService: UploadxService, private authTokenService: AuthTokenService) {
    super();
    this.isMobile = isMobile();
  }

  ngOnInit() {
    this.contextmenuSubscription = this.contextmenuService.contextmenuDto$
      .subscribe(dto => {
        this.isMobile = isMobile();
        if (dto) {
          this.item = dto.dataRow;
          this.resource = this.getResourceType(dto.dataRow);
          this.isSingleSelected = this.pageList && this.pageList.filter(m => m.isSelected).length <= 1;
          // delay 10ms to wait clientX clientY from cloneContextmenu
          setTimeout(() => {
            this.isVisible = true;
            if (!this.isMobile) {
              this.clientX = this.getBoundedClientX(dto.clientX);
              this.clientY = this.getBoundedClientY(dto.clientY);
            }
          }, 10);
        } else {
          this.isVisible = false;
        }
      });
  }

  emitBatchDelete() {
    this.batchDelete.emit();
  }

  emitBatchDownload() {
    this.batchDownload.emit();
  }

  emitDelete() {
    this.delete.emit(this.item);
  }

  emitEdit() {
    this.edit.emit(this.item);
  }

  emitCopyDoc() {
    this.copyDoc.emit(this.item);
  }

  emitAddFav() {
    this.addFav.emit(this.item);
  }

  onContextmenu(e: MouseEvent) {
    e.preventDefault();
  }

  upload() {
    const fileList: FileList = this.uploadFilesToFolder.nativeElement.files;
    if (fileList == null || fileList.length === 0) {
      return;
    }
    const savedDocumentContent$ = this.getUploadingDoc$().pipe(
      concatMap(documentContent => {
        documentContent.documentFiles = this.getDocumentFiles(fileList);
        return this.addArchives(documentContent);
      })
      // finalize(() => loadingHide())
    );
    // loadingShow();
    savedDocumentContent$.subscribe(
      documentContent => {
        const documentFiles = documentContent.documentFiles || [];
        Array.from(fileList).forEach(file => {
          const fileDto = documentFiles.find(fo => fo.fileName === file.name);
          this.uploadService.handleFile({ ...fileDto, file: file, actionUrl: this.hash });
        });
      }, error => {
        showInfo(error.message || 'error', 3000, MessageType.error);
        console.error('documentService.save ::', JSON.stringify(error));
      });
  }

  showQRCode() {
    const dto = this.item.data as DocumentDto;
    if (!dto) { return; }
    this.qrdata = this.getDocumentUrl(dto, false) || '';
    if (this.qrdata === '') { return; }
    popbox('pop-qrcode');
  }

  showShortTermDocQRCode() {
    const dto = this.item.data as DocumentDto;
    if (!dto) { return; }
    const url = this.getDocumentUrl(dto, true);
    if (url === '') { return; }
    this.showShortTermQRCode(url);
  }

  showShortTermUploadQRCode() {
    const url = this.getUploadFolderUrl() || '';
    if (url === '') { return; }
    this.showShortTermQRCode(url);
  }

  showShortTermQRCode(url: string) {
    this.authTokenService.getShortTermToken$()
      .subscribe(
        resp => {
          if (resp.state === 'Ok') {
            const jwt: JwtAuthObject = resp.result;
            const authUrl = url + `;auth=${jwt.token}`;
            this.qrdata = authUrl;
            popbox('pop-qrcode');
          } else {
            console.error('refreshToken ::', resp.message);
          }
        }, error => {
          this.qrdata = '';
          showInfo(error.message || 'error', 3000, MessageType.error);
          console.error('showShortTermQRCode::', JSON.stringify(error));
        }
      );
  }

  copyQRCodeUrl(e: MouseEvent) {
    this.copyToClipboard(this.qrdata);
    $(e.target).next('.copyReady').stop(false, true).fadeIn(200).delay(1400).fadeOut(200, () => { this.isVisible = false; });
  }

  onCopyReadyClose(e: MouseEvent) {
    $(e.target).parents('.copyReady').hide();
  }

  checkCanDelete(): boolean {
    if (this.item.type === 'folder') {
      const folder = this.item.data as DocumentFolder;
      const haveChild = folder.haveChild || '0';
      return folder.canAdmin && haveChild.trim() === '0';
    } else {
      const doc = this.item.data as DocumentDto;
      return doc.canAdmin;
    }
  }

  download() {
    if (this.resource === ResourceType.file) {
      this.getFileUrl$(this.item.data as DocumentDto).subscribe(
        url => {
          if (url) {
            window.open(url, 'fmDownloadFile');
          }
        }, error => {
          showInfo(error.message || 'error', 3000, MessageType.error);
          console.error('documentService.openDoc ::', JSON.stringify(error));
        }
      );
    }
  }

  isDeptVirtualFolderRoot(): boolean {
    return this.folderType === FolderType.DEPT_FOLDER && this.folderId === 0 && !this.deptId;
  }

  hideContextmenuAndUnselectAll() {
    this.contextmenuService.contextmenuDtoSubject.next(null);
    this.contextmenuService.preDataRow = null;
    this.pageList
      .filter(m => m.isSelected)
      .forEach(m => { m.isSelected = false; });
  }

  private getResourceType(dataRow: DataRow): ResourceType {
    if (dataRow.type === 'folder') {
      return ResourceType.folder;
    } else {
      const data = dataRow.data as DocumentDto;
      return data.isArchive ? ResourceType.file : ResourceType.document;
    }
  }

  private getDocumentFiles(fileList: FileList): DocumentFile[] {
    return Array.from(fileList).map(file => {
      return {
        fileId: 0,
        fileName: file.name,
        fileExtension: getFileExtension(file.name),
        fileSize: file.size,
        downloadCount: 0,
      };
    });
  }

  private getUploadingDoc$(): Observable<DocumentContent> {
    return this.documentService.getCreateDoc({ folderId: this.folderId, folderType: this.folderType }).pipe(
      tap(resp => { if (resp.state !== 'Ok') { showInfo(resp.message, 2000, MessageType.error); } }),
      filter(resp => resp.state === 'Ok'),
      map(resp => resp.result)
    );
  }

  private addArchives(documentContent: DocumentContent): Observable<DocumentContent> {
    const docContentResp$ = this.documentService.addArchives({ folderId: this.folderId, folderType: this.folderType, documentContent: documentContent });
    return docContentResp$.pipe(
      tap(resp => { if (resp.state !== 'Ok') { showInfo(resp.message, 2000, MessageType.error); } }),
      filter(resp => resp.state === 'Ok'),
      map(resp => resp.result)
    );
  }

  private getUploadFolderUrl(): string {
    // const encodedData = this.dataEncode({ folderType: this.folderType, folderId: this.folderId, deptId: this.deptId });
    const folderUrl = `${location.origin}${location.pathname}#/upload-content`; // ;data=${encodedData}
    return folderUrl;
  }

  private getDocumentUrl(dto: DocumentDto, isShortTerm: boolean): string {
    if (this.resource === ResourceType.folder) { return ''; }
    const encodedData = this.dataEncode({ folderType: this.folderType, folderId: this.folderId, docId: dto.documentId });
    const viewContent = isShortTerm ? 'view-content-s' : 'view-content';
    const docUrl = `${location.origin}${location.pathname}#/${viewContent};data=${encodedData}`;
    return docUrl;
  }

  private getFileUrl$(dto: DocumentDto): Observable<string> {
    return this.getDocumentContent$(dto).pipe(
      map(docContent => {
        const fileInfo = docContent.documentFiles[0];
        const host = environment.apiRoot === '' ? location.origin : environment.apiRoot;
        const fileUrl = fileInfo.exists ? `${host}/api/DownloadFile/Download?auth=${this.currentUserToken}&id=${fileInfo.guid}&fileName=${encodeURIComponent(fileInfo.fileName)}` : '';
        return fileUrl;
      })
    );
  }

  private getDocumentContent$(dto: DocumentDto): Observable<DocumentContent> {
    return this.documentService.openDoc({ folderId: this.folderId, folderType: this.folderType, docId: dto.documentId }).pipe(
      tap(resp => { if (resp.state !== 'Ok') { showInfo(resp.message, 2000, MessageType.error); } }),
      filter(resp => resp.state === 'Ok'),
      map(resp => resp.result)
    );
  }

  private copyToClipboard(url: string) {
    const el = document.createElement('textarea');
    el.value = url;
    document.body.appendChild(el);
    el.select();
    const result = document.execCommand('copy');
    console.log('document.execCommand copy ::', result);
    document.body.removeChild(el);
  }

  private getBoundedClientX(clientX: number): number {
    const offsetWidth = this.cloneContextmenu.nativeElement.offsetWidth;
    const isOverflow = clientX + offsetWidth - window.innerWidth > 0;
    return isOverflow ? clientX - offsetWidth : clientX;
  }

  private getBoundedClientY(clientY: number): number {
    const offsetHeight = this.cloneContextmenu.nativeElement.offsetHeight;
    const isBottomOverFlow = clientY + offsetHeight - window.innerHeight > 0;
    const isTopOverFlow = clientY - offsetHeight < 0;
    if (isTopOverFlow && isBottomOverFlow) {
      return window.innerHeight - offsetHeight;
    }
    return isBottomOverFlow ? clientY - offsetHeight : clientY;
  }


  ngOnDestroy() {
    this.contextmenuSubscription.unsubscribe();
    this.contextmenuService.contextmenuDtoSubject.next(null);
  }
}
