

import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SwPush, SwUpdate } from '@angular/service-worker';
import { AuthGuard } from './guard/auth-guard';
import { AuthTokenGuard } from './guard/auth-token-guard';
import { AuthTokenSysContentGuard } from './guard/auth-token-sys-content-guard';
import { AuthTokenShortTermGuard } from './guard/auth-token-short-term-guard';
import { AuthTokenMobilePortalGuard } from './guard/auth-token-mobile-portal-guard';
import { AuthTokenParamGuard } from './guard/auth-token-param-guard';
import { AuthUserGuard } from './guard/auth-user-guard';
import { AuthLoginGuard } from './guard/auth-login-guard';
import { ClientLogService, InfoService, DownloadService, CapService } from './service';
import { AuthTokenService } from './service';
import { ListNewestDocumentService } from './service';
import { FolderTreeService } from './service';
import { CanDoAuthService } from './service';
import { UploadxService } from './service';
import { PermissionService } from './service';
import { DocumentService } from './service';
import { DocumentFileService } from './service';
import { FolderService } from './service';
import { BreadcrumbsService } from './service';
import { FolderCanWriteTreeService } from './service';
import { SwUpdateService } from './service';
import { LoggerService } from './service';
import { EnvService } from './service';
import { NotificationService } from './service';
import { DocumentContextmenuService } from './service/document-contextmenu.service';
import { UiService } from './service/ui.service';
import { FolderDocumentService } from './service/folder-document.service';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
  ],
  exports: [
  ],
  providers: [
    AuthGuard,
    EnvService,
    CanDoAuthService,
    ClientLogService,
    AuthTokenGuard,
    AuthTokenSysContentGuard,
    AuthTokenShortTermGuard,
    AuthTokenMobilePortalGuard,
    AuthTokenParamGuard,
    AuthUserGuard,
    AuthLoginGuard,
    AuthTokenService,
    ListNewestDocumentService,
    FolderTreeService,
    UploadxService,
    PermissionService,
    DocumentService,
    DocumentFileService,
    FolderService,
    FolderDocumentService,
    BreadcrumbsService,
    FolderCanWriteTreeService,
    SwUpdateService,
    SwPush,
    SwUpdate,
    LoggerService,
    InfoService,
    DownloadService,
    NotificationService,
    DocumentContextmenuService,
    UiService,
    CapService,
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      // Prevent reimport of the CoreModule
      throw new Error(
        'Prevent reimport of the CoreModule, CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
