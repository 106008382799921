import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import {  map  } from 'rxjs/operators';
import { RespMessage } from 'app/core/model/data-model';
import { CanDoAuthService } from '../service/can-do-auth.service';
import { AuthTokenService } from '../service/auth-token.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authTokenService: AuthTokenService, private canDoAuthService: CanDoAuthService, private router: Router) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.canDoAuthService.doCheck(route.data)
    .pipe(
      map((resp: RespMessage) => {
        if (resp.state === 'Ok') {
          if (resp.result) {
            return true;
          } else {
            // alertMsg(`Forbidden!`);
            this.router.navigate(['']);
            return false;
          }
        } else {
          // alertMsg(`Forbidden!`);
          this.router.navigate(['']);
          return false;
        }
      })
    );
  }
}
