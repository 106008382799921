
import { FolderTypeName, showConfirm, RouteMap } from './../../shared/common';
import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FolderTreeService } from '../../core/service/folder-tree.service';
import { showInfo, loadingHide, MessageType, loadingShow, FolderType } from '../../shared/common';
import { RespMessage, DocumentFolder, DocumentDto, DataRow } from '../../core/model/data-model';
import { DocumentService } from '../../core/service/document.service';
import { FolderService } from '../../core/service/folder.service';
import { BaseFolderComponent } from '../base-folder/base-folder.component';
import { FolderCanWriteTreeService } from '../../core/service/folder-can-write-tree.service';
import { DocumentContextmenuService } from '../../core/service/document-contextmenu.service';
import { DownloadService } from 'app/core/service';
import { FolderDocumentService } from 'app/core/service/folder-document.service';
import { MatomoTracker  } from 'ngx-matomo';
import { AuthTokenService } from 'app/core/service/auth-token.service';

@Component({
  selector: 'doc-department-folder',
  templateUrl: './department-folder.component.html',
  styleUrls: ['./department-folder.component.css']
})
export class DepartmentFolderComponent extends BaseFolderComponent implements OnInit, OnDestroy {

  childFolders: DocumentFolder[];
  documents: DocumentDto[];
  deptId = '';

  constructor(private matomoTracker: MatomoTracker,protected folderTreeService: FolderTreeService, protected folderCanWriteTreeService: FolderCanWriteTreeService
    , protected documentService: DocumentService, protected folderService: FolderService
    , protected folderDocumentService: FolderDocumentService, protected downloadService: DownloadService
    , protected contextmenuService: DocumentContextmenuService
    , protected router: Router, protected route: ActivatedRoute, protected zone: NgZone
    , private authTokenService: AuthTokenService) {
    super(folderTreeService, folderCanWriteTreeService, folderService, documentService, folderDocumentService, downloadService, contextmenuService, router);
    this.folderId = 0;
    this.folderType = FolderType.DEPT_FOLDER;
    this.folderTypeName = FolderTypeName.DEPT;
  }

  ngOnInit() {
    super.ngOnInit();
    this.authTokenService.currentUser$
      .subscribe(user => {
        if (user) {
          this.matomoTracker.setUserId(user.userName);
          this.matomoTracker.trackPageView('page_dept');
          this.matomoTracker.setCustomVariable(1, "WorkId", user.workId, "page")
        }
      });
    this.route.params.subscribe((params) => {
      const data = this.dataDecode(params['data']);
      console.log('params data', data);
      if ('folderId' in data) {
        this.folderId = data['folderId'];
        this.deptId = data['deptId'];
        this.resultList = [];
        this.sortByCols = {
          'typeCss': '',
          'name': '',
          'modifyTime': '',
          'readCount': '',
        };
        this.sortBy = '';
        this.sortType = '';
        this.query();
      }
    });
  }

  query(sortBy = '', sortType = '', pageSize = 100, pageNum = 1) {
    loadingShow();
    this.folderTreeService.getDisplayFolder({ folderId: this.folderId, folderType: this.folderType }, sortBy, sortType, pageSize, pageNum, this.deptId)
      .subscribe(re => {
        loadingHide();
        const resp: RespMessage = re;
        if (resp.state === 'Ok') {
          this.dataCount = resp.dataCount;
          this.updatePageList();
          this.resultList = [];
          this.folderContent = resp.result;
          if (this.folderContent.folder == null) {
            this.folderName = this.folderTypeName;
          } else {
            this.folderName = this.folderContent.folder.folderName;
          }
          this.folderContent.childFolders.map(
            fol => this.resultList.push({ type: 'folder', id: fol.folderId, name: fol.folderName, readCount: -1, modifyTime: fol.modifyTime, data: fol })
          );
          this.folderContent.documents.map(
            doc => this.resultList.push({ type: 'doc', id: doc.documentId, name: doc.documentName, readCount: doc.readCount, modifyTime: doc.modifyTime, data: doc })
          );
          this.resultList.forEach(row => row.typeCss = this.getTypeCss(row.data));
          this.pageList = this.resultList;
          // this.sortBy = '';
          // this.sortType = '';
          // this.showList();
          this.breadcrumbsComponent.updateBreadcrumbsInfo({ folderId: this.folderId, folderType: this.folderType, folderTypeName: this.folderTypeName, folderHierarchy: this.folderContent.folderHierarchy });
          // console.log('getDisplayFolder', this.folderContent);
        } else {
          showInfo(resp.message || 'warning', 3000, MessageType.warning);
        }
      }, error => {
        loadingHide();
        showInfo(error.message || 'error', 3000, MessageType.error);
        console.error('folderTreeService.getDisplayFolder::', JSON.stringify(error));
      });
  }

  edit(data: DataRow) {
    if (data.type === 'folder') {
      const folder = data.data as DocumentFolder;
      this.router.navigate([`/folder/folder-edit`,
        { data: this.dataEncode({ folderType: this.folderType, folderId: folder.folderId, deptId: folder.departmentId }) }]);
    } else {
      const doc = data.data as DocumentDto;
      if (data.type === 'doc' && doc.isArchive === '1') {
        this.router.navigate([`/folder/archives-edit`,
          { data: this.dataEncode({ folderType: this.folderType, folderId: this.folderId, docId: doc.documentId, deptId: doc.departmentId }), hash: this.hash }]);
      } else {
        this.router.navigate([`/folder/document-edit`,
          { data: this.dataEncode({ folderType: this.folderType, folderId: this.folderId, docId: doc.documentId, deptId: doc.departmentId }), hash: this.hash }]);
      }
    }
  }

  delete(data: DataRow) {
    showConfirm(`資料刪除後將無法喚回`, `您確定要刪除${data.name}?`, MessageType.question)
      .then(
        result => {
          if (result.value) {
            if (data.type === 'folder') {
              const folder = data.data as DocumentFolder;
              this.folderService.delete({ folderId: folder.folderId, folderType: this.folderType })
                .subscribe((resp: RespMessage) => {
                  loadingHide();
                  if (resp.state === 'Ok') {
                    this.resultList = this.resultList.filter(r => r !== data);
                    this.showList();
                    showInfo('Success', 2000, MessageType.success);
                  } else {
                    showInfo(resp.message, 2000, MessageType.error);
                  }
                });
            } else {
              loadingShow();
              const doc = data.data as DocumentDto;
              this.documentService.delete({ folderId: this.folderId, folderType: this.folderType, docId: doc.documentId })
                .subscribe((resp: RespMessage) => {
                  loadingHide();
                  if (resp.state === 'Ok') {
                    this.resultList = this.resultList.filter(
                      r => !(r.data === doc));
                    this.showList();
                    showInfo('Success', 2000, MessageType.success);
                  } else {
                    showInfo(resp.message, 2000, MessageType.error);
                  }
                });
            }
          }
        });
  }

  refresh() {
    const url = RouteMap[this.folderType];
    this.router.navigate([url, { data: this.dataEncode({ folderId: this.folderId, deptId: this.deptId }) }]);
  }

  ngOnDestroy() {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    console.log('PrivateFolderComponent.....ngOnDestroy');
  }
}

