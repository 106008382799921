import { Injectable } from '@angular/core';
import { BreadcrumbsDto } from '../model/data-model';

@Injectable()
export class BreadcrumbsService {
  private breadcrumbsDto = {} as BreadcrumbsDto;
  constructor() {
    this.breadcrumbsDto = { folderId: 0, folderType: '', folderTypeName: '', folderHierarchy: [] };
  }

  set currentBreadcrumbs(breadcrumbs: BreadcrumbsDto) {
    this.breadcrumbsDto = breadcrumbs;
  }

  get currentBreadcrumbs() {
    return this.breadcrumbsDto;
  }

}
