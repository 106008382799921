import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import {  filter } from 'rxjs/operators';
import { AuthTokenService } from '../service/auth-token.service';

@Injectable()
export class AuthTokenShortTermGuard implements CanActivate {
  constructor(private authTokenService: AuthTokenService, private router: Router) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    const auth = route.paramMap.get('auth') || '';
    if (auth !== '') {
      return this.authTokenService.verifyShortTermToken$(auth).pipe(filter((v) => v));
    } else {
      return of(false);
    }
  }
}
