import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ListNewestDocumentService } from '../../core/service/list-newest-document.service';
import { RespMessage, NewestDocumentDto, EipNews, DocumentDto, DocumentContent } from '../../core/model/data-model';
import { loadingShow, loadingHide, confirmClose, showConfirm, MessageType, popbox, showInfo } from '../../shared/common';
import { BaseComponent } from '../../shared/component/base/base.component';
import { Router, ActivatedRoute } from '@angular/router';
import { PickFavoriteFolderComponent } from '../../shared/component/pick-favorite-folder/pick-favorite-folder.component';
import { FolderService } from '../../core/service/folder.service';
import { DocumentViewComponent } from '../document-view/document-view.component';
import { DocumentService } from '../../core/service/document.service';

@Component({
  selector: 'doc-list-newest-document',
  templateUrl: './list-newest-document.component.html',
  styleUrls: ['./list-newest-document.component.css']
})
export class ListNewestDocumentComponent extends BaseComponent implements OnInit, OnDestroy {

  readonly popDoc = 'pop-doc-from-newest';
  newestDocumentList: DocumentDto[];
  resultList: DocumentDto[] = [];
  eipNewsList: EipNews[];
  sortByCols = {
    'typeCss': '',
    'category': '',
    'modifyTime': '',
    'readCount': '',
  };
  sortBy = '';
  sortType = '';

  @ViewChild('pickFavoriteFolder', { static: false }) pickFavoriteFolder: PickFavoriteFolderComponent;
  @ViewChild('documentView', { static: false }) documentViewComponent: DocumentViewComponent;

  constructor(private listNewestDocumentService: ListNewestDocumentService
    , private documentService: DocumentService, private folderService: FolderService
    , protected router: Router, protected route: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    if (this.currentUser.type.toUpperCase() !== 'ASUS') {
      this.router.navigate([`/folder/system-folder`, { data: this.dataEncode({ folderId: 0 }) }]);
      return;
    }
    loadingShow();
    this.listNewestDocumentService.listNewestDocument()
      .subscribe((resp: RespMessage) => {
        loadingHide();
        // console.dir(resp);
        if (resp.state === 'Ok') {
          const result: NewestDocumentDto = resp.result;
          // console.log('listNewestDocument', result);
          this.newestDocumentList = result.newestDocumentList;
          this.eipNewsList = result.eipNewsList;
          this.newestDocumentList.forEach(row => row.typeCss = this.getTypeCss(row) );
          this.sortByCols = {
            'typeCss': '',
            'category': '',
            'modifyTime': '',
            'readCount': '',
          };
          this.resultList = this.newestDocumentList.slice();
          this.sortBy = 'modifyTime';
          this.sortType = 'DESC';
          this.showList();
        } else {
          showInfo(resp.message, 2000, MessageType.error);
        }
      },
        error => {
          loadingHide();
          console.error(error);
        });
  }

  sortChange(sortByCol) {
    for (const col in this.sortByCols) {
      if (sortByCol === col) {
        if (this.sortByCols[sortByCol] === '' || this.sortByCols[sortByCol] === 'DESC') {
          this.sortByCols[sortByCol] = 'ASC';
        } else {
          this.sortByCols[sortByCol] = 'DESC';
        }
        this.sortBy = sortByCol;
        this.sortType = this.sortByCols[sortByCol];
      } else {
        this.sortByCols[col] = '';
      }
    }
    this.showList();
  }

  filterType(belongTo) {
    if (belongTo === '') {
      this.resultList = this.newestDocumentList.slice();
    } else {
      this.resultList = this.newestDocumentList.filter(f => f.belongTo === belongTo);
    }
    this.showList();
  }

  showList() {
    this.resultList = this.resultList.sort((cur, nex) => {
      let re = 0;
      switch (this.sortBy) {
        case 'typeCss':
          if (this.sortType === 'ASC') {
            re = cur.typeCss > nex.typeCss ? 1 : -1;
          } else {
            re = cur.typeCss < nex.typeCss ? 1 : - 1;
          }
          break;
        case 'category':
          if (this.sortType === 'ASC') {
            re = cur.belongTo > nex.belongTo ? 1 : -1;
          } else {
            re = cur.belongTo < nex.belongTo ? 1 : - 1;
          }
          break;
        case 'modifyTime':
          const curDate = new Date(cur.createTime);
          const nexDate = new Date(nex.createTime);
          // console.log('modifyTime instanceof', cur.modifyTime instanceof Date);
          // console.log('modifyTime instanceof', typeof cur.modifyTime);
          // if (this.sortType === 'ASC') {
          //   re = cur.modifyTime > nex.modifyTime ? 1 : -1;
          // } else {
          //   re = cur.modifyTime < nex.modifyTime ? 1 : -1;
          // }
          if (this.sortType === 'ASC') {
            re = curDate.getTime() > nexDate.getTime() ? 1 : -1;
          } else {
            re = curDate.getTime() < nexDate.getTime() ? 1 : -1;
          }
          break;
        case 'readCount':
          if (this.sortType === 'ASC') {
            re = cur.readCount > nex.readCount ? 1 : -1;
          } else {
            re = cur.readCount < nex.readCount ? 1 : -1;
          }
          break;

        default:
          break;
      }
      return re;
    });

    // console.dir(this.pageList);
  }

  action(doc: DocumentDto) {
    // if (doc.isArchive === '1') {
    //   this.router.navigate([`/folder/system-folder`,
    //     { data: this.dataEncode({ folderType: doc.belongTo, folderId: doc.folderId, docId: doc.documentId, back: '/folder/list-newest-document' }) }]);
    // } else {
    //   this.router.navigate([`/folder/document-read`,
    //     { data: this.dataEncode({ folderType: doc.belongTo, folderId: doc.folderId, docId: doc.documentId, back: '/folder/list-newest-document' }) }]);
    // }
    this.openDoc(doc);
  }

  openDoc(doc: DocumentDto) {
    loadingShow();
    this.documentService.openDoc({ folderId: doc.folderId, folderType: doc.belongTo, docId: doc.documentId })
      .subscribe((resp: RespMessage) => {
        loadingHide();
        if (resp.state === 'Ok') {
          const documentContent: DocumentContent = resp.result;
          this.documentViewComponent.show(documentContent);
          popbox(this.popDoc);
        } else {
          showInfo(resp.message, 2000, MessageType.error);
        }
      }, error => {
        loadingHide();
        showInfo(error.message || 'error', 3000, MessageType.error);
        console.log('error', error);
      });
  }

  showAddfav(doc: DocumentDto, pickFavoriteFolder: PickFavoriteFolderComponent) {
    if (doc.inFavorite) {
      this.delfav(doc);
    } else {
      pickFavoriteFolder.documentDto = doc;
      pickFavoriteFolder.show();
      console.log(pickFavoriteFolder);
    }
  }

  delfav(doc: DocumentDto) {
    showConfirm(`移除收藏`, `您確定要刪除${doc.documentName}?`, MessageType.question)
      .then(
        result => {
          // console.log(`showConfirm:${result}`);
          if (result.value) {
            loadingShow();
            this.folderService.deleteFavoriteByDocId({ docId: doc.documentId })
              .subscribe((resp: RespMessage) => {
                loadingHide();
                if (resp.state === 'Ok') {
                  doc.inFavorite = false;
                  showInfo('Success', 2000, MessageType.success);
                } else {
                  showInfo(resp.message, 2000, MessageType.error);
                }
              }, error => {
                loadingHide();
                showInfo(error.message || 'error', 3000, MessageType.error);
                console.log('error', error);
              });
          }
        },
        reject => {
          console.log(`showConfirm:${reject}`);
        }
      );
  }

  openEipNews(url) {
    window.open(url);
  }

  ngOnDestroy() {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    console.log('ListNewestDocumentComponent.....ngOnDestroy');
  }
}
