<div class="main">
  <doc-breadcrumbs [folderHierarchy]="folderHierarchy" [viewType]="'cap'"></doc-breadcrumbs>
  <section class="panel panel-default panel-article">
    <div class="panel-heading">
      <h3 class="panel-title"><i class="circle mdi mdi-format-list-bulleted"></i>文件分享</h3>
    </div>
    <div class="panel-body">      
      <table class="table table-striped table-hover table-rwd">
        <tbody>
          <tr>
            <th class="type">類型</th>
            <th class="click">名稱</th>
            <th class="date click">修改日期</th>
            <th class="action">下載</th>
          </tr>
          <tr *ngFor="let item of resultList">
            <td class="type"><i class="{{item.typeCss}}"></i></td>
            <td class="name">
              <a *ngIf="item.type !== 'file'" href="javascript:void(0)" (click)="action(item)"><p class="title">{{item.name}}</p></a>
              <ng-container *ngIf="item.type === 'file'"><p class="title">{{item.name}}</p></ng-container>
            </td>
            <td class="date">{{item.data.modifyTime | date:'yyyy-MM-dd HH:mm a'}}</td>
            <td class="action center">
              <a *ngIf="item.type === 'file'" class="btn btn-info btn-xs" href="javascript:void(0)" title="下載" (click)="download(item.data)">
                <i class="mdi mdi-download"></i>Download</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</div>
<doc-document-view #documentView [popCss]="popDoc"><doc-document-view>
  
