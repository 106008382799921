import { Component, OnInit, NgZone, Output, EventEmitter, AfterViewInit, Input, ViewChild } from '@angular/core';
import { DocumentContent, DocumentDto, DocumentFile, DownloadCountRecordDto } from './../../core/model/data-model';
import { FolderType, FolderTypeName, loadingShow, loadingHide, popbox, showInfo, MessageType, dataEncode } from '../../shared/common';
import { BaseComponent, DocumentContentViewComponent } from '../../shared/component';
import { DomSanitizer } from '@angular/platform-browser';
import { DownloadService, DocumentService } from '../../core/service';
import { DownloadCountRecordComponent } from 'app/shared/component/download-count-record/download-count-record.component';
import { environment } from 'environments/environment';

@Component({
  selector: 'doc-document-view',
  templateUrl: './document-view.component.html',
  styleUrls: ['./document-view.component.css'],

})
export class DocumentViewComponent extends BaseComponent implements OnInit, AfterViewInit {

  folderId = 0;
  docId = 0;
  folderType: FolderType;
  folderTypeName = FolderTypeName;
  MEGABYTE = 1048576; // Bytes
  @Input() popDownloadCss = 'pop-download';
  @Input() popCss = 'pop-doc';
  @Input() documentContent = {} as DocumentContent;
  @Input() documentDto = {} as DocumentDto;
  @Input() documentFiles: DocumentFile[] = [];
  goBack = '';
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onAddfav = new EventEmitter<DocumentDto>();
  @Output() hitCountClick = new EventEmitter<DocumentDto>();
  @Output() downloadCountClick = new EventEmitter<DocumentDto>();
  @ViewChild('contentView', { static: false }) contentView: DocumentContentViewComponent;
  @ViewChild('downloadCountRecord', { static: false }) downloadCountRecord: DownloadCountRecordComponent;
  constructor(private zone: NgZone,
    private downloadService: DownloadService,
    protected sanitizer: DomSanitizer) {
    super();
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    this.zone.runOutsideAngular(() => {
      // close popbox
      $('.close, .close-btn').on('click', function(e) {
        $(this).parents('.popbg-fill').removeClass('display-block');
        $(this).parents('.pop-box').removeClass('display-block');
        $('body').removeClass('overflow-hidden');
        if ($('.popbg-fill').is('.display-block') === false) {
          $('body').removeClass('overflow-hidden');
        }
        e.preventDefault();
      });
    });
  }

  show(documentContent: DocumentContent) {
    this.documentContent = documentContent;
    this.documentDto = this.documentContent.document;
    this.documentFiles = this.documentContent.documentFiles;
    this.contentView.documentDto = this.documentDto;
    this.contentView.showContent();
  }

  downloadFile(item: DocumentFile) {
    let url = `${environment.apiRoot}/api/DownloadFile/Download?auth=${this.currentUserToken}&id=${item.guid}&fileName=${encodeURIComponent(item.fileName)}`;
    if (this.documentDto && this.documentDto.isArchive === '1') {
      this.close(event);
    }
    if (location.hostname === 'localhost') {
      url = 'http://localhost:56999' + url;
    }
    if (item.exists) {
      window.open(url, 'fmDownloadFile');
      //   this.downloadService.getFile(url, item.fileName);
    }
  }

  close(event) {
    // console.log('close.....', event);
    $(event.target).parents('.popbg-fill').removeClass('display-block');
    $(event.target).parents('.pop-box').removeClass('display-block');
    $('body').removeClass('overflow-hidden');
    if ($('.popbg-fill').is('.display-block') === false) {
      $('body').removeClass('overflow-hidden');
    }
    event.preventDefault();
    // console.log('closePopbox.....');
  }

  openNew(event) {
    this.close(event);
    // /#/folder/document-read;data=eyJmb2xkZXJJZCI6MH0%253D
    // data: this.dataEncode({ folderType: this.folderType, folderId: folder.folderId }) }
    window.open(`/#/view-content;data=${this.dataEncode({ folderType: this.documentDto.belongTo, folderId: this.documentDto.folderId, docId: this.documentDto.documentId })}`, '_blank');
  }

  addfav() {
    this.onAddfav.emit(this.documentDto);
  }

  onHitCountClick() {
    this.hitCountClick.emit(this.documentDto);
  }

  onDownloadCountClick(documentFile: DocumentFile) {
    this.downloadCountRecord.showRecord({ fileId: documentFile.fileId });
  }
}
