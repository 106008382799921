import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthTokenService } from '../core/service';
import { getUrlParams, getSSOParams, loadingShow } from '../shared/common';
import { environment } from 'environments/environment';

@Component({
  selector: 'doc-sso',
  templateUrl: './sso.component.html',
  styleUrls: ['./sso.component.css']
})
export class SsoComponent implements OnInit {

  constructor(private router: Router,
    private route: ActivatedRoute,
    private authTokenService: AuthTokenService) { }

  ngOnInit() {
    const url = location.href.replace(location.origin, '');
    const queryParams = getUrlParams(url);
    const targetUrl = queryParams['nextUrl']; // this.route.snapshot.queryParams['nextUrl'];

    console.log('location.search:', location.search);
    if (environment.enabledAdfs && this.route.snapshot.routeConfig.path === 'ssoverify') {
      const jwtToken = queryParams['jwtToken']; // this.route.snapshot.queryParams['SSO_KEY'];
      const apkey = queryParams['AP_KEY']; // this.route.snapshot.queryParams['AP_KEY'];
      // console.log('targetUrl', targetUrl);
      console.log('ssokey', jwtToken);
      console.log('apikey', apkey);
      // this.getAllHeaders();
      console.log('this.route.snapshot', this.route.snapshot.routeConfig.path === 'ssoverify');
      const sSoParams = getSSOParams();
      console.log('sSoParams', sSoParams);
      this.authTokenService.ssoVerify(jwtToken);
    }
  }

}
