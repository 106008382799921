import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { FolderType, loadingHide, loadingShow, FolderTypeName, showInfo, MessageType, RouteMap, showConfirm } from '../../shared/common';
import { RespMessage, DocumentFolder, TreeNode, PermissionVo1, FolderContent2, PermissionContentVo, ResourceType } from './../../core/model/data-model';
import { FolderService, FolderTreeService } from '../../core/service';
import { BaseComponent, TreeViewComponent } from '../../shared/component';
import { PermissionSettingComponent } from 'app/shared/component/permission-setting/permission-setting.component';


@Component({
  selector: 'doc-folder-add2',
  templateUrl: './folder-add2.component.html',
  styleUrls: ['./folder-add2.component.css']
})
export class FolderAdd2Component extends BaseComponent implements OnInit {
  resourceType = ResourceType.folder;
  folderId = 0;
  folderType: FolderType;
  folderTypeName = FolderTypeName;
  deptId = '';
  MEGABYTE = 1048576; // Bytes
  folderContent = {} as FolderContent2;
  documentFolder = {} as DocumentFolder;
  permissionContent: PermissionContentVo;


  constructor(private folderService: FolderService,
    private folderTreeService: FolderTreeService,
    private router: Router, private route: ActivatedRoute) {
    super();
  }

  ngOnInit() {

    this.route.params.subscribe((params) => {
      const data = this.dataDecode(params['data']);
      if (data['folderType']) {
        this.folderId = data['folderId'] || 0;
        this.folderType = data['folderType'];
        this.deptId = data['deptId'] || '';
        console.log('folderId', this.folderId, 'folderType', this.folderType, 'deptId', this.deptId);
        this.folderTypeName = FolderTypeName['' + this.folderType];
        loadingShow();
        this.folderService.getCreateFolder2({ folderId: this.folderId, folderType: this.folderType })
          .subscribe((resp) => {
            loadingHide();
            if (resp.state === 'Ok') {
              this.folderContent = resp.result;
              this.documentFolder = this.folderContent.folder;
              this.permissionContent = this.folderContent.permissionContent;
            } else {
              showInfo(resp.message, 2000, MessageType.error);
            }
          }, error => {
            loadingHide();
            showInfo(error.message || 'error', 3000, MessageType.error);
            console.error('loadData ::', JSON.stringify(error));
          });
      }
    });


  }

  goBack() {
    let parentId = 0;
    if (this.folderContent.folder != null && this.folderContent.folder.parentFolder != null) {
      parentId = this.folderContent.folder.parentFolder.folderId;
    }
    const url = RouteMap[this.folderType];
    this.router.navigate([url, { data: this.dataEncode({ folderId: parentId, deptId: this.deptId }) }]);
  }

  confirmSave(form: NgForm) {
    if (form.invalid) {
      Object.values(form.controls).forEach(ctl => {
        ctl.markAsTouched();
      });
      return;
    }
    this.save(form);
  }


  save(form: NgForm) {
    this.folderContent.permissionContent = this.permissionContent;
    const _folderType = this.folderType === FolderType.SHARE_WITH_ME ? FolderType.PRIVATE_FOLDER : this.folderType;
    this.folderContent.folder.folderName = this.folderContent.folder.folderName.trim();
    loadingShow();
    this.folderService.add2({ folderId: this.folderId, folderType: _folderType, folderContent: this.folderContent })
      .subscribe((resp: RespMessage) => {
        loadingHide();
        if (resp.state === 'Ok') {
          this.folderContent = resp.result;
          this.permissionContent = this.folderContent.permissionContent;
          this.documentFolder = this.folderContent.folder;
          this.updateTreeView();
          //  getTreeNodes(qry: { folderId: number, folderType, curNode: TreeNode })
          // console.log(this.folderContent);
          // console.log('save resp', this.documentContent);
          showInfo('Success', 2000, MessageType.success);
          this.goBack();
        } else {
          showInfo(resp.message, 2000, MessageType.error);
        }
      }, error => {
        loadingHide();
        showInfo(error.message || 'error', 3000, MessageType.error);
        console.error('folderService.add::', JSON.stringify(error));
      });

  }

  updateTreeView() {
    let parentId = 0;
    let refNode: TreeNode;
    if (this.folderContent.folder == null || this.folderContent.folder.parentFolder == null) {
      const allRoot = this.folderTreeService.currentFolderAllRoot();
      switch (this.folderType) {
        case FolderType.PRIVATE_FOLDER:
          refNode = allRoot.private_treenode_root;
          break;
        case FolderType.DEPT_FOLDER:
          refNode = allRoot.dept_treenode_root;
          break;
        case FolderType.SYS_FOLDER:
          refNode = allRoot.system_treenode_root;

          break;
        case FolderType.SHARE_WITH_ME:
          refNode = allRoot.shareWithMe_treenode_root;

          break;
        case FolderType.PUBLIC_FOLDER:
          refNode = allRoot.public_treenode_root;

          break;
        case FolderType.FAVORITE_FOLDER:
          refNode = allRoot.favorite_treenode_root;
          break;
        default:
          break;
      }
      if (refNode != null) {
        this.folderTreeService.getTreeNodes({ folderId: parentId, folderType: this.folderType, curNode: refNode })
          .subscribe(_ => { });
      }
    } else {
      parentId = this.folderContent.folder.parentFolder.folderId;
      const comp = this.folderTreeService.openTreeViewComponents.find(com => com instanceof TreeViewComponent && com.curNode.node_id === parentId);
      if (comp != null) {
        comp.getTreeNodes(comp.curNode, comp.folderType);
      }
    }
  }

}
