import { Component, OnInit, ElementRef, ViewChild, NgZone, ViewChildren, HostListener, AfterViewInit } from '@angular/core';
import { BreadcrumbsService } from '../../../core/service/breadcrumbs.service';
import { FolderTreeService } from '../../../core/service/folder-tree.service';
import { TreeNode, BreadcrumbsDto } from '../../../core/model/data-model';
import { NgForm } from '@angular/forms';
import { showAlert, MessageType, closePopbox, popbox } from '../../common';
import { DocumentService } from '../../../core/service/document.service';
import { Router } from '@angular/router';
import { BaseComponent } from '../base/base.component';
import { MatomoTracker } from 'ngx-matomo';
import { AuthTokenService } from 'app/core/service/auth-token.service';

declare var $: any;

@Component({
  selector: 'doc-search-document',
  templateUrl: './search-document.component.html',
  styleUrls: ['./search-document.component.css']
})
export class SearchDocumentComponent extends BaseComponent implements OnInit, AfterViewInit {
  documentName = '';
  content = '';
  keyword = '';
  description = '';
  author = '';
  queryType = '';
  isShow = false;
  lookup = '';
  workId = '';
  userName = '';
  pickTreeNode = {} as TreeNode;
  breadcrumbs = {} as BreadcrumbsDto;
  pickNode='';
  @ViewChild('createBeginDate', { static: false }) createBeginDate: ElementRef;
  @ViewChild('createEndDate', { static: false }) createEndDate: ElementRef;
  @ViewChild('refForm', { static: false }) refForm: NgForm;
  constructor(private folderTreeService: FolderTreeService, private documentService: DocumentService,
    private breadcrumbsService: BreadcrumbsService, protected router: Router, private zone: NgZone,
    private matomoTracker: MatomoTracker, private authTokenService: AuthTokenService) {
    super();
  }

  ngOnInit() {

    this.folderTreeService.currentPickTreeNode$.subscribe((treeNode: TreeNode) => {
      // console.log('pickTreeNode', treeNode);
      const self = this;
      setTimeout(() => {
        self.pickNode = '';
        if (treeNode != null) {
          self.pickNode = 'node_name' in treeNode ? treeNode.node_name : '';
        }
      }, 200);
    });

    this.authTokenService.currentUser$
    .subscribe(user => {
      if (user) {
        this.matomoTracker.setUserId(user.userName);
        this.matomoTracker.setCustomVariable(1, "WorkId", user.workId, "page")
      }
    });
  }

  ngAfterViewInit() {

    this.zone.runOutsideAngular(() => {
      // close popbox
      $('.close, .close-btn').on('click', function (e) {
        $(this).parents('.popbg-fill').removeClass('display-block');
        $(this).parents('.pop-box').removeClass('display-block');
        if ($('.popbg-fill').is('.display-block') === false) {
          $('body').removeClass('overflow-hidden');
        }
        e.preventDefault();
      });
      // 日曆
      $('.datetimepicker').datetimepicker({
        useCurrent: true,
        format: 'L'
      });
    });
  }

  openQuery(refForm) {
    // console.log('opeenQuery');
    this.resetAll(refForm);
    popbox('show-search-filter');
    this.isShow = true;
  }

  resetAll(refForm: NgForm) {
    this.documentName = '';
    this.content = '';
    this.keyword = '';
    this.description = '';
    this.author = '';
    this.queryType = '';
    this.createBeginDate.nativeElement.value = '';
    this.createEndDate.nativeElement.value = '';
    this.pickTreeNode = {} as TreeNode;
    this.breadcrumbs = {} as BreadcrumbsDto;
    this.folderTreeService.pickTreeNode = null;
  }

  doQuery() {
    const val = this.lookup.trim();
    if (val !== '') {
      this.documentService.search({ lookup: val });
      this.router.navigate([`/folder/search-result`, { now: new Date().getTime() }]);
      // this.close();
      //matomo click event: 條件搜尋
      this.matomoTracker.trackEvent('category', 'action', 'SearchAll', 1);
    }
  }

  query(refForm: NgForm) {
    console.log(refForm.value);
    // console.log('this.createBeginDate.value', this.createBeginDate.nativeElement.value);
    // console.log('this.createEndDate.value', this.createEndDate.nativeElement.value);
    // console.log('this.queryType', this.queryType);
    // console.log('queryType  breadcrumbs', this.breadcrumbs);
    // console.log('queryType pick_tree_node', this.pickTreeNode);
    let folderId = -1;
    let folderType = '';
    if (this.queryType === 'breadcrumbs') {
      folderId = this.breadcrumbs.folderId;
      folderType = this.breadcrumbs.folderType;
    }

    if (this.queryType === 'pick_tree_node') {
      folderId = this.pickTreeNode.node_id;
      folderType = this.pickTreeNode.folderTyp;
    }
    const documentName = this.documentName.trim();
    const content = this.content.trim();
    const keyword = this.keyword.trim();
    const description = this.description.trim();
    const author = this.author.trim();
    let createBeginDate: string = this.createBeginDate.nativeElement.value.trim();
    let createEndDate: string = this.createEndDate.nativeElement.value.trim();

    if (createBeginDate !== '') {
      const arr = createBeginDate.split('\/');
      createBeginDate = `${arr[2]}-${arr[0]}-${arr[1]}`;
      // console.log(arr);
    }
    if (createEndDate !== '') {
      const arr = createEndDate.split('\/');
      createEndDate = `${arr[2]}-${arr[0]}-${arr[1]}`;

    }
    if (`${documentName}${content}${keyword}${description}${author}${createBeginDate}${createEndDate}` === '') {
      showAlert('至少輸入一個條件', '至少輸入一個條件', 'Ok', MessageType.warning);
    } else if ((createBeginDate === '' && createEndDate !== '') || (createBeginDate !== '' && createEndDate === '')) {
      showAlert('請輸入起訖日期', '請輸入起訖日期', 'Ok', MessageType.warning);
    } else {
      this.documentService.search({
        folderId: folderId, folderType: folderType, documentName: documentName,
        keyword: keyword, author: author, description: description, content: content, createBeginDate: createBeginDate, createEndDate: createEndDate
      });
      this.router.navigate([`/folder/search-result`, { now: new Date().getTime() }]);
      this.close();
      //matomo click event: 條件搜尋
      this.matomoTracker.trackEvent('category', 'action', 'Search', 2);
    }
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    // console.log(event);
    // code : "Enter"
    //  keyCode : 13
    const focusElement = document.activeElement;
    if (event.keyCode === 13 && focusElement.getAttribute('name') === 'qryDoc') {
      // this.query(this.refForm);
      this.doQuery();
    }

  }

  close() {
    $('.show-search-filter').removeClass('display-block');
    $('.show-search-filter').children('.pop-box').removeClass('display-block');
    if ($('.popbg-fill').is('.display-block') === false) {
      $('body').removeClass('overflow-hidden');
    }
    this.isShow = false;
  }
  getBreadcrumbs() {
    this.breadcrumbs = this.breadcrumbsService.currentBreadcrumbs;
    this.queryType = 'breadcrumbs';
    // console.log('queryType  breadcrumbs',  this.breadcrumbs );
  }

  getPickTreeNode(selected: boolean) {
    if (selected) {
      this.pickTreeNode = this.folderTreeService.pickTreeNode;
      this.queryType = 'pick_tree_node';
      // console.log('queryType pick_tree_node',  this.pickTreeNode );
    }
  }

}
