<li *ngIf="curNode.have_child != '1' else havechildas1">
  <a [ngClass]="{'main-title':false}" [routerLink]="[ routerPath, {data:dataEncode( {folderId:folderId,deptId: curNode.dept_id} )}]"
    (click)="pickTreeNode(curNode, $event)" routerLinkActive="active" title="{{curNode.node_name}}">{{ curNode.node_name | translate}} </a>
</li>
<ng-template #havechildas1>
  <li>
    <a [routerLink]="[ routerPath,  {data:dataEncode( {folderId:folderId,deptId: curNode.dept_id} )}]" (click)="pickTreeNode(curNode, $event)"
      routerLinkActive="active" title="{{curNode.node_name}}" >{{curNode.node_name | translate}}
      <i class="mdi mdi-menu-right toggle-icon" docToggleTreeNode [refTreeViewComponent]="this" (click)="getTreeNodes(curNode,folderType)"></i>
    </a>
    <ul>
      <ng-container *ngIf="childrenNode!=null && folderType != ''">
        <doc-tree-view *ngFor="let item of childrenNode" [folderId]="item.node_id" [routerPath]='routerPath' [folderType]='folderType'
          [curNode]='item'></doc-tree-view>
      </ng-container>
    </ul>
  </li>
</ng-template>
