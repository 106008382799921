
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { RespMessage } from '../model/data-model';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Injectable()
export class InfoService extends BaseService {

  private onlineUserSubject = new BehaviorSubject<number>(1);
  public onlineUser$ = this.onlineUserSubject.asObservable().pipe(distinctUntilChanged());

  constructor(private http: HttpClient) {
    super();
  }

  loadOnlineUsers() {
    this.http.get<RespMessage<number>>(`${environment.apiRoot}/api/info/OnlineUsers`)
      .subscribe(
        resp => this.onlineUserSubject.next(resp.result),
        error => console.error('loadOnlineUsers', error)
      );
  }


}
