import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { AuthTokenService } from './../service/auth-token.service';

@Injectable()
export class AuthUserGuard implements CanActivate {
  constructor(private authTokenService: AuthTokenService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const allowedUserTypes: string[] = route.data.userTypes || ['ASUS'];
    return this.authTokenService.currentUser$
      .pipe(
        map(user => user && allowedUserTypes.indexOf(user.type) !== -1),
        filter(v => v)
      );
  }
}
