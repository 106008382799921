<div class="popbg-fill show-download {{ popCss }} va-center va-center-contentScroll-s">
  <div class="pop-box pop-size-700">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close"><i class="mdi mdi-close"></i></button>
        <h4>{{ "FileName1" | translate }} - {{ "DownloadRecord" | translate }}</h4>
      </div>
      <div class="pad10-rl">
        <doc-modal-paged-list #headerPagedList [dataCount]="dataCount" [pageSize]="pageSize" [pageNum]="pageNum"
          (pageNumChange)="pageChange($event)" (pageSizeChange)="pageChange($event)"></doc-modal-paged-list>
      </div>
      <div class="modal-body pad0-tb pad10-rl">
        <table class="table table-striped table-hover">
          <tbody>
            <tr>
              <!-- <th class="item">Item</th> -->
              <th class="id">ID</th>
              <th class="ip">DowloadFrom</th>
              <th class="time">Time</th>
            </tr>
            <tr *ngFor="let record of downloadCountRecordDtos.slice( pageSize*(pageNum-1), pageSize*pageNum )">
                <td class="id">{{ record.userName }}</td>
                <td class="item">{{ record.downloadFrom }}</td>
                <td class="time">{{ record.downloadDate | date:'yyyy-MM-dd HH:mm a'}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
