import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import {  map, filter  } from 'rxjs/operators';
import { AuthTokenService } from '../service/auth-token.service';
import { showInfo, MessageType } from 'app/shared/common';

@Injectable()
export class AuthTokenMobilePortalGuard implements CanActivate {
  constructor(private authTokenService: AuthTokenService, private router: Router) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    // filter false value due to env.service.checkReload
    return this.authTokenService.loggedIn$
    .pipe(
      map((result: boolean) => {
        if (result) {
          return true;
        } else {
          try {
            const mPortalKey = this.getMobilePortalKey(route);
            if (mPortalKey) {
              this.authTokenService.mPortalLogin(mPortalKey);
            } else {
              showInfo('Mobile Portal key not found.', 3000, MessageType.warning);
            }
          } catch (error) {
            console.log(error);
            showInfo(JSON.stringify(error), 3000, MessageType.error);
          }
          return false;
        }
      }),
      filter((v) => v)
    );
  }

  private getMobilePortalKey(route: ActivatedRouteSnapshot) {
    return route.paramMap.get('mportal') || route.queryParamMap.get('mportal');
  }

}
