import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { PermissionVo1, PermissionOperation, PermissionContentVo, DeleteOnePermissionVo, ResourceType } from 'app/core/model/data-model';
import { popbox, showConfirm, MessageType, showAlert, loadingShow, loadingHide, showInfo } from 'app/shared/common';
import { FolderService, DocumentService } from 'app/core/service';


@Component({
  selector: 'doc-permission-setting',
  templateUrl: './permission-setting.component.html',
  styleUrls: ['./permission-setting.component.css']
})
export class PermissionSettingComponent implements OnInit, AfterViewInit {

  @Input() parentFolderId: number;
  @Input() folderId: number;
  @Input() documentId: number;
  @Input() needCascade: boolean;
  @Input() needReadAll: boolean;
  @Input() folderType: string;
  i18nPrefix: 'FolderPermissionType' | 'DocumentPermissionType' = 'FolderPermissionType';
  private _resourceType: ResourceType = ResourceType.folder;
  @Input() set resourceType(val: ResourceType) {
    this._resourceType = val;
    if (val === ResourceType.folder) {
      this.i18nPrefix = 'FolderPermissionType';
    } else {
      this.i18nPrefix = 'DocumentPermissionType';
    }
  }
  _permissionContent: PermissionContentVo = this.initPermissionContent();
  @Input() set permissionContent(val: PermissionContentVo) {
    if (!val) { return; }
    this._permissionContent = val;
    this.vAllPermissionVos = val.deptPermissions
      .concat(val.groupPermissions)
      .concat(val.userPermissions);
    this.changeNeedFilter();
  }
  get userPermissionVos(): PermissionVo1[] {
    return this._permissionContent.userPermissions;
  }
  get deptPermissionVos(): PermissionVo1[] {
    return this._permissionContent.deptPermissions;
  }
  get groupPermissionVos(): PermissionVo1[] {
    return this._permissionContent.groupPermissions;
  }
  vAllPermissionVos: PermissionVo1[] = [];
  edittingPermissionVo: PermissionVo1;
  edittingFixShowParent: boolean;
  edittingShowParent: boolean;
  edittingReadOperation: PermissionOperation = this.initPermissionOperation();
  edittingReadAllOperation: PermissionOperation = this.initPermissionOperation();
  edittingWriteOperation: PermissionOperation = this.initPermissionOperation();
  edittingAdminOperation: PermissionOperation = this.initPermissionOperation();
  nameFilter = '';
  permissionTypeFilter = '';
  needFilter: boolean;
  cascadeSelectAll: boolean;
  showParentSelectAll: boolean;
  removeSelectAll: boolean;

  constructor(private folderService: FolderService, private documentService: DocumentService) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    $('.toggle-panel-btn').on('click', function () {
      $(this).parents('section').toggleClass('hide-panel-body');
    });
    $('.show-pop').click(function (e) {
      popbox($(this).attr('show-pop'));
      e.preventDefault();
    });
  }

  onNameFilterChange(nameFilter: string) {
    this.vAllPermissionVos = this.filterPermissions(this.deptPermissionVos, nameFilter)
      .concat(this.filterPermissions(this.groupPermissionVos, nameFilter))
      .concat(this.filterPermissions(this.userPermissionVos, nameFilter));
  }

  private filterPermissions(vos: PermissionVo1[], nameFilter: string) {
    if (!nameFilter || nameFilter.trim() === '') { return [...vos]; }
    return vos.filter(vo => vo.name.toLowerCase().indexOf(nameFilter.trim().toLowerCase()) > -1);
  }

  onCascadeSelectAllChange(value: boolean) {
    this.vAllPermissionVos
      .forEach(vo => {
        this.getFilterPermissionOperations(vo)
          .forEach(op => {
            op.cascade = value;
          });
      });
  }

  onShowParentSelectAllChange(value: boolean) {
    this.vAllPermissionVos
      .filter(v => !v.fixShowParent)
      .forEach(vo => {
        vo.showParent = value;
      });
  }

  private getFilterPermissionOperations(vo: PermissionVo1): PermissionOperation[] {
    switch (this.permissionTypeFilter) {
      case 'read':
        return [vo.readOperation];
      case 'readAll':
        return [vo.readAllOperation];
      case 'write':
        return [vo.writeOperation];
      case 'admin':
        return [vo.adminOperation];
      case '':
      default:
        return [vo.readOperation, vo.readAllOperation, , vo.writeOperation, vo.adminOperation];
    }
  }

  openPermissionEditting(vo: PermissionVo1) {
    this.edittingPermissionVo = vo;
    this.edittingFixShowParent = vo.fixShowParent;
    this.edittingShowParent = vo.showParent;
    this.edittingReadOperation = { ...vo.readOperation, fixValue: vo.readOperation.value };
    this.edittingReadAllOperation = { ...vo.readAllOperation, fixValue: vo.readAllOperation.value };
    this.edittingWriteOperation = { ...vo.writeOperation, fixValue: vo.writeOperation.value };
    this.edittingAdminOperation = { ...vo.adminOperation, fixValue: vo.adminOperation.value };
    popbox('show-select-account-permission-1');
  }

  savePermissionEditting() {
    if (!this.edittingPermissionVo) { return; }
    this.edittingPermissionVo.showParent = this.edittingShowParent;
    this.edittingPermissionVo.readOperation = { ...this.edittingReadOperation };
    this.edittingPermissionVo.readAllOperation = { ...this.edittingReadAllOperation };
    this.edittingPermissionVo.writeOperation = { ...this.edittingWriteOperation };
    this.edittingPermissionVo.adminOperation = { ...this.edittingAdminOperation };
  }

  private initPermissionOperation(): PermissionOperation {
    return { value: false, oldValue: false, cascade: false };
  }

  add(vo: PermissionContentVo) {
    this.userPermissionVos.push(...vo.userPermissions);
    this.groupPermissionVos.push(...vo.groupPermissions);
    this.deptPermissionVos.push(...vo.deptPermissions);
    this.changeNeedFilter();
    this.onNameFilterChange(this.nameFilter);
  }

  popbox(css: string) {
    popbox(css);
  }

  changeNeedFilter() {
    this.needFilter = this.userPermissionVos.length > 0 || this.groupPermissionVos.length > 0 || this.deptPermissionVos.length > 0;
  }

  private initPermissionContent(): PermissionContentVo {
    return {
      userPermissions: [],
      groupPermissions: [],
      deptPermissions: [],
      userFixShowParentLookup: {},
      groupFixShowParentLookup: {},
      deptFixShowParentLookup: {}
    };
  }

  removePermissionWithConfirm(vo: PermissionVo1, op: PermissionOperation, permissionType: 'read' | 'readAll' | 'write' | 'admin') {
    showConfirm(`刪除權限`, `您確定要刪除權限?`, MessageType.question)
      .then(result => {
        if (!result.value) { return; }
        this.removePermission(vo, op, permissionType);
      });
  }

  removePermission(vo: PermissionVo1, op: PermissionOperation, permissionType: 'read' | 'readAll' | 'write' | 'admin') {
    if (!op.oldValue) {
      this.removePermissionOnUI(vo, op);
      return;
    }
    const delVo: DeleteOnePermissionVo = {
      permissionId: vo.permissionId,
      permissionType: permissionType,
      parentFolderId: this.parentFolderId,
      folderId: this.folderId,
      docId: this.documentId,
      cascade: op.cascade,
      fixShowParent: vo.fixShowParent,
      oldShowParent: vo.oldShowParent
    };
    console.log('delVo', delVo);
    const delOnePerm$ = this._resourceType === ResourceType.folder ?
      this.folderService.deleteOnePermission(delVo) :
      this.documentService.deleteOnePermission(delVo);
    loadingShow();
    delOnePerm$.subscribe(resp => {
      loadingHide();
      if (resp.state === 'Ok') {
        this.removePermissionOnUI(vo, op);
      } else {
        showInfo(resp.message, 2000, MessageType.error);
      }
    }, err => {
      loadingHide();
      showInfo(err.message || 'error', 3000, MessageType.error);
      console.error('loadData ::', JSON.stringify(err));
    });
  }

  private removePermissionOnUI(vo: PermissionVo1, op: PermissionOperation) {
    op.value = false;
    if (!vo.readOperation.value && !vo.readAllOperation.value && !vo.writeOperation.value && !vo.adminOperation.value) {
      this.removeElementFromArray(this.userPermissionVos, vo);
      this.removeElementFromArray(this.groupPermissionVos, vo);
      this.removeElementFromArray(this.deptPermissionVos, vo);
      this.removeElementFromArray(this.vAllPermissionVos, vo);
    }
  }

  private removeElementFromArray<T>(arr: T[], ele: T) {
    const idx = arr.indexOf(ele);
    if (idx >= 0) {
      arr.splice(idx, 1);
    }
  }
}
