
import { Component, OnInit, Input, EventEmitter, Output, NgZone, OnDestroy } from '@angular/core';
import { UserNode, TreeNode, RespMessage } from '../../../core/model/data-model';
import { BaseComponent } from '../base/base.component';
import { FolderCanWriteTreeService } from '../../../core/service/folder-can-write-tree.service';

@Component({
  selector: 'doc-pick-tree-clone-doc-node',
  templateUrl: './pick-tree-clone-doc-node.component.html',
  styleUrls: ['./pick-tree-clone-doc-node.component.css']
})
export class PickTreeCloneDocNodeComponent extends BaseComponent implements OnInit, OnDestroy {

  @Input() folderId = 0;
  @Input() folderType = '';
  @Input() nodeName = '';
  @Input() curNode = {} as TreeNode;
  childrenNode: TreeNode[];
  constructor(private folderCanWriteTreeService: FolderCanWriteTreeService, private zone: NgZone) {
    super();
  }

  ngOnInit() {

  }

  getTreeNodes(curNode: TreeNode, folderType) {
    this.folderCanWriteTreeService.getCanWriteTreeNodes({ folderId: curNode.node_id, folderType: folderType, curNode: curNode, deptId: curNode.dept_id })
      .subscribe((resp: RespMessage<TreeNode>) => {
        if (resp.state === 'Ok') {
          this.childrenNode = curNode.childNode;
        }
      },
        error => {
          console.error('this.folderTreeService.getCanWriteTreeNodes ::', JSON.stringify(error));
        });
  }

  pickTreeNode(curNode: TreeNode) {
    curNode.folderTyp = this.folderType;
    this.folderCanWriteTreeService.pickTreeNode = curNode;
    // console.log('pickTreeNode', curNode);
  }

  canCloneDoc(): boolean {
    return this.curNode && this.curNode.canNewDoc && this.curNode.node_id > 0;
  }

  get anchorTitle(): string {
    return this.canCloneDoc() ? '' : 'NoWritePermission';
  }

  ngOnDestroy() {
    // console.log('PickTreeCloneDocNodeComponent.....ngOnDestroy');
  }

}
