import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PermissionOperation } from 'app/core/model/data-model';

@Component({
  selector: 'doc-permission-edit',
  templateUrl: './permission-edit.component.html',
  styleUrls: ['./permission-edit.component.css']
})
export class PermissionEditComponent implements OnInit {

  @Input() i18nPrefix: 'FolderPermissionType' | 'DocumentPermissionType' = 'FolderPermissionType';
  @Input() needReadAll: boolean;
  @Input() needCascade: boolean;
  @Input() readOperation: PermissionOperation = this.initPermissionOperation();
  @Input() readAllOperation: PermissionOperation  = this.initPermissionOperation();
  @Input() writeOperation: PermissionOperation = this.initPermissionOperation();
  @Input() adminOperation: PermissionOperation = this.initPermissionOperation();
  @Input() fixShowParent: boolean;
  @Input() showParent: boolean;
  @Output() showParentChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor() { }

  ngOnInit() {
  }

  private initPermissionOperation(): PermissionOperation {
    return { value: false, oldValue: false, cascade: false };
  }

  onValueChange(value: boolean, op: PermissionOperation) {
    if (!value) {
      op.cascade = false;
    }
    this.valueChange.emit(value);
  }

  onShowParentChange(value: boolean) {
    this.showParentChange.emit(value);
  }
}
