import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { RespMessage, CapFolderContentVo } from '../model/data-model';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CapService extends BaseService {

  constructor(private http: HttpClient) {
    super();
  }

  GetFoldersAndDocs(folderId: number) {
    return this.http.post<RespMessage<CapFolderContentVo>>(`${environment.apiRoot}/api/cap/getFoldersAndDocs`, { folderId });
  }

}
