import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { showInfo, MessageType, loadingHide, loadingShow, FolderTypeName, FolderType } from 'app/shared/common';
import { DocumentFile, DocumentContent, DocumentDto, HttpOptions, RespMessage, FolderFileSizeVM } from 'app/core/model/data-model';
import { DocumentService, FolderTreeService, PermissionService, UploadxService, FolderService, ClientLogService } from 'app/core/service';
import { BaseComponent } from 'app/shared/component';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import { flatMap } from 'rxjs/operators';
import { of } from 'rxjs';


@Component({
  selector: 'doc-upload-content',
  templateUrl: './upload-content.component.html',
  styleUrls: ['./upload-content.component.css']
})
export class UploadContentComponent extends BaseComponent implements OnInit {

  @ViewChild('uploadFiles', { static: false }) uploadFiles: ElementRef;
  folderId = 0;
  folderType: FolderType = FolderType.PRIVATE_FOLDER;
  auth = '';
  httpOptions: HttpOptions; // allow undefined
  folderFileSize: FolderFileSizeVM; // folderFileSize

  constructor(private documentService: DocumentService, private permissionService: PermissionService,
    private folderService: FolderService, private folderTreeService: FolderTreeService, private uploadService: UploadxService,
    private route: ActivatedRoute, private router: Router) {
    super();
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.auth = params['auth'] || '';
      if (this.auth !== '') {
        this.httpOptions = { 'headers': new HttpHeaders({ 'Authorization': `Bearer ${this.auth}` }) };
      }
      this.getFolderFileSize();
    }, error => {
      loadingHide();
      console.error('UploadContent::', JSON.stringify(error));
    });
  }

  save() {
    const fileList: File[] = this.getFiles();
    if (fileList.length === 0 || !this.calcuFileLimit(fileList)) {
      return;
    }
    loadingShow();
    this.folderService.getPrivateUploadTempFolderId(this.httpOptions)
      .pipe(
        flatMap(resp => {
          if (resp.state === 'Ok') {
            const uploadFolderId = resp.result;
            return this.documentService.getCreateDoc({ folderId: uploadFolderId, folderType: this.folderType }, this.httpOptions);
          }
          return of(this.ToResp<number, DocumentContent>(resp));
        }), flatMap(resp => {
          if (resp.state === 'Ok') {
            const createDocumentContent = resp.result;
            createDocumentContent.documentFiles = this.getCreateDocumentFiles();
            const uploadFolderId = createDocumentContent.folderId;
            return this.documentService.addArchives({ folderId: uploadFolderId, folderType: this.folderType, documentContent: createDocumentContent, notifySelf: true }, this.httpOptions);
          }
          return of(resp);
        })
      ).subscribe((resp: RespMessage<DocumentContent>) => {
        loadingHide();
        if (resp.state === 'Ok') {
          const documentContent = resp.result;
          if (fileList != null && fileList.length > 0) {
            fileList.forEach(file => {
              const fname = file.name;
              const fileDto = documentContent.documentFiles.find(fo => fo.fileName === fname);
              this.uploadService.handleFile({ ...fileDto, file: file }); // actionUrl: location.hash
              this.getFolderFileSize();
            });
          }
          // showInfo('Success', 2000, MessageType.success);
        } else {
          showInfo(resp.message, 2000, MessageType.error);
        }
      }, error => {
          loadingHide();
          console.error('documentService.addArchives ::', JSON.stringify(error));
      });
  }

  private calcuFileLimit(fileList: File[]) {
    const fileSize = fileList.map(f => f.size).reduce((acc, val) => acc + val, 0);
    console.log('fileSize', fileSize);
    if (this.folderFileSize.current + fileSize > this.folderFileSize.limit) {
      showInfo('超過限制大小無法新增', 2000, MessageType.error);
      return false;
    }
    return true;
  }

  private getFolderFileSize() {
    loadingShow();
    this.folderTreeService.getFolderFileSize({ folderId: 0, folderType: this.folderType }, this.httpOptions)
      .subscribe(resp => {
        loadingHide();
        if (resp.state === 'Ok') {
          this.folderFileSize = resp.result;
        } else {
          showInfo(resp.message, 2000, MessageType.error);
        }
      }, error => {
        console.error('folderTreeService.getFolderFileSize::', JSON.stringify(error));
      });
  }

  private getCreateDocumentFiles(): DocumentFile[] {
    const fileList = this.getFiles();
    const documentFiles: DocumentFile[] = fileList.map(file => {
      return {
        fileId: 0,
        fileName: file.name,
        fileExtension: file.name.lastIndexOf('.') > 0 ? file.name.substring(file.name.lastIndexOf('.')) : '',
        fileSize: file.size,
        downloadCount: 0,
      };
    });
    return documentFiles;
  }

  private getFiles(): File[] {
    const _fileList: FileList = this.uploadFiles.nativeElement.files;
    const fileList: File[] = [];
    if (_fileList.length > 0) {
      Array.from(_fileList).forEach(f => {
        fileList.push(f);
      });
    }
    return fileList;
  }

  private ToResp<T, S>(respT: RespMessage<T>, to?: (t: T) => S ): RespMessage<S> {
    // state: string;
    const respS = new RespMessage<S>();
    respS.pageSize = respT.pageSize;
    respS.dataCount = respS.dataCount;
    respS.pageNum = respT.pageNum;
    respS.pageCount = respT.pageCount;
    respS.state = respT.state;
    respS.message = respS.message;
    if (to) {
      respS.result = to(respT.result);
    }
    return respS;
  }

}
