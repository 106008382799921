import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { BaseService } from './base.service';
import { DataRow, DocumentContextmenuDto } from '../model/data-model';
import { BehaviorSubject, Subscription } from 'rxjs';
import { distinctUntilChanged, delay } from 'rxjs/operators';

@Injectable()
export class DocumentContextmenuService extends BaseService implements OnDestroy {

  readonly contextmenuDtoSubject = new BehaviorSubject<DocumentContextmenuDto>(null);
  readonly contextmenuDto$ = this.contextmenuDtoSubject.asObservable().pipe(distinctUntilChanged());

  private _isVisible: boolean;
  private isVisibleSubscription: Subscription;
  get isVisible() { return this._isVisible; }

  preDataRow: DataRow = null;

  constructor() {
    super();
    this.isVisibleSubscription = this.contextmenuDto$
      .subscribe(dto => {
        this._isVisible = dto !== null;
      });
  }

  ngOnDestroy(): void {
    this.isVisibleSubscription.unsubscribe();
  }

}
