import { Directive, Input, HostListener } from '@angular/core';
import { FolderType, isMobile } from 'app/shared/common';
import { DataRow, DocumentDto, DocumentFolder } from 'app/core/model/data-model';
import { DocumentContextmenuService } from 'app/core/service/document-contextmenu.service';

@Directive({
  selector: '[docDocumentContextmenu]'
})
export class DocumentContextmenuDirective {

  constructor(private contextmenuService: DocumentContextmenuService) {
    this.isMobile = isMobile();
  }

  isMobile: boolean;
  @Input('docDocumentContextmenu') dataRow: DataRow;
  @Input() docPageList: DataRow[] = [];

  @HostListener('contextmenu', ['$event'])
  onContextmenu(e: MouseEvent) {
    if (this.isMobile || this.isDeptVirtualFolder()) { return; }
    e.preventDefault();
    // if this.dataRow.isSelected do nothing
    if (!this.dataRow.isSelected) {
      this.docPageList
        .filter(m => m.isSelected)
        .forEach(m => { m.isSelected = false; });
      this.dataRow.isSelected = true;
      this.contextmenuService.preDataRow = this.dataRow;
    }
    this.contextmenuService.contextmenuDtoSubject.next({ dataRow: this.dataRow, clientX: e.clientX, clientY: e.clientY });
  }

  @HostListener('click', ['$event'])
  onClick(evt: MouseEvent) {
    if (this.isMobile || this.isDeptVirtualFolder()) { return; }
    // if (this.contextmenuService.isVisible) { return; }
    if (evt.ctrlKey && evt.shiftKey) { return; }
    if (evt.ctrlKey) {
      this.dataRow.isSelected = !this.dataRow.isSelected;
      this.contextmenuService.preDataRow = this.dataRow.isSelected ? this.dataRow : null;
      return;
    }
    if (evt.shiftKey) {
      if (!this.contextmenuService.preDataRow) {
        this.contextmenuService.preDataRow = this.dataRow;
      }
      const tmpPreIdx = this.docPageList.indexOf(this.contextmenuService.preDataRow); // null => -1
      const preIdx = tmpPreIdx >= 0 ? tmpPreIdx : 0;
      const curIdx = this.docPageList.indexOf(this.dataRow);
      const left = preIdx < curIdx ? preIdx : curIdx;
      const right = (preIdx < curIdx ? curIdx : preIdx) + 1;
      this.docPageList.slice(0, left).forEach(m => {
        m.isSelected = false;
      });
      this.docPageList.slice(right).forEach(m => {
        m.isSelected = false;
      });
      this.docPageList.slice(left, right).forEach(m => {
        m.isSelected = true;
      });

    }
  }

  private isDeptVirtualFolder(): boolean {
    const folder = (this.dataRow.data as DocumentFolder);
    return this.dataRow.type === 'folder' && folder.folderId === 0 && folder.folderType === FolderType.DEPT_FOLDER ;
  }
}
