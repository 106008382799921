
import { Component, OnInit, OnDestroy, EventEmitter, Output, Input } from '@angular/core';
import { FolderTreeService } from '../../../core/service/folder-tree.service';
import { loadingShow, loadingHide, FolderType, popbox } from '../../common';
import { RespMessage, UserNode, TreeNode } from '../../../core/model/data-model';
import { Observable } from 'rxjs';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'doc-pick-tree',
  templateUrl: './pick-tree.component.html',
  styleUrls: ['./pick-tree.component.css']
})
export class PickTreeComponent extends BaseComponent implements OnInit, OnDestroy {

  userAllNode: UserNode = null;
  @Input() pickType = 'Query';
  @Input() pickCss = 'show-nav-tree';
  @Output() onPickTreeNode = new EventEmitter<TreeNode>();
  @Output() onSelected = new EventEmitter<boolean>();
  pickNode = '';

  constructor(private folderTreeService: FolderTreeService) {
    super();
  }

  ngOnInit() {
    // prevent loadingShow loadingHide due to query or search documents
    this.resetPickTreeNode();
    this.folderTreeService.userAllNode$.subscribe(
      userAllNode => {
        this.userAllNode = userAllNode;
        // console.log('PickTreeComponent this.userAllNode', this.userAllNode);
      },
      error => {
        console.error('folderTreeService.userAllNode$ ::', JSON.stringify(error));
      }
    );

    this.folderTreeService.currentPickTreeNode$.subscribe((treeNode: TreeNode) => {
      // console.log('pickTreeNode', treeNode);
      const self = this;
      setTimeout(() => {
        self.pickNode = '';
        if (treeNode != null) {
          self.pickNode = 'node_name' in treeNode ? treeNode.node_name : '';
        }
      }, 200);
    });
  }

  show() {
    // console.log('show', this.pickCss);
    this.resetPickTreeNode();
    popbox(this.pickCss);
  }

  resetPickTreeNode() {
    this.folderTreeService.pickTreeNode = null;
    // this.pickNode = '';
  }

  action(selected: boolean) {
    if (selected && this.folderTreeService.pickTreeNode == null) {
    }
    // console.log('selected', this.folderTreeService.pickTreeNode);
    this.onSelected.emit(selected);
  }

  pickTreeNode(curNode: TreeNode, folderType) {
    curNode.folderTyp = folderType;
    this.folderTreeService.pickTreeNode = curNode;
    this.onPickTreeNode.emit(curNode);
    // console.log('pickTreeNode', curNode);
  }

  getTreeNodes(curNode: TreeNode, folderType) {
    this.folderTreeService.getTreeNodes({ folderId: curNode.node_id, folderType: folderType, curNode: curNode, deptId: curNode.dept_id })
      .subscribe((resp: RespMessage<TreeNode>) => {
        if (resp.state === 'Ok') { }
      }, error => {
        console.error('folderTreeService.getTreeNodes ::', JSON.stringify(error));
      });
  }

  ngOnDestroy() {
    console.log('PickTreeComponent.....ngOnDestroy');
  }
}
