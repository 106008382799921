<div class="main">
    <doc-breadcrumbs [folderHierarchy]="folderContent?.folderHierarchy" [folderType]="folderType" [folderTypeName]="folderTypeName"></doc-breadcrumbs>
    <section class="panel panel-default panel-article">
        <div class="panel-heading">
            <h3 class="panel-title" title='{{ folderName | translate }}'>
              <i class="circle mdi mdi-format-list-bulleted"></i> {{ folderName | translate }}</h3>
            <doc-paged-list #headerPagedList [dataCount]="dataCount" [pageSize]="pageSize" [pageNum]="pageNum" (pageNumChange)="pageChange($event)" (pageSizeChange)="pageChange($event)"></doc-paged-list>
            <div *ngIf="folderContent?.canNewDoc||folderContent?.canNewFolder">
                <!-- <a *ngIf="folderContent?.canNewFolder && folderId>0" class="btn btn-danger marg6-r swa-delete-file" href="javascript:void(0)"  (click)="deleteAll()" > <i class="mdi mdi-delete"></i>{{ "Delete Folder" | translate }}</a> -->
                <a *ngIf="folderContent?.canNewDoc" class="btn btn-btn btn-success marg6-r" [routerLink]="[ '/folder/archives-add', {data:dataEncode( {folderType:folderType,folderId:folderId})} ] "> <i class="mdi mdi-database"></i>{{ "Create File" | translate }} </a>
                <a *ngIf="folderContent?.canNewDoc" class="btn btn-primary marg6-r" [routerLink]="[ '/folder/document-add', {data:dataEncode( {folderType:folderType,folderId:folderId})} ]"> <i class="mdi mdi-file"></i> {{ "Create Document" | translate }}</a>
                <a *ngIf="folderContent?.canNewFolder" class="btn btn-warning" [routerLink]="[ '/folder/folder-add', {data:dataEncode( {folderType:folderType,folderId:folderId})} ]"> <i class="mdi mdi-folder"></i> {{ "Create Folder" | translate }}</a>
            </div>
        </div>
        <div class="panel-body">
            <table class="table table-striped table-hover table-btn table-rwd">
                <tbody>
                    <tr>
                        <th class="type click" (click)="sortChange('typeCss')">{{"Type"|translate}}
                            <em *ngIf="sortByCols['typeCss']=='ASC'; else typeCssAsc">↑</em>
                            <ng-template #typeCssAsc>↑</ng-template>
                            <em *ngIf="sortByCols['typeCss']=='DESC'; else typeCssDesc">↓</em>
                            <ng-template #typeCssDesc>↓</ng-template>
                        </th>
                        <th class="name click" (click)="sortChange('name')">{{"Name"|translate}}
                          <em *ngIf="sortByCols['name']=='ASC'; else nameAsc">↑</em>
                          <ng-template #nameAsc>↑</ng-template>
                          <em *ngIf="sortByCols['name']=='DESC'; else nameDesc">↓</em>
                          <ng-template #nameDesc>↓</ng-template>
                        </th>
                        <th class="date click" (click)="sortChange('modifyTime')">{{"Modified Date"|translate}}
                            <em *ngIf="sortByCols['modifyTime']=='ASC'; else modifyTimeAsc">↑</em>
                            <ng-template #modifyTimeAsc>↑</ng-template>
                            <em *ngIf="sortByCols['modifyTime']=='DESC'; else modifyTimeDesc">↓</em>
                            <ng-template #modifyTimeDesc>↓</ng-template>
                        </th>
                        <th class="account">{{"Modified User"|translate}}</th>
                        <th class="hit click" (click)="sortChange('readCount')">{{"Hits"|translate}}
                            <em *ngIf="sortByCols['readCount']=='ASC'; else readCountAsc">↑</em>
                            <ng-template #readCountAsc>↑</ng-template>
                            <em *ngIf="sortByCols['readCount']=='DESC'; else readCountDesc">↓</em>
                            <ng-template #readCountDesc>↓</ng-template>
                        </th>
                        <th class="action">{{"Action"|translate}}</th>
                        <th class="fav">{{"Favorite"|translate}}</th>
                    </tr>
                    <tr *ngFor="let item of pageList">
                        <td class="type"><i class="mdi mdi-paperclip " [ngClass]="{empty:!(item.type == 'doc' && item.data['hasFile'] == '1')}"></i>
                            <i class="{{item.typeCss}}"></i></td>
                        <td class="name">
                            <a href="javascript:void(0)" (click)="action(item)"><p class="title">{{item.name}}</p></a>
                        </td>
                        <td class="date">{{item.modifyTime | date:'yyyy-MM-dd HH:mm a'}}</td>
                        <td class="account">{{getUserViewName(item.user)}}</td>
                        <td class="hit">
                          <span *ngIf="item.type !== 'folder' && !item.data.canAdmin">{{ item.readCount }}</span>
                          <a *ngIf="item.type !== 'folder' && item.data.canAdmin" class="show-pop" show-pop="show-hit" href="javascript:void(0)" (click)="showHitCountRecord(item,hitCountRecord)">{{ item.readCount }}</a>
                        </td>
                        <td class="action">
                            <a *ngIf="checkCanDelete(item)" class="btn btn-xs btn-danger marg6-r swa-delete" href="javascript:void(0)" (click)="delete(item)" title='{{"Delete" | translate}}' > <i class="mdi mdi-delete"></i></a>
                            <a *ngIf="item.type === 'doc' " class="btn btn-xs btn-success marg6-r " (click)="showPickTree(item,pickTree)"   ><i class="mdi mdi-file-multiple"></i></a>
                            <a *ngIf="item.data.canWrite" class="btn btn-xs btn-info" href="javascript:void(0)" (click)="edit(item)"  title='{{"Edit" | translate}}'  > <i class="mdi mdi-pencil"></i></a>
                        </td>
                        <td class="fav">
                            <a *ngIf="item.type == 'doc'" class="icon" href="javascript:void(0)" (click)="delfav(item)" >
                                <i class="mdi mdi-star yellow" > </i>
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="panel-footer">
        </div>
    </section>
</div>
<div *ngIf="folderContent?.canNewDoc||folderContent?.canNewFolder">
  <div class="flatBtnPanel" [ngClass]="{ 'add-action': isFloatActed }" (click)="toggleFloatAction()">
    <div class="flatBtnPanel-btn-wrap">
      <a *ngIf="folderContent?.canNewDoc" [routerLink]="[ '/folder/archives-add', {data:dataEncode( {folderType:folderType,folderId:folderId} ),hash:hash}]">
        {{ "Create File" | translate }}<i class="mdi mdi-database btn btn-btn btn-success"></i></a>
      <a *ngIf="folderContent?.canNewDoc"[routerLink]="[ '/folder/document-add', {data:dataEncode( {folderType:folderType,folderId:folderId} ),hash:hash}]">
        {{ "Create Document" | translate }}<i class="mdi mdi-file btn btn-primary"></i></a>
      <a *ngIf="folderContent?.canNewFolder"[routerLink]="[ '/folder/folder-add',  {data:dataEncode({folderType:folderType,folderId:folderId} )}]">
        {{ "Create Folder" | translate }}<i class="mdi mdi-folder btn btn-warning"></i></a>
    </div>
  </div><a class="floatBtn" href="javascript:void(0)" [ngClass]="{ 'add-action': isFloatActed }" (click)="toggleFloatAction()" ><i></i></a>
</div>
<doc-pick-tree-clone-doc #pickTree pickType="Clone" pickCss="show-share" (selected)="getPickTreeNodeCloneDoc($event)"></doc-pick-tree-clone-doc>
<doc-document-view #documentView (onAddfav)="delfav({ type: 'doc', name: $event.documentName, readCount: $event.readCount, modifyTime: $event.modifyTime, data:$event})"></doc-document-view>
<doc-hit-count-record #hitCountRecord ></doc-hit-count-record>
