<div class="popbg-fill va-center show-document-preview">
  <div class="pop-box">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" (click)="closePreview()">
          <i class="mdi mdi-close"></i>
        </button>
        <h4>
          <i class="mdi mdi-magnify-plus-outline"></i>{{documentDto.documentName}} {{ "Preview" | translate }}</h4>
      </div>
      <div class="modal-body">
        <div class="main">
          <section class="panel panel-default panel-article">
            <div class="tab-panel-boddy">
              <div class="panel-body">
                <div class="detail-download">
                  {{ "Download Attachment" | translate }} :
                  <a *ngFor="let item of fileList" href="javascript:void(0)">
                    <i class="mdi mdi-paperclip"> </i>{{item.name}}</a>
                  <a *ngFor="let item of documentFiles" href="javascript:void(0)">
                    <i class="mdi mdi-paperclip"> </i>{{item.fileName}}
                    <ng-container *ngIf="item.exists === false">({{ "File Not Exist" | translate }})</ng-container>
                 </a>
                </div>
                <div class="main-document-content">
                  <doc-document-content-view  #contentView [documentDto]="documentDto"></doc-document-content-view>
                </div>
              </div>
            </div>
          </section>
          <div class="center pad20-b">
            <a class="btn btn-sm btn-dark marg6-r close-btn" href="javascript:void(0)" (click)="closePreview()">
              <i class="mdi mdi-reply"></i>{{ "GoBack" | translate }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
