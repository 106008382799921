import { Component, OnInit, ViewChild } from '@angular/core';
import { RespMessage, DocumentContent, CapDocumentVo, CapFolderContentVo, CapFolderVo, CapDataRow, FolderHierarchy } from 'app/core/model/data-model';
import { loadingShow, loadingHide, popbox, showInfo, MessageType, getArchiveTypeCss, TypeCss, getFileExtension } from 'app/shared/common';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseComponent } from 'app/shared/component/base/base.component';
import { DocumentService, CapService } from 'app/core/service';
import { DocumentViewComponent } from '..';
import { environment } from 'environments/environment';

@Component({
  selector: 'doc-cap',
  templateUrl: './cap.component.html',
  styleUrls: ['./cap.component.css']
})
export class CapComponent extends BaseComponent implements OnInit {
  // todo move to folder module, fix routing
  readonly popDoc = 'pop-doc-from-cap';
  folderId = 0;
  resultList: CapDataRow[] = [];
  folderHierarchy: FolderHierarchy[] = [];
  @ViewChild('documentView', { static: false }) documentViewComponent: DocumentViewComponent;
  constructor(protected router: Router, protected route: ActivatedRoute,
    private documentService: DocumentService, private capService: CapService) {
    super();
  }

  ngOnInit() {
    // todo paging sorting
    this.route.params.subscribe((params) => {
      const data = this.dataDecode(params['data']);
      this.folderId = data['folderId'] || 0;
      this.resultList = [];
      this.folderHierarchy = [];
      this.query();
    });
  }

  query() {
    loadingShow();
    this.capService.GetFoldersAndDocs(this.folderId)
      .subscribe((resp: RespMessage<CapFolderContentVo>) => {
        loadingHide();
        if (resp.state === 'Ok') {
          this.folderHierarchy = resp.result.folderHierarchy;
          const folders: CapDataRow[] = resp.result.folders.map(f => ({
            type: 'folder',
            typeCss: TypeCss.Folder,
            name: f.folderName,
            data: f
          }));
          const documents: CapDataRow[] = resp.result.documents.map(d => ({
            type: d.isArchive ? 'file' : 'doc',
            typeCss: d.isArchive ? this.getArchiveTypeCss(d.documentName) : TypeCss.Document,
            name: d.documentName,
            data: d
          }));
          this.resultList = folders.concat(documents);
        } else {
          showInfo(resp.message, 2000, MessageType.error);
        }
      }, error => {
        loadingHide();
        showInfo(error.message || 'error', 3000, MessageType.error);
        console.log('error', error);
      });
  }

  action(data: CapDataRow) {
    if (data.type === 'folder') {
      const folder = data.data as CapFolderVo;
      this.router.navigate(['/cp', { data: this.dataEncode({ folderId: folder.folderId }) }]);
    } else {
      const doc = data.data as CapDocumentVo;
      this.openDoc(doc);
    }
  }

  openDoc(doc: CapDocumentVo) {
    loadingShow();
    this.documentService.openDoc({ folderId: doc.folderId, folderType: doc.belongTo, docId: doc.documentId })
      .subscribe((resp: RespMessage) => {
        loadingHide();
        if (resp.state === 'Ok') {
          const documentContent: DocumentContent = resp.result;
          this.documentViewComponent.show(documentContent);
          popbox(this.popDoc);
        } else {
          showInfo(resp.message, 2000, MessageType.error);
        }
      }, error => {
        loadingHide();
        showInfo(error.message || 'error', 3000, MessageType.error);
        console.log('error', error);
      });
  }

  download(item: CapDocumentVo) {
    const auth = this.currentUserToken;
    const url = `${environment.apiRoot}/api/DownloadFile/Download?auth=${auth}&id=${item.fileGuid}&fileName=${encodeURIComponent(item.documentName)}`;
    console.log('url', url);
    window.open(url, 'fmDownloadFile');
  }

  private getArchiveTypeCss(fileName: string) {
    const fileExt = getFileExtension(fileName).toLowerCase();
    return getArchiveTypeCss(fileExt);
  }

  // private getModifyUserViewName(modifyUserName: string, modifyUserEngName: string): string {
  //   return modifyUserName ? `${modifyUserName} (${modifyUserEngName || ''})` : '';
  // }
}
