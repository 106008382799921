<header>
  <div class="wrap">
    <div class="hamburger-icon" [ngClass]="{ 'show-nav': isNav }" (click)="toggleNav()"><i></i></div>
    <a class="logo" [routerLink]="[ '/home' ]" title="{{ 'Back to Home' | translate }}">
      <i class="ASUS-logo"> </i>
      <b>Drive</b>
    </a>
    <div class="right-wrap">
      <!--切換語系-->
      <ul class="lang" title="{{ 'Switch Language' | translate }}">
        <li (click)="changeLang('zh')" [ngClass]="{'focus': uselang === 'zh' }">繁中</li>
        <li (click)="changeLang('en')" [ngClass]="{'focus': uselang === 'en' }">EN</li>
      </ul>
      <!--使用手冊-->
      <!--個人相關事項				-->
      <div class=" account-wrap " title="logout">
        <i class="mdi mdi-account-circle "> </i>
        <a href="javascript:void(0)" (click)="logout()" title="{{logonUser?.type==='ASUS'? 'Sign Out' : '' | translate }}">{{this.logonUser?.userName}} ({{this.logonUser?.userEngName}})
          <i class="fa fa-sign-out"></i>
        </a>
      </div>
      <!--通知-->
    </div>
  </div>
</header>
