import { DocumentContent, RespMessage, DocumentFile } from './../../core/model/data-model';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FolderType, loadingHide, loadingShow, FolderTypeName, showInfo, MessageType, RouteMap } from '../../shared/common';
import { DocumentService, DownloadService } from '../../core/service';



import { BaseComponent } from '../../shared/component/base/base.component';
import { environment } from 'environments/environment';
@Component({
  selector: 'doc-archives-read',
  templateUrl: './archives-read.component.html',
  styleUrls: ['./archives-read.component.css']
})
export class ArchivesReadComponent extends BaseComponent implements OnInit {


  folderId = 0;
  docId = 0;
  folderType: FolderType;
  deptId = '';
  folderTypeName = FolderTypeName;
  documentContent = {} as DocumentContent;
  documentFiles: DocumentFile[] = [];
  goBack = '';
  constructor(private documentService: DocumentService,
    private router: Router, private route: ActivatedRoute,
    private downloadService: DownloadService) {
    super();
  }

  ngOnInit() {

    this.route.params.subscribe(params => {
      const data = this.dataDecode(params['data']);
      if (data['folderType'] != null && data['docId'] != null) {
        this.folderId = data['folderId'] || 0;
        this.folderType = data['folderType'];
        this.deptId = data['deptId'] || '';
        this.goBack = data['back'] || '';
        this.folderTypeName = FolderTypeName['' + this.folderType];
        this.docId = data['docId'];
        loadingShow();
        this.documentService.openDoc({ folderId: this.folderId, folderType: this.folderType, docId: this.docId })
          .subscribe((resp: RespMessage<DocumentContent>) => {
            loadingHide();
            if (resp.state === 'Ok') {
              this.documentContent = resp.result;
              this.documentFiles = this.documentContent.documentFiles;
              // console.log(this.documentFiles);
            } else {
              showInfo(resp.message, 2000, MessageType.error);
            }
          }, error => {
            loadingHide();
            showInfo(error.message || 'error', 3000, MessageType.error);
            console.error('documentService.openDoc ::', JSON.stringify(error));
          });
      } else {
        showInfo('參數錯誤', 2000, MessageType.error);
      }
    }, error => {
      loadingHide();
      showInfo(error.message || 'error', 3000, MessageType.error);
      console.error('ArchivesReadComponent ::', JSON.stringify(error));
    });
  }

  downloadFile(item: DocumentFile) {
    let url = `${environment.apiRoot}/api/DownloadFile/Download?auth=${this.currentUserToken}&id=${item.guid}&fileName=${encodeURIComponent(item.fileName)}`;
    if (location.hostname === 'localhost') {
      url = 'http://localhost:56999' + url;
    }
    if (item.exists) {
      window.open(url, 'fmDownloadFile');
      //   this.downloadService.getFile(url, item.fileName);
    }
  }

  close() {
    let url = RouteMap[this.folderType];
    if (this.goBack !== '') {
      url = this.goBack;
    }
    this.router.navigate([url, { data: this.dataEncode({ folderId: this.folderId }) }]);
  }

}
