<div class="tree-wrap scrollbar">
  <div class="myFileSize">{{ "Total Documents" | translate }} {{myFileSize}}</div>
  <nav class="tree">
    <ul *ngIf="currentUser?.type == 'ASUS'  " class="nav-main myFile">
      <li>
        <a class="main-title" (click)="setNavTreeNode(userAllNode?.private_treenode_root, $event)" [routerLink]="[ '/folder/private-folder', {data:dataEncode( {folderId:0})} ]"
          routerLinkActive="active">{{ "Private Folder" | translate}}
          <i *ngIf="userAllNode?.private_treenode_root?.childNode?.length>0 " docToggleTreeNode [refTreeViewComponent]="userAllNode?.private_treenode_root"
            class="mdi mdi-menu-right toggle-icon" (click)="getTreeNodes(userAllNode?.private_treenode_root,'PRI')"></i>
        </a>
        <ul *ngIf="userAllNode?.private_treenode_root?.childNode?.length>0 ">
          <doc-tree-view *ngFor="let item of userAllNode?.private_treenode_root?.childNode" [folderId]="item.node_id" routerPath='/folder/private-folder'
            folderType='PRI' [curNode]='item'></doc-tree-view>
        </ul>
      </li>
    </ul>
    <ul class="nav-main systemFile">
      <li>
        <a class="main-title" (click)="setNavTreeNode(userAllNode?.system_treenode_root, $event)" [routerLink]="[ '/folder/system-folder', {data:dataEncode( {folderId:0})} ]"
          routerLinkActive="active">{{ "System Folder" | translate}}
          <i *ngIf="userAllNode?.system_treenode_root?.childNode?.length>0 " docToggleTreeNode [refTreeViewComponent]="userAllNode?.system_treenode_root"
            class="mdi mdi-menu-right toggle-icon" (click)="getTreeNodes(userAllNode?.system_treenode_root?.childNode,'SYS')"></i>
        </a>
        <ul *ngIf="userAllNode?.system_treenode_root?.childNode?.length>0 ">
          <doc-tree-view *ngFor="let item of userAllNode?.system_treenode_root.childNode" [folderId]="item.node_id" routerPath='/folder/system-folder'
            folderType='SYS' [curNode]='item'></doc-tree-view>
        </ul>
      </li>
    </ul>
    <ul *ngIf="currentUser?.type == 'ASUS'  " class="nav-main publicFile">
      <li>
        <a class="main-title" (click)="setNavTreeNode(userAllNode?.public_treenode_root, $event)" [routerLink]="[ '/folder/public-folder', {data:dataEncode( {folderId:0})} ]"
          routerLinkActive="active">{{ "Public Folder" | translate}}
          <i *ngIf="userAllNode?.public_treenode_root?.childNode?.length>0 " docToggleTreeNode [refTreeViewComponent]="userAllNode?.public_treenode_root"
            class="mdi mdi-menu-right toggle-icon" (click)="getTreeNodes(userAllNode?.public_treenode_root,'PUB')"></i>
        </a>
        <ul *ngIf="userAllNode?.public_treenode_root?.childNode?.length>0 ">
          <doc-tree-view *ngFor="let item of userAllNode?.public_treenode_root.childNode" [folderId]="item.node_id" routerPath='/folder/public-folder'
            folderType='PUB' [curNode]='item'></doc-tree-view>
        </ul>
      </li>
    </ul>
    <ul *ngIf="currentUser?.type == 'ASUS'  " class="nav-main buFile">
      <li>
        <a class="main-title" (click)="setNavTreeNode(userAllNode?.dept_treenode_root, $event)" [routerLink]="[ '/folder/department-folder', {data:dataEncode(  {folderId:0})} ]"
          routerLinkActive="active">{{ "Department Folder" | translate}}
          <i *ngIf="userAllNode?.dept_treenode_root?.childNode?.length>0 " docToggleTreeNode [refTreeViewComponent]="userAllNode?.dept_treenode_root"
            class="mdi mdi-menu-right toggle-icon" (click)="getTreeNodes(userAllNode?.dept_treenode_root,'DEPT')"></i>
        </a>
        <ul *ngIf="userAllNode?.dept_treenode_root?.childNode?.length>0 ">
          <doc-tree-view *ngFor="let item of userAllNode?.dept_treenode_root.childNode" [folderId]="item.node_id" routerPath='/folder/department-folder'
            folderType='DEPT' [curNode]='item'></doc-tree-view>
        </ul>
      </li>
    </ul>
    <ul *ngIf="currentUser?.type == 'ASUS'  " class="nav-main shareFile">
      <li>
        <a class="main-title" (click)="setNavTreeNode(userAllNode?.shareWithMe_treenode_root, $event)" [routerLink]="[ '/folder/share-with-me', {data:dataEncode( {folderId:0})} ]">{{ "Share With Me" | translate}}
          <i *ngIf="userAllNode?.shareWithMe_treenode_root?.childNode?.length>0 " docToggleTreeNode [refTreeViewComponent]="userAllNode?.shareWithMe_treenode_root"
            class="mdi mdi-menu-right toggle-icon" (click)="getTreeNodes(userAllNode?.shareWithMe_treenode_root,'SHARE')"></i>
        </a>
        <ul *ngIf="userAllNode?.shareWithMe_treenode_root?.childNode?.length>0 ">
          <doc-tree-view *ngFor="let item of userAllNode?.shareWithMe_treenode_root.childNode" [folderId]="item.node_id" routerPath='/folder/share-with-me'
            folderType='SHARE' [curNode]='item'></doc-tree-view>
        </ul>
      </li>
    </ul>
    <ul *ngIf="currentUser?.type == 'ASUS'  " class="nav-main latelyFile">
      <li>
        <a class="main-title" [routerLink]="[ '/folder/recent-access',{data:dataEncode( {folderId:0})} ]">{{ "Recent Access" | translate}}</a>
      </li>
    </ul>
    <ul *ngIf="currentUser?.type == 'ASUS'  " class="nav-main favFile">
      <li>
        <a class="main-title" (click)="setNavTreeNode(userAllNode?.favorite_treenode_root, $event)" [routerLink]="[ '/folder/favorite-folder',{data:dataEncode( {folderId:0})} ]">{{ "My Favorite" | translate}}
          <i *ngIf="userAllNode?.favorite_treenode_root?.childNode?.length>0 " docToggleTreeNode [refTreeViewComponent]="userAllNode?.shareWithMe_treenode_root"
            class="mdi mdi-menu-right toggle-icon" (click)="getTreeNodes(userAllNode?.favorite_treenode_root,'FAV')"></i>
        </a>
        <ul *ngIf="userAllNode?.favorite_treenode_root?.childNode?.length>0 ">
          <doc-tree-view *ngFor="let item of userAllNode?.favorite_treenode_root.childNode" [folderId]="item.node_id" routerPath='/folder/favorite-folder'
            folderType='FAV' [curNode]='item'></doc-tree-view>
        </ul>
      </li>
    </ul>
  </nav>
</div>
