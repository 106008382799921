import { Component, OnInit, NgZone, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { DocumentDto, RespMessage, DocumentFile, FolderFileSizeVM, DocumentContent2, PermissionContentVo, ResourceType } from './../../core/model/data-model';
import { FolderType, loadingHide, loadingShow, FolderTypeName, showInfo, MessageType, RouteMap, popbox, showAlert, showConfirm } from '../../shared/common';
import { DocumentService, PermissionService, UploadxService, FolderTreeService } from '../../core/service';
import { BaseComponent } from '../../shared/component';

@Component({
  selector: 'doc-archives-add2',
  templateUrl: './archives-add2.component.html',
  styleUrls: ['./archives-add2.component.css']
})
export class ArchivesAdd2Component extends BaseComponent implements OnInit {
  resourceType = ResourceType.file;
  folderId = 0;
  folderType: FolderType;
  folderTypeName = FolderTypeName;
  deptId = '';
  MEGABYTE = 1048576; // Bytes
  documentContent = {} as DocumentContent2;
  documentDto = {} as DocumentDto;
  existedFileNameList: string[] = [];
  strfileList: string[] = [];
  fileList: File[] = [];
  fileSize: FolderFileSizeVM;
  @ViewChild('uploadFiles', { static: false })
  uploadFiles: ElementRef;
  onDrag = false;
  hash = '';
  permissionContent: PermissionContentVo;

  constructor(private uploadService: UploadxService,
    private documentService: DocumentService,
    private folderTreeService: FolderTreeService,
    private router: Router, private route: ActivatedRoute) {
    super();
  }

  ngOnInit() {

    this.route.params.subscribe((params) => {
      if (params['data']) {
        const data = this.dataDecode(params['data']);
        this.folderId = data['folderId'] || 0;
        this.folderType = data['folderType'];
        this.deptId = data['deptId'] || '';
        this.hash = data['hash'] || '';
        if (this.hash.indexOf(';') > 0) {
          this.hash = this.hash.substring(0, this.hash.indexOf(';'));
        }
        console.log('this.hash', this.hash);
        this.folderTypeName = FolderTypeName['' + this.folderType];
        console.log('folderId', this.folderId, 'folderType', this.folderType, 'deptId', this.deptId);
        loadingShow();
        this.loadData()
          .subscribe((arr: RespMessage[]) => {
            loadingHide();
            let resp: RespMessage = arr[0];
            if (resp.state === 'Ok') {
              this.documentContent = resp.result;
              this.documentDto = this.documentContent.document;
              this.permissionContent = this.documentContent.permissionContent;
              // console.log(this.documentContent);
            } else {
              showInfo(resp.message, 2000, MessageType.error);
            }

            // resp = arr[1];
            // if (resp.state === 'Ok') {
            //   this.fileSize = resp.result;
            // } else {
            //   showInfo(resp.message, 2000, MessageType.error);
            // }

            resp = arr[1];
            if (resp.state === 'Ok') {
              this.existedFileNameList = resp.result.documents
                .filter((d: DocumentDto) => d.isArchive === '1')
                .map((d: DocumentDto) => d.documentName);
            } else {
              showInfo(resp.message, 2000, MessageType.error);
            }
          }, error => {
            loadingHide();
            showInfo(error.message || 'error', 3000, MessageType.error);
            console.error('ArchivesAddComponent loadData ::', JSON.stringify(error));
          });
      }
    });
  }

  loadData() {
    return forkJoin([
      this.documentService.getCreateDoc2({ folderId: this.folderId, folderType: this.folderType }),
      // this.folderTreeService.getFolderFileSize({ folderId: this.folderId, folderType: this.folderType, deptId: this.deptId, docId: 0 }),
      this.folderTreeService.getDisplayAllDocs({ folderId: this.folderId, folderType: this.folderType, deptId: this.deptId })
    ]).pipe(
      map((data: any[]) => {
        return data;
      })
    );
  }

  calcuFileLimit() {
    let all = 0; // MB
    if (this.fileList != null && this.fileList.length > 0) {
      this.fileList.map(f => all += f.size);
      // all = all / this.MEGABYTE;
    }
    // const current = this.fileSize.current + all;
    // console.log('this.fileSize', this.fileSize, current);
    // if (this.fileSize != null && this.fileSize.limit > 0) {
    //   if (current > this.fileSize.limit) {
    //     showInfo('超過限制大小無法新增', 2000, MessageType.error);
    //     return false;
    //   }
    // }
    return true;
  }

  removeFile(_file: File) {
    const idx = this.fileList.findIndex(f => f.name === _file.name);
    this.fileList.splice(idx, 1);
  }

  selFiles() {
    const _fileList: FileList = this.uploadFiles.nativeElement.files;
    Array.from(_fileList).map(f => {
      if (this.fileList.findIndex(fo => fo.name === f.name) < 0) {
        this.fileList.push(f);
      }
    }); // .forEach(f => this.fileList.push(f));
    const size = this.calcuFileLimit();
    // console.log('all size', size);
  }

  dropFiles(event) {
    event.preventDefault(); // 防止瀏覽器執行預設動作
    const files: FileList = event.dataTransfer.files; // 擷取拖曳的檔案
    Array.from(files).map(f => {
      if (this.fileList.findIndex(fo => fo.name === f.name) < 0) {
        this.fileList.push(f);
      }
    }); //
    // console.log('this.fileList', this.fileList);
    const size = this.calcuFileLimit();
    console.log('all size', size);
    this.onDrag = false;
  }

  dragHandler(event) {
    this.onDrag = true;
    event.preventDefault(); // 防止瀏覽器執行預設動作
  }

  getBytes(num) {
    return (num / this.MEGABYTE).toFixed(3);
  }

  showPreview() {
    popbox('show-document-preview');
  }
  goBack() {
    const url = RouteMap[this.folderType];
    this.router.navigate([url, { data: this.dataEncode({ folderId: this.folderId, deptId: this.deptId }) }]);
  }

  confirmSave(form: NgForm) {
    if (this.fileList == null || this.fileList.length === 0 || form.invalid) {
      showAlert('請選擇上傳檔案', '請選擇上傳檔案', 'Ok', MessageType.error);
      return;
    }
    if (!this.calcuFileLimit()) {
      return;
    }
    this.save(form);
  }

  save(form: NgForm) {
    const documentFile: DocumentFile[] = this.fileList.map(file => {
      return {
        fileId: 0,
        fileName: file.name,
        fileExtension: file.name.lastIndexOf('.') > 0 ? file.name.substring(file.name.lastIndexOf('.')) : '',
        fileSize: file.size,
        downloadCount: 0,
      };
    }// new DocumentFile
    );
    const _folderType = this.folderType === FolderType.SHARE_WITH_ME ? FolderType.PRIVATE_FOLDER : this.folderType;
    this.documentContent.documentFiles = documentFile;
    this.documentContent.permissionContent = this.permissionContent;
    // console.log('save', this.documentContent);
    loadingShow();
    this.documentService.addArchives2({ folderId: this.folderId, folderType: _folderType, documentContent: this.documentContent })
      .subscribe((resp: RespMessage) => {
        loadingHide();
        if (resp.state === 'Ok') {
          this.documentContent = resp.result;
          this.documentDto = this.documentContent.document;
          this.permissionContent = this.documentContent.permissionContent;
          const documentFiles = this.documentContent.documentFiles || [];
          if (this.fileList != null && this.fileList.length > 0) {
            this.fileList.forEach(file => {
              const fname = file.name;
              const fileDto = documentFiles.find(fo => fo.fileName === fname);
              this.uploadService.handleFile({ ...fileDto, file: file, actionUrl: this.hash });
              if (this.folderType === FolderType.PRIVATE_FOLDER || this.folderType === FolderType.SHARE_WITH_ME) {
                this.folderTreeService.getMyFileSize();
              }
            });
          }
          // console.log('save resp', this.documentContent);
          // showInfo('Success', 2000, MessageType.success);
          this.goBack();
        } else {
          showInfo(resp.message, 3000, MessageType.error);
        }
      }, error => {
        loadingHide();
        showInfo(error.message || 'error', 3000, MessageType.error);
        console.error('documentService.addArchives ::', JSON.stringify(error));
      });

  }


}
