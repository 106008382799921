import { Injectable } from '@angular/core';

declare var window: any;
@Injectable()
export class LoggerService {
  private _console = window['_console'] || window['console'];
  private level: LOGGER_LEVEL;

  constructor() {
  }

  public setLevel(level: LOGGER_LEVEL) {
    this.level = level;
  }

  private writeLog(level, args, funName = 'log') {
    if (this.level === LOGGER_LEVEL.OFF) {
      return;
    }
    if (this.level <= level) {
      if (funName in this._console) {
        this._console[funName](...args);
      } else {
        this._console.log(...args);
      }
      // this._console.log('funName', funName, ...args);
    }
  }

  public error(...args) {
    this.writeLog(LOGGER_LEVEL.ERROR, args, 'error');
  }

  public warn(...args) {
    this.writeLog(LOGGER_LEVEL.WARN, args, 'warn');
  }

  public info(...args) {
    this.writeLog(LOGGER_LEVEL.INFO, args, 'info');
  }

  public debug(...args) {
    this.writeLog(LOGGER_LEVEL.DEBUG, args, 'debug');
  }

  public log(...args) {
    this.writeLog(LOGGER_LEVEL.LOG, args, 'log');
  }

  public dir(...args) {
    this.writeLog(LOGGER_LEVEL.LOG, args, 'dir');
  }

  public table(...args) {
    this.writeLog(LOGGER_LEVEL.LOG, args, 'table');
  }

  public dirxml(...args) {
    this.writeLog(LOGGER_LEVEL.LOG, args, 'dirxml');
  }
}

// Logger 可以被指派等級。能夠指派給 Logger 的等級有 5 種等級的高低順序為 FATAL > ERROR > WARN > INFO > DEBUG。
// Logger 的等級決定它產生 log message 的數量 : Logger 只寫"出高於或等於本身等級"的 log message。
// 例如某個 Logger 的等級被設定為 WARN,那麼它只會寫出等級為 WARN, ERROR, FATAL 的 log message,對於 DEBUG, INFO 的 log message 則不予理會。
export enum LOGGER_LEVEL {
  LOG = 1,
  DEBUG = 2,
  INFO = 3,
  WARN = 4,
  ERROR = 5,
  OFF = 99,
}
