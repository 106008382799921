<header>
  <div class="wrap">
    <div class="hamburger-icon" [ngClass]="{ 'show-nav': isNav }" (click)="toggleNav()"><i></i></div>
    <a class="logo" [routerLink]="[ '/home' ]" title="{{ 'Back to Home' | translate }}">
      <i class="ASUS-logo"> </i>
      <b>Drive</b>
    </a>
    <div class="right-wrap" *ngIf="showUserInfo">
      <!--切換語系-->
      <ul class="lang" title="{{ 'Switch Language' | translate }}">
        <li (click)="changeLang('zh')" [ngClass]="{'focus': uselang === 'zh' }">繁中</li>
        <li (click)="changeLang('en')" [ngClass]="{'focus': uselang === 'en' }">EN</li>
      </ul>
      <!--使用手冊-->
      <a class="item-wrap" href="{{sopUrl}}" title="使用手冊" target="_blank"><i class="mdi mdi-book-open-page-variant"></i>使用手冊</a>
      <a class="item-wrap phone-hide show-pop" href="#" title="App 下載" show-pop="show-AppDownload"><i class="mdi mdi-cellphone"></i>App 下載</a>
      <!--個人相關事項				-->
      <div class=" account-wrap " title="logout">
        <i class="mdi mdi-account-circle "> </i>
        <a href="javascript:void(0)" (click)="logout()" title="{{logonUser?.type==='ASUS'? 'Sign Out' : '' | translate }}">{{this.logonUser?.userName}} ({{this.logonUser?.userEngName}})
          <i class="fa fa-sign-out"></i>
        </a>
      </div>
      <!--通知-->
      <doc-bell></doc-bell> 
    </div>
  </div>
</header>
<div class="popbg-fill show-AppDownload va-center">
  <div class="pop-box">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close"><i class="mdi mdi-close"></i></button>
        <h4>App Download</h4>
      </div>
      <div class="modal-body pad0"><img class="AppDownload-bg" src="assets/img/DownloadAppBg.png" alt="">
        <ul class="AppDownload-wrap">
          <li>
            <div class="os-title">Android APP</div>
            <div class="AppDownload-QRcode"><img src="assets/img/QRcode-Android.jpg">
              <div class="icon-os i mdi mdi-android"></div>
            </div>
          </li>
          <li>
            <div class="os-title">iOS APP</div>
            <div class="AppDownload-QRcode"><img src="assets/img/QRcode-iOS.jpg">
              <div class="icon-os mdi mdi-apple"></div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>