
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { DocumentContent, DocumentDto, RespMessage, DocumentFile } from './../../core/model/data-model';
import { FolderType, loadingHide, loadingShow, FolderTypeName, showInfo, MessageType, RouteMap, dataDecode, showConfirm } from '../../shared/common';
import { DocumentContentViewComponent , PickFavoriteFolderComponent , BaseComponent} from '../../shared/component';
import { DocumentService, FolderService, DownloadService } from '../../core/service';
import { environment } from 'environments/environment';

@Component({
  selector: 'doc-document-read',
  templateUrl: './document-read.component.html',
  styleUrls: ['./document-read.component.css']
})
export class DocumentReadComponent extends BaseComponent implements OnInit , AfterViewInit {


  folderId = 0;
  docId = 0;
  folderType: FolderType;
  folderTypeName = FolderTypeName;
  deptId = '';
  MEGABYTE = 1048576; // Bytes
  documentContent = {} as DocumentContent;
  documentDto = {} as DocumentDto;
  documentFiles: DocumentFile[] = [];
  goBack = '';
  @ViewChild('contentView', { static: false }) contentView: DocumentContentViewComponent;

  constructor(
    private documentService: DocumentService,
    private folderService: FolderService,
    private router: Router, private route: ActivatedRoute,
    private downloadService: DownloadService,
    protected sanitizer: DomSanitizer) {
    super();
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      const data = dataDecode(params['data']);
      if (data['folderType'] != null && data['docId'] != null) {
        this.folderId = data['folderId'] || 0;
        this.folderType = data['folderType'];
        this.deptId = data['deptId'] ||  '';
        this.folderTypeName = FolderTypeName['' + this.folderType];
        this.docId = data['docId'];
        this.goBack = data['back'] || '';
        loadingShow();
        this.documentService.openDoc({ folderId: this.folderId, folderType: this.folderType, docId: this.docId })
          .subscribe((resp: RespMessage) => {
            loadingHide();
            if (resp.state === 'Ok') {
              this.documentContent = resp.result;
              this.documentDto = this.documentContent.document;
              this.documentFiles = this.documentContent.documentFiles;
              this.contentView.documentDto = this.documentDto;
              this.contentView.showContent();
              // console.log(this.documentContent);
            } else {
              showInfo(resp.message, 2000, MessageType.error);
            }
          });
      } else {
        showInfo('參數錯誤', 2000, MessageType.error);
      }
    }, error => {
      loadingHide();
      showInfo(error.message || 'error', 3000, MessageType.error);
      console.error('documentService.openDoc ::', JSON.stringify(error));
    });
  }

  ngAfterViewInit() {
    this.contentView.documentDto = this.documentDto;
    this.contentView.showContent();
  }

  showAddfav(doc: DocumentDto, pickFavoriteFolder: PickFavoriteFolderComponent) {
    if (doc.inFavorite) {
      this.delfav(doc);
    } else {
      pickFavoriteFolder.documentDto = doc;
      pickFavoriteFolder.show();
    }
  }

  delfav(doc: DocumentDto) {
    showConfirm(`移除收藏`, `您確定要刪除${doc.documentName}?`, MessageType.question)
      .then(
        result => {
          // console.log(`showConfirm:${result}`);
          if (result.value) {
            loadingShow();
            this.folderService.deleteFavoriteByDocId({ docId: doc.documentId })
              .subscribe((resp: RespMessage) => {
                loadingHide();
                if (resp.state === 'Ok') {
                  doc.inFavorite = false;
                  showInfo('Success', 2000, MessageType.success);
                } else {
                  showInfo(resp.message, 2000, MessageType.error);
                }
              }, error => {
                loadingHide();
                showInfo(error.message || 'error', 3000, MessageType.error);
                console.log('error', error);
              });
          }
        },
        reject => {
          console.log(`showConfirm:${reject}`);
        }
      );
  }

  downloadFile(item: DocumentFile) {
    let url = `${environment.apiRoot}/api/DownloadFile/Download?auth=${this.currentUserToken}&id=${item.guid}&fileName=${encodeURIComponent(item.fileName)}`;
    if (location.hostname === 'localhost') {
      url = 'http://localhost:56999' + url;
    }
    window.open(url, 'fmDownloadFile');
    // if (item.exists) {
    //   this.downloadService.getFile(url, item.fileName);
    // }
  }

  close() {
    let url = RouteMap[this.folderType];
    if (this.goBack !== '') {
      url = this.goBack;
    }
    this.router.navigate([url, { data: this.dataEncode({ folderId: this.folderId }) }]);
  }


}
