
import { Component, OnInit, Input, EventEmitter, Output, NgZone, OnDestroy } from '@angular/core';
import { UserNode, TreeNode, RespMessage } from '../../../core/model/data-model';
import { FolderTreeService } from '../../../core/service/folder-tree.service';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'doc-pick-tree-node',
  templateUrl: './pick-tree-node.component.html',
  styleUrls: ['./pick-tree-node.component.css']
})
export class PickTreeNodeComponent extends BaseComponent implements OnInit, OnDestroy {

  @Input() folderId = 0;
  @Input() folderType = '';
  @Input() nodeName = '';
  @Input() curNode = {} as TreeNode;
  @Output() onPickTreeNode = new EventEmitter<TreeNode>();
  childrenNode: TreeNode[];
  constructor(private folderTreeService: FolderTreeService, private zone: NgZone) {
    super();
  }

  ngOnInit() {

  }

  getTreeNodes(curNode: TreeNode, folderType) {
    this.folderTreeService.getTreeNodes({ folderId: curNode.node_id, folderType: folderType, curNode: curNode, deptId: curNode.dept_id })
      .subscribe((resp: RespMessage<TreeNode>) => {
        if (resp.state === 'Ok') {
          this.childrenNode = curNode.childNode;
        }
      },
        error => { console.log(error); });
  }

  pickTreeNode(curNode: TreeNode) {
    curNode.folderTyp = this.folderType;
    this.folderTreeService.pickTreeNode = curNode;
    this.folderTreeService.currentPickTreeNodeComponent = this;
    this.onPickTreeNode.emit(curNode);
    // console.log('pickTreeNode', curNode);
  }

  ngOnDestroy() {
    console.log('PickTreeNodeComponent.....ngOnDestroy');
  }

}
