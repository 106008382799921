<doc-breadcrumbs [folderHierarchy]="documentContent?.folderHierarchy" [folderType]="folderType" [folderTypeName]="folderTypeName"></doc-breadcrumbs>
<div class="main">
  <section class="panel panel-default panel-article">
    <div class="tab-panel-boddy">
      <div class="panel-body">
        <div class="detail-download">
          {{ "Download Attachment" | translate }} :
          <ng-container *ngFor="let item of documentFiles">
            <a *ngIf="item.exists" href="javascript:void(0)" (click)="downloadFile(item)">
              <i class="mdi mdi-paperclip"> </i>{{item.fileName}}
              <ng-container *ngIf="!item.exists">({{ "File Not Exist" | translate }})</ng-container>
            </a>
          </ng-container>
        </div>
      </div>
    </div>
  </section>
  <div class="center pad20-b">
    <a class="btn btn-sm btn-dark marg6-r" href="javascript:void(0)" (click)="close()">
      <i class="mdi mdi-reply"></i>{{ "GoBack" | translate }}</a>
  </div>
</div>
