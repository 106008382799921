
import { Component, OnInit, OnDestroy, EventEmitter, Output, Input, NgZone, AfterViewInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FolderTreeService, FolderService } from '../../../core/service';
import { loadingShow, loadingHide, FolderType, popbox, showInfo, MessageType } from '../../common';
import { RespMessage, UserNode, TreeNode, DocumentDto, FolderContent, DocumentFolder } from '../../../core/model/data-model';
import { BaseComponent } from '../base/base.component';
import { TreeViewComponent } from '../tree-view/tree-view.component';

@Component({
  selector: 'doc-pick-favorite-folder',
  templateUrl: './pick-favorite-folder.component.html',
  styleUrls: ['./pick-favorite-folder.component.css']
})
export class PickFavoriteFolderComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() popCss = 'pop-fav';
  @Input() documentDto: DocumentDto;
  userAllNode: UserNode = null;
  @Output() onPickTreeNode = new EventEmitter<TreeNode>();
  @Output() onSelected = new EventEmitter<boolean>();

  folderId = 0;
  folderName = '';
  pickNode = '';
  folderContent = {} as FolderContent;
  documentFolder = {} as DocumentFolder;
  constructor(private folderTreeService: FolderTreeService, private folderService: FolderService,
    private zone: NgZone) {
    super();
  }

  ngOnInit() {

    console.log('PickFavoriteFolderComponent.....ngOnInit');
    this.resetPickTreeNode();
    // prevent loadingShow loadingHide due to query or search documents
    this.folderTreeService.userAllNode$.subscribe(
      userAllNode => {
        this.userAllNode = userAllNode;
      },
      error => {
        console.error('folderTreeService.userAllNode$ ::', JSON.stringify(error));
      }
    );
    this.folderTreeService.currentPickTreeNode$.subscribe((treeNode: TreeNode) => {
      // console.log('pickTreeNode', treeNode);
      const self = this;
      setTimeout(() => {
        self.pickNode = '';
        if (treeNode != null) {
          self.pickNode = 'node_name' in treeNode ? treeNode.node_name : '';
        }
      }, 200);
    });
  }

  ngAfterViewInit() {
    const popCss = this.popCss;
    this.zone.runOutsideAngular(() => {
      // close popbox
      $('.close, .close-btn').on('click', function (e) {
        $(this).parents('.popbg-fill').removeClass('display-block');
        $(this).parents('.pop-box').removeClass('display-block');
        if ($('.popbg-fill').is('.display-block') === false) {
          $('body').removeClass('overflow-hidden');
        }
        e.preventDefault();
      });
      $(popCss).css('"z-index', 99);
      $('.show-pop').click(function (e) {
        popbox($(this).attr('show-pop'));
        e.preventDefault();
      });

      // console.log('init close popbox.....');
    });

  }

  show() {
    this.resetPickTreeNode();
    popbox(this.popCss);
  }

  showNewFolder() {
    if (this.folderTreeService.pickTreeNode != null) {
      this.folderId = this.folderTreeService.pickTreeNode.node_id;
    }
    this.folderService.getCreateFolder({ folderId: this.folderId, folderType: FolderType.FAVORITE_FOLDER })
      .subscribe((resp: RespMessage) => {
        loadingHide();
        if (resp.state === 'Ok') {
          this.folderContent = resp.result;
          this.documentFolder = this.folderContent.folder;
          popbox('show-newfile');
          this.folderName = '';
          // console.log(this.folderContent);
        } else {
          showInfo(resp.message, 2000, MessageType.error);
        }
      }, error => {
        loadingHide();
        showInfo(error.message || 'error', 3000, MessageType.error);
        console.error('this.folderService.getCreateFolder ::', JSON.stringify(error));
      });
  }


  addFavorite() {
    const curNode = this.folderTreeService.pickTreeNode;
    if (curNode != null) {
      this.folderId = curNode.node_id;
    }
    this.folderService.addFavorite({ folderId: this.folderId, folderType: FolderType.FAVORITE_FOLDER, docId: this.documentDto.documentId })
      .subscribe((resp: RespMessage) => {
        loadingHide();
        if (resp.state === 'Ok') {
          this.folderContent = resp.result;
          this.documentFolder = this.folderContent.folder;
          this.documentDto.inFavorite = true;
          // console.log(this.folderContent);
          if (this.folderId === 0) {
            this.folderTreeService.getTreeNodes({ folderId: this.folderId, folderType: FolderType.FAVORITE_FOLDER, curNode: this.userAllNode.favorite_treenode_root })
              .subscribe((_) => { }
                , error => {
                  loadingHide();
                  showInfo(error.message || 'error', 3000, MessageType.error);
                  console.error('folderTreeService.getTreeNodes::', JSON.stringify(error));
                });
          } else {
            if (curNode != null) {
              this.folderTreeService.getTreeNodes({ folderId: this.folderId, folderType: FolderType.FAVORITE_FOLDER, curNode: curNode })
                .subscribe((_) => { }
                  , error => {
                    loadingHide();
                    showInfo(error.message || 'error', 3000, MessageType.error);
                    console.error('folderTreeService.getTreeNodes::', JSON.stringify(error));
                  });
            }
          }
          showInfo('Success', 2000, MessageType.success);
        } else {
          showInfo(resp.message, 2000, MessageType.error);
        }
      }, error => {
        loadingHide();
        showInfo(error.message || 'error', 3000, MessageType.error);
        console.error('folderService.addFavorite::', JSON.stringify(error));
      });
  }


  newFolder(form: NgForm) {
    if (!form.invalid) {
      const curNode = this.folderTreeService.pickTreeNode;
      if (curNode != null) {
        this.folderId = curNode.node_id;
      }
      this.documentFolder.folderName = this.folderName;
      this.folderService.add({ folderId: this.folderId, folderType: FolderType.FAVORITE_FOLDER, folderContent: this.folderContent })
        .subscribe((resp: RespMessage) => {
          loadingHide();
          if (resp.state === 'Ok') {
            this.folderContent = resp.result;
            this.documentFolder = this.folderContent.folder;
            if (this.folderId === 0) {
              this.folderTreeService.getTreeNodes({ folderId: this.folderId, folderType: FolderType.FAVORITE_FOLDER, curNode: this.userAllNode.favorite_treenode_root })
                .subscribe((_) => { }
                  , error => {
                    loadingHide();
                    showInfo(error.message || 'error', 3000, MessageType.error);
                    console.error('folderTreeService.getTreeNodes::', JSON.stringify(error));
                  });
            } else {
              if (curNode != null) {
                this.folderTreeService.getTreeNodes({ folderId: this.folderId, folderType: FolderType.FAVORITE_FOLDER, curNode: curNode })
                  .subscribe((_) => {
                    const currentPickTreeNodeComponent = this.folderTreeService.currentPickTreeNodeComponent;
                    if (currentPickTreeNodeComponent != null) {
                      currentPickTreeNodeComponent.childrenNode = [...curNode.childNode];
                      const openNode = this.folderTreeService.openTreeViewComponents.find(com => (com instanceof TreeViewComponent) && com.curNode.node_id === curNode.node_id);
                      if (openNode != null) {
                        openNode.curNode = curNode;
                        openNode.childrenNode = [...curNode.childNode];
                      }
                      this.folderTreeService.currentPickTreeNodeComponent = null;
                    }
                  }
                    , error => {
                      loadingHide();
                      showInfo(error.message || 'error', 3000, MessageType.error);
                      console.error('folderTreeService.getTreeNodes::', JSON.stringify(error));
                    });
              }
            }
            showInfo('Success', 2000, MessageType.success);
          } else {
            showInfo(resp.message, 2000, MessageType.error);
          }
        }, error => {
          loadingHide();
          showInfo(error.message || 'error', 3000, MessageType.error);
          console.error('this.folderService.add ::', JSON.stringify(error));
        });
    }
  }

  resetPickTreeNode() {
    // console.log('this.pickNode ', this.pickNode);
    // this.pickNode = '';
    this.folderTreeService.pickTreeNode = null;
  }

  action(selected: boolean) {
    if (selected && this.folderTreeService.pickTreeNode == null) {

    }
    this.onSelected.emit(selected);
  }

  pickTreeNode(curNode: TreeNode, folderType) {
    this.folderId = curNode.node_id;
    curNode.folderTyp = folderType;
    this.folderTreeService.pickTreeNode = curNode;
    this.onPickTreeNode.emit(curNode);
    // console.log('PickFavoriteFolderComponent:pickTreeNode', curNode);
  }

  getTreeNodes(curNode: TreeNode, folderType) {
    this.folderTreeService.getTreeNodes({ folderId: curNode.node_id, folderType: folderType, curNode: curNode })
      .subscribe((_) => { }
        , error => {
          console.error('this.folderTreeService.getTreeNodes ::', JSON.stringify(error));
        });
  }

  ngOnDestroy() {
    console.log('PickFavoriteFolderComponent.....ngOnDestroy');
  }

}
