import { Injectable, ReflectiveInjector } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { JwtAuthObject } from './../model/data-model';
import { LoggerService, LOGGER_LEVEL } from './logger.service';
import { environment } from '../../../environments/environment';
@Injectable()
export class BaseService {

  constructor() {
  }

  protected handleHttpError(err: HttpErrorResponse | any) {
    // here we can show an error message to the user,
    // for example via a service
    console.error('handleHttpError ::', JSON.stringify(err));
    let errMsg = '';
    if (err instanceof HttpErrorResponse) {
      if (err.error instanceof Error) {
        // A client-side or network error occurred. Handle it accordingly.
        console.log('An error occurred:', err.error.message);
        errMsg = 'An error occurred:' + err.error.message;
      } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        console.log(`Backend returned code ${err.status}, body was: ${err.error}`);
        errMsg = `Backend returned code ${err.status}, body was: ${err.error}`;
      }
    }
    return throwError(errMsg);
  }

}
