import { TreeNode } from './../../core/model/data-model';
import { FolderTypeName, showConfirm, RouteMap, popbox } from './../../shared/common';
import { Component, OnInit, OnDestroy, NgZone, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FolderTreeService } from '../../core/service/folder-tree.service';
import { showInfo, loadingHide, MessageType, loadingShow, FolderType } from '../../shared/common';
import { RespMessage, FolderContent, DocumentFolder, DocumentDto, DataRow } from '../../core/model/data-model';
import { BaseComponent } from '../../shared/component/base/base.component';
import { DocumentService } from '../../core/service/document.service';
import { PickFavoriteFolderComponent } from '../../shared/component/pick-favorite-folder/pick-favorite-folder.component';
import { FolderService } from '../../core/service/folder.service';
import { BaseFolderComponent } from '../base-folder/base-folder.component';
import { FolderCanWriteTreeService } from '../../core/service/folder-can-write-tree.service';
import { BreadcrumbsComponent } from '../../shared/component/breadcrumbs/breadcrumbs.component';
import { DocumentContextmenuService } from '../../core/service/document-contextmenu.service';
import { DownloadService } from 'app/core/service';
import { FolderDocumentService } from 'app/core/service/folder-document.service';
import { MatomoTracker  } from 'ngx-matomo';
import { AuthTokenService } from 'app/core/service/auth-token.service';

@Component({
  selector: 'doc-recent-access',
  templateUrl: './recent-access.component.html',
  styleUrls: ['./recent-access.component.css']
})
export class RecentAccessComponent extends BaseFolderComponent implements OnInit, OnDestroy {

  folderName = '';
  childFolders: DocumentFolder[];
  documents: DocumentDto[];
  @ViewChild(BreadcrumbsComponent, { static: false }) breadcrumbsComponent: BreadcrumbsComponent;

  constructor(private matomoTracker: MatomoTracker,protected folderTreeService: FolderTreeService, protected folderCanWriteTreeService: FolderCanWriteTreeService
    , protected documentService: DocumentService, protected folderService: FolderService
    , protected folderDocumentService: FolderDocumentService, protected downloadService: DownloadService
    , protected contextmenuService: DocumentContextmenuService
    , protected router: Router, protected route: ActivatedRoute, protected zone: NgZone
    , private authTokenService: AuthTokenService) {
    super(folderTreeService, folderCanWriteTreeService, folderService, documentService, folderDocumentService, downloadService, contextmenuService, router);
    this.folderId = 0;
    this.folderType = FolderType.RECENT_ACCESS;
    this.folderTypeName = FolderTypeName.RECENT;
  }

  ngOnInit() {
    this.authTokenService.currentUser$
    .subscribe(user => {
      if (user) {
        this.matomoTracker.setUserId(user.userName);
        this.matomoTracker.trackPageView('page_recent');
        this.matomoTracker.setCustomVariable(1, "WorkId", user.workId, "page")
      }
    });
    this.route.params.subscribe((params) => {
      const data = this.dataDecode(params['data']);
      if ('folderId' in data) {
        this.folderId = data['folderId'];
        this.resultList = [];
        this.sortByCols = {
          'typeCss': '',
          'name': '',
          'modifyTime': '',
          'readCount': '',
        };
        this.sortBy = '';
        this.sortType = '';
        this.query();
      }
    });
  }

  updatePageList() { // override BaseFolderComponent  近期存取沒有分頁所以也複寫
  }

  delete(data: DataRow) {
    // 資料刪除後將無法喚回
    // 您確定要刪除"XXXXXX"資料夾?
    showConfirm(`資料刪除後將無法喚回`, `您確定要刪除${data.name}?`, MessageType.question)
      .then(
        result => {
          // console.log(`showConfirm:${result}`);
          if (result.value) {
            loadingShow();
            if (data.type === 'folder') {
              const folder = data.data as DocumentFolder;
              // this.folderTreeService.delete(data.data);
            } else {
              const doc = data.data as DocumentDto;
              this.documentService.delete({ folderId: this.folderId, folderType: this.folderType, docId: doc.documentId })
                .subscribe((resp: RespMessage) => {
                  loadingHide();
                  if (resp.state === 'Ok') {
                    this.resultList = this.resultList.filter( r => !(r.data === doc));
                    this.showList();
                    showInfo('Success', 2000, MessageType.success);
                  } else {
                    showInfo(resp.message, 2000, MessageType.error);
                  }
                });
            }
          }
        },
        reject => {
          // console.log(`showConfirm:${reject}`);
        }
      );
  }

  ngOnDestroy() {
    console.log('PrivateFolderComponent.....ngOnDestroy');
  }
}
