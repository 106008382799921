import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders , HttpResponse , HttpParams } from '@angular/common/http';
import { BaseService } from './base.service';
import { RespMessage, NewestDocumentDto } from '../model/data-model';
import { environment } from 'environments/environment';


@Injectable()
export class ListNewestDocumentService  extends BaseService {

  constructor(private http: HttpClient) {
    super();
  }

  listNewestDocument() {
      return this.http.post<RespMessage<NewestDocumentDto>>(`${environment.apiRoot}/api/listNewestDocument`, { folderId: 0 });
  }

}
