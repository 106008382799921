import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { RespMessage, NotificationDto } from '../model/data-model';
import { environment } from 'environments/environment';

@Injectable()
export class NotificationService extends BaseService {

  constructor(private http: HttpClient) {
    super();
  }

  read(notifIds: number[]) {
    return this.http.post<RespMessage<boolean>>(`${environment.apiRoot}/api/Notification/Read`, notifIds);
  }

  queryUnread(vo: { dateTime?: Date }) {
    return this.http.post<RespMessage<NotificationDto[]>>(`${environment.apiRoot}/api/Notification/QueryUnread`, vo);
  }

}
