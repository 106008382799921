<div class="popbg-fill va-center " [ngClass]="{'show-nav-tree':pickCss == 'show-nav-tree','show-share':pickCss ==='show-share'}">
  <div class="pop-box pop-size-450">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close">
          <i class="mdi mdi-close"></i>
        </button>
        <h4>{{ pickCss == 'show-nav-tree' ? 'Select folder': 'Copy to the following folder' | translate }}</h4>
      </div>
      <div class="modal-body">
        <div class="tree-wrap">
          <nav class="tree">
            <ul *ngIf="currentUser?.type == 'ASUS'  " class="nav-main myFile">
              <li>
                <a class="main-title" href="javascript:void(0)" (click)="pickTreeNode(userAllNode?.private_treenode_root,'PRI')">{{ "Private Folder" | translate}}
                  <i *ngIf="userAllNode?.private_treenode_root?.childNode?.length>0 " docToggleTreeNode class="mdi mdi-menu-right toggle-icon"
                    (click)="getTreeNodes(userAllNode?.private_treenode_root,'PRI')"></i>
                </a>
                <ul *ngIf="userAllNode?.private_treenode_root?.childNode?.length>0 ">
                  <doc-pick-tree-node *ngFor="let item of userAllNode?.private_treenode_root?.childNode" [folderId]="item.node_id" folderType='PRI'
                    [curNode]='item'></doc-pick-tree-node>
                </ul>
              </li>
            </ul>
            <ul class="nav-main systemFile">
              <li>
                <a class="main-title" href="javascript:void(0)" (click)="pickTreeNode(userAllNode?.system_treenode_root,'SYS')">{{ "System Folder" | translate}}
                  <i *ngIf="userAllNode?.system_treenode_root?.childNode?.length>0 " docToggleTreeNode class="mdi mdi-menu-right toggle-icon"
                    (click)="getTreeNodes(userAllNode?.system_treenode_root?.childNode,'SYS')"></i>
                </a>
                <ul *ngIf="userAllNode?.system_treenode_root?.childNode?.length>0 ">
                  <doc-pick-tree-node *ngFor="let item of userAllNode?.system_treenode_root?.childNode" [folderId]="item.node_id" folderType='SYS'
                    [curNode]='item'></doc-pick-tree-node>
                </ul>
              </li>
            </ul>
            <ul *ngIf="currentUser?.type == 'ASUS'  " class="nav-main publicFile">
              <li>
                <a class="main-title" href="javascript:void(0)" (click)="pickTreeNode(userAllNode?.public_treenode_root,'PUB')">{{ "Public Folder" | translate}}
                  <i *ngIf="userAllNode?.public_treenode_root?.childNode?.length>0 " docToggleTreeNode class="mdi mdi-menu-right toggle-icon"
                    (click)="getTreeNodes(userAllNode?.public_treenode_root,'PUB')"></i>
                </a>
                <ul *ngIf="userAllNode?.public_treenode_root?.childNode?.length>0 ">
                  <doc-pick-tree-node *ngFor="let item of userAllNode?.public_treenode_root?.childNode" [folderId]="item.node_id" folderType='PUB'
                    [curNode]='item'></doc-pick-tree-node>
                </ul>
              </li>
            </ul>
            <ul *ngIf="currentUser?.type == 'ASUS'  " class="nav-main buFile">
              <li>
                <a class="main-title" href="javascript:void(0)" (click)="pickTreeNode(userAllNode?.dept_treenode_root,'DEPT')">{{ "Department Folder" | translate}}
                  <i *ngIf="userAllNode?.dept_treenode_root?.childNode?.length>0 " docToggleTreeNode class="mdi mdi-menu-right toggle-icon"
                    (click)="getTreeNodes(userAllNode?.dept_treenode_root,'DEPT')"></i>

                </a>
                <ul *ngIf="userAllNode?.dept_treenode_root?.childNode?.length>0 ">
                  <doc-pick-tree-node *ngFor="let item of userAllNode?.dept_treenode_root?.childNode" [folderId]="item.node_id" folderType='DEPT'
                    [curNode]='item'></doc-pick-tree-node>
                </ul>
              </li>
            </ul>
            <ul *ngIf="currentUser?.type == 'ASUS'  " class="nav-main shareFile">
              <li>
                <a class="main-title" href="javascript:void(0)" (click)="pickTreeNode(userAllNode?.shareWithMe_treenode_root,'SHARE')">{{ "Share With Me" | translate}}
                  <i *ngIf="userAllNode?.shareWithMe_treenode_root?.childNode?.length>0 " docToggleTreeNode class="mdi mdi-menu-right toggle-icon"
                    (click)="getTreeNodes(userAllNode?.shareWithMe_treenode_root,'SHARE')"></i>
                </a>
                <ul *ngIf="userAllNode?.shareWithMe_treenode_root?.childNode?.length>0 ">
                  <doc-pick-tree-node *ngFor="let item of userAllNode?.shareWithMe_treenode_root?.childNode" [folderId]="item.node_id" folderType='SHARE'
                    [curNode]='item'></doc-pick-tree-node>
                </ul>
              </li>
            </ul>
            <ul *ngIf="currentUser?.type == 'ASUS'  && pickType=='Query'  " class="nav-main latelyFile">
              <li>
                <a class="main-title" href="javascript:void(0)" (click)="pickTreeNode(userAllNode,'RECENT')">{{ "Recent Access" | translate}}</a>
              </li>
            </ul>
            <ul *ngIf="currentUser?.type == 'ASUS' && pickType=='Query'  " class="nav-main favFile">
              <li>
                <a class="main-title" href="javascript:void(0)" (click)="pickTreeNode(userAllNode?.favorite_treenode_root,'FAV')">{{ "My Favorite" | translate}}
                  <i *ngIf="userAllNode?.favorite_treenode_root?.childNode?.length>0 " docToggleTreeNode class="mdi mdi-menu-right toggle-icon"
                    (click)="getTreeNodes(userAllNode?.favorite_treenode_root,'FAV')"></i>

                </a>
                <ul *ngIf="userAllNode?.favorite_treenode_root?.childNode?.length>0 ">
                  <doc-pick-tree-node *ngFor="let item of userAllNode?.favorite_treenode_root?.childNode" [folderId]="item.node_id" folderType='FAV'
                    [curNode]='item'></doc-pick-tree-node>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div class="modal-footer center">
          {{pickNode}}
          <br><br>
          <a class="btn btn-sm btn-dark close-btn marg6-r" (click)="action(false);">
            <i class="mdi mdi-reply"></i>{{ "Cancel" | translate }}</a>
          <a class="btn btn-sm btn-primary close-btn" (click)="action(true);">
            <i class="mdi mdi-check"></i>{{ "Select" | translate }}</a>
      </div>
    </div>
  </div>
</div>
