import { FolderTypeName, showConfirm, RouteMap, popbox } from './../../shared/common';
import { Component, OnInit, OnDestroy, NgZone, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FolderTreeService } from '../../core/service/folder-tree.service';
import { showInfo, loadingHide, MessageType, loadingShow, FolderType } from '../../shared/common';
import { RespMessage, FolderContent, DocumentFolder, DocumentDto, DataRow, FavoriteDocument } from '../../core/model/data-model';
import { DocumentService } from '../../core/service/document.service';
import { FolderService } from '../../core/service/folder.service';

import { Observable } from 'rxjs';
import { BaseFolderComponent } from '../base-folder/base-folder.component';
import { FolderCanWriteTreeService } from '../../core/service/folder-can-write-tree.service';
import { BreadcrumbsComponent } from '../../shared/component/breadcrumbs/breadcrumbs.component';
import { DocumentContextmenuService } from '../../core/service/document-contextmenu.service';
import { DownloadService } from 'app/core/service';
import { FolderDocumentService } from 'app/core/service/folder-document.service';
import { MatomoTracker  } from 'ngx-matomo';
import { AuthTokenService } from 'app/core/service/auth-token.service';

@Component({
  selector: 'doc-favorite-folder',
  templateUrl: './favorite-folder.component.html',
  styleUrls: ['./favorite-folder.component.css']
})
export class FavoriteFolderComponent extends BaseFolderComponent implements OnInit, OnDestroy {

  childFolders: DocumentFolder[];
  documents: DocumentDto[];

  constructor(private matomoTracker: MatomoTracker, protected folderTreeService: FolderTreeService, protected folderService: FolderService
    , protected documentService: DocumentService, protected folderCanWriteTreeService: FolderCanWriteTreeService
    , protected folderDocumentService: FolderDocumentService, protected downloadService: DownloadService
    , protected contextmenuService: DocumentContextmenuService
    , protected router: Router, protected route: ActivatedRoute, protected zone: NgZone
    , private authTokenService: AuthTokenService) {
    super(folderTreeService, folderCanWriteTreeService, folderService, documentService, folderDocumentService, downloadService, contextmenuService, router);
    this.folderId = 0;
    this.folderType = FolderType.FAVORITE_FOLDER;
    this.folderTypeName = FolderTypeName.FAV;

  }

  ngOnInit() {
    this.authTokenService.currentUser$
      .subscribe(user => {
        if (user) {
          this.matomoTracker.setUserId(user.userName);
          this.matomoTracker.trackPageView('page_favorite');
          this.matomoTracker.setCustomVariable(1, "WorkId", user.workId, "page")
        }
      });
    this.route.params.subscribe((params) => {
      const data = this.dataDecode(params['data']);
      if ('folderId' in data) {
        this.folderId = data['folderId'];
        this.resultList = [];
        this.sortByCols = {
          'typeCss': '',
          'name': '',
          'modifyTime': '',
          'readCount': '',
        };
        this.sortBy = '';
        this.sortType = '';
        this.query();
      }
    });
  }

  query(sortBy = '', sortType = '', pageSize = 100, pageNum = 1) {  // override BaseFolderComponent
    loadingShow();
    this.folderTreeService.getDisplayFolder({ folderId: this.folderId, folderType: this.folderType }, sortBy, sortType, pageSize, pageNum)
          .subscribe(re => {
            loadingHide();
            const resp: RespMessage = re;
            if (resp.state === 'Ok') {
              this.dataCount = resp.dataCount;
              this.updatePageList();
              this.resultList = [];
              this.folderContent = resp.result;
              if (this.folderContent.folder == null) {
                this.folderName = this.folderTypeName;
              } else {
                this.folderName = this.folderContent.folder.folderName;
              }
              this.folderContent.childFolders.map(
                fol => this.resultList.push(this.getFolderDataRow(fol))
              );
              this.folderContent.favoriteDocuments.map(
                favdoc => this.resultList.push(this.getFavDocDataRow(favdoc))
              );
              this.resultList.forEach(row => row.typeCss = this.getTypeCss(row.data));
              this.pageList = this.resultList;
              this.breadcrumbsComponent.updateBreadcrumbsInfo({ folderId: this.folderId, folderType: this.folderType, folderTypeName: this.folderTypeName, folderHierarchy: this.folderContent.folderHierarchy });
              // console.log('getDisplayFolder', this.folderContent);
            } else {
              showInfo(resp.message || 'warning', 3000, MessageType.warning);
            }
          }, error => {
            loadingHide();
            showInfo(error.message || 'error', 3000, MessageType.error);
            console.log('error', error);
          });
  }


  delfav(data: DataRow) {
    const doc = data.data as DocumentDto;
    let favoriteId = 0;
    if (doc.favoriteDocuments != null && doc.favoriteDocuments.length > 0) {
      favoriteId = doc.favoriteDocuments[0].favoriteId;
    }
    if (favoriteId > 0) {
      showConfirm(`移除收藏`, `您確定要刪除${doc.documentName}?`, MessageType.question)
        .then(
          result => {
            // console.log(`showConfirm:${result}`);
            if (result.value) {
              loadingShow();
              this.folderService.deleteFavorite({ folderId: this.folderId, folderType: this.folderType, favId: favoriteId })
                .subscribe((resp: RespMessage) => {
                  loadingHide();
                  if (resp.state === 'Ok') {
                    this.resultList = this.resultList.filter(r => !(r.data === doc));
                    this.showList();
                    showInfo('Success', 2000, MessageType.success);
                  } else {
                    showInfo(resp.message, 2000, MessageType.error);
                  }
                });
            }
          },
          reject => {
            console.log(`showConfirm:${reject}`);
          }
        );
    }
  }

  delete(data: DataRow) {
    // 資料刪除後將無法喚回
    // 您確定要刪除"XXXXXX"資料夾?
    showConfirm(`資料刪除後將無法喚回`, `您確定要刪除${data.name}?`, MessageType.question)
      .then(
        result => {
          // console.log(`showConfirm:${result}`);
          if (result.value) {
            if (data.type === 'folder') {
              const folder = data.data as DocumentFolder;
              this.folderService.delete({ folderId: folder.folderId, folderType: this.folderType })
                .subscribe((resp: RespMessage) => {
                  loadingHide();
                  if (resp.state === 'Ok') {
                    this.resultList = this.resultList.filter(r => r !== data);
                    this.dataCount--;
                    this.updatePageList();
                    this.showList();
                    this.updateTreeView();
                    showInfo('Success', 2000, MessageType.success);
                  } else {
                    showInfo(resp.message, 2000, MessageType.error);
                  }
                }, error => {
                  loadingHide();
                  showInfo(error.message || 'error', 3000, MessageType.error);
                  console.error('folderService.delete::', JSON.stringify(error));
                });
            } else {
              loadingShow();
              const doc = data.data as DocumentDto;
              let favoriteId = 0;
              if (doc.favoriteDocuments != null && doc.favoriteDocuments.length > 0) {
                favoriteId = doc.favoriteDocuments[0].favoriteId;
              }
              if (favoriteId > 0) {
                this.documentService.delete({ folderId: this.folderId, folderType: this.folderType, docId: doc.documentId })
                  .subscribe((resp: RespMessage) => {
                    // this.folderService.moveFavorite({ folderId: folder.folderId, folderType: this.folderType, docId: doc.documentId });
                    loadingHide();
                    if (resp.state === 'Ok') {
                      this.resultList = this.resultList.filter(r => r !== data);
                      this.dataCount--;
                      this.updatePageList();
                      this.showList();
                      showInfo('Success', 2000, MessageType.success);
                    } else {
                      showInfo(resp.message, 2000, MessageType.error);
                    }
                  }, error => {
                    loadingHide();
                    showInfo(error.message || 'error', 3000, MessageType.error);
                    console.error('documentService.delete::', JSON.stringify(error));
                  });
              }
            }
          }
        },
        reject => {
          // console.log(`showConfirm:${reject}`);
        }
      );
  }

  protected getFavDocDataRow(favdoc: FavoriteDocument): DataRow {
    return {
      type: 'doc', folderType: 'FAV',
      name: favdoc.document.documentName,
      user: favdoc.document.createUser,
      modifyTime: favdoc.document.modifyTime,
      readCount: favdoc.document.readCount,
      data: { ...favdoc.document, favoriteDocuments: [favdoc] }
    };
  }

  ngOnDestroy() {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    console.log('PrivateFolderComponent.....ngOnDestroy');
  }

}
