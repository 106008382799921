import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders , HttpResponse , HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map  } from 'rxjs/operators';
import { BaseService } from './base.service';
import { RespMessage } from '../model/data-model';
import { environment } from 'environments/environment';

@Injectable()
export class ClientLogService extends BaseService {

  constructor(private http: HttpClient) {
    super();
  }

  infoLog(msg) {
    // [HttpPost]
    // [Route("api/ClientLog/InfoLog")]
      return this.http.post<RespMessage>(`${environment.apiRoot}/api/ClientLog/InfoLog`, { Message: msg });
  }


  errorLog(msg) {
    //  [HttpPost]
    //     [Route("api/ClientLog/ErrorLog")]
    return this.http.post<RespMessage>(`${environment.apiRoot}/api/ClientLog/ErrorLog`, { Message: msg });
  }

}
