import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { BaseService } from './base.service';
import { RespMessage, JwtAuthObject, User } from './../model/data-model';
import { distinctUntilChanged } from 'rxjs/operators';
import { loadingHide, showInfo, MessageType, newGuid } from '../../shared/common';
import { SwUpdateService } from './sw-update.service';
import { environment } from '../../../environments/environment';
import { EnvService } from './env.service';

@Injectable()
export class UiService extends BaseService {
  // rwd navigator
  private readonly isNavSubject = new BehaviorSubject<boolean>(false);
  readonly isNav$ = this.isNavSubject.asObservable().pipe(distinctUntilChanged());

  toggleNav() {
    this.isNavSubject.next(!this.isNavSubject.value);
  }
  openNav() {
    this.isNavSubject.next(true);
  }
  closeNav() {
    this.isNavSubject.next(false);
  }
}