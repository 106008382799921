<div class="main">
  <doc-breadcrumbs [folderHierarchy]="documentContent?.folderHierarchy" [folderType]="folderType" [folderTypeName]="folderTypeName"></doc-breadcrumbs>
  <form #form="ngForm" (keydown.enter)="false">
    <section class="panel panel-default panel-article">
      <div class="panel-heading">
        <h3 class="panel-title">
          <i class="circle mdi mdi-database-plus"></i>{{ "Create File" | translate }}</h3>
        <div>
          <a class="btn btn-dark marg6-r" href="javascript:void(0)" (click)="goBack()">
            <i class="mdi mdi-reply"></i>{{ "Cancel" | translate }}</a>
          <a class="btn btn-primary" href="javascript:void(0)" (click)="confirmSave(form)">
            <i class="mdi mdi-content-save"></i>{{ "Save" | translate }}</a>
        </div>
      </div>
      <div class="panel-body">
        <ul class="upolad-content " [ngClass]="{'drag':onDrag}" (dragover)="dragHandler($event);" (drop)="dropFiles($event)">
          <li>
            <p></p>
            <div class="input-file-btn">
              <input type="file" #uploadFiles multiple name="uploadFiles" (change)="selFiles()">
              <a class="btn btn-xs btn-primary" href="#"><i class="mdi mdi-format-vertical-align-top"></i>{{ "UploadFiles" | translate }} </a>
            </div>
          </li>
          <li>
            <p class="gray">{{ "Save Then Upload" | translate }} Drag and drop files here!</p>
          </li>
          <li *ngFor="let item of fileList">
            <p>{{item.name}}
              <span *ngIf="existedFileNameList.indexOf(item.name) !== -1" class="red">{{ "The same file name already existed" | translate }}</span>
            </p>
            <div class="upolad-content-r">
              <em>{{ getBytes(item.size) }}MB</em>
              <div class="status waiting">
                <i class="mdi mdi-minus-circle"></i>
                <p>{{ "Save then Upload" | translate }}</p>
                <a class="mdi mdi-delete btn" (click)="removeFile(item) "></a>
              </div>
            </div>
          </li>
          <li class="empty">
            <img class="upoladimg" src="assets/img/upload.svg">
            <h3>{{ "Drag and Drop File" | translate }}</h3>
            <p class="gray center">{{ "Not close window when uploading file" | translate }}</p>
          </li>
        </ul>
      </div>
    </section>
    <doc-permission-setting [folderType]="folderType" [folderId]="documentContent?.folderId" [documentId]="documentContent?.docId"
      [resourceType]="resourceType" [needCascade]="false" [needReadAll]="false" [permissionContent]="permissionContent" >
    </doc-permission-setting>
  </form>
  <div class="center pad20-b">
    <a class="btn btn-sm btn-dark marg6-r" href="javascript:void(0)" (click)="goBack()">
      <i class="mdi mdi-reply"></i>{{ "Cancel" | translate }}</a>
    <a class="btn btn-sm btn-primary" href="javascript:void(0)" (click)="confirmSave(form)">
      <i class="mdi mdi-content-save"></i>{{ "Save" | translate }}</a>
  </div>
</div>