import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';

import {
  UploadxOptions,
  UploadState,
  UploadxControlEvent,
  UploaderOptions
} from '../../uploadx/interfaces';
import { Uploader } from './uploader';
import { distinctUntilChanged } from 'rxjs/operators';
import { DocumentFile } from '../model/data-model';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

/**
 *
 * @class UploadxService
 */
@Injectable()
export class UploadxService {
  subj: Subject<UploadState> = new Subject();
  private queue: Uploader[] = [];
  private concurrency = 2;
  private autoUpload = true;
  private options: UploadxOptions;
  constructor(private http: HttpClient) { }
  get uploadWorkCount() {
    // return this.queue.filter((uploader: Uploader) => uploader.status !== 'complete').length;
    return this.queue.length;
  }

  get uploaderOptions(): UploaderOptions {
    return {
      method: this.options.method || 'POST',
      url: this.options.url || '/upload/',
      headers: this.options.headers,
      token: this.options.token,
      chunkSize: this.options.chunkSize || 0,
      withCredentials: this.options.withCredentials || false,
      subj: this.subj,
      nextFile: () => this.processQueue()
    };
  }
  /**
   * Set global module options
   */
  init(options: UploadxOptions): Observable<UploadState> {
    this.options = options;
    this.concurrency = options.concurrency || this.concurrency;
    this.autoUpload = options.autoUpload || false;
    return this.subj.asObservable();
  }
  /**
   *
   * Create Uploader and add to the queue
   */
  handleFileList(fileList: FileList) {
    for (let i = 0; i < fileList.length; i++) {
      const uploader: Uploader = new Uploader(
        fileList.item(i),
        this.uploaderOptions
      );
      this.queue.push(uploader);
    }
    if (this.autoUpload) {
      this.queue.forEach(upload => {
        upload.configure();
      });
      this.processQueue();
    }
  }

  /**
   *
   * Create Uploader and add to the queue
   */
  handleFile(fileDto: DocumentFile) {
    const uploader: Uploader = new Uploader(
      fileDto.file,
      this.uploaderOptions
    );
    uploader.actionUrl = fileDto.actionUrl;
    uploader.uploadGuid = fileDto.guid;
    // uploader.metadata = { fileGuid: fileDto.guid };
    this.queue.push(uploader);
    if (this.autoUpload) {
      this.queue.forEach(upload => {
        upload.configure();
      });
      this.processQueue();
    }
  }
  /**
   * Control upload status
   * @example
   * this.uploadService.control({ action: 'pauseAll' });
   *
   */
  control(event: UploadxControlEvent) {
    switch (event.action) {
      case 'cancelAll':
        this.queue
          .filter(f => f.status !== 'complete')
          .map(f => (f.status = 'cancelled'));
        break;
      case 'pauseAll':
        this.queue
          .filter(f => f.status !== 'complete')
          .map(f => (f.status = 'paused'));
        break;
      case 'uploadAll':
        this.queue
          .filter(f => f.status !== 'complete' && f.status !== 'uploading')
          .map(f => (f.status = 'queue'));
        this.processQueue();
        break;
      case 'upload':
        const uploadId = event.uploadId || event.itemOptions.uploadId;
        const target = this.queue.find(f => f.uploadId === uploadId);
        target.configure(event.itemOptions);
        this.processQueue();
        break;
      case 'cancel':
        this.queue.find(f => f.uploadId === event.uploadId).status = 'cancelled';
        this.processQueue();
        break;
      case 'pause':
        this.queue.find(f => f.uploadId === event.uploadId).status = 'paused';
        break;
      default:
        break;
    }
  }

  /**
   * Queue management
   */
  private processQueue() {
    const running = this.queue.filter(
      (uploader: Uploader) => uploader.status === 'uploading'
    );

    const complete = this.queue.findIndex(
      (uploader: Uploader) => uploader.status === 'complete'
    );

    if (complete !== -1) {
      const fcomplete = this.queue[complete];
      this.http.post(`${environment.apiRoot}/api/UploadFile/UpdateStatus?fileGuid=${fcomplete.uploadGuid}&status=complete`,
        { fileGuid: fcomplete.uploadGuid, status: 'complete' })
        .subscribe(
          msg => {
            let hash = location.hash;
            if (hash.indexOf(';up=') > 0) {
              hash = hash.substring(0, hash.indexOf(';up='));
            }
            if (hash.indexOf(fcomplete.actionUrl) >= 0) {
              location.hash = `${hash};up=${new Date().getTime()}`;
            }
            console.log(`hash`, hash, 'fcomplete.actionUrl', fcomplete.actionUrl, hash.indexOf(fcomplete.actionUrl));
            console.log(`${environment.apiRoot}/api/UploadFile/UpdateStatus msg`, msg);
          },
          error => console.error('/api/UploadFile/UpdateStatus error ::', JSON.stringify(error))
        ); //// .unsubscribe();
      this.queue.splice(complete, 1);
    }

    const cancelled = this.queue.findIndex(
      (uploader: Uploader) => uploader.status === 'cancelled'
    );

    this.queue
      .filter(f => f.status === 'cancelled')
      .map(fcancelled => {
        this.http.post(`${environment.apiRoot}/api/UploadFile/UpdateStatus?fileGuid=${fcancelled.uploadGuid}&status=cancelled`,
          { fileGuid: fcancelled.uploadGuid, status: 'cancelled' })
          .subscribe(
            msg => console.log(`${environment.apiRoot}/api/UploadFile/UpdateStatus msg`, msg),
            error => console.error('/api/UploadFile/UpdateStatus error ::', JSON.stringify(error))
          ); //// .unsubscribe();
      });
    this.queue = this.queue.filter(f => f.status !== 'cancelled');

    this.queue
      .filter((uploader: Uploader) => uploader.status === 'queue')
      .slice(0, this.concurrency - running.length)
      .forEach((uploader: Uploader) => {
        uploader.upload();
      });
  }
}
