import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { of } from 'rxjs';

import { BaseService } from './base.service';
import { RespMessage } from '../model/data-model';

@Injectable()
export class CanDoAuthService extends BaseService {

  constructor(private http: HttpClient) {
    super();
  }

  doCheck(data) {
    const resp = new RespMessage();
    resp.state = 'Ok';
    resp.result = true;
    return of(resp);
  }
}
