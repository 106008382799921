import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { FolderType, loadingHide, showInfo, MessageType, loadingShow,showAlert } from '../../shared/common';
import { RespMessage, DocumentContent, FolderContent, HitCountRecordDto, DownloadCountRecordDto, SearchVo, SearchAnyVo, HttpOptions, DocumentContent2, DeleteOnePermissionVo } from '../model/data-model';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Injectable()
export class DocumentService extends BaseService {

  queryVo: SearchVo | SearchAnyVo;

  private searchResultSubject = new BehaviorSubject<RespMessage<FolderContent>>(null);
  public searchResult$ = this.searchResultSubject.asObservable().pipe(distinctUntilChanged());
  constructor(private http: HttpClient) {
    super();
  }

  openDoc(vo: { folderId?: number, folderType, docId: number }, options?: HttpOptions) {
    return this.http.post<RespMessage<DocumentContent>>(`${environment.apiRoot}/api/Document/openDoc`, vo, options);
  }

  getCreateDoc(vo: { folderId?: number, folderType }, options?: HttpOptions) {
    return this.http.post<RespMessage<DocumentContent>>(`${environment.apiRoot}/api/Document/getCreateDoc`, vo, options);
  }

  getCreateDoc2(vo: { folderId?: number, folderType }, options?: HttpOptions) {
    return this.http.post<RespMessage<DocumentContent2>>(`${environment.apiRoot}/api/Document/getCreateDoc2`, vo, options);
  }

  getEditDoc(vo: { folderId?: number, folderType: FolderType, docId: number }) {
    return this.http.post<RespMessage<DocumentContent>>(`${environment.apiRoot}/api/Document/GetEditDoc`, vo);
  }

  getEditDoc2(vo: { folderId?: number, folderType: FolderType, docId: number }) {
    return this.http.post<RespMessage<DocumentContent2>>(`${environment.apiRoot}/api/Document/GetEditDoc2`, vo);
  }

  add(vo: { folderId?: number, folderType: FolderType, documentContent: DocumentContent }) {
    return this.http.post<RespMessage<DocumentContent>>(`${environment.apiRoot}/api/Document/Add/?folderId=${vo.folderId}&folderType=${vo.folderType}`, vo.documentContent);
  }

  add2(vo: { folderId?: number, folderType: FolderType, documentContent: DocumentContent2 }) {
    return this.http.post<RespMessage<DocumentContent2>>(`${environment.apiRoot}/api/Document/Add2/?folderId=${vo.folderId}&folderType=${vo.folderType}`, vo.documentContent);
  }

  addArchives(vo: { folderId?: number, folderType: FolderType, documentContent: DocumentContent, notifySelf?: boolean }, options?: HttpOptions) {
    const notifySelfN = vo.notifySelf ? '1' : '0';
    return this.http.post<RespMessage<DocumentContent>>(`${environment.apiRoot}/api/Document/AddArchives/?folderId=${vo.folderId}&folderType=${vo.folderType}&notifySelf=${notifySelfN}`, vo.documentContent, options);
  }

  addArchives2(vo: { folderId?: number, folderType: FolderType, documentContent: DocumentContent2, notifySelf?: boolean }, options?: HttpOptions) {
    const notifySelfN = vo.notifySelf ? '1' : '0';
    return this.http.post<RespMessage<DocumentContent2>>(`${environment.apiRoot}/api/Document/AddArchives2/?folderId=${vo.folderId}&folderType=${vo.folderType}&notifySelf=${notifySelfN}`, vo.documentContent, options);
  }

  save(vo: { folderId?: number, folderType: FolderType, documentContent: DocumentContent }) {
    return this.http.post<RespMessage<DocumentContent>>(`${environment.apiRoot}/api/Document/Save/?folderId=${vo.folderId}&folderType=${vo.folderType}`, vo.documentContent);
  }

  save2(vo: { folderId?: number, folderType: FolderType, documentContent: DocumentContent2 }) {
    return this.http.post<RespMessage<DocumentContent2>>(`${environment.apiRoot}/api/Document/Save2/?folderId=${vo.folderId}&folderType=${vo.folderType}`, vo.documentContent);
  }

  saveWithPerm(vo: { folderId?: number, folderType: FolderType, documentContent: DocumentContent2 }) {
    return this.http.post<RespMessage<DocumentContent2>>(`${environment.apiRoot}/api/Document/SaveWithPerm/?folderId=${vo.folderId}&folderType=${vo.folderType}`, vo.documentContent);
  }

  delete(vo: { folderId?: number, folderType: FolderType, docId: number }) {
    return this.http.post<RespMessage<boolean>>(`${environment.apiRoot}/api/Document/Delete`, vo);
  }

  clone2Folder(vo: { folderId: number, folderType: FolderType, docId: number }) {
    return this.http.post<RespMessage<boolean>>(`${environment.apiRoot}/api/Document/DocClone2Folder/?docId=${vo.docId}&folderId=${vo.folderId}&folderType=${vo.folderType}`, vo);
  }

  clone2Folders(vo: { folderIds: number[], docIds: number[] }) {
    return this.http.post<RespMessage<boolean>>(`${environment.apiRoot}/api/Document/DocsClone2Folders/`, vo);
  }

  search(vo: SearchVo | SearchAnyVo,
    sortBy = '', sortType = '', pageSize = 100, pageNum = 1) {
    vo['sortBy'] = sortBy;
    vo['sortType'] = sortType;
    vo['pageSize'] = pageSize;
    vo['pageNum'] = pageNum;
    this.queryVo = vo;
    let resp$: Observable<RespMessage<FolderContent>>;
    if ('lookup' in vo) {
      resp$ = this.http.post<RespMessage<FolderContent>>(`${environment.apiRoot}/api/Document/SearchAnyCols/`, vo);
    } else {
      resp$ = this.http.post<RespMessage<FolderContent>>(`${environment.apiRoot}/api/Document/Search/`, vo);
    }
    loadingShow();
    resp$.subscribe((resp: RespMessage) => {
        loadingHide();
        if (resp.state === 'Ok') {
          this.searchResultSubject.next(resp);
          // console.log('search', resp.result);
        }
        else if(resp.state === 'Syntax error'){
          showAlert('請輸入正確格式', '', 'Ok', MessageType.error);
        } else {
          showInfo(resp.message || 'warning', 3000, MessageType.warning);
        }
      }, (error: any) => {
        loadingHide();
        showInfo(error.message || 'error', 3000, MessageType.error);
        console.log(JSON.stringify(error));
      });
  }

  getHitCountRecord(vo: { docId: number, folderId: number }): Observable<RespMessage<HitCountRecordDto[]>> {
    return this.http.post<RespMessage<HitCountRecordDto[]>>(`${environment.apiRoot}/api/Document/DocumentRecord`, vo);
  }

  getDownloadCountRecord(vo: { fileId: number }): Observable<RespMessage<DownloadCountRecordDto[]>> {
    return this.http.post<RespMessage<DownloadCountRecordDto[]>>(`${environment.apiRoot}/api/Document/DownloadRecord`, vo);
  }

  deleteOnePermission(vo: DeleteOnePermissionVo) {
    return this.http.post<RespMessage<boolean>>(`${environment.apiRoot}/api/Document/DeleteOnePermission`, vo);
  }
}
