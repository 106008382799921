import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { DeleteResourceMailVo, RespMessage } from '../model/data-model';

@Injectable()
export class FolderDocumentService extends BaseService {

  constructor(private http: HttpClient) {
    super();
  }

  batchDelete(vo: { folderIds: number[], docIds: number[], folderType: string, deleteResourceMailVo: DeleteResourceMailVo }) {
    return this.http.post<RespMessage<boolean>>(`${environment.apiRoot}/api/FolderDocument/BatchDelete`, vo);
  }

}
