

import { TreeNode } from './../../core/model/data-model';
import { FolderTypeName, showConfirm, RouteMap, popbox } from './../../shared/common';
import { Component, OnInit, OnDestroy, NgZone, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FolderTreeService } from '../../core/service/folder-tree.service';
import { showInfo, loadingHide, MessageType, loadingShow, FolderType } from '../../shared/common';
import { RespMessage, FolderContent, DocumentFolder, DocumentDto, DataRow } from '../../core/model/data-model';
import { DocumentService } from '../../core/service/document.service';
import { PagedListComponent } from '../../shared/component/paged-list/paged-list.component';
import { FolderService } from '../../core/service/folder.service';
import { PickFavoriteFolderComponent } from '../../shared/component/pick-favorite-folder/pick-favorite-folder.component';
import { BaseFolderComponent } from '../base-folder/base-folder.component';
import { FolderCanWriteTreeService } from '../../core/service/folder-can-write-tree.service';
import { BreadcrumbsComponent } from '../../shared/component/breadcrumbs/breadcrumbs.component';
import { DocumentContextmenuService } from '../../core/service/document-contextmenu.service';
import { DownloadService } from 'app/core/service';
import { FolderDocumentService } from 'app/core/service/folder-document.service';
import { MatomoTracker  } from 'ngx-matomo';
import { AuthTokenService } from 'app/core/service/auth-token.service';

@Component({
  selector: 'doc-public-folder',
  templateUrl: './public-folder.component.html',
  styleUrls: ['./public-folder.component.css']
})
export class PublicFolderComponent extends BaseFolderComponent implements OnInit, OnDestroy {

  childFolders: DocumentFolder[];
  documents: DocumentDto[];

  @ViewChild('headerPagedList', { static: false }) headerPagedList: PagedListComponent;
  @ViewChild(BreadcrumbsComponent, { static: false }) breadcrumbsComponent: BreadcrumbsComponent;

  constructor( private matomoTracker: MatomoTracker,protected folderTreeService: FolderTreeService, protected folderCanWriteTreeService: FolderCanWriteTreeService
    , protected documentService: DocumentService, protected folderService: FolderService
    , protected folderDocumentService: FolderDocumentService, protected downloadService: DownloadService
    , protected contextmenuService: DocumentContextmenuService
    , protected router: Router, protected route: ActivatedRoute, protected zone: NgZone
    , private authTokenService: AuthTokenService) {
    super(folderTreeService, folderCanWriteTreeService, folderService, documentService, folderDocumentService, downloadService, contextmenuService, router);

    this.folderId = 0;
    this.folderType = FolderType.PUBLIC_FOLDER;
    this.folderTypeName = FolderTypeName.PUB;
  }
  ngOnInit() {
    super.ngOnInit();
    this.authTokenService.currentUser$
      .subscribe(user => {
        if (user) {
          this.matomoTracker.setUserId(user.userName);
          this.matomoTracker.trackPageView('page_public');
          this.matomoTracker.setCustomVariable(1, "WorkId", user.workId, "page")
        }
      });
    this.route.params.subscribe((params) => {
      const data = this.dataDecode(params['data']);
      if ('folderId' in data) {
        this.folderId = data['folderId'];
        this.resultList = [];
        this.sortByCols = {
          'typeCss': '',
          'name': '',
          'modifyTime': '',
          'readCount': '',
        };
        this.sortBy = '';
        this.sortType = '';
        this.query();
      }
    });
  }

  getPickTreeNode(selected: boolean) {
    if (selected) {
      this.pickTreeNode = this.folderTreeService.pickTreeNode;
    }
  }

  isCentralFolder(folderContent: FolderContent): boolean {
    if (folderContent && folderContent.folderHierarchy && folderContent.folderHierarchy.length >= 1) {
      return folderContent.folderHierarchy[0].folder_name === 'ASUS_Document';
    }
    return false;
  }

  isCentralExceptLastFolder(folderContent: FolderContent): boolean {
    return folderContent.childFolders.length > 0 && this.isCentralFolder(folderContent);
  }

  ngOnDestroy() {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    console.log('PublicFolderComponent.....ngOnDestroy');
  }
}
