<header>
  <div class="wrap justify-center"><a class="logo" href="index.html" title="回首頁"><i class="ASUS-logo"> </i><b>Drive</b></a></div>
</header>
<article class="article">
  <aside class="right"> 
    <div class="main">
      <section class="panel panel-default panel-article">
        <div class="panel-heading">
          <h3 class="panel-title"><i class="circle mdi mdi-file-document"></i>檔案上傳</h3>
        </div>
        <div class="panel-body pad50-tb">
          <p class="text-gray center">點擊下方 「新增上傳檔案」按鈕<br>上傳您分享的檔案</p>
          <div class="input-file-btn center pad10-b">
            <input type="file" #uploadFiles multiple name="uploadFiles"  (change)="save()">
            <a class="btn btn-sm btn-primary" href="#"><i class="mdi mdi-format-vertical-align-top"></i>新增上傳檔案</a>
          </div>
        </div>
      </section>
    </div>
  </aside>
</article>
