
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import {SharedModule} from '../shared/shared.module';
import { FooterComponent } from 'app/layout/footer/footer.component';
import { HeaderComponent } from 'app/layout/header/header.component';
import { HeaderLessComponent } from './header-less/header-less.component';
import { LayoutComponent } from 'app/layout/layout/layout.component';
import { LayoutLessComponent } from './layout-less/layout-less.component';
import { DocumentContentComponent } from './document-content/document-content.component';
import { FolderModule } from '../folder/folder.module';
import { SystemFolderContentComponent } from './system-folder-content/system-folder-content.component';
import { BellComponent } from './bell/bell.component';
import { MobilePortalComponent } from './mobile-portal/mobile-portal.component';
import { UploadContentComponent } from './upload-content/upload-content.component';
import { IePopupComponent } from './ie-popup/ie-popup.component';

@NgModule({
  imports: [
    RouterModule,
    SharedModule,
    FolderModule
  ],
  exports: [
    FooterComponent,
    HeaderComponent,
    LayoutComponent,
    DocumentContentComponent,
    UploadContentComponent,
  ],
  declarations: [
    FooterComponent,
    HeaderComponent,
    HeaderLessComponent,
    LayoutComponent,
    LayoutLessComponent,
    DocumentContentComponent,
    SystemFolderContentComponent,
    MobilePortalComponent,
    BellComponent,
    UploadContentComponent,
    IePopupComponent,
  ]
})
export class LayoutModule { }
