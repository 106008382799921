<div class="page-wrap">
  <span class="total">{{ ("TotalCounts_0_"|translate).replace("{0}", dataCount) }}
    <ng-container *ngIf="showPageNum">，{{ "Rows per Page" | translate }}</ng-container> 
  </span>
  <select class="form-control number" [(ngModel)]="pageSize" (change)="emitPageSize()">
    <option value="100000">{{ "All" | translate }}</option>
    <option value="10">10</option>
    <option value="20">20</option>
    <option value="30">30</option>
    <option value="40">40</option>
    <option value="50">50</option>
    <option value="100">100</option>
  </select>
  <ul class="pagination-panel" *ngIf="showPageNum" >
    <li *ngIf="pageNum>1">
      <a (click)="emitPageNum(pageNum-1);">{{ "Previous Page" | translate }}</a>
    </li>
    <li>
      <select class="form-control number" [(ngModel)]="pageNum" (change)="emitPageNum($event.target.value);">
        <option *ngFor="let item of pageArry ; let idx = index;" value="{{idx+1}}">{{ ("Page_0_"|translate).replace('{0}', idx+1) }}</option>
      </select>
    </li>
    <li *ngIf="pageNum < pageCount" >
      <a (click)="emitPageNum(pageNum+1);">{{ "Next Page" | translate }}</a>
    </li>
  </ul>
</div>
