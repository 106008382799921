import { Component, OnInit, Input, NgZone, AfterViewInit, ViewChild } from '@angular/core';
import { DocumentDto, DocumentFile } from '../../core/model/data-model';
import { closePopbox } from '../../shared/common';
import { DomSanitizer } from '@angular/platform-browser';
import { DocumentContentViewComponent } from '../../shared/component';

@Component({
  selector: 'doc-document-preview',
  templateUrl: './document-preview.component.html',
  styleUrls: ['./document-preview.component.css']
})
export class DocumentPreviewComponent implements OnInit, AfterViewInit {

  MEGABYTE = 1048576; // Bytes
  @Input() fileList: File[];
  @Input() documentFiles: DocumentFile[] = [];
  @Input() documentDto = {} as DocumentDto;
  @ViewChild('contentView', { static: false }) contentView: DocumentContentViewComponent;

  constructor(private zone: NgZone, protected sanitizer: DomSanitizer) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.zone.runOutsideAngular(() => {
      // close popbox
      $('.close, .close-btn').on('click', function (e) {
        $(this).parents('.popbg-fill').removeClass('display-block');
        $(this).parents('.pop-box').removeClass('display-block');
        if ($('.popbg-fill').is('.display-block') === false) {
          $('body').removeClass('overflow-hidden');
        }
        e.preventDefault();
      });
    });

  }

  showContent() {
    this.contentView.documentDto = this.documentDto;
    this.contentView.showContent();
  }

  closePreview() {
    closePopbox();
  }

}
