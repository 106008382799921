import * as JSZip from 'jszip';
import { BackoffRetry } from './backoffRetry';
import { XHRFactory } from './xhrfactory';

import {
  UploadStatus,
  UploadItem,
  UploaderOptions,
  UploadState
} from '../../uploadx/interfaces';
import { JwtAuthObject } from '../model/data-model';
import { getFileExtension } from 'app/shared/common';
import * as CryptoJS from 'crypto-js';

// check   <script src="assets/plugin/js/spark-md5.min.js"></script>
// declare var SparkMD5: any;
const noop = () => { };
/**
 * Implements XHR/CORS Resumable Upload
 * @see
 * https://developers.google.com/drive/v3/web/resumable-upload
 * https://developer.vimeo.com/api/upload/videos#resumable-upload
 * @class Uploader
 */
export class Uploader implements UploaderOptions {
  headers: any;
  metadata: any;
  method: string;
  mimeType: string;
  name: string;
  progress: number;
  remaining: number;
  response: any;
  size: number;
  speed: number;
  uploadGuid: String;
  uploadId: string;
  url: any;
  actionUrl?: string;
  compressing: boolean;
  private startTime: number;
  private _status: UploadStatus;
  private retry: BackoffRetry;
  private abort;
  /**
   * Creates an instance of Uploader.
   */
  constructor(private file: File, private options: UploaderOptions) {
    this.uploadId = Math.random().toString(36).substring(2, 15);
    this.name = file.name;
    this.size = file.size;
    this.compressing = getFileExtension(file.name).toLowerCase() === '.iso';
    this.mimeType = file.type || 'application/octet-stream';
    this.status = 'added';
    this.retry = new BackoffRetry();
  }
  /**
   * Set individual file options and add to queue
   */
  configure(item?: UploadItem) {
    if (this.status === 'added') {
      this.metadata = (this.metadata == null)
        ? Object.assign({ name: this.name, mimeType: this.mimeType, fileGuid: this.uploadGuid, compressed: this.compressing }, this.options.metadata || {})
        : Object.assign({ name: this.name, mimeType: this.mimeType, fileGuid: this.uploadGuid, compressed: this.compressing, ...this.metadata }, this.options.metadata || {});
      this.headers = (this.options.headers instanceof Function)
        ? this.options.headers(this.file)
        : this.options.headers;
      this.url = this.options.url;
      this.method = this.options.method;
    }
    this.status = 'queue';
  }

  set status(s: UploadStatus) {
    this._status = s;
    this.notifyState();
    if (s === 'cancelled' || s === 'paused') {
      if (this.abort) {
        this.abort();
      }
    }
  }
  get status() {
    return this._status;
  }
  /**
   * Emit UploadState
   */
  private notifyState() {
    const state: UploadState = {
      file: this.file,
      name: this.name,
      progress: this.progress,
      remaining: this.remaining,
      response: this.response,
      size: this.size,
      speed: this.speed,
      status: this._status,
      uploadId: this.uploadId,
    };
    // tick for control events detect
    setTimeout(() => {
      this.options.subj.next(state);
    });
  }

  private setHeaders(xhr: XMLHttpRequest) {
    if (this.headers) {
      Object.keys(this.headers)
        .forEach(key => xhr.setRequestHeader(key, this.headers[key]));
    }
  }

  /**
   * Initiate upload
   */
  upload() {
    if (this.status === 'added') {
      this.configure();
    }
    this.status = 'uploading';
    if (this.progress > 0) {
      return this.resume();
    }
    // const xhr = new XMLHttpRequest();
    const xhr = XHRFactory.getInstance();
    // setRequestHeader 方法只能在 xhr.open 方法之後調用，否則IE會報InvalidStateError
    xhr.open(this.method, this.options.url, true);
    // setRequestHeader 方法只能在 xhr.open 方法之後調用，否則IE會報InvalidStateError
    xhr.responseType = 'json';
    if (!!this.options.withCredentials) {
      xhr.withCredentials = true;
    }
    this.setHeaders(xhr);

    if (localStorage.getItem('token') != null) {
      const token: JwtAuthObject = JSON.parse(localStorage.getItem('token'));
      xhr.setRequestHeader('Authorization', 'Bearer ' + token.token);
    } else {
      this.options.token
        ? xhr.setRequestHeader('Authorization', 'Bearer ' + this.options.token)
        : noop();
    }

    xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
    xhr.setRequestHeader('X-Upload-Content-Length', this.size.toString());
    xhr.setRequestHeader('X-Upload-Content-Type', this.mimeType);
    xhr.onload = () => {
      if (xhr.status < 400 && xhr.status > 199) {
        // get secure upload link
        this.url = xhr.getResponseHeader('Location');
        this.startTime = this.startTime || new Date().getTime();
        this.sendFile();
      } else {
        this.response = xhr.response;
        this.status = 'error';
      }
    };
    xhr.send(JSON.stringify(this.metadata));
  }
  /**
   * Request upload state after 5xx errors or network failures
   */
  private resume(): void {
    const xhr: XMLHttpRequest = XHRFactory.getInstance();
    xhr.open('PUT', this.url, true);
    if (xhr.responseType !== 'json') {
      xhr.responseType = 'json';
    }
    if (!!this.options.withCredentials) {
      xhr.withCredentials = true;
    }
    xhr.setRequestHeader('Content-Range', `bytes */${this.size}`);
    xhr.setRequestHeader('Content-Type', this.mimeType);
    this.setHeaders(xhr);
    const onDataSendError = () => {
      // 5xx errors or network failures
      if (this.retry.retriedCount <= 10 && (xhr.status > 499 || !xhr.status)) {
        XHRFactory.release(xhr);
        this.retry.wait()
          .then(() => this.resume());
      } else {
        // 4xx errors
        this.response = xhr.response || {
          'error': {
            'code': +xhr.status,
            'message': xhr.statusText
          }
        };
        this.status = 'error';
        XHRFactory.release(xhr);
        this.options.nextFile();
      }
    };
    const onDataSendSuccess = () => {
      if (xhr.status === 200 || xhr.status === 201) {
        this.progress = 100;
        this.response = xhr.response;
        this.status = 'complete';
        XHRFactory.release(xhr);
        this.options.nextFile();
      } else if (xhr.status && xhr.status < 400) {
        const range = +xhr.getResponseHeader('Range').split('-')[1] + 1;
        this.retry.reset();
        XHRFactory.release(xhr);
        this.abort = this.sendFile(range);
      } else {
        onDataSendError();
      }
    };
    xhr.onerror = onDataSendError;
    xhr.onload = onDataSendSuccess;
    xhr.send();
  }


  // /**
  //  *
  //  * Content upload
  //  * @private
  //  */
  private sendFile(start: number = 0): () => void {
    if (this.status === 'cancelled' || this.status === 'paused') {
      return;
    }
    let end: number = (this.options.chunkSize) ? start + this.options.chunkSize : this.size;
    end = (end > this.size) ? this.size : end;
    const chunk: Blob = this.file.slice(start, end);
    const xhr: XMLHttpRequest = XHRFactory.getInstance();
    // setRequestHeader 方法只能在 xhr.open 方法之後調用，否則IE會報InvalidStateError
    xhr.open('PUT', this.url, true);
    if (xhr.responseType !== 'json') {
      xhr.responseType = 'json';
    }
    if (!!this.options.withCredentials) {
      xhr.withCredentials = true;
    }
    xhr.setRequestHeader('Content-Range', `bytes ${start}-${end - 1}/${this.size}`);
    xhr.setRequestHeader('Content-Type', this.mimeType);
    this.setHeaders(xhr);
    const updateProgress = (pEvent: ProgressEvent) => {
      const uploaded = pEvent.lengthComputable
        ? start + (end - start) * (pEvent.loaded / pEvent.total)
        : start;
      this.progress = +((uploaded / this.size) * 100).toFixed(2);
      const now = new Date().getTime();
      this.speed = Math.round(uploaded / (now - this.startTime) * 1000);
      this.remaining = Math.ceil((this.size - uploaded) / this.speed);
      this.notifyState();
    };
    const onDataSendError = () => {
      // 5xx errors or network failures
      if (this.retry.retriedCount <= 10 && (xhr.status > 499 || !xhr.status)) {
        XHRFactory.release(xhr);
        this.retry.wait()
          .then(() => this.resume());
      } else {
        // 4xx errors
        this.response = xhr.response || {
          'error': {
            'code': +xhr.status,
            'message': xhr.statusText
          }
        };
        this.status = 'error';
        XHRFactory.release(xhr);
        this.options.nextFile();
      }
    };
    const onDataSendSuccess = () => {
      if (xhr.status === 200 || xhr.status === 201) {
        this.progress = 100;
        this.response = xhr.response;
        this.status = 'complete';
        XHRFactory.release(xhr);
        this.options.nextFile();
      } else if (xhr.status && xhr.status < 400) {
        const range = +xhr.getResponseHeader('Range').split('-')[1] + 1;
        this.retry.reset();
        XHRFactory.release(xhr);
        // send next chunk
        this.abort = this.sendFile(range);
      } else {
        onDataSendError();
      }
    };
    xhr.onerror = onDataSendError;
    xhr.onload = onDataSendSuccess;
    xhr.upload.onprogress = updateProgress;
    this.getSHA2Promise(chunk).then((sha2) => {
      xhr.setRequestHeader('x-content-sha256-hex', sha2);
      if (this.compressing) {
        this.ZipBlobAsync(chunk, (zippedChunk) => xhr.send(zippedChunk));
      } else {
        xhr.send(chunk);
      }
    }, (error) => console.error(error));
    return () => { xhr.abort(); };
  }

  private ZipBlobAsync(blob: Blob, callback: ((zippedBlob: Blob) => void)) {
    const zip = new JSZip();
    zip.file('file1', blob, { binary: true })
      .generateAsync({
        type: 'blob',
        compression: 'DEFLATE',
        compressionOptions: {
          level: 1
        }
      })
      .then(callback, (error) => console.error(error));
  }

  private getSHA2Promise(blob: Blob): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      // const spark = new SparkMD5.ArrayBuffer();
      const fileReader = new FileReader();
      fileReader.onload = (e: Event) => {
        const target = e.target as FileReader;
        const arrayBuffer: ArrayBuffer = target.result as ArrayBuffer;
        const chunk = new Uint8Array(arrayBuffer);
        const wordArray = CryptoJS.lib.WordArray.create(chunk);
        const hexHash = CryptoJS.SHA256(wordArray);
        resolve(hexHash);
      };
      fileReader.readAsArrayBuffer(blob);
    });
  }

  // /**
  //  *
  //  * Content upload
  //  * @private
  //  */
  // private sendFile(start: number = 0): () => void {
  //   if (this.status === 'cancelled' || this.status === 'paused') {
  //     return;
  //   }

  //   let end: number = (this.options.chunkSize) ? start + this.options.chunkSize : this.size;
  //   end = (end > this.size) ? this.size : end;
  //   const chunk: Blob = this.file.slice(start, end);
  //   const spark = new SparkMD5.ArrayBuffer();
  //   const fileReader = new FileReader();
  //   fileReader.onload = (e: any) => {

  //     spark.append(e.target.result); // append array buffer
  //     const hexHash = spark.end(); // /MD5 hex hash string
  //     console.log('x-content-md5-hex', hexHash);
  //     const xhr: XMLHttpRequest = XHRFactory.getInstance();
  //     if (xhr.responseType !== 'json') {
  //       xhr.responseType = 'json';
  //     }
  //     xhr.open('PUT', this.url, true);
  //     if (!!this.options.withCredentials) {
  //       xhr.withCredentials = true;
  //     }
  //     xhr.setRequestHeader('x-content-md5-hex', hexHash);
  //     xhr.setRequestHeader('Content-Range', `bytes ${start}-${end - 1}/${this.size}`);
  //     xhr.setRequestHeader('Content-Type', this.mimeType);
  //     this.setHeaders(xhr);
  //     const updateProgress = (pEvent: ProgressEvent) => {
  //       const uploaded = pEvent.lengthComputable
  //         ? start + (end - start) * (pEvent.loaded / pEvent.total)
  //         : start;
  //       this.progress = +((uploaded / this.size) * 100).toFixed(2);
  //       const now = new Date().getTime();
  //       this.speed = Math.round(uploaded / (now - this.startTime) * 1000);
  //       this.remaining = Math.ceil((this.size - uploaded) / this.speed);
  //       this.notifyState();
  //     };
  //     const onDataSendError = () => {
  //       // 5xx errors or network failures
  //       if (xhr.status > 499 || !xhr.status) {
  //         XHRFactory.release(xhr);
  //         this.retry.wait()
  //           .then(() => this.resume());
  //       } else {
  //         // 4xx errors
  //         this.response = xhr.response || {
  //           'error': {
  //             'code': +xhr.status,
  //             'message': xhr.statusText
  //           }
  //         };
  //         this.status = 'error';
  //         XHRFactory.release(xhr);
  //         this.options.nextFile();
  //       }
  //     };
  //     const onDataSendSuccess = () => {
  //       if (xhr.status === 200 || xhr.status === 201) {
  //         this.progress = 100;
  //         this.response = xhr.response;
  //         this.status = 'complete';
  //         XHRFactory.release(xhr);
  //         this.options.nextFile();
  //       } else if (xhr.status && xhr.status < 400) {
  //         const range = +xhr.getResponseHeader('Range').split('-')[1] + 1;
  //         this.retry.reset();
  //         XHRFactory.release(xhr);
  //         // send next chunk
  //         this.abort = this.sendFile(range);
  //       } else {
  //         onDataSendError();
  //       }
  //     };
  //     xhr.onerror = onDataSendError;
  //     xhr.onload = onDataSendSuccess;
  //     xhr.upload.onprogress = updateProgress;
  //     xhr.send(chunk);
  //     return () => { xhr.abort(); };

  //   };
  //   fileReader.readAsArrayBuffer(chunk);
  // }

}
