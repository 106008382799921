import { JwtAuthObject } from './../model/data-model';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import {  map, filter  } from 'rxjs/operators';
import { RespMessage } from 'app/core/model/data-model';
import { AuthTokenService } from './../service/auth-token.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class AuthTokenSysContentGuard implements CanActivate {
  constructor(private authTokenService: AuthTokenService, private router: Router) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    return this.authTokenService.loggedIn$
    .pipe(
      map((result: boolean) => {
        if (result) {
          return true;
        } else {
          try {
            const auth = route.paramMap.get('auth') || '';
            if (auth !== '') {
              this.authTokenService.refreshToken(auth);
            }
          } catch (error) {
            console.log(error);
          }
          return false;
        }
      }),
      filter((v) => v)
    );
  }
}
