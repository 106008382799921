<div class="main">
  <section class="panel panel-default panel-article">
    <div class="panel-heading">
      <h3 class="panel-title">
        <i class="circle mdi mdi-format-list-bulleted"></i>{{ "Search Result" | translate }}</h3>
      <doc-paged-list #headerPagedList [dataCount]="dataCount" [pageSize]="pageSize" [pageNum]="pageNum" (pageNumChange)="pageChange($event)"
        (pageSizeChange)="pageChange($event)"></doc-paged-list>
      <div></div>
    </div>
    <div class="panel-body">
      <table class="table table-striped table-hover table-btn table-rwd">
        <tbody>
          <tr>
            <th class="type click" (click)="sortChange('typeCss')">{{"Type"|translate}}
              <em *ngIf="sortByCols['typeCss']=='ASC'; else typeCssAsc">↑</em>
              <ng-template #typeCssAsc>↑</ng-template>
              <em *ngIf="sortByCols['typeCss']=='DESC'; else typeCssDesc">↓</em>
              <ng-template #typeCssDesc>↓</ng-template>
            </th>
            <th class="class click" (click)="sortChange('category')">{{"Category"|translate}}
              <em *ngIf="sortByCols['category']=='ASC'; else categoryAsc">↑</em>
              <ng-template #categoryAsc>↑</ng-template>
              <em *ngIf="sortByCols['category']=='DESC'; else categoryDesc">↓</em>
              <ng-template #categoryDesc>↓</ng-template>
            </th>
            <th class="name click" (click)="sortChange('name')">{{"Name"|translate}}
              <em *ngIf="sortByCols['name']=='ASC'; else nameAsc">↑</em>
              <ng-template #nameAsc>↑</ng-template>
              <em *ngIf="sortByCols['name']=='DESC'; else nameDesc">↓</em>
              <ng-template #nameDesc>↓</ng-template>
            </th>
            <th class="date click" (click)="sortChange('modifyTime')">{{"Modified Date"|translate}}
              <em *ngIf="sortByCols['modifyTime']=='ASC'; else modifyTimeAsc">↑</em>
              <ng-template #modifyTimeAsc>↑</ng-template>
              <em *ngIf="sortByCols['modifyTime']=='DESC'; else modifyTimeDesc">↓</em>
              <ng-template #modifyTimeDesc>↓</ng-template>
            </th>
            <th class="account">{{"Modified User"|translate}}</th>
            <th class="hit click" (click)="sortChange('readCount')">{{"Hits"|translate}}
              <em *ngIf="sortByCols['readCount']=='ASC'; else readCountAsc">↑</em>
              <ng-template #readCountAsc>↑</ng-template>
              <em *ngIf="sortByCols['readCount']=='DESC'; else readCountDesc">↓</em>
              <ng-template #readCountDesc>↓</ng-template>
            </th>
            <th class="fav">{{"Favorite"|translate}}</th>
          </tr>
          <tr *ngFor="let item of pageList">
            <td class="type">
              <i class="mdi mdi-paperclip " [ngClass]="{empty:!(item.type == 'doc' && item.data['hasFile'] == '1')}"></i>
              <i class="{{item.typeCss}}"></i>
            </td>
            <td [ngClass]="item.data.vFolderType | docTypeCss:[currentUser?.userId,item.user?.userId]">{{item.data.vFolderType | docTypeName:[currentUser?.userId,item.user?.userId] |translate}}</td>
            <td class="name">
              <a (click)="action(item)">
                <p class="title">{{item.name}}</p><em class="path">{{item['data']?.folderHier}} </em>
              </a>
            </td>
            <td class="date"> {{item.modifyTime | date:'yyyy-MM-dd HH:mm a'}}</td>
            <td class="account">{{getUserViewName(item.user)}}</td>
            <td class="hit">
              <span *ngIf="item.type === 'doc'">{{ item.readCount }}</span>
            </td>
            <td class="fav">
              <a *ngIf="item.type === 'doc'" class="icon show-pop" href="javascript:void(0)" (click)="showAddfav(item,pickFavoriteFolder) ">
                <i class="mdi mdi-star" [ngClass]="{'yellow':item.data.inFavorite}"> </i>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="panel-footer">
    </div>
  </section>
</div>
<doc-pick-favorite-folder #pickFavoriteFolder></doc-pick-favorite-folder>
<doc-pick-tree-clone-doc #pickTree pickType="Clone" pickCss="show-share" (selected)="getPickTreeNodeCloneDoc($event)"></doc-pick-tree-clone-doc>
<doc-document-view #documentView (onAddfav)="onAddfav($event,pickFavoriteFolder)"><doc-document-view>>

