export const environment = {
  production: true,
  env: 'prod',
  apId: { 'idrive.asus.com': 102, 'mydrive.asus.com': 103, 'drive.asus.com': 104 },
  adfsSSO: 'https://eiddc.asus.com/banana',
  redirectUrl: '/apps/#/ssoverify', // https://mydrive.asus.com/apps/#/ssoverify
  enabledAdfs: true,
  version: '1.0.4.0',
  buildDate: '2022-06-22 12:00',
  baseHref: '/apps',
  enabledServiceWorker: false,
  releaseNotes: `
  部門文件功能調整。
  `,
  apiRoot: '',
  matomoUrl: 'https://analytics.asus.com/',
  matomoSiteID: 86
};
