<doc-breadcrumbs [folderHierarchy]="documentContent?.folderHierarchy" [folderType]="folderType" [folderTypeName]="folderTypeName"></doc-breadcrumbs>
<div class="main">
  <section class="panel panel-default panel-article">
    <div class="panel-heading">
      <h3 class="panel-title">
        <i class="circle mdi mdi-file-document"></i>{{documentDto?.documentName}}</h3>
      <div>
        <a class="btn btn-dark marg6-r"  href="javascript:void(0)" (click)="close()" >
          <i class="mdi mdi-reply"></i>{{ "GoBack" | translate }}</a>
      </div>
    </div>
    <div class="tab-panel-boddy">
      <div class="panel-body">
        <div class="detail-info-right">
          <a *ngIf="currentUser?.type ==='ASUS'"  class="show-pop"  href="javascript:void(0)"  (click)="showAddfav(documentDto,pickFavoriteFolder) " >
            <i class="mdi mdi-star"  [ngClass]="{'yellow':documentDto?.inFavorite }" ></i>{{ "Add to favorite" | translate }} </a>
          <i class="mdi mdi-clock"> </i>{{documentDto?.createTime | date:'yyyy-MM-dd HH:mm a'}}
          <i class="mdi mdi-account-circle"> </i>{{documentDto?.createUser?.userName}}({{documentDto?.createUser?.userEngName}}) {{ "Publish" | translate }}
          <i class="mdi mdi-eye"> </i>{{documentDto?.readCount}} {{ "Hit Counts" | translate }} </div>
        <div class="detail-download" *ngIf="documentFiles?.length> 0">
          {{ "Download Attachment" | translate }} :

          <ng-container  *ngFor="let item of documentFiles">
            <a *ngIf="item.exists" href="javascript:void(0)" (click)="downloadFile(item)">
              <i class="mdi mdi-paperclip"> </i>{{item.fileName}}
              <ng-container *ngIf="!item.exists">({{ "File Not Exist" | translate }})</ng-container> </a>
          </ng-container>

        </div>
        <div class="main-document-content" >
            <doc-document-content-view  #contentView [documentDto]="documentDto" ></doc-document-content-view>
        </div>
      </div>
    </div>
  </section>
  <div class="center pad20-b">
    <a class="btn btn-sm btn-dark marg6-r" href="javascript:void(0)" (click)="close()">
      <i class="mdi mdi-reply"></i>{{ "GoBack" | translate }}</a>
  </div>
</div>
<doc-pick-favorite-folder #pickFavoriteFolder></doc-pick-favorite-folder>
