
import { Component, OnInit, OnDestroy, EventEmitter, Output, Input, AfterViewInit } from '@angular/core';
import { loadingShow, loadingHide, FolderType, popbox, closePopbox } from '../../common';
import { RespMessage, UserNode, TreeNode } from '../../../core/model/data-model';
import { BaseComponent } from '../base/base.component';
import { FolderCanWriteTreeService } from '../../../core/service/folder-can-write-tree.service';

@Component({
  selector: 'doc-pick-tree-clone-doc',
  templateUrl: './pick-tree-clone-doc.component.html',
  styleUrls: ['./pick-tree-clone-doc.component.css']
})
export class PickTreeCloneDocComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {

  userAllNode: UserNode = null;
  treeNode: TreeNode;
  @Input() folderType = '';
  @Input() pickType = 'Clone';
  @Input() pickCss = 'show-share';
  // @Input() pickMulCss = 'show-share-publicFile';
  @Output() selected = new EventEmitter<TreeNode>();
  constructor(private folderCanWriteTreeService: FolderCanWriteTreeService) {
    super();
  }

  ngOnInit() {
    console.log('PickTreeCloneDocComponent.....ngOnInit');
    this.resetPickTreeNode();
    this.folderCanWriteTreeService.userAllNode$.subscribe(
      userAllNode => {
        this.userAllNode = userAllNode;
      },
      error => {
        console.error(error);
      }
    );
    this.folderCanWriteTreeService.pickTreeNode$.subscribe((treeNode: TreeNode) => {
      setTimeout(() => {
        this.treeNode = treeNode;
      }, 200);
    });
  }

  ngAfterViewInit() {

  }
  show() {
    this.folderCanWriteTreeService.pickTreeNode = null;
    popbox(this.pickCss);
  }

  // showTree() {
  //   closePopbox();
  //   popbox(this.pickCss);
  // }

  // showMul() {
  //   closePopbox();
  //   popbox(this.pickMulCss);
  // }

  resetPickTreeNode() {
    this.folderCanWriteTreeService.pickTreeNode = null;
  }

  action(selected: boolean) {
    if (selected && this.canCloneDoc()) {
      this.selected.emit(this.treeNode);
    }
  }

  pickTreeNode(curNode: TreeNode, folderType) {
    curNode.folderTyp = folderType;
    this.folderCanWriteTreeService.pickTreeNode = curNode.folderTyp === 'PRI' ? curNode : {} as TreeNode;
  }

  getTreeNodes(curNode: TreeNode, folderType) {
    this.folderCanWriteTreeService.getCanWriteTreeNodes({ folderId: curNode.node_id, folderType: folderType, curNode: curNode, deptId: curNode.dept_id })
      .subscribe((resp: RespMessage<TreeNode>) => {
        if (resp.state === 'Ok') { }
      }, error => {
        console.error('folderTreeService.getCanWriteTreeNodes ::', JSON.stringify(error));
      });
  }

  get treeNodeName(): string {
    return this.canCloneDoc() ? this.treeNode.node_name : '';
  }

  canCloneDoc(): boolean {
    return this.treeNode && this.treeNode.canNewDoc && this.treeNode.node_id > 0;
  }

  ngOnDestroy() {
    console.log('PickTreeCloneDocComponent.....ngOnDestroy');
  }
}
