<table class="table table-striped table-w-auto">
  <tbody>
    <tr>
      <th class="center">選擇</th>
      <th>權限</th>
      <th class="center" *ngIf="needCascade">套用至子資料夾<br>(依權限)</th>
      <th class="center">顯示上層資料夾<br>(依人員群組)</th>
    </tr>
    <tr>
      <td class="center">
        <input type="checkbox" [(ngModel)]="readOperation.value" (ngModelChange)="onValueChange($event, readOperation)" [disabled]="readOperation.fixValue">
      </td>
      <td>{{ i18nPrefix + '.read' | translate }}</td>
      <td class="center" *ngIf="needCascade">
        <input type="checkbox" [(ngModel)]="readOperation.cascade" [disabled]="!readOperation.value">
      </td>
      <td class="center">
        <input type="checkbox" [(ngModel)]="showParent" (ngModelChange)="onShowParentChange($event)" [disabled]="fixShowParent">
      </td>
    </tr>
    <tr *ngIf="needReadAll">
      <td class="center">
        <input type="checkbox" [(ngModel)]="readAllOperation.value" (ngModelChange)="onValueChange($event, readAllOperation)" [disabled]="readAllOperation.fixValue">
      </td>
      <td>{{ i18nPrefix + '.readAll' | translate }}</td>
      <td class="center" *ngIf="needCascade">
        <input type="checkbox" [(ngModel)]="readAllOperation.cascade" [disabled]="!readAllOperation.value">
      </td>
      <td class="center">
        <input type="checkbox" [(ngModel)]="showParent" (ngModelChange)="onShowParentChange($event)" [disabled]="fixShowParent">
      </td>
    </tr>
    <tr>
      <td class="center">
        <input type="checkbox" [(ngModel)]="writeOperation.value" (ngModelChange)="onValueChange($event, writeOperation)" [disabled]="writeOperation.fixValue">
      </td>
      <td>{{ i18nPrefix + '.write' | translate }}</td>
      <td class="center" *ngIf="needCascade">
        <input type="checkbox" [(ngModel)]="writeOperation.cascade" [disabled]="!writeOperation.value">
      </td>
      <td class="center">
        <input type="checkbox" [(ngModel)]="showParent" (ngModelChange)="onShowParentChange($event)" [disabled]="fixShowParent">
      </td>
    </tr>
    <tr>
      <td class="center">
        <input type="checkbox" [(ngModel)]="adminOperation.value" (ngModelChange)="onValueChange($event, adminOperation)" [disabled]="adminOperation.fixValue">
      </td>
      <td>{{ i18nPrefix + '.admin' | translate }}</td>
      <td class="center" *ngIf="needCascade">
        <input type="checkbox" [(ngModel)]="adminOperation.cascade" [disabled]="!adminOperation.value">
      </td>
      <td class="center">
        <input type="checkbox" [(ngModel)]="showParent" (ngModelChange)="onShowParentChange($event)" [disabled]="fixShowParent">
      </td>
    </tr>
  </tbody>          
</table>