import { ErrorHandler, Inject, forwardRef, NgZone, Injectable } from '@angular/core';
import { ClientLogService } from './service/client-log.service';
import { environment } from './../../environments/environment';
@Injectable()
export class CommonErrorHandler extends ErrorHandler {
  constructor(@Inject(forwardRef(() => ClientLogService)) private clientLogService: ClientLogService,
    @Inject(forwardRef(() => NgZone)) private zone: NgZone) {
    super();
  }

  public handleError(error: any): void {
    // You can add your own logic here.
    // It is not required to delegate to the original implementation
    if (location.hostname !== 'localhost') {
      this.zone.runOutsideAngular(() => {
        this.clientLogService.errorLog(`message:::${error.message}\r\nstack:::${error.stack}\r\n`)
          .subscribe(values => {
            // console.log(values);
          },
            err => {
              console.error('CommonErrorHandler::handleError' , JSON.stringify(error));
            });
      }
      );
    }
    console.error('CommonErrorHandler::handleError' , `message:::${error.message}\r\nstack:::${error.stack}\r\n`);
    super.handleError(error);
  }
}
