import { NgModule } from '@angular/core';
import { PreloadAllModules, Route, Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './layout/layout/layout.component';
import { HomeComponent } from './home/home.component';
import { fallbackRoute } from './shared/fallback-route';
import { AuthTokenGuard } from './core/guard/auth-token-guard';
import { AuthTokenSysContentGuard } from './core/guard/auth-token-sys-content-guard';
import { AuthTokenShortTermGuard } from './core/guard/auth-token-short-term-guard';
import { AuthTokenMobilePortalGuard } from './core/guard/auth-token-mobile-portal-guard';
import { AuthTokenParamGuard } from './core/guard/auth-token-param-guard';
import { AuthUserGuard } from './core/guard/auth-user-guard';
import { AuthLoginGuard } from './core/guard/auth-login-guard';
import { LoginComponent } from './login/login.component';
import { DocumentContentComponent } from './layout/document-content/document-content.component';
import { SystemFolderContentComponent } from './layout/system-folder-content/system-folder-content.component';
import { SsoComponent } from './sso/sso.component';
import { MobilePortalComponent } from './layout/mobile-portal/mobile-portal.component';
import { UploadContentComponent } from './layout/upload-content/upload-content.component';
import { CapComponent } from './folder/cap/cap.component';
import { LayoutLessComponent } from './layout/layout-less/layout-less.component';
// import { FolderModule } from './folder/folder.module';
// import { ErrorPageModule } from './error-page/error-page.module';

// const routes: Routes = [
//   {
//     path: '', component: LayoutComponent,
//     children: [
//       { path: '', redirectTo: 'folder/list-newest-document', pathMatch: 'full' },
//       { path: 'home', redirectTo: 'folder/list-newest-document', pathMatch: 'full' },
//       { path: 'folder', loadChildren: () => FolderModule },
//     ], canActivate: [AuthTokenGuard]
//   },
//   { path: 'view-content', component: DocumentContentComponent, pathMatch: 'full' },
//   { path: 'system', component: SystemFolderContentComponent, pathMatch: 'full' },
//   { path: 'login', component: LoginComponent },
//   { path: 'sso', component: SsoComponent },
//   { path: 'ssoverify', component: SsoComponent },
//   { path: 'error', loadChildren: () => ErrorPageModule },
//   fallbackRoute
// ];
const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full', canActivate: [AuthTokenGuard] },
  { path: 'home', component: HomeComponent, pathMatch: 'full', canActivate: [AuthTokenGuard] },
  {
    path: '', component: LayoutComponent,
    children: [
      { path: 'folder', loadChildren: () => import('app/folder/folder.module').then(m => m.FolderModule) },
    ], canActivate: [AuthTokenGuard, AuthUserGuard], data: { userTypes: ['ASUS'] }
  },
  {
    path: '', component: LayoutLessComponent,
    children: [
      { path: 'cp', component: CapComponent, pathMatch: 'full', canActivate: [AuthTokenGuard, AuthUserGuard], data: { userTypes: ['CAP'] } },
    ]
  },
  { path: 'view-content', component: DocumentContentComponent, pathMatch: 'full', canActivate: [AuthTokenGuard] },
  { path: 'view-content-s', component: DocumentContentComponent, pathMatch: 'full', canActivate: [AuthTokenShortTermGuard] },
  { path: 'upload-content', component: UploadContentComponent, pathMatch: 'full', canActivate: [AuthTokenShortTermGuard, AuthUserGuard], data: { userTypes: ['ASUS'] } },
  { path: 'mobile-portal', component: MobilePortalComponent, pathMatch: 'full', canActivate: [AuthTokenMobilePortalGuard, AuthUserGuard], data: { userTypes: ['ASUS'] } },
  { path: 'system', component: SystemFolderContentComponent, pathMatch: 'full', canActivate: [AuthTokenSysContentGuard, AuthUserGuard], data: { userTypes: ['ASUS', 'SCM'] } },
  { path: 'login', component: LoginComponent, pathMatch: 'full', canActivate: [AuthLoginGuard] },
  { path: 'sso', component: SsoComponent },
  { path: 'ssoverify', component: SsoComponent },
  // { path: 'tokenverify', component: HomeComponent, pathMatch: 'full', canActivate: [AuthTokenParamGuard] },
  { path: 'error', loadChildren: () => import('app/error-page/error-page.module').then(m => m.ErrorPageModule) },
  fallbackRoute
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    // enableTracing: true,
    useHash: true,
    preloadingStrategy: PreloadAllModules,
    relativeLinkResolution: 'legacy'
})],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
