import { Pipe, PipeTransform } from '@angular/core';
import { FolderType } from '../common';

@Pipe({
  name: 'docTypeCss'
})
export class DocTypeCssPipe implements PipeTransform {

  transform(value: any, args: any[]): any {
    if (args != null && args.length > 0) {
      switch (value) {
        case FolderType.DEPT_FOLDER: {
          return { 'dot bu': true };
        }
        case FolderType.PUBLIC_FOLDER: {
          return { 'dot public': true };
        }
        case FolderType.FAVORITE_FOLDER: {
          return { 'dot public': true };
        }
        case FolderType.PRIVATE_FOLDER: {
          return { 'dot public': true };
        }
        case FolderType.SHARE_WITH_ME: {
          return { 'dot share': true };
        }
        case FolderType.SYS_FOLDER: {
          return { 'dot system': true };
        }
        default: {
          return '';
        }
      }
    }
  }
}
