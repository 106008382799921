import { Component, OnInit, AfterViewInit } from '@angular/core';
import { isIE, popbox } from 'app/shared/common';

@Component({
  selector: 'doc-ie-popup',
  templateUrl: './ie-popup.component.html',
  styleUrls: ['./ie-popup.component.css']
})
export class IePopupComponent implements OnInit, AfterViewInit {
  readonly popCss = 'pop-ie';
  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    if (isIE()) {
      popbox(this.popCss);
    }
  }
}
