
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, NgZone, AfterViewInit, ViewEncapsulation, Input } from '@angular/core';
import { popbox } from '../../shared/common';
import { JwtAuthObject, User } from '../../core/model/data-model';
import { TranslateService } from '@ngx-translate/core';
import { AuthTokenService } from '../../core/service/auth-token.service';
import { UiService } from 'app/core/service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'doc-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit, AfterViewInit {
  menuHost = '';
  uselang = '';
  logonUser: User = null;
  isNav = false;
  sopUrl = environment.apiRoot + '/SOP/SOP.html';
  @Input() showUserInfo = true;
  constructor(private translate: TranslateService,
    private authTokenService: AuthTokenService,
    private uiService: UiService,
    private router: Router, private route: ActivatedRoute, private zone: NgZone) {
  }

  ngOnInit() {

    this.uselang = localStorage.getItem('browserLang');
    if (this.uselang == null) {
      this.uselang = 'zh';
      localStorage.setItem('browserLang', this.uselang);
    }
    this.authTokenService.loggedIn$
      .subscribe((isLogged: boolean) => {
        if (isLogged && localStorage.getItem('token') != null) {
          const token: JwtAuthObject = JSON.parse(localStorage.getItem('token'));
          this.logonUser = token.user;
        }
      });
    this.uiService.isNav$.subscribe(isNav => { this.isNav = isNav; });
    this.initQryDTO();
  }

  logout() {
    // if (this.logonUser.type.toUpperCase() === 'ASUS') {
    //   this.authTokenService.logout();
    // }
    this.authTokenService.logout();
  }

  ngAfterViewInit() {

  }

  changeLang(lang) {
    this.uselang = lang;
    localStorage.setItem('browserLang', this.uselang);
    this.translate.setDefaultLang(this.uselang);
    this.translate.use(this.uselang);
    const uicss = document.getElementById('uicss_lang');
    const tick = environment.buildDate.replace(/\W+/g, '');
    if (this.uselang === 'zh') {
      uicss.setAttribute('href', 'assets/css/UI-zh.css?d=' + tick);
    } else {
      uicss.setAttribute('href', 'assets/css/UI-en.css?d=' + tick);
    }
    // console.log(uicss);
  }

  initQryDTO() {

  }

  getChildren(parentId: number) {

  }


  opPop(show) {
    popbox(show);
  }

  toggleNav() {
    this.uiService.toggleNav();
  }
  
}
