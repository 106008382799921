import { Component, OnInit, ViewChild, NgZone, AfterViewInit, OnDestroy } from '@angular/core';
import { PageComponent, PagedListComponent, BreadcrumbsComponent } from '../../shared/component';
import { DataRow, DocumentDto, TreeNode, DocumentFolder, RespMessage, FolderContent, JwtAuthObject } from '../../core/model/data-model';
import { FolderType, RouteMap, loadingHide, popbox, showInfo, MessageType, loadingShow, FolderTypeName, initCommonJs } from '../../shared/common';
import { FolderTreeService, FolderCanWriteTreeService, DocumentService, FolderService, AuthTokenService } from '../../core/service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'doc-system-folder-content',
  templateUrl: './system-folder-content.component.html',
  styleUrls: ['./system-folder-content.component.css']
})
export class SystemFolderContentComponent extends PageComponent implements OnInit, AfterViewInit, OnDestroy {

  folderId = 0;
  folderType = FolderType.SYS_FOLDER;
  folderTypeName = FolderTypeName.SYS;
  folderName = '';
  pickTreeNode: TreeNode;
  pickTreeNodeCloneDoc: TreeNode;
  folderContent: FolderContent;
  sortBy = '';
  sortType = '';
  sortByCols = {
    'typeCss': '',
    'name': '',
    'modifyTime': '',
    'readCount': '',
  };
  pickDocument: DocumentDto;
  resultList: DataRow[] = [];
  pageList: DataRow[] = [];
  @ViewChild('headerPagedList', { static: false }) headerPagedList: PagedListComponent;
  @ViewChild(BreadcrumbsComponent, { static: false }) breadcrumbsComponent: BreadcrumbsComponent;
  constructor(protected folderTreeService: FolderTreeService, protected folderCanWriteTreeService: FolderCanWriteTreeService,
    protected folderService: FolderService, protected documentService: DocumentService, private authTokenService: AuthTokenService,
    protected router: Router, protected route: ActivatedRoute,
    private zone: NgZone) {
    super();
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      const data = this.dataDecode(params['data']);
      if ('folderId' in data) {
        this.folderId = data['folderId'];
        this.resultList = [];
        this.sortByCols = {
          'typeCss': '',
          'name': '',
          'modifyTime': '',
          'readCount': '',
        };
        this.sortBy = '';
        this.sortType = '';
        this.query();
      }
    });
  }

  ngAfterViewInit() {
    this.zone.runOutsideAngular(() => {
      initCommonJs();
      $('body').addClass('scm-iframe').addClass('scrollbar');
      $('.breadcrumbs').addClass('panel-breadcrumbs');
    });
  }

  ngOnDestroy(): void {
    this.zone.runOutsideAngular(() => {
      $('body').removeClass('scm-iframe').removeClass('scrollbar');
      $('.breadcrumbs').removeClass('panel-breadcrumbs');
    });
  }

  query(sortBy = '', sortType = '', pageSize = 100, pageNum = 1) {
    loadingShow();
    this.folderTreeService.getDisplayFolder({ folderId: this.folderId, folderType: this.folderType }, sortBy, sortType, pageSize, pageNum)
      .subscribe(re => {
        loadingHide();
        const resp: RespMessage = re;
        if (resp.state === 'Ok') {
          this.dataCount = resp.dataCount;
          this.updatePageList();
          this.resultList = [];
          this.folderContent = resp.result;
          if (this.folderContent.folder == null) {
            this.folderName = this.folderTypeName;
          } else {
            this.folderName = this.folderContent.folder.folderName;
          }
          this.folderContent.childFolders.map(
            fol => this.resultList.push({ type: 'folder', name: fol.folderName, readCount: -1, modifyTime: fol.modifyTime, data: fol })
          );
          this.folderContent.documents.map(
            doc => this.resultList.push({ type: 'doc', name: doc.documentName, readCount: doc.readCount, modifyTime: doc.modifyTime, data: doc })
          );
          this.resultList.forEach(row => row.typeCss = this.getTypeCss(row.data));
          this.pageList = this.resultList;
          this.breadcrumbsComponent.updateBreadcrumbsInfo({ folderId: this.folderId, folderType: this.folderType, folderTypeName: this.folderTypeName, folderHierarchy: this.folderContent.folderHierarchy });
        } else {
          showInfo(resp.message || 'warning', 3000, MessageType.warning);
        }
      }, error => {
        loadingHide();
        showInfo(error.message || 'error', 3000, MessageType.error);
        console.error('folderTreeService.getDisplayFolder::', JSON.stringify(error));
      });
  }

  pageChange(val: { pageSize: number, pageNum: number, pageCount: number }) {
    this.pageSize = val.pageSize;
    this.pageNum = val.pageNum;
    this.pageCount = val.pageCount;
    this.query(this.sortBy, this.sortType, this.pageSize, this.pageNum);
  }

  sortChange(sortByCol) {
    for (const col in this.sortByCols) {
      if (sortByCol === col) {
        if (this.sortByCols[sortByCol] === '' || this.sortByCols[sortByCol] === 'DESC') {
          this.sortByCols[sortByCol] = 'ASC';
        } else {
          this.sortByCols[sortByCol] = 'DESC';
        }
        this.sortBy = sortByCol;
        this.sortType = this.sortByCols[sortByCol];
      } else {
        this.sortByCols[col] = '';
      }
    }
    // this.sortByCols = { ...this.sortByCols };
    console.log('pageCount', this.pageCount);
    if (this.pageCount > 1) {
      this.query(this.sortBy, this.sortType, this.pageSize, this.pageNum);
    } else {
      this.showList();
    }
  }

  showList() {
    if (this.pageNum === 0) {
      this.pageNum = 1;
    }
    const start = (this.pageNum - 1) * this.pageSize;
    const end = start + this.pageSize;
    this.resultList = this.resultListSort(this.resultList);
    this.pageList = this.resultList.slice(start, end);
  }

  updatePageList() {
    this.headerPagedList.dataCount = this.dataCount;
    this.headerPagedList.pageNum = this.pageNum;
    this.headerPagedList.pageSize = this.pageSize;
    this.headerPagedList.updatePagedListInfo();
    this.pageCount = this.headerPagedList.pageCount;
  }

  protected action(data: DataRow) {
    if (data.type === 'folder') {
      const folder = data.data as DocumentFolder;
      this.router.navigate(['/system', { data: this.dataEncode({ folderId: folder.folderId }) }]);
    } else {
      const doc = data.data as DocumentDto;
      if (data.type === 'doc' && doc.isArchive === '1') {
        this.openWindow(`/#/view-content;data=${this.dataEncode({ folderType: this.folderType, folderId: this.folderId, docId: doc.documentId })}`, '_blank');
      } else {
        window.open(`/#/view-content;data=${this.dataEncode({ folderType: this.folderType, folderId: this.folderId, docId: doc.documentId })}`, '_blank');
      }
    }
  }

  openWindow(url, name = '', iWidth = 600, iHeight = 400) {
    const iTop = (window.screen.availHeight - 30 - iHeight) / 2;  // 視窗的垂直位置;
    const iLeft = (window.screen.availWidth - 10 - iWidth) / 2;   // 視窗的水平位置;
    window.open(url, name, 'height=' + iHeight + ',,innerHeight=' + iHeight + ',width=' + iWidth + ',innerWidth=' + iWidth + ',top=' + iTop + ',left=' + iLeft +
      ',status=no,location=no,status=no,menubar=no,toolbar=no,resizable=no,scrollbars=no');
  }

  protected goBack() {
    let parentId = 0;
    if (this.folderContent.folder != null && this.folderContent.folder.parentFolder != null) {
      parentId = this.folderContent.folder.parentFolder.folderId;
    }
    const url = RouteMap[this.folderType];
    this.router.navigate([url, { data: this.dataEncode({ folderId: parentId }) }]);
  }

  resultListSort(resultList: any[]) {
    return resultList.sort((cur, nex) => {
      let re = 0;
      switch (this.sortBy) {
        case 'typeCss':
          if (this.sortType === 'ASC') {
            re = cur.typeCss > nex.typeCss ? 1 : -1;
          } else {
            re = cur.typeCss < nex.typeCss ? 1 : -1;
          }
          break;
        case 'modifyTime':

          // const curDate = new Date(cur.modifyTime);
          // const nexDate = new Date(nex.modifyTime);
          // console.log('modifyTime instanceof', cur.modifyTime instanceof Date);
          // console.log('modifyTime instanceof', typeof cur.modifyTime);
          if (this.sortType === 'ASC') {
            re = cur.modifyTime > nex.modifyTime ? 1 : -1;
          } else {
            re = cur.modifyTime < nex.modifyTime ? 1 : -1;
          }
          break;
        case 'readCount':
          if (this.sortType === 'ASC') {
            re = cur.readCount > nex.readCount ? 1 : -1;
          } else {
            re = cur.readCount < nex.readCount ? 1 : -1;
          }
          break;
        case 'name':
          if (this.sortType === 'ASC') {
            re = cur.name > nex.name ? 1 : -1;
          } else {
            re = cur.name < nex.name ? 1 : -1;
          }
          break;

        default:
          break;
      }
      return re;
    });
  }

}
