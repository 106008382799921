import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { environment } from 'environments/environment';

@Injectable()
export class DocumentFileService extends BaseService {

  constructor(private http: HttpClient) {
    super();
  }

  delete(vo: { docId?: number, fileGuid: String }) {
    return this.http.post(`${environment.apiRoot}/api/UploadFile/Delete?fileGuid=${vo.fileGuid}`, vo);
  }

}
