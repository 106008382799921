import { Component, OnInit, OnDestroy } from '@angular/core';
import { FolderTreeService } from '../../../core/service/folder-tree.service';
import { loadingShow, loadingHide, FolderType, sizeFormat } from '../../common';
import { RespMessage, UserNode, TreeNode } from '../../../core/model/data-model';
import { Observable } from 'rxjs';
import { BaseComponent } from '../base/base.component';
import { UiService } from 'app/core/service';

@Component({
  selector: 'doc-tree-view-nav',
  templateUrl: './tree-view-nav.component.html',
  styleUrls: ['./tree-view-nav.component.css']
})
export class TreeViewNavComponent extends BaseComponent implements OnInit, OnDestroy {

  userAllNode: UserNode = null;
  myFileSize = '0';
  constructor(private folderTreeService: FolderTreeService, private uiService: UiService) {
    super();
  }

  ngOnInit() {
    // loadingShow();
    this.folderTreeService.myFileSizeSubject$.subscribe(
      myFileSize => this.myFileSize = sizeFormat(myFileSize)
    );
    this.folderTreeService.userAllNode$.subscribe(
      allNode => {
        //  loadingHide();
        this.userAllNode = allNode;
        // console.log('TreeViewNavComponent:this.userAllNode', this.userAllNode);
      },
      error => {
        //   loadingHide();
        console.error('folderTreeService.userAllNode$. ::', JSON.stringify(error));
      }
    );
    this.folderTreeService.getFolderAllRoot();
  }

  getTreeNodes(curNode: TreeNode, folderType) {
    this.folderTreeService.getTreeNodes({ folderId: curNode.node_id, folderType: folderType, curNode: curNode })
      .subscribe((resp: RespMessage<TreeNode>) => {
        if (resp.state === 'Ok') {
          // console.log('TreeViewNavComponent:this.getTreeNodes', resp.result);
        }
      }, error => {
          console.error('reeService.getTreeNodes::', JSON.stringify(error));
      });
  }

  setNavTreeNode(curNode: TreeNode, event: Event) {
    this.folderTreeService.currentNavTreeNode = curNode;
    if (event.target['tagName'] === 'A') {
      this.uiService.closeNav();
    }
  }

  ngOnDestroy() {
    console.log('TreeViewNavComponent.....ngOnDestroy');
  }
}
