import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { interval } from 'rxjs';
import { BaseService } from './base.service';
import { MessageType, showAlert } from '../../shared/common';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SwUpdateService extends BaseService {

  constructor(private swUpdate: SwUpdate) {
    super();
  }

  checkServiceWorkerdisEnabled() {
    return this.swUpdate.isEnabled;
  }

  checkActivated() {
    console.info('service-worker checkActivated');
    this.swUpdate.activated.subscribe(event => {
      console.info('service-worker activated version is', JSON.stringify(event));
    }
      , error => console.error('swUpdate.activated', JSON.stringify(event)));
  }

  checkAvailable() {
    this.swUpdate.available.subscribe(event => {
      console.info('service-worker available version is', JSON.stringify(event));
    });
  }

  checkAavailableUpdate() {
    console.info('service-worker auto check available update');
    this.swUpdate.available.subscribe(event => {
      // console.log('service-worker available version is', JSON.stringify(event));
      showAlert('New version available', 'New version available. Load New Version !!! \n' + environment.releaseNotes, 'Ok', MessageType.info).then(
        result => {
          console.log(`showAlert:`, JSON.stringify(result));
          window.location.reload(true);
        }
      );
    }
      , error => console.error('swUpdate.available', JSON.stringify(event)));
  }

  autoCheckForUpdate() {
    interval(3 * 60 * 1000)// 每3分鐘檢查版本更新
      .subscribe(() => {
        this.swUpdate.checkForUpdate()
          .then(() => { console.info('service-worker checkForUpdate', new Date()); })
          .catch(error => console.error('swUpdate.checkForUpdate', JSON.stringify(event)));
      });
  }
}
