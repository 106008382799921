import { JwtAuthObject, Env } from './../model/data-model';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import {  map, filter  } from 'rxjs/operators';
import { RespMessage } from 'app/core/model/data-model';
import { AuthTokenService } from './../service/auth-token.service';
import { environment } from '../../../environments/environment';
import { EnvService } from '../service/env.service';

@Injectable()
export class AuthTokenGuard implements CanActivate {
  constructor(private authTokenService: AuthTokenService, private envService: EnvService) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    // filter false value due to env.service.checkReload
    return this.authTokenService.loggedIn$
    .pipe(
      map((result: boolean) => {
        if (result) {
          return true;
        } else {
          try {
            if (this.authTokenService.isJumpingRootOrCP()) {
              return false;
            }
            sessionStorage['prev_url'] = location.hash.substring(1);
            const mPortalKey = this.getMobilePortalKey(route);
            if (mPortalKey) {
              this.authTokenService.mPortalLogin(mPortalKey);
            } else if (environment.enabledAdfs) {
              // this.adfsLogin();
              this.authTokenService.adfsLogin();
            } else {
              this.authTokenService.ssoLogin();
            }
          } catch (error) {
            console.log(error);
            sessionStorage.removeItem('prev_url');
          }
          return false;
        }
      }),
      filter((v) => v)
    );
  }

  private adfsLogin() {
    this.envService.env$.subscribe((env: Env) => {
      // after location.reload(true)
      if (env.buildDate === environment.buildDate) {
        this.authTokenService.adfsLogin();
      } else {
        console.warn('Adfs not login, buildDate not correct.');
      }
    }, error => console.error('Adfs not login, env json got an error.', error));
  }

  private getMobilePortalKey(route: ActivatedRouteSnapshot) {
    return route.queryParamMap.get('mportal') || route.paramMap.get('mportal');
  }

}
