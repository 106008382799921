import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import {
  NavigationStart, Router, NavigationEnd, ActivationStart,
  ActivationEnd, ChildActivationEnd, ActivatedRoute, RoutesRecognized,
  GuardsCheckStart, ChildActivationStart, GuardsCheckEnd, ResolveStart, ResolveEnd
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthTokenService } from './core/service/auth-token.service';
import { JwtAuthObject } from './core/model/data-model';
import { SwUpdateService } from './core/service/sw-update.service';
import { environment } from '../environments/environment';
import { BaseComponent } from './shared/component/base/base.component';
import { LoggerService, LOGGER_LEVEL } from './core/service/logger.service';
import { EnvService } from './core/service';
import { MatomoInjector } from 'ngx-matomo';
import { MatomoTracker  } from 'ngx-matomo';

declare var window: any;
@Component({
  selector: 'doc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent extends BaseComponent implements OnInit, OnDestroy {
  title = 'app works!';
  lang = 'zh';
  constructor(
    private matomoInjector: MatomoInjector,
    private matomoTracker: MatomoTracker,
    private translate: TranslateService,
    private authTokenService: AuthTokenService,
    private swUpdateService: SwUpdateService,
    private router: Router,
    private envService: EnvService,
    private logger: LoggerService) {
    super();
  }

  ngOnInit() {
    // if (location.hostname.toLowerCase() === 'localhost') {
    //   // ckeditorJs
    //   const ckeditorJs = document.getElementById('ckeditorJs');
    //   ckeditorJs.setAttribute('src', 'https://drive.asus.com/ckeditor/4.13.0/ckeditor.js?d=' + (new Date().getTime()));
    // }
    // this.matomoInjector.init(environment.matomoUrl, environment.matomoSiteID);

    if (environment.production) {
      window['_console'] = window['console'];
      window['console'] = this.logger;
      this.logger.setLevel(LOGGER_LEVEL.INFO);
    } else {
      this.logger.setLevel(LOGGER_LEVEL.LOG);
    }
    if (!this.swUpdateService.checkServiceWorkerdisEnabled()) {
      console.info('service-worker is disabled 🙁');
    } else {
      console.info('service-worker is enabled');
      this.swUpdateService.checkAavailableUpdate();
    }
    this.envService.checkReload();
    this.router.events
      .subscribe(e => {
        // console.log('AppComponent this.router.events', e);
        if (e instanceof NavigationStart) {

        } else if (e instanceof RoutesRecognized) {

        } else if (e instanceof GuardsCheckStart) {

        } else if (e instanceof ChildActivationStart) {

        } else if (e instanceof ActivationStart) {
          const params = e.snapshot.params;
          console.log('AppComponent route.params', JSON.stringify(params));
          if (params['data']) {
            const data = this.dataDecode(params['data']);
            if ('contentOnly' in data) {
              sessionStorage.setItem('contentOnly', 'true');
            }
          } else if (params['mod']) {
            const mod = params['mod'];
            if (mod.toLocaleLowerCase() === 'content') {
              sessionStorage.setItem('contentOnly', 'true');
            }
          }
        } else if (e instanceof GuardsCheckEnd) {

        } else if (e instanceof ResolveStart) {

        } else if (e instanceof ResolveEnd) {
          // Child Component.....ngOnDestroy............

        } else if (e instanceof ActivationEnd) {

        } else if (e instanceof ChildActivationEnd) {

        } else if (e instanceof NavigationEnd) {

        }

      });


    if (localStorage.getItem('browserLang') == null) {
      localStorage.setItem('browserLang', this.lang);
    } else {
      this.lang = localStorage.getItem('browserLang');
    }
    this.translate.setDefaultLang(this.lang);
    window.onload = () => {
      const uicss = document.getElementById('uicss_lang');
      const tick = environment.buildDate.replace(/\W+/g, '');
      if (this.lang === 'zh') {
        uicss.setAttribute('href', 'assets/css/UI-zh.css?d=' + tick);
      } else {
        uicss.setAttribute('href', 'assets/css/UI-en.css?d=' + tick);
      }
    };
    // console.log('AppComponent.....ngOnInit');
  }

  ngOnDestroy() {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    console.log('AppComponent.....ngOnDestroy');
  }
}
