import { Component, OnInit } from '@angular/core';
import { JwtAuthObject, DocumentDto, DocumentFolder, User } from '../../../core/model/data-model';
import { getArchiveTypeCss, TypeCss, getFileExtension } from 'app/shared/common';

@Component({
  selector: 'doc-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.css'],
})
export class BaseComponent implements OnInit {

  constructor() {
  }

  dataEncode(data: any): string {
    // Encode the String
    try {
      const encodedString = encodeURIComponent(btoa(JSON.stringify(data)));
      // console.log('dataEncode', encodedString);
      return encodedString;
    } catch (error) {
      console.error('dataEncode', data);
      return '';
    }
  }

  dataDecode(data: string): any {
    // Decode the String
    try {
      if (!data) { return {}; }
      const decodedString = atob(decodeURIComponent(data));
      return JSON.parse(decodedString);
    } catch (error) {
      console.error('dataDecode', data);
      return {};
    }
  }

  ngOnInit() {

  }

  get currentUser() {
    if (localStorage.getItem('token') != null) {
      const token: JwtAuthObject = JSON.parse(localStorage.getItem('token'));
      return token.user;
    } else {
      return null;
    }
  }

  get currentUserToken() {
    if (localStorage.getItem('token') != null) {
      const token: JwtAuthObject = JSON.parse(localStorage.getItem('token'));
      return token.token;
    } else {
      return null;
    }
  }

  getTypeCss(data: DocumentDto | DocumentFolder): string {
    const folder = data as DocumentFolder;
    if (folder.folderName !== undefined) {
      return TypeCss.Folder;
    }
    const doc = data as DocumentDto;
    if (doc.documentName !== undefined) {
      if (doc.isArchive === '1') {
        // console.log('fname', fname);
        const fileExt = getFileExtension(doc.documentName).toLowerCase();
        return getArchiveTypeCss(fileExt);
      } else {
        return TypeCss.Document;
      }
    }
    return '';
  }

  getUserViewName(user: User): string {
    return `${user.userName} (${user.userEngName})`;
  }

}
