
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { RespMessage, FolderContent, HttpOptions, FolderContent2, DeleteOnePermissionVo } from '../model/data-model';
import { HttpClient } from '@angular/common/http';
import { FolderType } from '../../shared/common';
import { environment } from 'environments/environment';

@Injectable()
export class FolderService extends BaseService {

  constructor(private http: HttpClient) {
    super();
  }

  getPrivateUploadTempFolderId(httpOptions?: HttpOptions) {
    return this.http.put<RespMessage<number>>(`${environment.apiRoot}/api/Folder/UploadTempFolder`, {}, httpOptions);
  }

  getCreateFolder(vo: { folderId?: number, folderType: FolderType }) {
    return this.http.post<RespMessage<FolderContent>>(`${environment.apiRoot}/api/Folder/GetCreatefolder`, vo);
  }

  getCreateFolder2(vo: { folderId?: number, folderType: FolderType }) {
    return this.http.post<RespMessage<FolderContent2>>(`${environment.apiRoot}/api/Folder/GetCreatefolder2`, vo);
  }

  getEditFolder(vo: { folderId?: number, folderType: FolderType }) {
    return this.http.post<RespMessage<FolderContent>>(`${environment.apiRoot}/api/Folder/GetEditFolder`, vo);
  }

  getEditFolder2(vo: { folderId?: number, folderType: FolderType }) {
    return this.http.post<RespMessage<FolderContent2>>(`${environment.apiRoot}/api/Folder/GetEditFolder2`, vo);
  }

  add(vo: { folderId?: number, folderType: FolderType, folderContent: FolderContent }) {
    return this.http.post<RespMessage<FolderContent>>(`${environment.apiRoot}/api/Folder/Add/?folderId=${vo.folderId}&folderType=${vo.folderType}`, vo.folderContent);
  }

  add2(vo: { folderId?: number, folderType: FolderType, folderContent: FolderContent2 }) {
    return this.http.post<RespMessage<FolderContent2>>(`${environment.apiRoot}/api/Folder/Add2/?folderId=${vo.folderId}&folderType=${vo.folderType}`, vo.folderContent);
  }

  save(vo: { folderId?: number, folderType: FolderType, folderContent: FolderContent }) {
    return this.http.post<RespMessage<FolderContent>>(`${environment.apiRoot}/api/Folder/Save/?folderId=${vo.folderId}&folderType=${vo.folderType}`, vo.folderContent);
  }

  save2(vo: { folderId?: number, folderType: FolderType, folderContent: FolderContent2 }) {
    return this.http.post<RespMessage<FolderContent2>>(`${environment.apiRoot}/api/Folder/Save2/?folderId=${vo.folderId}&folderType=${vo.folderType}`, vo.folderContent);
  }

  saveWithPerm(vo: { folderId?: number, folderType: FolderType, folderContent: FolderContent2 }) {
    return this.http.post<RespMessage<FolderContent2>>(`${environment.apiRoot}/api/Folder/SaveWithPerm/?folderId=${vo.folderId}&folderType=${vo.folderType}`, vo.folderContent);
  }

  delete(vo: { folderId?: number, folderType: FolderType }) {
    return this.http.post<RespMessage<boolean>>(`${environment.apiRoot}/api/Folder/Delete`, vo);
  }

  deleteAll(vo: { folderId?: number, folderType: FolderType }) {
    return this.http.post<RespMessage<boolean>>(`${environment.apiRoot}/api/Folder/DeleteAll`, vo);
  }

  addFavorite(vo: { folderId?: number, folderType: FolderType, docId: number }) {
    return this.http.post<RespMessage<FolderContent>>(`${environment.apiRoot}/api/Favorite/Add/?folderId=${vo.folderId}&folderType=${vo.folderType}`, vo);
  }

  moveFavorite(vo: { folderId?: number, folderType: FolderType, favId: number }) {
    return this.http.post<RespMessage<FolderContent>>(`${environment.apiRoot}/api/Favorite/MoveFolder/?folderId=${vo.folderId}&folderType=${vo.folderType}`, vo);
  }

  deleteFavorite(vo: { folderId?: number, folderType: FolderType, favId: number }) {
    return this.http.post<RespMessage<FolderContent>>(`${environment.apiRoot}/api/Favorite/Delete/?folderId=${vo.folderId}&folderType=${vo.folderType}`, vo);
  }

  deleteFavoriteByDocId(vo: { docId: number }) {
    return this.http.post<RespMessage<FolderContent>>(`${environment.apiRoot}/api/Favorite/DeleteByDocId/`, vo);
  }

  deleteOnePermission(vo: DeleteOnePermissionVo) {
    return this.http.post<RespMessage<boolean>>(`${environment.apiRoot}/api/Folder/DeleteOnePermission`, vo);
  }
}
