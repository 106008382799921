<div class="popbg-fill va-center {{popCss}}">
  <div class="pop-box pop-size-450">
    <div class="modal-content">
      <button class="close marg4-t marg4-r"><i class="mdi mdi-close"></i></button>
      <div class="modal-body text-center">
        <h1 class="text-danger mdi mdi-alert"></h1>
        <h4 class="marg10-b">為提供您更良好的使用體驗<br>建議您使用Chorme瀏覽器</h4>
        <p class="text-muted">使用 IE 瀏覽器，會於上傳檔案時出現異常</p>
      </div>
    </div>
  </div>
</div>