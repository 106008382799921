import { Component, OnInit, NgZone, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthTokenService } from '../core/service/auth-token.service';
import { newGuid, initCommonJs, loadingShow } from '../shared/common';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../environments/environment';

@Component({
  selector: 'doc-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, AfterViewInit {

  account = { domain: '', userID: '', password: '', captcha: '' };
  uuid = '';
  captchaUrl = 'api/captcha';
  errorMag = '';
  uselang = '';
  now = new Date().getTime();

  constructor(private translate: TranslateService,
    private authTokenService: AuthTokenService, private zone: NgZone) {
    this.uuid = newGuid();
    this.captchaUrl = `${environment.apiRoot}/api/captcha?uuid=${this.uuid}`;
  }

  ngOnInit() {
    this.uselang = localStorage.getItem('browserLang');
    if (this.uselang == null) {
      this.uselang = 'zh';
      localStorage.setItem('browserLang', this.uselang);
    }
  }

  getAllHeaders() {
    const req = new XMLHttpRequest();
    req.open('GET', location.href, false);
    req.send(null);
    const headers = req.getAllResponseHeaders().toLowerCase();
    const allheaders = headers.split(/\n|\r|\r\n/g).reduce(function (a, b) {
      if (b.length) {
        const [key, value] = b.split(': ');
        a[key] = value;
      }
      return a;
    }, {});

    console.log(allheaders);
  }

  ngAfterViewInit() {
    this.zone.runOutsideAngular(() => {
      initCommonJs();
    });
  }

  login({ valid }: { value: Object, valid: boolean }) {
    if (valid) {
      loadingShow('loading....');
      this.authTokenService.login(this.account.domain, this.account.userID, this.account.password, this.account.captcha, this.uuid);
    }
  }

  ssoLogin() {
    loadingShow('loading....');
    try {
      if (environment.enabledAdfs) {
        this.authTokenService.adfsLogin();
      } else {
        this.authTokenService.ssoLogin();
      }
    } catch (error) {
      console.log(error);
    }
  }

  refreshCaptcha() {
    this.now = new Date().getTime();
  }

  changeLang(lang) {
    this.uselang = lang;
    localStorage.setItem('browserLang', this.uselang);
    this.translate.setDefaultLang(this.uselang);
    this.translate.use(this.uselang);
    const uicss = document.getElementById('uicss_lang');
    const tick = environment.buildDate.replace(/\W+/g, '');
    if (this.uselang === 'zh') {
      uicss.setAttribute('href', 'assets/css/UI-zh.css?d=' + tick);
    } else {
      uicss.setAttribute('href', 'assets/css/UI-en.css?d=' + tick);
    }
  }

}
